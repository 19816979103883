/**
 * SoundManager class for managing game audio
 * Handles sound effects and background music with separate volume controls
 * Provides global on/off functionality and music track switching
 */
export class SoundManager {
    /**
     * Constructor for SoundManager
     * @param {Object} options - Configuration options
     * @param {string} [options.soundPath='assets/sounds/'] - Path to sound files
     * @param {Array<string>} [options.defaultSounds=[]] - Default sounds to preload at initialization
     * @param {boolean} [options.startMuted=false] - Whether to start with sound muted until user interaction (default: false)
     */
    constructor(options = {}) {
      // Configuration options with defaults
  // Configuration options with defaults
  const {
    soundPath = 'assets/sounds/',
    defaultSounds = [],
    startMuted = false,
    prioritySounds = [] // New option: Sounds that must be loaded before game starts
  } = options;
      
      // Main audio settings
      this.enabled = !startMuted; // Start with sound enabled by default
      this.soundEffectsVolume = 1.0;
      this.backgroundMusicVolume = 0.3;
      this._audioReady = false; // Flag to track if audio is ready to play
      
      // Music system state
      this.musicEnabled = true; // Track music enabled/disabled separately
      
      // Sound collections
      this.soundEffects = {};
      this.backgroundMusic = null;
      
      // NEW: Track storage for multiple music tracks
      this.musicTracks = {};
      this.currentTrackName = null;
      
      // Path to sound assets
      this.soundPath = soundPath;
      
      // Audio context
      this.audioContext = null;
      
      // Initialize audio system
      this.initAudioContext();
      
  // Sound loading queue and state
  this.soundLoadQueue = [];
  this.loadingInProgress = false;
  
  // Preload only priority sounds immediately if provided
  if (prioritySounds.length > 0) {
    this.preloadPrioritySounds(prioritySounds);
  }
  
  // Queue the rest of the sounds for background loading
  if (defaultSounds.length > 0) {
    this.queueSoundsForLoading(defaultSounds);
  }
      
      // Set up event listeners to enable audio on first user interaction
      this.setupAutoEnableAudio();
      
      // Set up UI controls for sound and music
      this.setupSoundControls();
    }
    
    /**
     * Initialize the Web Audio API context
     */
    initAudioContext() {
      try {
        // Create audio context (with fallback for older browsers)
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        this.audioContext = new AudioContext({
          latencyHint: 'playback',  // Optimize for playback quality over latency
          sampleRate: 44100         // Standard CD-quality sample rate
        });

        // Web Audio API starts in suspended state in many browsers
        // We'll use this to our advantage - this way we can preload sounds
        // but not actually play anything until user interaction
        
        // Create main volume controls (gain nodes)
        this.masterGain = this.audioContext.createGain();
        this.effectsGain = this.audioContext.createGain();
        this.musicGain = this.audioContext.createGain();
        
        // Connect the gain nodes
        this.effectsGain.connect(this.masterGain);
        this.musicGain.connect(this.masterGain);
        this.masterGain.connect(this.audioContext.destination);
        
        // Set initial volumes
        this.effectsGain.gain.value = this.enabled ? this.soundEffectsVolume : 0;
        this.musicGain.gain.value = this.musicEnabled ? this.backgroundMusicVolume : 0;
        this.masterGain.gain.value = (this.enabled || this.musicEnabled) ? 1 : 0;
        
        console.log(`Audio context initialized successfully (state: ${this.audioContext.state})`);
      } catch (error) {
        console.error('Failed to initialize audio context:', error);
      }
    }
    
    /**
 * Preload only the highest priority sounds before game starts
 * @param {Array<string>} soundNames - Array of critical sound names to preload immediately
 * @returns {Promise} - A promise that resolves when priority sounds are loaded
 */
async preloadPrioritySounds(soundNames) {
  console.log(`Preloading ${soundNames.length} priority sounds...`);
  
  const loadPromises = soundNames.map(name => this.loadSound(name));
  
  try {
    await Promise.all(loadPromises);
    console.log('All priority sounds loaded successfully');
    
    // Optionally dispatch a simple event when priority sounds are loaded
    document.dispatchEvent(new CustomEvent('prioritySoundsLoaded'));
    
    return true;
  } catch (error) {
    console.error('Error preloading priority sounds:', error);
    return false;
  }
}

/**
 * Queue sounds for background loading
 * @param {Array<string>} soundNames - Array of sound names to queue
 */
queueSoundsForLoading(soundNames) {
  // Add sounds to the queue if they're not already loaded
  soundNames.forEach(name => {
    if (!this.soundEffects[name] && !this.soundLoadQueue.includes(name)) {
      this.soundLoadQueue.push(name);
    }
  });
  
  // Start background loading if not already in progress
  if (!this.loadingInProgress) {
    this.startBackgroundLoading();
  }
}
/**
 * Start or continue background loading process
 */
startBackgroundLoading() {
  if (this.soundLoadQueue.length === 0 || this.loadingInProgress) {
    return;
  }
  
  this.loadingInProgress = true;
  
  // Process the queue
  this.processNextInQueue();
}
    /**
     * Set up event listeners to enable audio on first user interaction
     */
    setupAutoEnableAudio() {
      // List of events that indicate user interaction
      const userInteractionEvents = [
        'click', 'touchstart', 'keydown', 'touchend',
        'pointerdown', 'mousedown'
      ];
      
      // One-time event handler for first interaction
      const enableAudioOnFirstInteraction = () => {
        // Only run once
        if (this._audioReady) return;
        
        // Try to resume the audio context if it's suspended
        if (this.audioContext && this.audioContext.state === 'suspended') {
          this.audioContext.resume().then(() => {
            this._audioReady = true;
            
            // Remove all event listeners since we don't need them anymore
            userInteractionEvents.forEach(eventType => {
              document.removeEventListener(eventType, enableAudioOnFirstInteraction, true);
            });
            
            // Dispatch an event to notify the game that audio is now available
            const audioReadyEvent = new CustomEvent('audioready', { detail: { manager: this } });
            document.dispatchEvent(audioReadyEvent);
          });
        }
      };
      
      // Add all the event listeners
      userInteractionEvents.forEach(eventType => {
        document.addEventListener(eventType, enableAudioOnFirstInteraction, true);
      });
      
    }
    
    /**
     * Check if the audio system is ready to play sounds
     * @returns {boolean} - Whether the audio context is running and ready
     */
    isAudioReady() {
      return this.audioContext && this.audioContext.state === 'running';
    }
    
    /**
     * Load a sound effect and store it in the collection
     * @param {string} name - The identifier for the sound
     * @returns {Promise} - A promise that resolves when the sound is loaded
     */
    loadSound(name) {
      return new Promise((resolve, reject) => {
        // Create the full path to the sound file
        const url = `${this.soundPath}${name}.mp3`;
        
        // Fetch the audio file
        fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error(`Failed to load sound: ${url}`);
            }
            return response.arrayBuffer();
          })
          .then(arrayBuffer => this.audioContext.decodeAudioData(arrayBuffer))
          .then(audioBuffer => {
            // Store the decoded audio buffer
            this.soundEffects[name] = audioBuffer;
            resolve();
          })
          .catch(error => {
            console.error(`Error loading sound "${name}":`, error);
            reject(error);
          });
      });
    }
    

/**
 * Process the next sound in the loading queue
 */
processNextInQueue() {
  if (this.soundLoadQueue.length === 0) {
    this.loadingInProgress = false;
    console.log('All background sounds loaded successfully');
    
    // Optionally dispatch a simple event when all sounds are loaded
    document.dispatchEvent(new CustomEvent('allSoundsLoaded'));
    
    return;
  }
  
  // Get the next sound to load
  const nextSound = this.soundLoadQueue.shift();
  
  // Load the sound
  this.loadSound(nextSound)
    .then(() => {
      // Continue with next sound after a short delay (to avoid blocking the main thread)
      setTimeout(() => this.processNextInQueue(), 10);
    })
    .catch(error => {
      console.error(`Error loading sound "${nextSound}":`, error);
      // Continue with next sound even if this one failed
      setTimeout(() => this.processNextInQueue(), 10);
    });
}

/**
 * Check if a specific sound is loaded
 * @param {string} name - The name of the sound to check
 * @returns {boolean} - Whether the sound is loaded
 */
isSoundLoaded(name) {
  return !!this.soundEffects[name];
}

// Modified preloadSounds method to use the new queue system
/**
 * Preload multiple sound effects
 * @param {Array<string>} soundNames - Array of sound names to preload
 * @param {boolean} [background=true] - Whether to load in background or wait for completion
 * @returns {Promise} - A promise that resolves when all sounds are loaded (if background=false)
 */
async preloadSounds(soundNames, background = true) {
  if (background) {
    // Add to background loading queue
    this.queueSoundsForLoading(soundNames);
    return Promise.resolve();
  } else {
    // Load immediately and wait for completion
    const loadPromises = soundNames.map(name => this.loadSound(name));
    return Promise.all(loadPromises)
      .then(() => {
        console.log('All sounds preloaded successfully');
        return true;
      })
      .catch(error => {
        console.error('Error preloading sounds:', error);
        return false;
      });
  }
}
    
    /**
     * Play a sound effect with optional delay
     * @param {string} name - The name of the sound to play
     * @param {Object} [options] - Options for playing the sound
     * @param {number} [options.volume=1.0] - Volume override for this specific sound play (0.0 to 1.0)
     * @param {number} [options.delay=0] - Delay in milliseconds before playing the sound
     * @returns {AudioBufferSourceNode|null} - The source node or null if sound cannot be played
     */
    playSound(name, options = {}) {
      // Default options
      const { volume = 1.0, delay = 0 } = typeof options === 'number' 
        ? { volume: options, delay: 0 } // For backward compatibility
        : options;
        
      // Check if sound system is enabled and the sound exists
      if (!this.enabled || !this.soundEffects[name]) {
        return null;
      }
      
      try {
        // If audio context is suspended, we'll resume it but with a warning
        // This shouldn't normally happen because of our auto-enable system
        if (this.audioContext.state === 'suspended') {
          console.warn('Audio context still suspended during playSound. Auto-enable may not have fired yet.');
          this.audioContext.resume();
        }
        
        // Create a source node
        const source = this.audioContext.createBufferSource();
        source.buffer = this.soundEffects[name];
        
        // Create a gain node for this specific sound's volume
        const gainNode = this.audioContext.createGain();
        gainNode.gain.value = volume;
        
        // Connect the nodes
        source.connect(gainNode);
        gainNode.connect(this.effectsGain);
        
        // Calculate start time with delay
        const startTime = delay > 0 
          ? this.audioContext.currentTime + (delay / 1000) // Convert ms to seconds
          : 0; // 0 means "now"
        
        // Play the sound only if context is running
        if (this.audioContext.state === 'running') {
          source.start(startTime);
          
          return source;
        } else {
          return null;
        }
      } catch (error) {
        console.error(`Error playing sound "${name}":`, error);
        return null;
      }
    }
    
    /**
     * Load and play background music on repeat with optional fade-in
     * @param {string} name - The name of the background music track
     * @param {number} [fadeInTime=0] - Time in milliseconds to fade in (0 for immediate)
     * @param {boolean} [forceRestart=false] - Whether to restart the track if it's already loaded
     * @returns {Audio} - The Audio element for this track
     */
    playBackgroundMusic(name, fadeInTime = 0, forceRestart = false) {
      // If this track is already playing, no need to do anything
      if (this.currentTrackName === name && !forceRestart) {
        return this.backgroundMusic;
      }
      
      // First fade out the current music if any
      this.pauseCurrentMusic(fadeInTime).then(() => {
        // After fadeout is complete, start the new track only if music is enabled
        if (this.musicEnabled) {
          this._playOrResumeTrack(name, fadeInTime);
        } else {
          // Just load the track but don't play it
          if (!this.musicTracks[name]) {
            this._loadNewMusicTrack(name, 0);
          }
          // Set as current track, but don't play
          this.backgroundMusic = this.musicTracks[name]?.audio || null;
          this.currentTrackName = name;
        }
      });
      
      return this.musicTracks[name]?.audio || null;
    }
    
    /**
     * Internal method to play a new track or resume an existing one
     * @param {string} name - The name of the track to play or resume
     * @param {number} fadeInTime - Fade in time in milliseconds
     * @private
     */
    _playOrResumeTrack(name, fadeInTime) {
      const track = this.musicTracks[name];
      
      // If we already have this track loaded
      if (track) {
        // Set as the current active track
        this.backgroundMusic = track.audio;
        this.currentTrackName = name;
        
        // If music is disabled, don't actually play the track
        if (!this.musicEnabled) {
          return;
        }
        
        // Apply fade in if requested
        if (fadeInTime > 0) {
          this.musicGain.gain.value = 0;
          
          // Play the music
          track.audio.play().then(() => {
            track.isPlaying = true;
            
            // Use linearRampToValueAtTime for more reliable fading
            this.musicGain.gain.setValueAtTime(0, this.audioContext.currentTime);
            this.musicGain.gain.linearRampToValueAtTime(
              this.backgroundMusicVolume,
              this.audioContext.currentTime + (fadeInTime / 1000)
            );

          }).catch(error => {
            console.error(`Error resuming background music "${name}":`, error);
          });
        } else {
          // No fade, just set the volume and play
          this.musicGain.gain.value = this.backgroundMusicVolume;
          
          track.audio.play().then(() => {
            track.isPlaying = true;

          }).catch(error => {
            console.error(`Error resuming background music "${name}":`, error);
          });
        }
      } else {
        // Track doesn't exist yet, create it
        this._loadNewMusicTrack(name, fadeInTime);
      }
    }
    
    /**
     * Load a new music track that hasn't been loaded before
     * @param {string} name - The name of the track to load
     * @param {number} fadeInTime - Fade in time in milliseconds
     * @private
     */
    _loadNewMusicTrack(name, fadeInTime) {
      // Create the full path to the music file
      const url = `${this.soundPath}${name}.mp3`;
      
      // Create an audio element for background music
      const audio = new Audio(url);
      audio.loop = true;
      
      // Store the audio element in our tracks collection
      this.musicTracks[name] = {
        audio,
        source: null, // Will be created when connected to Web Audio API
        isPlaying: false
      };
      
      // Set as current active track
      this.backgroundMusic = audio;
      this.currentTrackName = name;
      
      // Connect it to the Web Audio API for volume control
      const source = this.audioContext.createMediaElementSource(audio);
      source.connect(this.musicGain);
      this.musicTracks[name].source = source;
      
      // If music is disabled, don't play the track
      if (!this.musicEnabled) {
        return;
      }
      
      // If fade-in is requested, start volume at 0
      if (fadeInTime > 0) {
        this.musicGain.gain.value = 0;
        
        // Play the music
        audio.play().then(() => {
          this.musicTracks[name].isPlaying = true;
          
          // Use linearRampToValueAtTime for more reliable fading
          this.musicGain.gain.setValueAtTime(0, this.audioContext.currentTime);
          this.musicGain.gain.linearRampToValueAtTime(
            this.backgroundMusicVolume,
            this.audioContext.currentTime + (fadeInTime / 1000)
          );

        }).catch(error => {
          console.error(`Error playing background music "${name}":`, error);
        });
      } else {
        // No fade, just set the volume and play
        this.musicGain.gain.value = this.backgroundMusicVolume;
        
        audio.play().then(() => {
          this.musicTracks[name].isPlaying = true;

        }).catch(error => {
          console.error(`Error playing background music "${name}":`, error);
        });
      }
    }
    
    /**
     * Pause the currently playing background music with optional fade-out
     * @param {number} [fadeOutTime=0] - Time in milliseconds to fade out (0 for immediate)
     * @returns {Promise} - A promise that resolves when fadeout is complete
     */
    pauseCurrentMusic(fadeOutTime = 0) {
      return new Promise((resolve) => {
        if (!this.backgroundMusic || !this.currentTrackName) {
          resolve();
          return;
        }
        
        const currentTrack = this.musicTracks[this.currentTrackName];
        
        if (fadeOutTime > 0 && this.enabled && currentTrack && currentTrack.isPlaying) {
          // Get current volume
          const startVolume = this.musicGain.gain.value;
          
          // Instead of setTargetAtTime, use linearRampToValueAtTime for more reliable fading
          this.musicGain.gain.setValueAtTime(startVolume, this.audioContext.currentTime);
          this.musicGain.gain.linearRampToValueAtTime(
            0,
            this.audioContext.currentTime + (fadeOutTime / 1000)
          );
          
          // Pause after fade completes but keep the track in our collection
          setTimeout(() => {
            if (currentTrack && currentTrack.audio) {
              currentTrack.audio.pause();
              currentTrack.isPlaying = false;
              
              // Reset the gain to normal for next music
              this.musicGain.gain.cancelScheduledValues(this.audioContext.currentTime);
              this.musicGain.gain.setValueAtTime(this.backgroundMusicVolume, this.audioContext.currentTime);
              
              // Set the current track to null but don't remove from our collection
              this.backgroundMusic = null;
              
              const pausedTrackName = this.currentTrackName;
              this.currentTrackName = null;
              
              // Trigger an event in case the game wants to know about music state changes
              const musicPausedEvent = new CustomEvent('musicpaused', { 
                detail: { trackName: pausedTrackName, position: currentTrack.audio.currentTime } 
              });
              document.dispatchEvent(musicPausedEvent);
            }
            resolve();
          }, fadeOutTime);
        } else {
          // No fade, just pause immediately
          if (currentTrack && currentTrack.audio) {
            currentTrack.audio.pause();
            currentTrack.isPlaying = false;
            
            // Trigger an event in case the game wants to know about music state changes
            const musicPausedEvent = new CustomEvent('musicpaused', { 
              detail: { trackName: this.currentTrackName, position: currentTrack.audio.currentTime } 
            });
            document.dispatchEvent(musicPausedEvent);
                        
            this.backgroundMusic = null;
            this.currentTrackName = null;
          }
          resolve();
        }
      });
    }
    
    /**
     * Get the playback position of a specific music track
     * @param {string} name - The name of the track to check
     * @returns {number} - Current position in seconds, or -1 if the track is not loaded
     */
    getMusicPosition(name) {
      if (this.musicTracks[name] && this.musicTracks[name].audio) {
        return this.musicTracks[name].audio.currentTime;
      }
      return -1; // Track not found
    }
    
    /**
     * Check if a specific music track is currently playing
     * @param {string} name - The name of the track to check
     * @returns {boolean} - Whether the track is currently playing
     */
    isMusicPlaying(name) {
      return this.currentTrackName === name && 
             this.musicTracks[name] && 
             this.musicTracks[name].isPlaying;
    }
    
    /**
     * Switch immediately to another track with crossfade
     * @param {string} name - The name of the track to switch to
     * @param {number} crossfadeTime - Time in milliseconds for the crossfade
     */
    crossfadeTo(name, crossfadeTime = 1000) {
      // If the requested track is already playing, do nothing
      if (this.currentTrackName === name) {
        return;
      }
      
      // If music is disabled, just update the track name but don't play
      if (!this.musicEnabled) {
        // Store a reference to the old track
        const oldTrackName = this.currentTrackName;
        const oldTrack = this.musicTracks[oldTrackName];
        
        // Prepare the new track but don't play it
        if (!this.musicTracks[name]) {
          this._loadNewMusicTrack(name, 0);
        }
        
        const newTrack = this.musicTracks[name];
        
        // Set the new track as current
        this.backgroundMusic = newTrack.audio;
        this.currentTrackName = name;
        return;
      }
      
      // Store a reference to the old track name and audio
      const oldTrackName = this.currentTrackName;
      const oldTrack = this.musicTracks[oldTrackName];
      
      if (!oldTrack || !oldTrack.audio) {
        // If there's no current track, just play the new one
        this._playOrResumeTrack(name, crossfadeTime);
        return;
      }
      
      // Prepare the new track but don't connect it to the main gain yet
      if (!this.musicTracks[name]) {
        this._loadNewMusicTrack(name, 0);
      }
      
      const newTrack = this.musicTracks[name];
      
      // Save current volume value
      const currentVolume = this.musicGain.gain.value;
      
      // Set the new track as current
      this.backgroundMusic = newTrack.audio;
      this.currentTrackName = name;
      
      // Start with volume at 0
      this.musicGain.gain.value = 0;
      
      // Play the new track
      newTrack.audio.play().then(() => {
        newTrack.isPlaying = true;
        
        // Fade in the new track
        this.musicGain.gain.setValueAtTime(0, this.audioContext.currentTime);
        this.musicGain.gain.linearRampToValueAtTime(
          this.backgroundMusicVolume, 
          this.audioContext.currentTime + (crossfadeTime / 1000)
        );
        
        // Create a separate gain node for the old track
        const oldTrackGain = this.audioContext.createGain();
        const oldTrackSource = this.audioContext.createMediaElementSource(oldTrack.audio);
        
        // Connect the old track to its own gain node and then to destination
        oldTrackSource.connect(oldTrackGain);
        oldTrackGain.connect(this.audioContext.destination);
        
        // Set initial gain for old track
        oldTrackGain.gain.setValueAtTime(currentVolume, this.audioContext.currentTime);
        
        // Fade out the old track
        oldTrackGain.gain.linearRampToValueAtTime(
          0,
          this.audioContext.currentTime + (crossfadeTime / 1000)
        );
        
        // After the crossfade, pause the old track
        setTimeout(() => {
          if (this.musicTracks[oldTrackName] && this.musicTracks[oldTrackName].audio) {
            this.musicTracks[oldTrackName].audio.pause();
            this.musicTracks[oldTrackName].isPlaying = false;
            
            // Disconnect the old track's special gain node to clean up
            try {
              oldTrackSource.disconnect();
              oldTrackGain.disconnect();
            } catch (e) {
              console.warn("Error disconnecting old track nodes:", e);
            }
          }
        }, crossfadeTime);
        
      }).catch(error => {
        console.error(`Error during crossfade to "${name}":`, error);
      });
    }
    
    /**
     * Stop and remove a specific background music track
     * @param {string} name - The name of the track to stop and remove
     * @param {number} [fadeOutTime=0] - Time in milliseconds to fade out (0 for immediate)
     * @returns {Promise} - A promise that resolves when the operation is complete
     */
    stopAndRemoveTrack(name, fadeOutTime = 0) {
      return new Promise((resolve) => {
        if (!this.musicTracks[name]) {
          resolve();
          return;
        }
        
        const track = this.musicTracks[name];
        
        // If this is the current track, handle it specially
        if (this.currentTrackName === name) {
          this.pauseCurrentMusic(fadeOutTime).then(() => {
            // After fadeout, dispose of the track completely
            delete this.musicTracks[name];
            resolve();
          });
        } else {
          // If it's not the current track, just stop and remove it immediately
          if (track.audio) {
            track.audio.pause();
            track.audio.currentTime = 0;
          }
          delete this.musicTracks[name];
          resolve();
        }
      });
    }
    
    /**
     * Stop all background music with optional fade-out and clear the tracks collection
     * @param {number} [fadeOutTime=0] - Time in milliseconds to fade out (0 for immediate)
     * @returns {Promise} - A promise that resolves when fadeout is complete
     */
    stopAllMusic(fadeOutTime = 0) {
      return new Promise((resolve) => {
        // First pause current music with fadeout if requested
        this.pauseCurrentMusic(fadeOutTime).then(() => {
          // Then clear all tracks
          for (const trackName in this.musicTracks) {
            if (this.musicTracks[trackName] && this.musicTracks[trackName].audio) {
              this.musicTracks[trackName].audio.pause();
              this.musicTracks[trackName].audio.currentTime = 0;
            }
          }
          
          // Clear the collections
          this.musicTracks = {};
          this.backgroundMusic = null;
          this.currentTrackName = null;
          
          resolve();
        });
      });
    }
    
    /**
     * Set background music volume
     * @param {number} volume - Volume level (0.0 to 1.0)
     */
    setMusicVolume(volume) {
      // Clamp volume between 0 and 1
      this.backgroundMusicVolume = Math.max(0, Math.min(1, volume));
      this.musicGain.gain.value = this.backgroundMusicVolume;
    }
    
    /**
     * Set sound effects volume
     * @param {number} volume - Volume level (0.0 to 1.0)
     */
    setSoundEffectsVolume(volume) {
      // Clamp volume between 0 and 1
      this.soundEffectsVolume = Math.max(0, Math.min(1, volume));
      this.effectsGain.gain.value = this.soundEffectsVolume;
    }
    
    /**
     * Setup UI controls for sound and music toggle
     */
    setupSoundControls() {
      // Find the music and sound toggle buttons
      const musicToggle = document.getElementById('music');
      const soundToggle = document.getElementById('sound');
      
      if (musicToggle) {
        // Set initial state based on musicEnabled property
        if (!this.musicEnabled) {
          musicToggle.classList.add('off');
        } else {
          musicToggle.classList.remove('off');
        }
        
        // Add click handler for music toggle
        musicToggle.addEventListener('click', () => {
          this.toggleMusic();
          
          // Toggle the visual state of the button
          if (this.musicEnabled) {
            musicToggle.classList.remove('off');
          } else {
            musicToggle.classList.add('off');
          }
        });
      }
      
      if (soundToggle) {
        // Set initial state based on enabled property
        if (!this.enabled) {
          soundToggle.classList.add('off');
        } else {
          soundToggle.classList.remove('off');
        }
        
        // Add click handler for sound toggle
        soundToggle.addEventListener('click', () => {
          this.toggleSound();
          
          // Toggle the visual state of the button
          if (this.enabled) {
            soundToggle.classList.remove('off');
          } else {
            soundToggle.classList.add('off');
          }
        });
      }
      
      // Debug the initial states
      console.log(`Initial sound state: ${this.enabled ? 'enabled' : 'disabled'}`);
      console.log(`Initial music state: ${this.musicEnabled ? 'enabled' : 'disabled'}`);
    }
    
    /**
     * Toggle all sound effects on/off
     * @returns {boolean} - The new enabled state
     */
    toggleSound() {
      this.enabled = !this.enabled;
      
      // Update sound effects gain to instantly mute/unmute all sound effects
      this.effectsGain.gain.value = this.enabled ? this.soundEffectsVolume : 0;
      
      // We also need to update master gain for some operations that use it
      this.masterGain.gain.value = (this.enabled || this.musicEnabled) ? 1 : 0;
      
      console.log(`Sound effects ${this.enabled ? 'enabled' : 'disabled'}`);
      return this.enabled;
    }
    
    /**
     * Toggle music on/off
     * @returns {boolean} - The new enabled state
     */
    toggleMusic() {
      this.musicEnabled = !this.musicEnabled;
      
      // Update music gain to instantly mute/unmute all music
      this.musicGain.gain.value = this.musicEnabled ? this.backgroundMusicVolume : 0;
      
      // We also need to update master gain for some operations that use it
      this.masterGain.gain.value = (this.enabled || this.musicEnabled) ? 1 : 0;
      
      // Handle background music separate from the gain
      for (const trackName in this.musicTracks) {
        const track = this.musicTracks[trackName];
        if (track && track.audio) {
          if (this.musicEnabled && trackName === this.currentTrackName) {
            track.audio.play().catch(e => console.error(`Error resuming background music "${trackName}":`, e));
            track.isPlaying = true;
          } else if (!this.musicEnabled && track.isPlaying) {
            track.audio.pause();
            track.isPlaying = false;
          }
        }
      }
      
      console.log(`Music ${this.musicEnabled ? 'enabled' : 'disabled'}`);
      return this.musicEnabled;
    }
    
    /**
     * Set sound enabled/disabled state directly
     * @param {boolean} state - Whether sound should be enabled (true) or disabled (false)
     */
    setSoundEnabled(state) {
      if (this.enabled !== state) {
        this.toggleSound();
      }
    }
    
    /**
     * Set music enabled/disabled state directly
     * @param {boolean} state - Whether music should be enabled (true) or disabled (false)
     */
    setMusicEnabled(state) {
      if (this.musicEnabled !== state) {
        this.toggleMusic();
      }
    }
    
    /**
     * Get a list of all currently loaded music tracks
     * @returns {Array<Object>} - Array of track info objects
     */
    getLoadedMusicTracks() {
      const tracks = [];
      for (const trackName in this.musicTracks) {
        const track = this.musicTracks[trackName];
        tracks.push({
          name: trackName,
          isPlaying: track.isPlaying,
          position: track.audio ? track.audio.currentTime : 0,
          duration: track.audio ? track.audio.duration : 0,
          isCurrentTrack: trackName === this.currentTrackName
        });
      }
      return tracks;
    }
    
    /**
     * Update background music volume based on 3D distance
     * @param {number} distance - Distance from listener to sound source
     * @param {Object} [options] - Configuration options
     * @param {number} [options.minDistance=1] - Distance at which volume is maximum
     * @param {number} [options.maxDistance=100] - Distance at which volume reaches minimum
     * @param {number} [options.minVolume=0.05] - Minimum volume (at maxDistance)
     * @param {number} [options.maxVolume=1.0] - Maximum volume (at minDistance)
     * @param {Function} [options.falloffFn] - Custom falloff function (defaults to inverse distance)
     */
    updateMusicVolumeByDistance(distance, options = {}) {
      // Default parameters for spatial audio
      const {
        minDistance = 1,
        maxDistance = 100,
        minVolume = 0.05,
        maxVolume = 1.0,
        falloffFn = null
      } = options;
      
      // Clamp distance between min and max
      const clampedDistance = Math.max(minDistance, Math.min(maxDistance, distance));
      
      // Calculate falloff ratio (0 to 1)
      let falloffRatio;
      
      if (falloffFn && typeof falloffFn === 'function') {
        // Use custom falloff function if provided
        falloffRatio = falloffFn(clampedDistance, minDistance, maxDistance);
      } else {
        // Default: Inverse distance falloff (1/r)
        // Normalized to range 0-1 where 0 is maxDistance and 1 is minDistance
        falloffRatio = 1 - ((clampedDistance - minDistance) / (maxDistance - minDistance));
      }
      
      // Calculate final volume (lerp between min and max volume)
      const volume = minVolume + falloffRatio * (maxVolume - minVolume);
      
      // Apply the volume - ONLY to background music, not sound effects
      this.setMusicVolume(volume);
      
      return volume;
    }
    
    /**
     * Provides several audio falloff functions that can be used with updateMusicVolumeByDistance
     */
    static FalloffFunctions = {
      // Linear falloff
      LINEAR: (distance, minDistance, maxDistance) => {
        return 1 - ((distance - minDistance) / (maxDistance - minDistance));
      },
      
      // Inverse falloff (1/r)
      INVERSE: (distance, minDistance, maxDistance) => {
        // Normalized inverse distance
        return (1 / distance - 1 / maxDistance) / (1 / minDistance - 1 / maxDistance);
      },
      
      // Inverse square falloff (1/r²) - more physically accurate
      INVERSE_SQUARE: (distance, minDistance, maxDistance) => {
        // Normalized inverse square distance
        const distSquared = distance * distance;
        const minDistSquared = minDistance * minDistance;
        const maxDistSquared = maxDistance * maxDistance;
        return (1 / distSquared - 1 / maxDistSquared) / (1 / minDistSquared - 1 / maxDistSquared);
      },
      
      // Exponential falloff
      EXPONENTIAL: (distance, minDistance, maxDistance) => {
        return Math.pow(0.5, (distance - minDistance) / (maxDistance - minDistance) * 5);
      }
    }
  }

  /**
 * NullSoundManager class - implements the SoundManager interface but does nothing
 * Use this for mobile devices to completely disable audio functionality
 */
export class NullSoundManager {
  /**
   * Constructor for NullSoundManager
   * @param {Object} options - Configuration options (ignored in null implementation)
   */
  constructor(options = {}) {
    // Set properties to default values
    this.enabled = false;
    this.musicEnabled = false;
    this.soundEffectsVolume = 0;
    this.backgroundMusicVolume = 0;
    this._audioReady = false;
    this.soundEffects = {};
    this.backgroundMusic = null;
    this.musicTracks = {};
    this.currentTrackName = null;
    this.soundPath = '';
    
    console.log('NullSoundManager: Audio system disabled for mobile');
    
    // Setup UI controls to "off" state
    this.setupSoundControls();
  }
  
  /**
   * Initialize the Web Audio API context (does nothing in null implementation)
   */
  initAudioContext() {}
  
  /**
   * Set up event listeners for audio (does nothing in null implementation)
   */
  setupAutoEnableAudio() {}
  
  /**
   * Check if the audio system is ready (always false in null implementation)
   * @returns {boolean} - Always false
   */
  isAudioReady() {
    return false;
  }
  
  /**
   * Load a sound effect (does nothing in null implementation)
   * @param {string} name - The identifier for the sound
   * @returns {Promise} - A resolved promise
   */
  loadSound(name) {
    return Promise.resolve();
  }
  
  /**
   * Preload multiple sound effects (does nothing in null implementation)
   * @param {Array<string>} soundNames - Array of sound names to preload
   * @returns {Promise} - A resolved promise
   */
  preloadSounds(soundNames) {
    return Promise.resolve();
  }
  
  /**
   * Play a sound effect (does nothing in null implementation)
   * @param {string} name - The name of the sound to play
   * @param {Object|number} [options] - Options for playing the sound
   * @returns {null} - Always null
   */
  playSound(name, options = {}) {
    return null;
  }
  
  /**
   * Load and play background music (does nothing in null implementation)
   * @param {string} name - The name of the background music track
   * @param {number} [fadeInTime=0] - Time in milliseconds to fade in
   * @param {boolean} [forceRestart=false] - Whether to restart the track
   * @returns {null} - Always null
   */
  playBackgroundMusic(name, fadeInTime = 0, forceRestart = false) {
    return null;
  }
  
  /**
   * Internal method to play a track (does nothing in null implementation)
   * @private
   */
  _playOrResumeTrack(name, fadeInTime) {}
  
  /**
   * Load a new music track (does nothing in null implementation)
   * @private
   */
  _loadNewMusicTrack(name, fadeInTime) {}
  
  /**
   * Pause the current music (does nothing in null implementation)
   * @param {number} [fadeOutTime=0] - Time in milliseconds to fade out
   * @returns {Promise} - A resolved promise
   */
  pauseCurrentMusic(fadeOutTime = 0) {
    return Promise.resolve();
  }
  
  /**
   * Get the playback position (always -1 in null implementation)
   * @param {string} name - The name of the track to check
   * @returns {number} - Always -1
   */
  getMusicPosition(name) {
    return -1;
  }
  
  /**
   * Check if a music track is playing (always false in null implementation)
   * @param {string} name - The name of the track to check
   * @returns {boolean} - Always false
   */
  isMusicPlaying(name) {
    return false;
  }
  
  /**
   * Switch to another track with crossfade (does nothing in null implementation)
   * @param {string} name - The name of the track to switch to
   * @param {number} crossfadeTime - Time in milliseconds for the crossfade
   */
  crossfadeTo(name, crossfadeTime = 1000) {}
  
  /**
   * Stop and remove a track (does nothing in null implementation)
   * @param {string} name - The name of the track to stop and remove
   * @param {number} [fadeOutTime=0] - Time in milliseconds to fade out
   * @returns {Promise} - A resolved promise
   */
  stopAndRemoveTrack(name, fadeOutTime = 0) {
    return Promise.resolve();
  }
  
  /**
   * Stop all music (does nothing in null implementation)
   * @param {number} [fadeOutTime=0] - Time in milliseconds to fade out
   * @returns {Promise} - A resolved promise
   */
  stopAllMusic(fadeOutTime = 0) {
    return Promise.resolve();
  }
  
  /**
   * Set music volume (does nothing in null implementation)
   * @param {number} volume - Volume level (0.0 to 1.0)
   */
  setMusicVolume(volume) {}
  
  /**
   * Set sound effects volume (does nothing in null implementation)
   * @param {number} volume - Volume level (0.0 to 1.0)
   */
  setSoundEffectsVolume(volume) {}
  
  /**
   * Setup UI controls for sound and music
   */
  setupSoundControls() {
    // Find the music and sound toggle buttons and set them to "off" state
    const musicToggle = document.getElementById('music');
    const soundToggle = document.getElementById('sound');
    
    if (musicToggle) {
      musicToggle.classList.add('off');
    }
    
    if (soundToggle) {
      soundToggle.classList.add('off');
    }
  }
  
  /**
   * Toggle sound effects (always false in null implementation)
   * @returns {boolean} - Always false
   */
  toggleSound() {
    return false;
  }
  
  /**
   * Toggle music (always false in null implementation)
   * @returns {boolean} - Always false
   */
  toggleMusic() {
    return false;
  }
  
  /**
   * Set sound enabled state (does nothing in null implementation)
   * @param {boolean} state - Desired state
   */
  setSoundEnabled(state) {}
  
  /**
   * Set music enabled state (does nothing in null implementation)
   * @param {boolean} state - Desired state
   */
  setMusicEnabled(state) {}
  
  /**
   * Get loaded music tracks (always empty in null implementation)
   * @returns {Array} - Empty array
   */
  getLoadedMusicTracks() {
    return [];
  }
  
  /**
   * Update music volume by distance (does nothing in null implementation)
   * @param {number} distance - Distance from listener to sound source
   * @param {Object} [options] - Configuration options
   * @returns {number} - Always 0
   */
  updateMusicVolumeByDistance(distance, options = {}) {
    return 0;
  }
}