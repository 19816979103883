import * as THREE from 'three';

/**
 * Creates an overlay texture with the largest possible canvas size.
 * @param {number} desiredWidth - The desired width of the canvas (not used in current implementation).
 * @param {number} desiredHeight - The desired height of the canvas (not used in current implementation).
 * @returns {Object|null} An object containing the canvas and its 2D context, or null if creation fails.
 */

// Cache the result to avoid repeatedly creating large canvases
let cachedResult = null;

export function createOverlayTexture(desiredWidth, desiredHeight) {
    // Return cached result if available
    if (cachedResult) {
 //       return cachedResult;
    }

    // Detect mobile Safari or Windows mobile
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileSafari = /iphone|ipod|ipad/.test(userAgent) && /safari/.test(userAgent) && !(/chrome/.test(userAgent));
    const isWindowsMobile = /windows phone|windows mobile/.test(userAgent);
    const shouldUseSmaller = isMobileSafari || isWindowsMobile;
    
    // Set canvas size based on device detection
    const canvasWidth = shouldUseSmaller ? 4096 : 8192;
    const canvasHeight = shouldUseSmaller ? 2048 : 4096;
    
    try {
        console.log(`Creating canvas of size: ${canvasWidth}x${canvasHeight} (${shouldUseSmaller ? 'mobile' : 'desktop'} mode)`);
        
        const canvas = document.createElement('canvas');
        
        // Set dimensions
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        
        // Get context with performance hints
        const ctx = canvas.getContext('2d', { 
            willReadFrequently: true,
            alpha: true 
        });
        
        if (!ctx) {
            throw new Error('Failed to get 2D context');
        }
        
        // Initialize canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        console.log(`Successfully created canvas: ${canvas.width}x${canvas.height}`);
        
        // Cache the result
        cachedResult = { canvas, ctx };
        return cachedResult;
    } catch (error) {
        console.error(`Failed to create canvas: ${error.message}`);
        return null;
    }
}


// Add a cleanup function to release resources when needed
export function releaseOverlayTexture() {
    if (cachedResult) {
        // Clear the canvas to help garbage collection
        cachedResult.ctx.clearRect(0, 0, cachedResult.canvas.width, cachedResult.canvas.height);
        cachedResult = null;
    }
}

// Function to convert lat/long to x/y/z on the sphere
export function latLongToVector3(lat, lon, radius) {
    const phi = (90 - lat) * (Math.PI / 180); // Convert latitude to radians
    const theta = (lon + 180) * (Math.PI / 180); // Convert longitude to radians

    const x = -(radius * Math.sin(phi) * Math.cos(theta)); // Corrected sign for X
    const y = radius * Math.cos(phi);
    const z = radius * Math.sin(phi) * Math.sin(theta);

    return new THREE.Vector3(x, y, z);
}

export function vector3ToLatLong(vector, globeContainer) {
    const globalPosition = vector.clone().sub(globeContainer.position);
    const radius = globalPosition.length();

    // Latitude calculation remains the same
    const lat = 90 - (Math.acos(globalPosition.y / radius) * 180 / Math.PI);

    // Adjusted longitude calcu lation
    let lon = (Math.atan2(-globalPosition.z, globalPosition.x) * 180 / Math.PI);

    // Ensure longitude is in the range -180 to 180
    lon = ((lon + 180) % 360) - 180;

    return { lat, lon };
}


export function calculateDotSize(population) {
    const minSize = 0.001; // Reduced minimum size
    const maxSize = 0.008; // Reduced maximum size

    const minPop = 350000;
    const maxPop = 37732000;

    const logMin = Math.log(minPop);
    const logMax = Math.log(maxPop);
    const logPop = Math.log(Math.max(population, minPop));

    const normalizedSize = (logPop - logMin) / (logMax - logMin);
    const size = minSize + Math.pow(normalizedSize, 1.2) * (maxSize - minSize); // Adjusted power for more gradual scaling

    return { size, importance: normalizedSize };
}


export function loadTexture(url) {
    return new Promise((resolve, reject) => {
        new THREE.TextureLoader().load(url, resolve, undefined, reject);
    });
}

export function setupTexture(texture, anisotropy, offsetX = 0.5, offsetY = 0) {
    //    texture.encoding = THREE.sRGBEncoding;
    texture.colorSpace = THREE.SRGBColorSpace;


    texture.anisotropy = anisotropy;
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(1, 1);
    texture.offset.set(offsetX, offsetY);
    return texture;
}

export function scaleEquirectangular(fontSize, x, y, canvasHeight) {
    // Calculate the latitude (in radians) based on the y-coordinate
    const latitude = ((y / canvasHeight) - 0.5) * Math.PI;

    // Calculate the scale factor based on the latitude with dampening
    const dampeningFactor = 0.5; // Adjust this value to control the maximum size increase
    const rawScaleFactor = 1 / Math.cos(latitude);
    const scaleFactor = 1 + (rawScaleFactor - 1) * dampeningFactor;

    // Clamp the scale factor to prevent excessive growth
    const minScaleFactor = 0.5;
    const maxScaleFactor = 2.0;
    const clampedScaleFactor = Math.max(minScaleFactor, Math.min(maxScaleFactor, scaleFactor));

    // Apply the clamped scale factor to the font size
    return fontSize * clampedScaleFactor;
}

/**
 * Creates an activity manager for OrbitControls that handles auto-rotation and user interaction
 * @param {THREE.OrbitControls} controls - The OrbitControls instance to manage
 * @param {Object} options - Configuration options
 * @param {number} [options.inactivityDelay=25000] - Time in ms before auto-rotation starts
 * @param {number} [options.accelerationDuration=3000] - Time in ms to reach full speed
 * @param {number} [options.decelerationDuration=500] - Time in ms to stop rotation
 * @param {number} [options.maxAutoRotateSpeed=2.5] - Maximum rotation speed
 * @param {Function} [options.isDisabled] - Optional function that returns true when auto-rotation should be disabled
 * @returns {Object} The activity manager instance
 */
export function createOrbitControlActivityManager(controls, options = {}) {
    const STATES = {
        IDLE: 'idle',
        TRANSITIONING_TO_AUTO: 'transitioning-to-auto',
        AUTO_ROTATING: 'auto-rotating',
        TRANSITIONING_TO_IDLE: 'transitioning-to-idle'
    };

    const config = {
        inactivityDelay: options.inactivityDelay || 60000,
        accelerationDuration: options.accelerationDuration || 3000,
        decelerationDuration: options.decelerationDuration || 500,
        maxAutoRotateSpeed: options.maxAutoRotateSpeed || 2.5,
        isDisabled: options.isDisabled || (() => false)
    };

    let state = STATES.IDLE;
    let lastActivityTime = Date.now();
    let transitionStartTime = null;
    let currentSpeed = 0;
    let enabled = true;

    function handleActivity() {
        lastActivityTime = Date.now();

        if (isInAutoRotateState()) {
            transitionToIdle();
        }
    }

    function isInAutoRotateState() {
        return [STATES.AUTO_ROTATING, STATES.TRANSITIONING_TO_AUTO].includes(state);
    }

    function transitionToIdle() {
        if (state !== STATES.TRANSITIONING_TO_IDLE) {
            state = STATES.TRANSITIONING_TO_IDLE;
            transitionStartTime = Date.now();
        }
    }

    function transitionToAutoRotate() {
        if (state !== STATES.TRANSITIONING_TO_AUTO) {
            state = STATES.TRANSITIONING_TO_AUTO;
            transitionStartTime = Date.now();
        }
    }

    function easeInOutCubic(x) {
        return x < 0.5 ?
            4 * x * x * x :
            1 - Math.pow(-2 * x + 2, 3) / 2;
    }

    function updateTransition(deltaTime) {
        if (!transitionStartTime) return;

        const duration = state === STATES.TRANSITIONING_TO_AUTO
            ? config.accelerationDuration
            : config.decelerationDuration;

        const progress = Math.min(
            (Date.now() - transitionStartTime) / duration,
            1
        );

        const eased = easeInOutCubic(progress);

        if (state === STATES.TRANSITIONING_TO_AUTO) {
            currentSpeed = config.maxAutoRotateSpeed * eased;
            if (progress === 1) {
                state = STATES.AUTO_ROTATING;
                transitionStartTime = null;
            }
        } else if (state === STATES.TRANSITIONING_TO_IDLE) {
            currentSpeed = config.maxAutoRotateSpeed * (1 - eased);
            if (progress === 1) {
                state = STATES.IDLE;
                transitionStartTime = null;
            }
        }
    }

    function checkInactivity() {
        // Don't start auto-rotation if disabled or explicitly disabled via function
        if (!enabled || config.isDisabled()) {
            if (isInAutoRotateState()) {
                transitionToIdle();
            }
            return;
        }

        const timeSinceActivity = Date.now() - lastActivityTime;
        if (state === STATES.IDLE && timeSinceActivity > config.inactivityDelay) {
            transitionToAutoRotate();
        }
    }

    function update(deltaTime) {
        checkInactivity();
        updateTransition(deltaTime);

        controls.autoRotate = currentSpeed > 0;
        controls.autoRotateSpeed = currentSpeed;
        controls.update(deltaTime);
    }

    // Setup event listeners
    const boundHandleActivity = handleActivity.bind(null);
    const events = ['pointerdown', 'pointermove', 'wheel'];

    events.forEach(event => {
        controls.domElement.addEventListener(event, boundHandleActivity);
    });

    ['keydown', 'mousemove'].forEach(event => {
        document.addEventListener(event, boundHandleActivity);
    });

    // Return public interface
    return {
        update,
        dispose: () => {
            events.forEach(event => {
                controls.domElement.removeEventListener(event, boundHandleActivity);
            });
            ['keydown', 'mousemove'].forEach(event => {
                document.removeEventListener(event, boundHandleActivity);
            });
        },
        getState: () => state,
        getCurrentSpeed: () => currentSpeed,
        enable: () => {
            enabled = true;
            lastActivityTime = Date.now(); // Reset activity timer when enabled
        },
        disable: () => {
            enabled = false;
            if (isInAutoRotateState()) {
                transitionToIdle();
            }
        },
        isEnabled: () => enabled
    };
}

// Example usage:
/*
import { createOrbitControlActivityManager } from './orbitControlsManager';

const controls = new THREE.OrbitControls(camera, renderer.domElement);
const activityManager = createOrbitControlActivityManager(controls, {
    inactivityDelay: 25000,
    accelerationDuration: 3000,
    decelerationDuration: 500,
    maxAutoRotateSpeed: 2.5,
    isDisabled: () => isGlobeRolledOut || isRollingGlobe // Pass in your conditions
});

// Temporarily disable auto-rotation:
activityManager.disable();

// Re-enable auto-rotation:
activityManager.enable();

// In animation loop:
activityManager.update(deltaTime);

// When cleaning up:
activityManager.dispose();
*/

export function createComfortMaterial() {
    const material = new THREE.MeshPhysicalMaterial({
        color: 0xdFa0f6,          // Warmer gold, less green
        emissive: 0xdfddff,       // Warmer glow
        emissiveIntensity: 0.4,
        metalness: 1.0,
        roughness: 0.2,
        transparent: true,
        side: THREE.DoubleSide,
        envMapIntensity: 0.6,
        
        // Subtle iridescence
        iridescence: 0.8,
        iridescenceIOR: 1.2,
        iridescenceThicknessRange: [100, 400],

        // Glossy layer
        clearcoat: 0.4,
        clearcoatRoughness: 0.2,

        // Warm edge highlights
        sheen: 0.1,
        sheenRoughness: 0.3,
        sheenColor: 0xdFaCf0,     // More orange-gold sheen

        transmission: 0.01,
        thickness: 0.5,
        ior: 2.5,

        reflectivity: 1.0,
        
        // Warmer specular highlights
        specularIntensity: 1.2,
        specularColor: 0xddaaff    // Warmer specular
    });

    material.updateLightPosition = function(lightPos) {
        return;
    };

    material.updateOpacity = function(value) {
        this.opacity = value;
    };
    
    material.update = function(deltaTime) {
        return;
    };

    return material;
}

export function createDashedMaterial(dashScale = null, baseColor = null) {
    const material = new THREE.ShaderMaterial({
        uniforms: {
            lightPosition: { value: new THREE.Vector3() },
            globeCenter: { value: new THREE.Vector3(0, 0, 0) },
            globeRadius: { value: 1.0 },
            dashScale: { value: dashScale ? dashScale : 30.0 },  // Controls the size of dashes
//            baseColor: { value: baseColor ? baseColor : new THREE.Vector3(0.0, 0.1, 0.5) },  // Blue base color
            baseColor: { value: baseColor ? baseColor : new THREE.Vector3(0.0, 0.1, 0.5) },  // Blue base color
  
            opacity: { value: 1.0 },
            ...THREE.UniformsLib.lights
        },
        vertexShader: `
            uniform vec3 globeCenter;
            uniform float globeRadius;
            
            varying vec2 vUv;
            varying vec3 vNormal;
            varying vec3 vWorldPosition;
            varying float vDistanceFromGlobe;
            varying vec3 vViewPosition;

            #include <common>
            #include <shadowmap_pars_vertex>

            void main() {
                vUv = uv;
                vNormal = normalMatrix * normal;
                vec4 worldPosition = modelMatrix * vec4(position, 1.0);
                vWorldPosition = worldPosition.xyz;
                
                vec4 mvPosition = viewMatrix * worldPosition;
                vViewPosition = -mvPosition.xyz;
                
                vec3 positionVec = worldPosition.xyz;
                vec3 directionFromCenter = normalize(positionVec - globeCenter);
                float distanceFromCenter = length(positionVec - globeCenter);
                vDistanceFromGlobe = (distanceFromCenter - globeRadius) / globeRadius;
                
                gl_Position = projectionMatrix * mvPosition;
                
                #include <beginnormal_vertex>
                #include <defaultnormal_vertex>
                #include <shadowmap_vertex>
            }
        `,
        fragmentShader: `
            uniform vec3 lightPosition;
            uniform vec3 globeCenter;
            uniform float globeRadius;
            uniform float dashScale;
            uniform vec3 baseColor;
            uniform float opacity;
            
            #include <common>
            #include <packing>
            #include <lights_pars_begin>
            #include <shadowmap_pars_fragment>
            #include <shadowmask_pars_fragment>

            varying vec2 vUv;
            varying vec3 vNormal;
            varying vec3 vWorldPosition;
            varying float vDistanceFromGlobe;
            varying vec3 vViewPosition;

            void main() {
                float shadowMask = getShadowMask();
                
                vec3 normal = normalize(vNormal);
                vec3 lightDir = normalize(lightPosition - vWorldPosition);
                vec3 viewDir = normalize(vViewPosition);
                vec3 halfDir = normalize(lightDir + viewDir);
                
                // Basic lighting
                float diffuse = 1.0 - max(dot(normal, lightDir), 0.0);
                diffuse = pow(diffuse, 1.5);
                
                // Create dashed pattern
                float dashPattern = step(0.5, fract(vUv.x * dashScale));
                
                // Add some edge fade to the dashes
                float edgeFade = smoothstep(0.48, 0.52, fract(vUv.x * dashScale));
                float antiEdgeFade = smoothstep(0.52, 0.48, fract(vUv.x * dashScale));
                float finalDash = mix(dashPattern, 1.0 - dashPattern, edgeFade * antiEdgeFade);
                
                // Lighting calculations
                float ambient = 0.35;
                float diffuseMult = 0.75;
                
                float mainLight = ambient + (diffuseMult * (1.0 - pow(diffuse, 0.7)));
                float shadowEffect = mix(0.8, 1.0, shadowMask);
                float planetShadowEffect = mix(0.8, 1.0, smoothstep(-0.2, 0.2, dot(normalize(vWorldPosition - globeCenter), normalize(lightPosition))));
                
                float lighting = mainLight * shadowEffect * planetShadowEffect;
                
                // Simple fresnel for edge highlight
                float fresnel = pow(1.0 - max(dot(normal, viewDir), 0.0), 3.0);
                
                vec3 color = baseColor;
                color += vec3(0.3) * fresnel; // Add subtle edge highlight
                
                vec3 finalColor = color * lighting;
                
                // Set alpha based on dash pattern
                float finalAlpha = finalDash * opacity;
                
                gl_FragColor = vec4(finalColor, finalAlpha);
            }
        `,
        lights: true,
        side: THREE.DoubleSide,
        transparent: true
    });

    // Add update methods to the material
    material.updateLightPosition = function (lightPos) {
        this.uniforms.lightPosition.value.copy(lightPos);
    };

    material.updateColor = function (color) {
        this.uniforms.baseColor.value.set(color.r, color.g, color.b);
    };

    material.updateOpacity = function (value) {
        this.uniforms.opacity.value = value;
    };

    material.updateDashScale = function (scale) {
        this.uniforms.dashScale.value = scale;
    };

    return material;
}


export function createGoldMaterial() {
    const material = new THREE.MeshPhysicalMaterial({
        color: 0xFF7046,          // Warmer gold, less green
        emissive: 0x331100,       // Warmer glow
        metalness: 1.0,
        roughness: 0.2,
        transparent: true,
        side: THREE.DoubleSide,
        envMapIntensity: 0.8,
        
        // Subtle iridescence
        iridescence: 0.1,
        iridescenceIOR: 1.2,
        iridescenceThicknessRange: [100, 400],

        // Glossy layer
        clearcoat: 0.4,
        clearcoatRoughness: 0.2,

        // Warm edge highlights
        sheen: 0.1,
        sheenRoughness: 0.3,
        sheenColor: 0xFF4C00,     // More orange-gold sheen

        transmission: 0.01,
        thickness: 0.5,
        ior: 2.5,

        reflectivity: 1.0,
        
        // Warmer specular highlights
        specularIntensity: 1.2,
        specularColor: 0xFF9933    // Warmer specular
    });

    material.updateLightPosition = function(lightPos) {
        return;
    };

    material.updateOpacity = function(value) {
        this.opacity = value;
    };
    
    material.update = function(deltaTime) {
        return;
    };

    return material;
}

export function createPaperMaterial() {
    // Create paper fiber texture
    const textureSize = 256;
    const canvas = document.createElement('canvas');
    canvas.width = textureSize;
    canvas.height = textureSize;
    const ctx = canvas.getContext('2d');
  
    // Pure white base
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, textureSize, textureSize);
  
    // Subtle fibers
    for (let i = 0; i < 1000; i++) {
      const x = Math.random() * textureSize;
      const y = Math.random() * textureSize;
      const length = 2 + Math.random() * 3;
      const angle = Math.random() * Math.PI * 2;
  
      ctx.save();
      ctx.translate(x, y);
      ctx.rotate(angle);
  
      const alpha = Math.random() * 0.03;
      ctx.strokeStyle = `rgba(0, 0, 0, ${alpha})`;
      ctx.lineWidth = 0.5;
  
      ctx.beginPath();
      ctx.moveTo(-length / 2, 0);
      ctx.lineTo(length / 2, 0);
      ctx.stroke();
  
      ctx.restore();
    }
  
    // Create the texture and set wrapping behavior
    const paperTexture = new THREE.CanvasTexture(canvas);
    paperTexture.wrapS = THREE.RepeatWrapping;
    paperTexture.wrapT = THREE.RepeatWrapping;
  
    // Create a basic material using built-in MeshStandardMaterial (simpler than Physical)
    const material = new THREE.MeshPhysicalMaterial({
      map: paperTexture,
      color: 0xfff9f0,        // Very slightly warm white to counter blue
      roughness: 0.9,         // High roughness for paper
      metalness: 0.0,         // Non-metallic
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 1.0,
      shadowSide: THREE.FrontSide,
      emissive: 0xfff0e0,     // Slight warm glow to counter blue tint in scene
      emissiveIntensity: 0.08  // Subtle emission
    });
  
    // Add methods to update material properties
    material.updateLightPosition = function(lightPos) {
      // Not needed as Three.js handles lighting automatically
    };
  
    material.updateColor = function(color) {
      this.color.copy(color);
    };
  
    material.updateOpacity = function(value) {
      this.opacity = value;
    };
  
    return material;
  }

export function getTextBoundingBox(ctx, text, font) {
    ctx.font = font;
    const metrics = ctx.measureText(text);

    const actualBoundingBoxAscent = metrics.actualBoundingBoxAscent || 0;
    const actualBoundingBoxDescent = metrics.actualBoundingBoxDescent || 0;
    const actualBoundingBoxLeft = metrics.actualBoundingBoxLeft || 0;
    const actualBoundingBoxRight = metrics.actualBoundingBoxRight || metrics.width;

    const width = actualBoundingBoxRight - actualBoundingBoxLeft;
    const height = actualBoundingBoxAscent + actualBoundingBoxDescent;

    return { width, height };
}


/**
 * Finds random cities within the same longitude band as the specified city
 * Uses the current date as a seed for consistent daily results
 * @param {string} cityName - Name of the reference city
 * @param {number} n - Number of random cities to return
 * @param {number} bandWidth - Width of the longitude band in degrees (default: 10)
 * @param {Array} cities - Array of city objects with lng property
 * @returns {Array} Array of objects containing cities and their distances from reference city
 */
export function findCitiesInLongitudeBand(cityName, n, bandWidth = 10, cities) {
    // Input validation
    if (!cityName || !cities || typeof cities !== 'object') {
        throw new Error('Invalid input parameters');
    }

    // Earth's radius in kilometers
    const EARTH_RADIUS = 6378;

    function normalizeLongitudeDiff(lon1, lon2) {
        let diff = lon2 - lon1;
        while (diff > 180) diff -= 360;
        while (diff < -180) diff += 360;
        return diff;
    }

    // Find the reference city
    const referenceCity = cities[cityName.toLowerCase()] ||
        Object.values(cities).find(city =>
            city.city.toLowerCase() === cityName.toLowerCase()
        );

    if (!referenceCity) {
        throw new Error(`City "${cityName}" not found`);
    }

    const referenceLng = parseFloat(referenceCity.lng);
    const referenceLat = parseFloat(referenceCity.lat);

    // Find all cities within the longitude band
    const citiesInBand = Object.values(cities).filter(city => {
        const cityLng = parseFloat(city.lng);
        const lngDiff = Math.abs(cityLng - referenceLng);
        return lngDiff <= bandWidth / 2 && city.city !== referenceCity.city;
    }).map(city => ({
        ...city,
        distance: calculateGreatCircleDistance(
            referenceLat,
            referenceLng,
            parseFloat(city.lat),
            parseFloat(city.lng),
            EARTH_RADIUS
        )
    }));

    // If we don't have enough cities in the band, return all we have
    if (citiesInBand.length <= n) {
        return citiesInBand;
    }

    // Create a seeded random number generator based on the current date
    const today = new Date();
    let dateSeed = today.getFullYear() * 10000 +
        (today.getMonth() + 1) * 100 +
        today.getDate();

    function seededRandom() {
        let x = Math.sin(dateSeed++) * 10000;
        return x - Math.floor(x);
    }

    // Randomly select n cities from the band using seeded random
    const result = [];
    const usedIndices = new Set();

    while (result.length < n && usedIndices.size < citiesInBand.length) {
        const randomIndex = Math.floor(seededRandom() * citiesInBand.length);

        if (!usedIndices.has(randomIndex)) {
            result.push(citiesInBand[randomIndex]);
            usedIndices.add(randomIndex);
        }
    }

    return result;
}

// Example usage:
// const nearbyCities = findCitiesInLongitudeBand("Tokyo", 5, 10, cities);
// console.log(nearbyCities);
// Example output:
// [
//   { 
//     city: "Seoul", 
//     lat: 37.5665, 
//     lng: 126.9780, 
//     population: 9963452,
//     capital: "primary",
//     distance: 1161.45 
//   },
//   ...
// ]


export function createCityPin(position, size, headMaterialOptions = {}) {
    const pinGroup = new THREE.Group();

    const headGeometry = new THREE.SphereGeometry(size / 2.5, 16, 16);

    const defaultHeadMaterialOptions = {
        color: 0xff0000,
        metalness: 0.1,
        roughness: 0.05,        // Smoother surface
        transmission: 1,        // More light transmission
        thickness: 1.0,         // Increased thickness
        envMapIntensity: 0.2,   // Some environment reflections
        clearcoat: 0.8,        // More pronounced clearcoat
        clearcoatRoughness: 0.1,
        ior: 1.8,              // Higher refraction
        attenuationDistance: 0.5, // More color concentration
        attenuationColor: 0xff2200, // Deeper red internal color
        transparent: true,
        opacity: 0.9
    };

    const headMaterial = new THREE.MeshPhysicalMaterial({
        ...defaultHeadMaterialOptions,
        ...headMaterialOptions
    });

    const head = new THREE.Mesh(headGeometry, headMaterial);
    head.castShadow = true;
    head.receiveShadow = true;
    // Create pin shaft (cylinder)

    // Adjust base radius to be thinner
    const shaftRadius = size * 0.04; // Reduced from 0.06

    // Create tapered cylinder by using different top and bottom radii
    const shaftTopRadius = shaftRadius;  // Keep original radius at top
    const shaftBottomRadius = shaftRadius * 0.7;  // 70% of top radius for subtle taper
    const shaftHeight = size * 4;

    const shaftGeometry = new THREE.CylinderGeometry(
        shaftTopRadius,
        shaftBottomRadius,
        shaftHeight,
        8  // Keep good number of segments for smoothness
    );

    const shaftMaterial = new THREE.MeshPhysicalMaterial({
        color: 0xdedede,
        metalness: 0.8,      // Reduced from 0.95 for less extreme reflections
        roughness: 0.3,      // Increased for more light scatter
        envMapIntensity: 1, // Reduced environment reflections
        clearcoat: 0.1,
        clearcoatRoughness: 0.3,
        // Removed anisotropy since it might be contributing to the harsh contrast
    });

    const shaft = new THREE.Mesh(shaftGeometry, shaftMaterial);

    head.position.y = -shaftHeight / 2;
    pinGroup.add(head);
    pinGroup.add(shaft);
    pinGroup.position.copy(position);
    pinGroup.lookAt(new THREE.Vector3(0, 0, 0));

    // Add random wiggle
    const wiggleAmount = Math.PI / 8; // Adjust this value to control the maximum wiggle angle
    const randomAngleX = (Math.random() - 0.5) * wiggleAmount;
    const randomAngleZ = (Math.random() - 0.5) * wiggleAmount;

    pinGroup.rotateX(randomAngleX);
    pinGroup.rotateZ(randomAngleZ);
    pinGroup.rotateX(Math.PI / 2);
    pinGroup.translateY(-shaftHeight / 4); // Adjust this value to control penetration depth

    // Enable shadow casting for the entire pin
    pinGroup.traverse((object) => {
        if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = true;

        }
    });

    pinGroup.isCustomPin = true;
    pinGroup.isPreviewPin = false;  // Add this line
    pinGroup.connectedRibbons = []; // Add this line to store connected ribbons

    return pinGroup;
}


// Base material options optimized for contrast against map colors
const baseMaterialOptions = {
    metalness: 0.3,          // Increased for better contrast
    roughness: 0.1,          // Smooth for strong highlights
    transmission: 0.7,       // Reduced for more solid appearance
    thickness: 4.0,          // Strong internal effects
    envMapIntensity: 2.0,    // Very strong environment reflections
    clearcoat: 1.0,          // Maximum surface highlights
    clearcoatRoughness: 0.05,
    ior: 2.4,                // High refraction
    transparent: true,
    opacity: 0.95            // More opaque for better visibility
};

export const pinHeadMaterials = {
    finished: {
        ...baseMaterialOptions,
        color: 0xffd700,              // Rich gold base
        metalness: 1.0,               // Maximum metalness
        transmission: 0.0,
        thickness: 1.0,
        clearcoat: 0.8,               // Reduced to let gold show more
        ior: 2.4,                     // Increased for stronger gold effect
        iridescence: 0.4,             // Reduced to let gold dominate
        iridescenceIOR: 1.3,
        envMapIntensity: 1.8,         // Reduced to prevent washing out gold
        roughness: 0.1,               // Slightly increased for gold character
        opacity: 1.0,
        emissive: 0xffa500,          // Strong orange-gold glow
        emissiveIntensity: 0.4,       // Increased for more intense gold effect
        attenuationColor: 0xffb732    // Deep gold core
    },

    london: {
        ...baseMaterialOptions,
        color: 0xE0E7EC,              // Platinum-silver base
        metalness: 0.9,               // High but not maximum to allow some diffuse
        transmission: 0.0,
        thickness: 1.0,
        clearcoat: 1.0,               // Maximum clearcoat for shine
        ior: 2.2,                     // Slightly lower than gold for different character
        iridescence: 0.6,             // Increased for "special" quality
        iridescenceIOR: 1.5,          // Higher for stronger color shifts
        envMapIntensity: 2.0,         // Increased for more environmental reflection
        roughness: 0.05,              // Very smooth
        opacity: 1.0,
        emissive: 0x4169E1,          // Royal blue emissive
        emissiveIntensity: 0.5,       // Strong glow
        attenuationColor: 0xADD8E6    // Light blue core
    },



    green_pearl:  {
        ...baseMaterialOptions,
        color: 0xe3b151,              // Slightly darker, warmer gold
        metalness: 0.6,               // Balanced for color retention
        transmission: 0.0,            // No transmission for metal
        thickness: 1.0,
        clearcoat: 1.0,               // Maximum clearcoat
        clearcoatRoughness: 0.01,     // Ultra smooth clearcoat
        ior: 2.8,
        envMapIntensity: 1.3,         // Slightly reduced to maintain color
        roughness: 0.01,              // Mirror-like smoothness
        opacity: 1.0,
        attenuationDistance: 0.05,
        attenuationColor: 0xd4a147    // Slightly warmer core
    },

    made_friend: {
        ...baseMaterialOptions,
        color: 0x00FFE0,              // Bright turquoise
        metalness: 0.7,               // Moderately metallic
        transmission: 0.5,            // Enhanced transmission
        thickness: 1.5,               // Slightly thinner for brighter look
        attenuationDistance: 0.3,     // More attenuation to enhance glow effect
        attenuationColor: 0x00FFE0,   // Matching bright turquoise core
        clearcoat: 1.0,               // Full clearcoat
        ior: 2.0,                     // Higher index of refraction
        iridescence: 0.8,             // Moderate iridescence
        iridescenceIOR: 1.5,          // Higher iridescence IOR for more rainbow effect
        envMapIntensity: 2.0,         // More intense reflections
        roughness: 0.0,               // Perfectly smooth for maximum shine
        opacity: 0.95                 // Nearly fully opaque
    }
};


// Helper function with error handling
export function createCustomPinMaterial(basePreset, customizations = {}) {
    if (!pinHeadMaterials[basePreset]) {
        console.warn(`Material "${basePreset}" not found, falling back to "notStarted"`);
        return {
            ...pinHeadMaterials.notStarted,
            ...customizations
        };
    }
    return {
        ...pinHeadMaterials[basePreset],
        ...customizations
    };
}

// Note: For maximum contrast against map background:
// 1. HDR environment map with high contrast
// 2. Bloom post-processing with:
//    - threshold: 0.6
//    - intensity: 1.8
//    - radius: 0.8
// 3. Ambient light intensity around 0.5
// 4. Strong directional main light with slight orange/golden tint
// 5. Optional: Slight ambient occlusion for better depth
// 6. Optional: Screen-space reflections for metallic materials

// Add this helper function to normalize longitude difference
function normalizeLongitudeDiff(lon1, lon2) {
    // Convert both longitudes to be in range [-180, 180]
    lon1 = ((lon1 + 180) % 360) - 180;
    lon2 = ((lon2 + 180) % 360) - 180;

    // Calculate the absolute difference
    let diff = Math.abs(lon1 - lon2);

    // If the difference is greater than 180 degrees,
    // then the shorter path is the other way around the globe
    if (diff > 180) {
        diff = 360 - diff;
    }

    return diff;
}

/**
 * Generates a list of flights based on airport codes
 * @param {string[]} airportCodes - List of airport codes to generate flights for
 * @param {Object} cities - Lookup object containing city information
 * @param {string} homeCity - Name of the home city
 * @param {Function} calculateGreatCircleDistance - Function to calculate distance between coordinates
 * @returns {Object[]} List of flight objects
 */
export function generateFlights(airportCodes, cities, homeCity, airportToCity) {
    // Find home city coordinates using the city name
    const homeCityInfo = cities[homeCity];
    
    if (!homeCityInfo) {
      throw new Error(`Home city "${homeCity}" not found in the cities lookup`);
    }
    
    const homeLat = homeCityInfo.lat;
    const homeLng = homeCityInfo.lng;
    
    const flights = [];
    
    // Define status probabilities for different positions in the list
    const statusProbabilities = [
      // First few flights: Higher chance of Final Call, Boarding
      { 'Final Call': 60, 'Boarding': 30, 'Delayed': 10, 'Go To Gate': 0, 'On Time': 0, '': 0 },
      { 'Final Call': 50, 'Boarding': 40, 'Delayed': 30, 'Go To Gate': 0, 'On Time': 0, '': 0 },
      { 'Final Call': 30, 'Boarding': 50, 'Delayed': 15, 'Go To Gate': 5, 'On Time': 0, '': 0 },
      // Middle flights: More Go To Gate and On Time
      { 'Final Call': 10, 'Boarding': 15, 'Delayed': 20, 'Go To Gate': 50, 'On Time': 15, '': 20 },
      { 'Final Call': 0, 'Boarding': 0, 'Delayed': 20, 'Go To Gate': 40, 'On Time': 40, '': 30 },
      { 'Final Call': 0, 'Boarding': 0, 'Delayed': 15, 'Go To Gate': 15, 'On Time': 60, '': 40 },
      // Later flights: Mostly On Time or empty status with sharp transition
      { 'Final Call': 0, 'Boarding': 0, 'Delayed': 10, 'Go To Gate': 0, 'On Time': 55, '': 65 },
      { 'Final Call': 0, 'Boarding': 0, 'Delayed': 5, 'Go To Gate': 0, 'On Time': 35, '': 60 },
      { 'Final Call': 0, 'Boarding': 0, 'Delayed': 0, 'Go To Gate': 0, 'On Time': 20, '': 80 },
      { 'Final Call': 0, 'Boarding': 0, 'Delayed': 0, 'Go To Gate': 0, 'On Time': 10, '': 90 },
      { 'Final Call': 0, 'Boarding': 0, 'Delayed': 0, 'Go To Gate': 0, 'On Time': 5, '': 95 },
      { 'Final Call': 0, 'Boarding': 0, 'Delayed': 0, 'Go To Gate': 0, 'On Time': 0, '': 100 }
    ];
    
    const gates = ['A', 'B', 'C', 'D'];
    const airlines = ['BA', 'AF', 'DL', 'LH', 'UA', 'AA', 'EK', 'QF', 'SQ', 'TK'];
    
    // Function to select a status based on position-specific probabilities
    function selectStatusByPosition(position) {
      // If position is beyond our defined probabilities, use the last one
      const probabilities = position < statusProbabilities.length 
        ? statusProbabilities[position] 
        : statusProbabilities[statusProbabilities.length - 1];
      
      // Convert probabilities to ranges for selection
      let total = 0;
      const ranges = {};
      for (const [status, prob] of Object.entries(probabilities)) {
        ranges[status] = { start: total, end: total + prob };
        total += prob;
      }
      
      // Generate a random number within the total probability range
      const random = Math.floor(Math.random() * total);
      
      // Find which status range contains our random number
      for (const [status, range] of Object.entries(ranges)) {
        if (random >= range.start && random < range.end) {
          return status;
        }
      }
      
      // Fallback (should never reach here if probabilities sum to 100)
      return 'On Time';
    }
    
    airportCodes.forEach((code, index) => {
      // Use the lookup table to find the city name
      const cityName = airportToCity[code];
      
      if (!cityName) {
        console.warn(`Airport code "${code}" not found in the airport lookup`);
        return; // Skip this iteration
      }
      
      // Access city information directly using city name
      const cityInfo = cities[cityName];
      
      if (!cityInfo) {
        console.warn(`City "${cityName}" not found in the cities lookup`);
        return; // Skip this iteration
      }
      
      // Calculate distance
      const distance = calculateGreatCircleDistance(
        homeLat, 
        homeLng, 
        cityInfo.lat, 
        cityInfo.lng, 
        6378 // Earth radius in KM
      );
      
      // Generate deterministic "random" values based on the city name and code
      const seed = (cityName.length * code.length * (index + 1)) % 100;
      
      // Generate divisor between 2 and 4
//      const divisor = 2 + (seed % 3) / 10 + 2;
      const divisor = 2 + (seed % 3);
      
      // Calculate price based on distance and divisor
      const price = Math.round(distance / divisor);
      
      // Generate flight number
      const airlineCode = airlines[seed % airlines.length];
      const flightNumber = 100 + (seed * 7) % 900;
      const flight = `${airlineCode}${flightNumber}`;
      
      // Generate gate
      const gateTerminal = gates[seed % gates.length];
      const gateNumber = 1 + (seed % 20);
      const gate = `${gateTerminal}${gateNumber}`;
      
      // Select status based on position in list
      const status = selectStatusByPosition(index);
      
      // Create destination string
      const destination = `${cityName} (${code})`;
      
      // Add flight to the list
      flights.push({
        price: price,
        flight: flight,
        destination: destination,
        gate: gate,
        status: status,
        from: homeCity,
        to: cityName
      });
    });
    
    return flights;
  }

export function calculateGreatCircleDistance(lat1, lon1, lat2, lon2, radius) {
    // Convert latitude and longitude to radians
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;

    // Get the normalized longitude difference in radians
    const Δλ = normalizeLongitudeDiff(lon1, lon2) * Math.PI / 180;

    // Calculate using spherical law of cosines
    const d = Math.acos(
        Math.sin(φ1) * Math.sin(φ2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.cos(Δλ)
    );

    return Math.floor(radius * d);
}


export async function loadCityData(citiesUrl, accessUrl, countryCodesUrl) {
    return Promise.all([
        new Promise((resolve, reject) => {
            Papa.parse(citiesUrl, {
                download: true,
                header: true,
                complete: (results) => resolve(results.data),
                error: reject
            });
        }),
        new Promise((resolve, reject) => {
            Papa.parse(accessUrl, {
                download: true,
                header: true,
                complete: (results) => {
                    // Transform into lookup structure
                    const accessLookup = {};
                    results.data.forEach(row => {
                        // Create entry for this country if it doesn't exist
                        if (!accessLookup[row.country_code]) {
                            accessLookup[row.country_code] = {};
                        }

                        // Split the accessible countries and create fast lookup
                        const accessibleCountries = row.accessible_countries.split(',');
                        accessibleCountries.forEach(countryCode => {
                            accessLookup[row.country_code][countryCode] = true;
                        });
                    });

                    resolve(accessLookup);
                },
                error: reject
            });
        }),
        new Promise((resolve, reject) => {
            Papa.parse(countryCodesUrl, {
                download: true,
                header: true,
                complete: (results) => {
                    // Create lookup object indexed by alpha-2 codes
                    const countryCodesLookup = {};
                    results.data.forEach(row => {
                        countryCodesLookup[row['alpha-2']] = row;
                    });
                    resolve(countryCodesLookup);
                },
                error: reject
            });
        })
    ]).then(([citiesData, accessLookup, countryCodesLookup]) => {
        return {
            cities: citiesData,
            accessLookup: accessLookup,
            countryCodesLookup: countryCodesLookup
        };
    });
}

export function animatePinPushIn(globe, pin, finalPosition) {
    const direction = finalPosition.clone().sub(globe.container.position).normalize();
    const pushDistance = 0.007;
    const animationDuration = 500;

    // Store the initial rotation
    const initialRotation = pin.quaternion.clone();
    
    // Calculate the base orientation (without wiggle)
    const baseOrientation = new THREE.Object3D();
    baseOrientation.position.copy(finalPosition);
    baseOrientation.lookAt(globe.container.position);
    baseOrientation.rotateX(Math.PI / 2);
    
    // Calculate the difference between initial and base orientation
    // This represents our wiggle that we want to preserve
    const wiggleRotation = new THREE.Quaternion();
    wiggleRotation.multiplyQuaternions(
        initialRotation,
        baseOrientation.quaternion.clone().invert()
    );

    const startTime = Date.now();

    function animate() {
        const elapsedTime = Date.now() - startTime;
        const progress = Math.min(elapsedTime / animationDuration, 1);

        if (progress < 0.5) {
            // Push in
            const pushInProgress = progress * 2; // Rescale to 0-1
            const currentPushDistance = pushDistance * pushInProgress;
            pin.position.copy(finalPosition).sub(direction.clone().multiplyScalar(currentPushDistance));
        }

        // Apply base orientation
        pin.lookAt(globe.container.position);
        pin.rotateX(Math.PI / 2);
        
        // Re-apply the wiggle
        pin.quaternion.multiplyQuaternions(pin.quaternion, wiggleRotation);

        if (progress < 1) {
            requestAnimationFrame(animate);
        }
    }

    animate();
}

// Simple timing utility
export const Checkpoint = {
    lastTime: null,
    
    mark(label) {
      const now = performance.now();
      
      if (this.lastTime !== null) {
        const elapsed = now - this.lastTime;
        console.log(`[${label}] Time: ${elapsed.toFixed(2)}ms`);
      } else {
        console.log(`[${label}] Starting timer`);
      }
      
      this.lastTime = now;
      return now;
    },
    
    reset() {
      this.lastTime = null;
      console.log('Timer reset');
    }
  };


/**
 * Distributes rewards across a list of questions with maximized differences
 * @param {Array} questions - Array of question objects
 * @param {number} totalAmount - Total amount to distribute (must be divisible by increment)
 * @param {number} minAmount - Minimum amount per question (default: 50)
 * @param {number} increment - Increment size (default: 50)
 * @returns {Array} - Original questions array with added reward property
 */
export function distributeRewards(questions, totalAmount, minAmount = 50, increment = 50) {
    if (!questions || !Array.isArray(questions) || questions.length === 0) {
      throw new Error('Questions must be a non-empty array');
    }
    
    const numItems = questions.length;
    
    // Verify total amount is adequate and divisible by increment
    if (totalAmount < numItems * minAmount) {
      throw new Error(`Cannot distribute ${totalAmount} with minimum ${minAmount} across ${numItems} items`);
    }
    
    if (totalAmount % increment !== 0) {
      throw new Error(`Total amount (${totalAmount}) must be divisible by increment (${increment})`);
    }
    
    // Create a deep copy of questions to avoid mutating the original
    const questionsWithRewards = JSON.parse(JSON.stringify(questions));
    
    // Start with minimum amount for each question
    questionsWithRewards.forEach(question => {
      question.reward = minAmount;
    });
    
    let remainingAmount = totalAmount - (minAmount * numItems);
    const remainingIncrements = remainingAmount / increment;
    
    // Create an array of weights that we'll use to bias our random selection
    let weights = Array(numItems).fill(1);
    
    // Distribute remaining increments
    for (let i = 0; i < remainingIncrements; i++) {
      // Select a random index based on weights
      const totalWeight = weights.reduce((a, b) => a + b, 0);
      let randomValue = Math.random() * totalWeight;
      let selectedIndex = 0;
      
      // Find the selected index
      for (let j = 0; j < numItems; j++) {
        randomValue -= weights[j];
        if (randomValue <= 0) {
          selectedIndex = j;
          break;
        }
      }
      
      // Add increment to the selected question
      questionsWithRewards[selectedIndex].reward += increment;
      
      // Adjust weights to create moderate volatility (less extreme than before)
      // Use a more balanced approach to prevent winner-takes-all
      for (let j = 0; j < numItems; j++) {
        if (j === selectedIndex) {
          // Increase weight for selected question, but with diminishing returns
          // This creates a moderate bias rather than an extreme one
          weights[j] = Math.pow(questionsWithRewards[j].reward / minAmount, 0.8);
          
          // Add a ceiling effect to prevent excessive concentration
          if (weights[j] > 3) weights[j] = 3;
        } else {
          // Only slightly decrease others
          weights[j] *= 0.98;
          
          // Add a floor to prevent weights from getting too low
          if (weights[j] < 0.5) weights[j] = 0.5;
        }
      }
    }
    
    // Verify total just in case
    const total = questionsWithRewards.reduce((sum, q) => sum + q.reward, 0);
    if (total !== totalAmount) {
      console.warn(`Adjustment needed: ${totalAmount - total}`);
      // Adjust the first question to ensure total is correct
      questionsWithRewards[0].reward += (totalAmount - total);
    }
    
    return questionsWithRewards;
  }
  
  /**
 * Distributes rewards across a list of questions, doubling the reward for each step
 * @param {Array} questions - Array of question objects
 * @param {number} baseReward - The starting reward amount for the first question
 * @returns {Array} - Original questions array with added reward property
 */
export function distributeDoubledRewards(questions, baseReward = 50) {
    if (!questions || !Array.isArray(questions) || questions.length === 0) {
      throw new Error('Questions must be a non-empty array');
    }
    
    if (baseReward <= 0) {
      throw new Error('Base reward must be a positive number');
    }
    
    // Create a deep copy of questions to avoid mutating the original
    const questionsWithRewards = JSON.parse(JSON.stringify(questions));
    
    // Calculate the total amount that will be distributed
    const totalAmount = baseReward * (Math.pow(2, questions.length) - 1);
    
    // Assign rewards, doubling for each question
    for (let i = 0; i < questionsWithRewards.length; i++) {
      const currentReward = baseReward * Math.pow(2, i);
      questionsWithRewards[i].reward = currentReward;
    }
    
    // Log the total for verification
    console.log(`Total reward distributed: ${totalAmount}`);
    
    return questionsWithRewards;
  }
  
  // Example usage:
  // const questions = [
  //   { id: 1, text: "What is your favorite color?" },
  //   { id: 2, text: "How many hours do you sleep?" },
  //   // ... more questions
  // ];
  // 
  // const questionsWithRewards = distributeRewards(questions, 1000);
  // console.log(questionsWithRewards);
  // => [
  //      { id: 1, text: "What is your favorite color?", reward: 150 },
  //      { id: 2, text: "How many hours do you sleep?", reward: 350 },
  //      ...
  //    ]


  /**
 * Updates a 2D lookup table with flight pricing data
 * @param {Array} departures - Array of objects with from, to, and price properties
 * @param {Object} [dealsTable={}] - Optional existing dealsTable to update (will create new if not provided)
 * @returns {Object} Updated dealsTable
 */
export function updateDealsTable(departures, dealsTable = {}) {
    // Process each departure and update the table
    departures.forEach(element => {
      // Make sure the "from" key exists in the dealsTable
      if (!dealsTable[element.from]) {
        dealsTable[element.from] = {};
      }
      
      // Now it's safe to set the nested value
      dealsTable[element.from][element.to] = element.price;
    });
    
    return dealsTable;
  }