export class CounterManager {
    constructor() {
        this.setup();
        this.activeAnimations = new Set();
        // Track pending updates for each counter
        this.pendingUpdates = {
            score: null,
            account: null,
            travelled: null,
            fun: null
        };
        // Generate flip animations only once
        this.generateFlipAnimations();
    }

    setup() {
        const container = document.createElement('div');
        container.className = 'counter-container';

        // Create the Score counter with larger size (like Account was)
        this.scoreCounter = this.createCounter('Score', 0, '?', 4, 12);
        
        // Create Account counter with smaller size (like Travelled was)
        this.accountCounter = this.createCounter('Account', 0, '$', 4, 12);
        
        // Still create the Travelled counter but don't display it
        this.travelledCounter = this.createCounter('Travelled', 0,4, 12);
        this.travelledCounter.element.style.display = 'none'; // Hide it
        
        this.funCounter = this.createFunCounter('Fun', 0, 4, 12);

        container.append(
            this.scoreCounter.element,      // Score on top
            this.accountCounter.element,    // Then Account
            this.funCounter.element         // Then Fun
            // Travelled is not appended since it's hidden
        );
        document.body.appendChild(container);

        this.scoreValue = 0;
        this.travelledValue = 0;
        this.accountValue = 0;
        this.funValue = 0;

        this.addStyles();
    }

    generateFlipAnimations() {
        if (document.getElementById('flip-animations')) return;

        const style = document.createElement('style');
        style.id = 'flip-animations';

        // Generate all possible transitions (0-9 to 0-9)
        let animations = '';
        for (let from = 0; from < 10; from++) {
            for (let to = 0; to < 10; to++) {
                if (from === to) continue;

                animations += `
                @keyframes flip-${from}-to-${to} {
                    0% {
                        transform: rotateX(0);
                    }
                    49.9% {
                        transform: rotateX(-90deg);
                    }
                    50% {
                        transform: rotateX(-90deg);
                    }
                    100% {
                        transform: rotateX(-180deg);
                    }
                }
                @keyframes flip-text-${from}-to-${to} {
                    0%, 49.9% {
                        content: "${from}";
                    }
                    50%, 100% {
                        content: "${to}";
                    }
                }`;
            }
        }

        style.textContent = animations;
        document.head.appendChild(style);
    }

    createCounter(label, initialValue, kind = 'km', gap = 4, size = 13) {
        const counterDiv = document.createElement('div');
        counterDiv.className = 'counter-wrapper';
        counterDiv.style.gap = gap + 'px';
        counterDiv.style.fontSize = size + 'px';

        const labelSpan = document.createElement('span');
        labelSpan.className = 'counter-label';
        labelSpan.textContent = `${label}`;

        const valueContainer = document.createElement('div');
        valueContainer.className = 'counter-value-container';

        const digits = Math.round(initialValue).toString().padStart(10, '0');
        const digitElements = digits.split('').map(digit => this.createDigit(digit));

        valueContainer.append(...digitElements.map(d => d.element));

        const unitSpan = document.createElement('span');
        unitSpan.className = 'counter-unit';
        unitSpan.textContent = kind;

        counterDiv.append(labelSpan, valueContainer, unitSpan);

        return {
            element: counterDiv,
            digits: digitElements
        };
    }

    createFunCounter(label, initialValue, gap = 4, size = 13) {
        const counterDiv = document.createElement('div');
        counterDiv.className = 'counter-wrapper fun-counter';
        counterDiv.style.gap = gap + 'px';
        counterDiv.style.fontSize = size + 'px';

        const labelSpan = document.createElement('span');
        labelSpan.className = 'counter-label';
        labelSpan.textContent = `${label}`;

        const valueContainer = document.createElement('div');
        valueContainer.className = 'counter-value-container fun-value-container';

        // Create 10 segments
        const segments = Array.from({ length: 10 }, (_, i) => {
            const segment = this.createFunSegment();
            return segment;
        });

        valueContainer.append(...segments.map(s => s.element));

        const kindSpan = document.createElement('span');
        kindSpan.className = 'counter-unit fun-kind';
        kindSpan.textContent = ':)';

        counterDiv.append(labelSpan, valueContainer, kindSpan);

        return {
            element: counterDiv,
            segments,
            container: valueContainer,
            kindLabel: kindSpan
        };
    }

    createFunSegment() {
        const wrapper = document.createElement('div');
        wrapper.className = 'fun-segment-wrapper';

        const segment = document.createElement('div');
        segment.className = 'fun-segment';
        segment.setAttribute('data-value', '○');  // Start with empty circle

        wrapper.appendChild(segment);

        return {
            element: wrapper,
            segment,
            value: '○',
            isFlipping: false
        };
    }

    createDigit(initialValue) {
        const wrapper = document.createElement('div');
        wrapper.className = 'digit-wrapper';

        const digit = document.createElement('div');
        digit.className = 'digit';
        digit.setAttribute('data-value', initialValue);

        wrapper.appendChild(digit);

        return {
            element: wrapper,
            digit,
            value: parseInt(initialValue),
            isFlipping: false
        };
    }

    addStyles() {
        if (document.getElementById('counter-styles')) return;

        const styleSheet = document.createElement('style');
        styleSheet.id = 'counter-styles';

        document.head.appendChild(styleSheet);
    }

    async updateFunSegment(segment, newValue) {
        return new Promise(resolve => {
            if (segment.isFlipping) {
                resolve();
                return;
            }

            segment.isFlipping = true;

            // Apply flip animation
            segment.segment.style.animation = 'flip-fun-segment 0.15s linear';
            segment.segment.style.animationFillMode = 'forwards';

            const handleAnimationEnd = () => {
                segment.segment.style.animation = '';
                segment.segment.style.animationFillMode = '';
                segment.segment.setAttribute('data-value', newValue);
                segment.value = newValue;
                segment.isFlipping = false;
                segment.segment.removeEventListener('animationend', handleAnimationEnd);
                resolve();
            };

            segment.segment.addEventListener('animationend', handleAnimationEnd, { once: true });
        });
    }

    updateFunLevel(value) {
        const container = this.funCounter.container;
        const level = value <= 25 ? 'low-level' :
            value <= 50 ? 'mid-level' :
                'high-level';

        // Remove all level classes
        container.classList.remove('low-level', 'mid-level', 'high-level');
        // Add new level class
        container.classList.add(level);
    }

    setFun(value) {

        // Ensure value is between 0 and 100
        value = Math.max(0, Math.min(100, value));

        // Determine color level
        const colorLevel = value <= 25 ? 'low-level' :
            value <= 50 ? 'mid-level' :
                'high-level';

        // Calculate how many segments should be filled
        const fullSegments = Math.floor(value / 10);
        const partialSegment = value % 10;

        const emoticon = value <= 25 ? ':(' :
            value <= 50 ? ':|' :
                ':)';

        // Update the emoticon
        this.funCounter.kindLabel.textContent = emoticon;


        // Update each segment
        this.funCounter.segments.forEach((segment, index) => {
            let newValue = '';  // Default to empty

            if (index < fullSegments) {
                newValue = '●';  // Full circle for complete segments
            } else if (index === fullSegments && partialSegment > 0) {
                newValue = partialSegment >= 5 ? '◐' : '○';  // Half circle for 5-9, empty circle for 1-4
            }

            // Update segment value and color
            if (segment.value !== newValue || segment.segment.className !== `fun-segment ${newValue ? colorLevel : ''}`) {
                segment.segment.className = `fun-segment ${newValue ? colorLevel : ''}`;
                this.updateFunSegment(segment, newValue);
            }
        });
    }

    flipDigit(digit, from, to) {
        return new Promise((resolve, reject) => {
            try {
                // Convert values to ensure they are numbers
                from = parseInt(from, 10);
                to = parseInt(to, 10);

                // Safety check for invalid values
                if (isNaN(from) || isNaN(to)) {
                    console.error(`Invalid flip values: from=${from}, to=${to}`);
                    digit.isFlipping = false;
                    return resolve(); // Skip the animation but resolve the promise
                }

                // Normalize values to ensure they're in the 0-9 range
                from = ((from % 10) + 10) % 10;
                to = ((to % 10) + 10) % 10;

                // If digit is already flipping, wait for it to complete
                if (digit.isFlipping) {
                    return resolve();
                }

                digit.isFlipping = true;

                // For the same digit, skip animation
                if (from === to) {
                    digit.digit.setAttribute('data-value', to.toString());
                    digit.value = to;
                    digit.isFlipping = false;
                    return resolve();
                }

                // Set up the animation with a moderate duration
                digit.digit.style.animation = `flip-${from}-to-${to} 0.3s ease-in-out`;
                digit.digit.style.animationFillMode = 'forwards';
                digit.digit.style.setProperty('--digit-animation', `flip-text-${from}-to-${to}`);

                // Update the digit value after animation should be complete
                // This ensures the value is set correctly even if animation event doesn't fire
                setTimeout(() => {
                    digit.digit.setAttribute('data-value', to.toString());
                    digit.value = to;
                }, 300); // Match the animation duration
                
                // Use a more reliable method with an appropriate timeout
                const safetyTimeout = setTimeout(() => {
                    if (digit.isFlipping) {
                        // Clean up
                        digit.digit.style.animation = '';
                        digit.digit.style.animationFillMode = '';
                        digit.digit.setAttribute('data-value', to.toString());
                        digit.value = to;
                        digit.isFlipping = false;
                        resolve();
                    }
                }, 400); // Slightly longer than animation duration

                // Handle animation completion
                const handleAnimationEnd = () => {
                    clearTimeout(safetyTimeout);
                    // Clean up the animation properties
                    digit.digit.style.animation = '';
                    digit.digit.style.animationFillMode = '';
                    digit.digit.setAttribute('data-value', to.toString());
                    digit.value = to;
                    digit.isFlipping = false;
                    resolve();
                };

                digit.digit.addEventListener('animationend', handleAnimationEnd, { once: true });
            } catch (error) {
                console.error(`Error in flipDigit: ${error}`);
                // Make sure we clean up and resolve even if there's an error
                digit.digit.setAttribute('data-value', to.toString());
                digit.value = to;
                digit.isFlipping = false;
                resolve();
            }
        });
    }
    
    async animateToTarget(digit, targetValue, isSpending) {
        // If there are many sequential animations happening, just set the final value
        // This helps avoid animation congestion
        if (this.activeAnimations.size > 3) {
            digit.digit.setAttribute('data-value', targetValue.toString());
            digit.value = targetValue;
            digit.isFlipping = false;
            return;
        }
        
        // Get current and target values
        let current = parseInt(digit.value, 10);
        targetValue = parseInt(targetValue, 10);
        
        // Handle NaN values
        if (isNaN(current)) {
            console.error(`Current digit value is NaN: ${digit.value}`);
            current = 0;
        }
        
        if (isNaN(targetValue)) {
            console.error(`Target value is NaN: ${targetValue}`);
            targetValue = 0;
        }
        
        // Skip animation if values are already the same
        if (current === targetValue) {
            return;
        }
        
        // For simple approach, just directly flip to the target value
        try {
            await this.flipDigit(digit, current, targetValue);
            // Add a delay between digits for a more visible cascade effect
            await new Promise(resolve => setTimeout(resolve, 250));
        } catch (error) {
            console.error(`Error during digit flip: ${error}`);
            // Update the digit manually to avoid getting stuck
            digit.value = targetValue;
            digit.digit.setAttribute('data-value', targetValue);
            digit.isFlipping = false;
        }
    }

    async updateCounter(counterObj, newValue, isSpending = false) {
        // Ensure newValue is a valid number
        newValue = Math.max(0, Math.round(Number(newValue)));
        if (isNaN(newValue)) {
            console.error(`Invalid counter value: ${newValue}`);
            return;
        }

        const counterKey = counterObj === this.scoreCounter ? 'score' :
                         counterObj === this.travelledCounter ? 'travelled' : 
                         counterObj === this.accountCounter ? 'account' : 'fun';

        // If animation is in progress, store this update as pending and return
        if (this.activeAnimations.has(counterKey)) {
            this.pendingUpdates[counterKey] = {
                value: newValue,
                isSpending: isSpending
            };
            return;
        }

        // If too many total animations are active, just update values directly without animation
        if (this.activeAnimations.size > 3) {
            const newDigits = newValue.toString().padStart(10, '0');
            for (let index = 0; index < counterObj.digits.length; index++) {
                const digit = counterObj.digits[index];
                const targetValue = parseInt(newDigits[index], 10);
                digit.value = targetValue;
                digit.digit.setAttribute('data-value', targetValue.toString());
                digit.isFlipping = false;
            }
            return;
        }

        // Mark this counter as animating
        this.activeAnimations.add(counterKey);

        try {
            // Important: We need to ensure we're working with the actual current values
            // not just what's in the display
            const currentValues = counterObj.digits.map(d => d.value);
            const newDigits = newValue.toString().padStart(10, '0');
            
            const animationPromises = [];
            
            for (let index = 0; index < counterObj.digits.length; index++) {
                const digit = counterObj.digits[index];
                const targetValue = parseInt(newDigits[index], 10);
                const currentValue = parseInt(currentValues[index], 10);

                if (currentValue === targetValue) continue;

                const animationPromise = new Promise(resolve => {
                    setTimeout(() => {
                        // CRITICAL FIX: Explicitly set the digit's current value to ensure 
                        // it matches what we expect before animating
                        digit.value = currentValue;
                        digit.digit.setAttribute('data-value', currentValue.toString());
                        
                        // Now animate to target
                        this.animateToTarget(digit, targetValue, isSpending)
                            .then(resolve)
                            .catch(error => {
                                console.error(`Animation error for digit ${index}: ${error}`);
                                digit.value = targetValue;
                                digit.digit.setAttribute('data-value', targetValue.toString());
                                digit.isFlipping = false;
                                resolve();
                            });
                    }, index * 80); // Delay between digits for cascade effect
                });

                animationPromises.push(animationPromise);
            }

            await Promise.all(animationPromises);
        } catch (error) {
            console.error(`Counter update error: ${error}`);
            // Ensure the counter shows the correct final value
            const newDigits = newValue.toString().padStart(10, '0');
            for (let index = 0; index < counterObj.digits.length; index++) {
                const digit = counterObj.digits[index];
                const targetValue = parseInt(newDigits[index], 10);
                digit.value = targetValue;
                digit.digit.setAttribute('data-value', targetValue.toString());
                digit.isFlipping = false;
            }
        } finally {
            this.activeAnimations.delete(counterKey);
            
            // Process any pending updates that came in during animation
            if (this.pendingUpdates[counterKey]) {
                const pendingUpdate = this.pendingUpdates[counterKey];
                this.pendingUpdates[counterKey] = null;
                
                // Small delay to allow UI to update
                setTimeout(() => {
                    this.updateCounter(counterObj, pendingUpdate.value, pendingUpdate.isSpending);
                }, 10);
            }
        }
    }

    // New function to set Score
    setScore(value) {
        const isDecrease = value < this.scoreValue;
        this.scoreValue = value;
        this.updateCounter(this.scoreCounter, value, isDecrease);
    }

    // Keep existing functions
    setTravelled(value) {
        // Still support this function for backward compatibility
        // Even though the counter is hidden
        const isIncrease = value > this.travelledValue;
        this.travelledValue = value;
        this.updateCounter(this.travelledCounter, value, !isIncrease);
    }

    setAccount(value) {
        const isSpending = value < this.accountValue;
        this.accountValue = value;
        this.updateCounter(this.accountCounter, value, isSpending);
    }
}