import * as THREE from 'three';

/**
 * SphericalPulseSystem - A class for adding animated concentric circles to specific lat/long points
 * on a globe using a single spherical mesh with a shader
 */
export class SphericalPulseSystem {
    /**
     * @param {Object} options - Configuration options
     * @param {number} options.globeRadius - Radius of the globe
     * @param {THREE.Object3D} options.container - The container to add the pulse system to
     * @param {THREE.Mesh} options.globeMesh - Reference to the globe mesh
     * @param {THREE.Texture} [options.normalMap] - The normal map from the globe
     * @param {number} [options.maxMarkers=20] - Maximum number of simultaneous markers
     * @param {number} [options.maxRadius=0.2] - Maximum radius for the pulse animation (as fraction of globe radius)
     * @param {number} [options.animationDuration=2.5] - Duration of a complete pulse animation in seconds
     * @param {number} [options.numRings=3] - Number of rings per pulse marker
     * @param {string} [options.color='#00ffff'] - Default color of the pulse rings
     * @param {number} [options.opacity=0.8] - Maximum opacity of the rings
     * @param {number} [options.ringWidth=0.03] - Width of each ring (relative to total radius)
     */
    constructor(options) {
        this.globeRadius = options.globeRadius;
        this.container = options.container;
        this.globeMesh = options.globeMesh;
        this.normalMap = options.normalMap;
        this.maxMarkers = options.maxMarkers || 20;
        this.maxRadius = options.maxRadius || 0.2;
        this.animationDuration = options.animationDuration || 2.5;
        this.numRings = options.numRings || 3;
        this.defaultColor = options.color || '#00ffff';
        this.opacity = options.opacity || 0.8;
        this.ringWidth = options.ringWidth || 0.03;
        
        // Active markers data
        this.markers = new Map();
        this.nextMarkerId = 0;
        
        // Setup clock for animation
        this.clock = new THREE.Clock();
        this.clock.start();
        
        // Flag to control animation
        this.animating = false;
        
        this._initShader();
    }
    
    /**
     * Initialize the shader and create the spherical mesh
     * @private
     */
    _initShader() {
        // Create arrays to store marker data in the shader
        const markerPositions = new Float32Array(this.maxMarkers * 3);
        const markerParams = new Float32Array(this.maxMarkers * 4); // [active, phase, size, reserved]
        const markerColors = new Float32Array(this.maxMarkers * 3);
        
        // Fill with default values
        for (let i = 0; i < this.maxMarkers; i++) {
            markerPositions[i * 3] = 0;
            markerPositions[i * 3 + 1] = 0;
            markerPositions[i * 3 + 2] = 0;
            
            markerParams[i * 4] = 0; // inactive
            markerParams[i * 4 + 1] = 0; // phase
            markerParams[i * 4 + 2] = this.maxRadius; // size
            markerParams[i * 4 + 3] = 0; // reserved
            
            const defaultColor = new THREE.Color(this.defaultColor);
            markerColors[i * 3] = defaultColor.r;
            markerColors[i * 3 + 1] = defaultColor.g;
            markerColors[i * 3 + 2] = defaultColor.b;
        }
        
        // Make sure we have a valid normal map or create a dummy one
        const normalMap = this.normalMap || new THREE.DataTexture(
            new Uint8Array([127, 127, 255, 255]), // RGBA default normal pointing up
            1, 1, 
            THREE.RGBAFormat
        );
        
        if (!this.normalMap) {
            normalMap.needsUpdate = true;
        }
        
        // Create shader material
        this.material = new THREE.ShaderMaterial({
            uniforms: {
                markerPositions: { value: markerPositions },
                markerParams: { value: markerParams },
                markerColors: { value: markerColors },
                maxMarkers: { value: this.maxMarkers },
                globeRadius: { value: this.globeRadius },
                normalMap: { value: normalMap },
                maxRadius: { value: this.maxRadius },
                ringWidth: { value: this.ringWidth },
                opacity: { value: this.opacity },
                time: { value: 0 }
            },
            vertexShader: `
                uniform float time;
                uniform float globeRadius;
                uniform sampler2D normalMap;
                
                varying vec3 vPosition;
                varying vec3 vNormal;
                varying vec2 vUv;
                
                void main() {
                    vPosition = position;
                    vNormal = normalize(position);
                    
                    // Calculate UV coordinates (polar mapping)
                    float lat = acos(vNormal.y / length(vNormal)) / 3.14159;
                    float lon = (atan(vNormal.z, vNormal.x) + 3.14159) / (2.0 * 3.14159);
                    vUv = vec2(lon, lat);
                    
                    // Pass position directly without modification
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
            `,
            fragmentShader: `
                uniform float time;
                uniform float opacity;
                uniform float maxRadius;
                uniform float ringWidth;
                uniform int maxMarkers;
                uniform float globeRadius;
                uniform vec3 markerPositions[${this.maxMarkers}];
                uniform vec4 markerParams[${this.maxMarkers}]; // [active, phase, size, reserved]
                uniform vec3 markerColors[${this.maxMarkers}];
                uniform sampler2D normalMap;
                
                varying vec3 vPosition;
                varying vec3 vNormal;
                varying vec2 vUv;
                
float calculateRingAlpha(vec3 position, vec3 markerPos, float phase, float size) {
    // Normalize positions to get directions
    vec3 posDir = normalize(position);
    vec3 markerDir = normalize(markerPos);
    
    // Calculate angular distance using dot product
    float cosAngle = dot(posDir, markerDir);
    
    // Protection against floating point errors
    cosAngle = clamp(cosAngle, -1.0, 1.0);
    
    float angle = acos(cosAngle);
    
    // Convert to distance on sphere (in radians)
    float dist = angle;
    
    // Scale by globe radius to get actual distance
    float actualDist = dist * globeRadius;
    
    // Calculate multiple rings for this marker
    float markerAlpha = 0.0;
    
    for (int i = 0; i < 3; i++) {
        float ringPhase = mod(phase - float(i) * 0.33, 1.0);
        float maxDist = size;
        float ringRadius = ringPhase * maxDist;
        
        // Calculate ring width (can be adjusted based on expansion)
        float actualRingWidth = ringWidth * maxDist;
        
        // Calculate inner and outer boundaries of the ring
        float innerRadius = ringRadius - (actualRingWidth * 0.5);
        float outerRadius = ringRadius + (actualRingWidth * 0.5);
        
        // Determine if the current point is within the ring boundaries
        // Using smoothstep for anti-aliasing at the edges
        float edgeSmoothing = min(0.005 * globeRadius, actualRingWidth * 0.1);
        
        // Inner edge (1.0 when actualDist > innerRadius)
        float innerMask = smoothstep(innerRadius - edgeSmoothing, innerRadius + edgeSmoothing, actualDist);
        
        // Outer edge (1.0 when actualDist < outerRadius)
        float outerMask = 1.0 - smoothstep(outerRadius - edgeSmoothing, outerRadius + edgeSmoothing, actualDist);
        
        // Combine to get the ring (1.0 only within the ring, 0.0 elsewhere)
        float ringMask = innerMask * outerMask;
        
        // Fade out as the ring expands
        float fadeOut = 1.0 - ringPhase;
        
        // Combine all factors
        markerAlpha = max(markerAlpha, ringMask * fadeOut);
    }
    
    return markerAlpha;
}
                
                void main() {
                    // Default transparent
                    vec4 finalColor = vec4(0.0, 0.0, 0.0, 0.0);
                    
                    // Get normal from normal map for lighting
                    vec3 normalFromMap = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
                    vec3 mixedNormal = normalize(mix(vNormal, normalFromMap, 0.3));
                    
                    // Light direction for normal-based lighting
                    vec3 lightDir = normalize(vec3(0.5, 1.0, 0.3));
                    float lightIntensity = max(0.5, dot(mixedNormal, lightDir));
                    
                    // Process each marker
                    for (int i = 0; i < ${this.maxMarkers}; i++) {
                        // Skip inactive markers
                        if (markerParams[i].x < 0.5) continue;
                        
                        // Get marker data
                        vec3 markerPos = markerPositions[i];
                        float phase = markerParams[i].y;
                        float size = markerParams[i].z * maxRadius;
                        vec3 markerColor = markerColors[i];
                        
                        // Calculate this marker's contribution
                        float alpha = calculateRingAlpha(vPosition, markerPos, phase, size);
                        
                        // Apply lighting to the marker color
                        vec3 litColor = markerColor * lightIntensity;
                        
                        // Blend with existing colors (use premultiplied alpha)
                        float markerOpacity = alpha * opacity;
                        finalColor.rgb = finalColor.rgb * (1.0 - markerOpacity) + litColor * markerOpacity;
                        finalColor.a = max(finalColor.a, markerOpacity);
                    }
                    
                    gl_FragColor = finalColor;
                }
            `,
            transparent: true,
            side: THREE.FrontSide,
            depthWrite: false,
            blending: THREE.AdditiveBlending
        });
        
        // Create a sphere slightly larger than the globe
        const geometry = new THREE.SphereGeometry(this.globeRadius * 1.006, 64, 64);
        this.mesh = new THREE.Mesh(geometry, this.material);
        this.container.add(this.mesh);
    }
    
    /**
     * Convert latitude and longitude to 3D position
     * @param {number} lat - Latitude in degrees
     * @param {number} lng - Longitude in degrees
     * @returns {THREE.Vector3} - 3D position vector
     */
    latLongToVector3(lat, lng) {
        const phi = (90 - lat) * (Math.PI / 180);
        const theta = (lng + 180) * (Math.PI / 180);
        
        const x = -this.globeRadius * Math.sin(phi) * Math.cos(theta);
        const y = this.globeRadius * Math.cos(phi);
        const z = this.globeRadius * Math.sin(phi) * Math.sin(theta);
        
        return new THREE.Vector3(x, y, z);
    }
    
    /**
     * Add a marker at the specified latitude and longitude
     * @param {number} lat - Latitude in degrees
     * @param {number} lng - Longitude in degrees
     * @param {Object} [options] - Optional customization
     * @param {string|THREE.Color} [options.color] - Custom color for this marker (hex string or THREE.Color)
     * @param {number} [options.maxRadius] - Custom max radius for this marker
     * @param {number} [options.phase=0] - Initial phase (0-1) of the animation
     * @returns {number} - ID of the created marker for future reference
     */
    addMarker(lat, lng, options = {}) {
        // Find an available marker slot
        let markerId = -1;
        for (let i = 0; i < this.maxMarkers; i++) {
            if (this.material.uniforms.markerParams.value[i * 4] < 0.5) {
                markerId = i;
                break;
            }
        }
        
        if (markerId === -1) {
            console.warn('Maximum number of markers reached');
            return -1;
        }
        
        // Position
        const position = this.latLongToVector3(lat, lng);
        this.material.uniforms.markerPositions.value[markerId * 3] = position.x;
        this.material.uniforms.markerPositions.value[markerId * 3 + 1] = position.y;
        this.material.uniforms.markerPositions.value[markerId * 3 + 2] = position.z;
        
        // Parameters
        this.material.uniforms.markerParams.value[markerId * 4] = 1.0; // active
        this.material.uniforms.markerParams.value[markerId * 4 + 1] = options.phase !== undefined ? options.phase : Math.random(); // phase
        this.material.uniforms.markerParams.value[markerId * 4 + 2] = options.maxRadius || 1.0; // relative size
        
        // Color - handle THREE.Color or hex string
        let color;
        if (options.color instanceof THREE.Color) {
            color = options.color;
        } else if (typeof options.color === 'string') {
            color = new THREE.Color(options.color);
        } else {
            color = new THREE.Color(this.defaultColor);
        }
        
        this.material.uniforms.markerColors.value[markerId * 3] = color.r;
        this.material.uniforms.markerColors.value[markerId * 3 + 1] = color.g;
        this.material.uniforms.markerColors.value[markerId * 3 + 2] = color.b;
        
        // Need to mark the arrays as needing update
        this.material.uniforms.markerPositions.needsUpdate = true;
        this.material.uniforms.markerParams.needsUpdate = true;
        this.material.uniforms.markerColors.needsUpdate = true;
        
        // Store metadata for this marker
        const markerData = {
            id: markerId,
            position,
            lat,
            lng,
            options
        };
        
        // Generate a unique id for external reference
        const uniqueId = this.nextMarkerId++;
        this.markers.set(uniqueId, markerData);
        
        // Start animation if not already started
        if (!this.animating) {
            this.startAnimation();
        }
        
        return uniqueId;
    }
    
    /**
     * Remove a marker by its ID
     * @param {number} markerId - ID returned from addMarker
     */
    removeMarker(markerId) {
        if (!this.markers.has(markerId)) {
            return;
        }
        
        const markerData = this.markers.get(markerId);
        const slotId = markerData.id;
        
        // Deactivate in shader
        this.material.uniforms.markerParams.value[slotId * 4] = 0.0; // set inactive
        this.material.uniforms.markerParams.needsUpdate = true;
        
        // Remove from our tracking
        this.markers.delete(markerId);
        
        // Stop animation if no markers left
        if (this.markers.size === 0) {
            this.stopAnimation();
        }
    }
    
    /**
     * Update the settings for an existing marker
     * @param {number} markerId - ID returned from addMarker
     * @param {Object} options - New options for the marker
     * @param {string|THREE.Color} [options.color] - New color for the marker
     * @param {number} [options.maxRadius] - New maximum radius
     * @param {number} [options.phase] - New animation phase (0-1)
     */
    updateMarker(markerId, options) {
        if (!this.markers.has(markerId)) {
            return;
        }
        
        const markerData = this.markers.get(markerId);
        const slotId = markerData.id;
        
        // Update color if specified
        if (options.color !== undefined) {
            let color;
            if (options.color instanceof THREE.Color) {
                color = options.color;
            } else {
                color = new THREE.Color(options.color);
            }
            
            this.material.uniforms.markerColors.value[slotId * 3] = color.r;
            this.material.uniforms.markerColors.value[slotId * 3 + 1] = color.g;
            this.material.uniforms.markerColors.value[slotId * 3 + 2] = color.b;
            this.material.uniforms.markerColors.needsUpdate = true;
            markerData.options.color = options.color;
        }
        
        // Update size if specified
        if (options.maxRadius !== undefined) {
            this.material.uniforms.markerParams.value[slotId * 4 + 2] = options.maxRadius;
            this.material.uniforms.markerParams.needsUpdate = true;
            markerData.options.maxRadius = options.maxRadius;
        }
        
        // Update phase if specified
        if (options.phase !== undefined) {
            this.material.uniforms.markerParams.value[slotId * 4 + 1] = options.phase;
            this.material.uniforms.markerParams.needsUpdate = true;
        }
    }
    
    /**
     * Clear all markers
     */
    clearMarkers() {
        // Reset all markers to inactive
        for (let i = 0; i < this.maxMarkers; i++) {
            this.material.uniforms.markerParams.value[i * 4] = 0.0; // inactive
        }
        this.material.uniforms.markerParams.needsUpdate = true;
        
        // Clear our tracking
        this.markers.clear();
        
        // Stop animation
        this.stopAnimation();
    }
    
    /**
     * Start the animation
     */
    startAnimation() {
        if (!this.animating) {
            this.animating = true;
            this.animate();
        }
    }
    
    /**
     * Stop the animation
     */
    stopAnimation() {
        this.animating = false;
    }
    
    /**
     * Animation loop
     */
    animate() {
        if (!this.animating) return;
        
        const deltaTime = this.clock.getDelta();
        const time = this.clock.getElapsedTime();
        const pulseSpeed = 1 / this.animationDuration;
        
        // Update time uniform
        this.material.uniforms.time.value = time;
        
        // Update phases for all active markers
        for (let i = 0; i < this.maxMarkers; i++) {
            if (this.material.uniforms.markerParams.value[i * 4] > 0.5) {
                let phase = this.material.uniforms.markerParams.value[i * 4 + 1];
                phase = (phase + deltaTime * pulseSpeed) % 1.0;
                this.material.uniforms.markerParams.value[i * 4 + 1] = phase;
            }
        }
        this.material.uniforms.markerParams.needsUpdate = true;
        
        // Ensure we're rendering
        if (this.markers.size > 0) {
            this.material.needsUpdate = true;
        }
        
        // Request next frame
        requestAnimationFrame(() => this.animate());
    }
    
    /**
     * Set visibility of the pulse system
     * @param {boolean} visible - Whether the system should be visible
     */
    setVisible(visible) {
        this.mesh.visible = visible;
    }
    
    /**
     * Batch add multiple markers
     * @param {Array} markersData - Array of marker data objects with {lat, lng, options}
     * @returns {Array} - Array of created marker IDs
     */
    addMarkers(markersData) {
        return markersData.map(data => this.addMarker(data.lat, data.lng, data.options));
    }
    
    /**
     * Dispose resources
     */
    dispose() {
        this.container.remove(this.mesh);
        this.mesh.geometry.dispose();
        this.material.dispose();
        this.clearMarkers();
    }
}