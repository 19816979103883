// HelpModal.js
import { CardSpreadModal } from './modal-system.js';

/**
 * A class that handles showing help information in a card spread modal
 */
export class HelpModal {
  constructor( three ) {

    this.three = three;
    this.setupEventListeners(  );
  }

  /**
   * Set up event listeners for the help modal trigger
   */
  setupEventListeners() {
    const aboutLink = document.getElementById('about');
    if (aboutLink) {
      aboutLink.addEventListener('click', (e) => {

        this.three.stopRender();

        e.preventDefault();
        this.modal = new CardSpreadModal({
            onClose: async () => {
                console.log('closing modal');
                this.three.startRender();
            }});    
        this.showHelpCards();
      });
    }
  }

  /**
   * Show the help cards in the modal
   */
  showHelpCards() {
    const helpCards = [
      
      {
        type: 'title',
        title: '',
        gradient: 'linear-gradient(135deg, rgb(60 145 230) 0%, rgb(135 206 250) 100%)',
        backgroundImage: 'assets/about-duck.jpg',
      },
      {
        type: 'info',
        title: 'About',
        category: 'Trailmarks',
        message: 'Trailmarks is a global fact-checking adventure that will take you travelling around the world! Journey between cities, complete quizzes and challenges to circumnavigate the globe from London back to London.<br/><br/>Made by <a target="_blank" href="https://www.einarsen.no/">Mats Einarsen</a>.<br/><br/>Have feedback or feature requests? Find me on <a target="_blank" href="https://bsky.app/profile/mats.einarsen.no">BlueSky</a>.',
      },
      {
        type: 'info',
        category: 'Getting Started',
        title: 'How to Play',
        message: 'Begin in London and travel the globe while managing your budget and fun level.<br/><br/>Click on cities to fly between them. Explore each location\'s cards for money-making opportunities and ensure your fun meter stays above zero.<br/><br/>Complete your journey by returning to London with the highest possible score.',
      },
      {
        type: 'info',
        title: 'Game Objective',
        message: 'Your mission is to circumnavigate the globe, ending where you started in London. You\'ll earn points for correct answers, achievements, good trades and efficient travel.<br/><br/>Balance your budget and fun level throughout your journey to achieve the highest possible fact-checker ranking upon completion.',
      },
      {
        type: 'info',
        title: 'Loading and Saving',
        message: 'Without an account, your game is saved in your unique URL. Bookmark this URL to continue your game later.<br/><br/>Keep this URL private—anyone with your public ID can access and play your game.<br/><br/>Account-based saving is coming soon for enhanced security.',
      },
      {
        type: 'info',
        title: 'Flights',
        message: 'Travel to any city by clicking or tapping its name. Your boarding pass will display the price and any restrictions.<br/><br/>If travel is blocked, you\'ll need to resolve any issues before departure or select an alternative destination.<br/><br/>Pay attention to scheduled flights. You can find them on the departure board in cities with airports. They are much cheaper. If there are none, try a short trip to a city with an airport.',
      },
      {
        type: 'info',
        title: 'Visas',
        message: 'Most destinations require visas for entry. The Trailmarks travel agency gives you a special benefit: you can visit any country that citizens of your current location can enter visa-free.<br/><br/>Use this advantage to plan your route strategically!',
      },
      {
        type: 'info',
        title: 'Fun Meter',
        category: 'Work/Life Balance',
        message: 'If your Fun Meter reaches zero, you\'ll be grounded until it increases. Economy flights, incorrect answers, and unsuccesful border checks all reduce your fun level.<br/><br/>You can boost your Fun Meter by taking a day out in the city—though using the outdated Trailmarks guide guarantees some misadventures!',
      },
      {
        type: 'info',
        title: 'Trade Goods',
        category: 'Budget Management',
        message: 'Many cities offers unique local specialties you can purchase and resell elsewhere for profit. Some items also provide special effects, particularly for your Fun Meter.<br/><br/>Warning: Certain goods are taxed or banned in some countries. Pay close attention to your inventory when crossing borders to avoid penalties.',
      },
      {
        type: 'info',
        title: 'Crossing Borders',
        message: 'Border crossings may require taxes on goods you\'re carrying. Prohibited items will be confiscated and may result in being sent back without a ticket refund.<br/><br/>Check local regulations before traveling to avoid costly setbacks to your journey.',
      },
      {
        type: 'info',
        title: 'Thank you!',
        category: 'Acknowlegdements',
        message: `Thanks, Wikipedia contributors <a href="https://en.wikipedia.org/wiki/File:World_elevation_map.png" target="_blank">Avsa</a> and <a target="_blank" href="https://commons.wikimedia.org/wiki/File:Equirectangular-projection-topographic-world.jpg">Gundan</a>, for the CC-BY 4.0 licenced source maps. Thank you to <a target="_blank" href="https://simplemaps.com/data/world-cities">Simple Maps</a> for city data.
      <br/><br/>Made with <a href="https://threejs.org/" target="_blank">three.js</a>. Thank you, three.js contributors!<br/><br/><a target="_blank" href="https://jam.pieter.com" style="font-family: 'system-ui', sans-serif; font-size: 14px; font-weight: bold; background: #fff; color: #000; text-decoration: none; z-index: 10; border-top-left-radius: 12px; z-index: 10000; border: 1px solid #fff;">🕹️ Vibe Jam 2025</a>`,
      },
    ];
    this.modal.show(helpCards);
  }
}