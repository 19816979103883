import * as THREE from 'three';

export class Globe {
    constructor(renderer, camera, options = {}) {
        this.renderer = renderer;
        this.camera = camera;
        this.radius = 1;
        this.widthSegments = 48;
        this.heightSegments = 48;
        this.container = new THREE.Object3D();
        this.mesh = null;
        this.atmosphere = null;
        this.atmosphericFog = null;
        this.colorTexture = null;
        this.normalTexture = null;
        this.roughnessTexture = null;
        this.aoTexture = null;

        this.textureUrls = {
//            color: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/10a143d4-6576-4782-3e0b-71aaf3b1c800/Unchanged',
            color: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/10c4b684-89b0-4a72-96ab-15e3056c3600/Unchanged', // upscaled
      //      color: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/1eeb1e94-3808-4af3-8f26-53416af48600/Unchanged',
    //     normal: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/82a0ff7d-58f6-4ef8-6bd9-ee7ee22d1900/Unchanged',
        normal: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/56e234b6-85a6-46bb-6f20-6675107f3700/Unchanged',       
            ao: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/aa99635d-1918-43a0-dda1-7ef3bc973700/Unchanged',
   //         normal: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/b415735b-1bb2-435c-b19b-29685ea03900/Unchanged',
   // roughness: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/855805a9-f43c-4b4b-1985-19666cad7100/Unchanged',
          //  roughness: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/900d989e-63af-41f2-ea29-d3562ed99b00/Unchanged',
         //        roughness: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/e656c7e6-c6e9-45ba-e5f8-bcd4aa89ff00/Unchanged',
         roughness: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/4ff8d3f9-d7b2-47e3-a874-c24eb2821900/Unchanged',   
         //roughness: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/2beae295-d1be-4309-4cef-ccdca907aa00/Unchanged'
            
        };
    }

    async initialize(envMap = null) {
        // Load textures
        [this.colorTexture, this.normalTexture, this.aoTexture, this.roughnessTexture] = await Promise.all([
            this.loadTexture(this.textureUrls.color),
            this.loadTexture(this.textureUrls.normal),
            this.loadTexture(this.textureUrls.ao),
            this.loadTexture(this.textureUrls.roughness)
        ]);

        // Setup textures
        [this.colorTexture, this.normalTexture, this.aoTexture, this.roughnessTexture].forEach(texture => 
            this.setupTexture(texture, this.renderer.capabilities.getMaxAnisotropy())
        );

        const geometry = new THREE.SphereGeometry(this.radius, this.widthSegments, this.heightSegments);
        const material = new THREE.MeshPhysicalMaterial({
            map: this.colorTexture,
            normalMap: isMobile ? null : this.normalTexture,
            aoMap: isMobile ? null : this.aoTexture,
            aoMapIntensity: 1.2, // Adjust to taste
            normalScale: new THREE.Vector2(-1.3, 1.3),
            roughnessMap: isMobile ? null : this.roughnessTexture,
            roughness: 0.8,
            metalness: 0.2,
            envMap: isMobile ? null : envMap,
            envMapIntensity: 0.7,
            transparent: false,
            iridescence: 0.5, 
            iridescenceIOR: 1.8,
            side: THREE.DoubleSide,
            depthWrite: true,
            
        });
        

        this.mesh = new THREE.Mesh(geometry, material);
        this.mesh.castShadow = true;
        this.mesh.receiveShadow = true;
        this.mesh.rotation.y = Math.PI;
        
        this.container.add(this.mesh);

        return this;
    }

    loadTexture(url) {
        return new Promise((resolve, reject) => {
            new THREE.TextureLoader().load(url, resolve, undefined, reject);
        });
    }

    setupTexture(texture, maxAnisotropy) {
        texture.anisotropy = maxAnisotropy;
        
        // Color/albedo maps should be in sRGB
        if (texture === this.colorTexture) {
            texture.colorSpace = THREE.SRGBColorSpace;
        } else {
            // Normal and roughness maps should be in linear space
            texture.colorSpace = THREE.LinearSRGBColorSpace;
        }
        
        return texture;
    }


}   