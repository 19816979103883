export class CityExplorer {
    constructor(cityData, cityName, globeState, cities) {
        this.cityData = cityData;
        this.genericEvents = genericEvents;
        this.cityName = cityName;
        this.globeState = globeState;
        this.cities = cities;
        
        // Define the allowed metrics keys
        this.allowedMetricsKeys = new Set([
            'fun', 
            'account', 
//            'add_item', 
            'random_item', 
            'lost_item', 
            'persona_non_grata', 
            'quiz_sudden_death', 
            'local_sudden_death', 
            'must_go_local', 
            'must_go_country', 
            'must_go_nearby', 
            'must_go_city', 
            'made_friend', 
            'next_class', 
            'quiz_bonus'
        ]);

        // Set the current city in globeState if it's different
        const currentCity = this.globeState.get('currentCity');
        if (currentCity !== cityName) {
            this.globeState.set('currentCity', cityName);
            // Clear cached itinerary when city changes
            this.globeState.set('cachedItinerary', null);
        }
    }

    // Create a probability-weighted sorted list of events
    sortEventsByProbability(events) {
        const eventsWithRandom = events.map(event => ({
            ...event,
            randomValue: Math.random() * event.probability
        }));
        
        return eventsWithRandom
            .sort((a, b) => b.randomValue - a.randomValue)
            .map(({ randomValue, ...event }) => event);
    }

    // Calculate total fun score for a set of events
    calculateNetFun(events) {
        return events.reduce((total, event) => {
            return total + (event.impacts?.fun || 0);
        }, 0);
    }

    // Summarize all impacts across events
    summarizeImpacts(events) {
        const summary = {
            numeric: {},      // For numeric impacts like fun, cost
            special: {},      // For boolean/string impacts like lost_item
            flags: new Set()  // For tracking any boolean flags
        };

        events.forEach(event => {
            const impacts = event.impacts || {};
            
            // Process all impact keys
            Object.entries(impacts).forEach(([key, value]) => {
                // Skip if the key is not in the allowed list
                if (!this.allowedMetricsKeys.has(key.toLowerCase())) {
                    return;
                }
                
                // Handle the special must_go_nearby case
                if (key.toLowerCase() === "must_go_nearby") {
                    if (!summary.special[key]) {
                        summary.special[key] = new Set();
                    }
                    // Replace the placeholder with the actual nearest ISO2 code
                    summary.special[key].add(this.cities[this.cityName].nearestIso2);
                    return; // Skip the rest of the processing for this key
                }
                
                // Handle numeric values
                if (typeof value === 'number') {
                    summary.numeric[key] = (summary.numeric[key] || 0) + value;
                }
                // Handle boolean flags
                else if (typeof value === 'boolean') {
                    if (value) {
                        summary.flags.add(key);
                    }
                }
                // Handle string/other values
                else if (value) {
                    if (!summary.special[key]) {
                        summary.special[key] = new Set();
                    }
                    summary.special[key].add(value);
                }
            });
        });

        // Convert Sets to arrays for JSON serialization
        summary.flags = Array.from(summary.flags);
        Object.keys(summary.special).forEach(key => {
            summary.special[key] = Array.from(summary.special[key]);
        });

        return summary;
    }
    
    // Generate a 3-step itinerary
    generateItinerary() {
        // Check if we have a cached itinerary for the current city
        const cachedItinerary = this.globeState.get('cachedItinerary');
        
        // If we have a cached itinerary and it's for the current city, return it with the onSpin function
        if (cachedItinerary && cachedItinerary.cardTitle === `${this.cityName} Itinerary`) {
            // Restore the onSpin function to the cached itinerary
            return {
                ...cachedItinerary,
                onSpin: (results) => {
                    console.log('Card spun, results:', results);
                }
            };
        }
        
        // Otherwise generate a new itinerary
        const allEvents = [
            ...this.cityData,
            ...this.genericEvents
        ];
        
        let selectedEvents;
        let attempts = 0;
        const MAX_ATTEMPTS = 10;

        do {
            const sortedEvents = this.sortEventsByProbability(allEvents);
            selectedEvents = sortedEvents.slice(0, 3);
            attempts++;
        } while (this.calculateNetFun(selectedEvents) <= 0 && attempts < MAX_ATTEMPTS);

        if (attempts >= MAX_ATTEMPTS) {
            const positiveEvents = allEvents.filter(event => (event.impacts?.fun || 0) > 0);
            const otherEvents = allEvents.filter(event => (event.impacts?.fun || 0) <= 0);
            
            const sortedPositive = this.sortEventsByProbability(positiveEvents);
            const sortedOther = this.sortEventsByProbability(otherEvents);
            
            selectedEvents = [
                ...sortedPositive.slice(0, 2),
                ...sortedOther.slice(0, 1)
            ].slice(0, 3);
        }
        
        const steps = selectedEvents.map(event => ({
            title: event.action,
            description: event.context || "",
            metrics: this.formatMetrics(event.impacts || {})
        }));
        
        const itinerary = {
            type: 'itinerary-card',
            title: 'Create a memory',
            subtitle: `Take a day out and explore ${this.cityName}.<br/><br/>Fun guaranteed!`,
            buttonText: '$50',
            cardTitle: `${this.cityName} Itinerary`,
            results: steps,
            totalImpacts: this.summarizeImpacts(selectedEvents),
            onSpin: (results) => {
                console.log('Card spun, results:', results);
            }
        };
        
        // Create a serializable version without the function reference
        const serializableItinerary = {
            ...itinerary,
            onSpin: null // Remove the function reference for Firebase storage
        };
        
        // Cache the serializable version of the itinerary
        this.globeState.set('cachedItinerary', serializableItinerary);
        
        return itinerary;
    }
    
    formatMetrics(impacts) {
        const metrics = {};
        
        // Format only the allowed impacts
        Object.entries(impacts).forEach(([key, value]) => {
            // Convert key to lowercase for case-insensitive matching
            const lowercaseKey = key.toLowerCase();
            
            // Only include keys that are in the allowed list
            if (this.allowedMetricsKeys.has(lowercaseKey)) {
                // Check if this is our special placeholder key
                if (lowercaseKey === "must_go_nearby") {
                    // Replace with the actual nearest ISO2 code
                    metrics[key] = this.cities[this.cityName].nearestIso2;
                } else {
                    // Otherwise use the original value
                    metrics[key] = value;
                }
            }
        });
        
        return metrics;
    }
}


// Generic events that could happen in any city
const genericEvents = [
    // Streamlined Original Events
    {
        id: "generic_1",
        action: "Boarded wrong train",
        context: "Navigation challenges in unfamiliar cities",
        probability: 0.1,
        impacts: {
            fun: -5,
            account: -100
        }
    },
    {
        id: "generic_2",
        action: "Won travel pillow in street raffle",
        context: "Comfort item for long journeys",
        probability: 0.05,
        impacts: {
            fun: 5,
            new_item: "travel_pillow",
        }
    },
    {
        id: "generic_3",
        action: "Found rare comic in second-hand bookstore",
        context: "Hidden treasures in local shops",
        probability: 0.08,
        impacts: {
            fun: 10,
            new_item: "comic_book",
        }
    },
    {
        id: "generic_4",
        action: "Surprise upgrade to first class",
        context: "Occasional airline perk on undersold flights",
        probability: 0.05,
        impacts: {
            fun: 10,
            next_class: "first"
        }
    },
    {
        id: "generic_5",
        action: "Pickpocketed in crowded market",
        context: "Tourists often targeted in busy areas",
        probability: 0.06,
        impacts: {
            fun: -10,
            account: -250
        }
    },
    {
        id: "generic_19",
        action: "Returned to hotel to discover someone had gone through the luggage",
        context: "Should probably have stayed somewhere else",
        probability: 0.08,
        impacts: {
            fun: -10,
            lost_item: 1,
        }
    },
    {
        id: "generic_20",
        action: "Airline lost your luggage",
        context: "Baggage handling systems occasionally fail",
        probability: 0.03,
        impacts: {
            fun: -15,
            lost_item: 2,
        }
    },
    {
        id: "generic_28",
        action: "Taxi drove off with your luggage",
        context: "Unbelievable!",
        probability: 0.005,
        impacts: {
            fun: -20,
            lost_item: 3,
        }
    },
    {
        id: "generic_21",
        action: "Rental car had hidden fees",
        context: "Because of course it did",
        probability: 0.1,
        impacts: {
            fun: -7,
            account: -125
        }
    },

    {
        id: "generic_26",
        action: "Boarded wrong train",
        context: "Navigation challenges in unfamiliar cities",
        probability: 0.1,
        impacts: {
            fun: -5,
            account: -250
        }
    },
    {
        id: "generic_27",
        action: "A fellow traveller gave you a gift from their country",
        context: "Oh wow, look at that!",
        probability: 0.05,
        impacts: {
            fun: 5,
            random_item: 1,
        }
    },

    {
        id: "generic_28",
        action: "Believed confusing misinformation",
        context: "That newspaper was free for a reason",
        probability: 0.15,
        impacts: {
            quiz_bonus: -10,
        }
    },
    {
        id: "generic_29",
        action: "International Travel Advisory - Diplomatic Crisis!",
        context: "Borders closing rapidly",
        probability: 0.17,
        impacts: {
            quiz_sudden_death: 1,
        }
    },
    {
        id: "generic_30",
        action: "Flash Flood Warning!",
        context: "City streets rapidly becoming impassable",
        probability: 0.12,
        impacts: {
            local_sudden_death: 1,
        }
    },
    {
        id: "generic_31",
        action: "Plane tickets disappeared from hotel room",
        context: "Need expensive last minute tickets for next flight",
        probability: 0.1,
        impacts: {
            next_class: 'comfort',
        }
    },
    {
        id: "generic_32",
        action: "Family emergency in Russia",
        context: "Close relative hospitalized - need to get there immediately",
        probability: 0.01,
        impacts: {
            account: 2000,
            must_go_country: 'RU',
        }
    },
    {
        id: "generic_33",
        action: "Visa issue requires border crossing",
        context: "Need to visit nearest consulate in neighboring country immediately",
        probability: 0.1,
        impacts: {
            must_go_nearby: 'local',
        }
    }
];