import * as THREE from 'three';
import { generateFlights, distributeDoubledRewards, distributeRewards, Checkpoint, pinHeadMaterials, createCustomPinMaterial, animatePinPushIn, loadCityData, findCitiesInLongitudeBand, createDashedMaterial, createGoldMaterial, createComfortMaterial, calculateGreatCircleDistance, createCityPin, getTextBoundingBox, createPaperMaterial, scaleEquirectangular, createOrbitControlActivityManager, createOverlayTexture, calculateDotSize, vector3ToLatLong, latLongToVector3, loadTexture, setupTexture } from './utils.js';
import GlobeAutorotate from './GlobeAutorotate.js';
import { Globe } from './earth.js';
import { RibbonAnimator } from './ribbonAnimator.js';
import { SparkleSystem } from './sparkleSystem';
import { FactCheck } from './fact-check.js';
import { CounterManager } from './counterManager.js';
import { CardSpreadModal } from './modal-system.js';
import GameScoreSystem from './game-score-system.js';
import { WaterCoverageAnalyzer } from './water-coverage-analyzer.js';
import { statusManager } from './status-manager.js';
import TradingCardSystem from './trading-card-system.js';
import { GlobeState, getGlobeState } from './globe-state.js';
import { CityExplorer } from './city-explorer.js';
import { getThreeJsSetup } from './ThreeJsSetup.js';
import { HelpModal } from './help-modal.js';
import { SoundManager, NullSoundManager } from './sound-manager.js';
import { PulseMarkerSystem } from './pulse-marker-system.js';

import { SphericalPulseSystem } from './spherical-pulse-system.js';
import { FlightPriceTable } from './flight-price-table.js';
import { Globe3DMarkerSystem } from './globe3d-marker-system.js'

let cities = {};
let airportToCity = {};
let labelSystem;
let countryCodes;
let pulseSystem;
let markerSystem;
const dealsTable = new FlightPriceTable();


const cityDataPromise = loadCityData(
    '/assets/city_list.csv',
    '/assets/passport-access.csv',
    '/assets/country_iso.csv'
);

let imgString = 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/';
let imgStringPost = '/Unchanged';

const funMultiplierBorderFailure = 0.002;
const funConstantBorderFailure = 2;
const funMultiplierFreeCity = 0.0005;
const funMultiplierEconomy = 0.002;
const funConstantEconomy = 1;
const funMultiplierFirst = -0.001;
const funConstantFirst = -2;
const funMultiplierTax = 0.0005;
const funConstantTax = 1;
const funMultiplierQuiz = 1;
const funConstantQuiz = 0;

let pins = [];
let ribbons = [];

let distanceUnit = 100;
let mouseDownTime = 0;
const CLICK_TIME_THRESHOLD = 200; // milliseconds


if (globeNew) {

    const modal = new CardSpreadModal({ maxRotation: 3, countryCodes: countryCodes });
    
    const introcards = [
        {
            type: 'title',
            title: 'Trailmarks',
            gradient: 'linear-gradient(135deg, rgb(132 112 149) 0%, rgb(195 214 221) 100%)',
            backgroundImage: 'assets/guidebooks.jpg',
        },
        {
            type: 'start',
            category: 'Discover the hidden gems',
            title: 'Welcome to Trailmarks',
            //  message: 'Welcome as Trailmarks Travel Guides\' new fact checker!<br/><br/>Starting in the London office, we need you to travel the world and fact check for our travel guides.<br/><br/>We need you to do a circumnavigation of the planet, and have given you a bit of a starter allowance! Have fun!',
            message: "Welcome as our new fact checker!<br/><br/>You'll be traveling the world to verify information for our travel guides. We've arranged for you to complete a global circumnavigation, and we've provided you with a starter allowance of $2,000 to begin your journey.<br/><br/>Have fun exploring and come back to London when you're ready!",
        },
        {
            type: 'boarding-pass',
            fromCity: 'YOU', // Current city code
            fromCityName: 'Your Location', // Current city name
            fromIso2: '',
            toCity: 'LHR', // Destination city code
            toCityName: 'London', // Destination city name
            toIso2: 'GB',
            distance: '0',
            buttonText: 'GO!',
            confirmCallback: () => {
                modal.close();
                const londonLat = 51.5074;
                const londonLon = -0.1278 + 180;
                goToLocation(londonLat, londonLon);
            }
        },
        {
            type: 'info',
            title: 'About',
         //   category: 'Acknowlegdements',
            message: `Trailmarks.earth is a travel game, taking you around the globe answering quizzes and trading goods. <br/><br/>The game is made by <a target="_blank" ahref="https://bsky.app/profile/mats.einarsen.no">Mats Einarsen</a> in 2025. 
            <br/><br/>Press GO! on your boarding pass to get started. Use the help function if you are confused.
          <br/><br/>Made with <a href="https://threejs.org/" target="_blank">three.js</a>. Thank you, three.js contributors!<br/><br/><a target="_blank" href="https://jam.pieter.com" style="font-family: 'system-ui', sans-serif; font-size: 14px; font-weight: bold; background: #fff; color: #000; text-decoration: none; z-index: 10; border-top-left-radius: 12px; z-index: 10000; border: 1px solid #fff;">🕹️ Vibe Jam 2025</a>`,
          },
    ];
    
        modal.show(introcards, 1);
    }
    

const pinLookup = {};

// Get the THREE.js setup singleton
const threeJs = getThreeJsSetup();

// Extract essential components from the setup
const scene = threeJs.scene;
const camera = threeJs.camera;
const renderer = threeJs.renderer;
const controls = threeJs.controls;
const composer = threeJs.composer;
const bokehPass = threeJs.bokehPass;
const sunLight = threeJs.sunLight;
const rimLight = threeJs.rimLight;


const helpModal = new HelpModal(threeJs);

const globeRadius = 1;

// In your initialization code
const globe = new Globe(renderer, camera);

// Get global state instance
const globeState = getGlobeState();

let globeDataPromise = startLoadingGlobeData(db, globeId);

globeState.onFunAccountZero(() => {
    threeJs.dim();
    console.log('You have hit rock bottom')
});

globeState.onFunAccountNotZero(() => {
    threeJs.undim();
    console.log('Things are getting better')
});

const soundManager = isMobile ? new NullSoundManager() : new SoundManager({
    prioritySounds: ['wubb-2', 'airport-announcement' ], 
    defaultSounds: [
    'tm-foreground-bossa', 'seatbelts', 'laser-buzz-3', 'pen-scratch', 'stamp',  'denied',
     'double-ding', 'ding', 'sparkle', 'shuffle-out', 'shuffle-in',
     'tm-background-bossa', 'kaching', 'light-pling', 'rustle', 'sudden-death', 'satisfied', 'discard'
  ] });

document.addEventListener('audioready', (event) => {
    console.log('Audio system is now ready!');
    
    // At this point, we can safely play background music
    soundManager.setSoundEnabled(true); // Make sure sound is enabled
    soundManager.playBackgroundMusic('tm-foreground-bossa', 500); // Fade in over 1 second
    if (isDevelopment || isMobile) {
     //   soundManager.stopAllMusic();
    }
});

const sparkleSystem = new SparkleSystem();
const scoreSystem = new GameScoreSystem(db);

const counterManager = new CounterManager(500); // 500ms animation duration
globeState.setCounterManager(counterManager);
globeState.setScoreSystem(scoreSystem);

// Set the environment map for the entire scene
const envMapLoader = new THREE.CubeTextureLoader();

let envMap;

let countryAccessLookup = null;

let waterCoverageAnalyzer;
const tradingSystem = TradingCardSystem.getInstance( );

envMapLoader.load([
    'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/f55e2c63-2017-4c1a-ef9b-64b32cdd7900/Screen',
    'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/12a97250-bd94-476b-0c1c-b9e7ee7c1f00/Screen',
    'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/b05382da-e85d-4bb6-cdb5-41950cb60800/Screen',
    'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/43746577-38b1-4382-6a66-4603d0525400/Screen',
    'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/d942f2cd-f9c3-448f-72fb-f67fd0487100/Screen',
    'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/66bab579-8586-46c3-53f4-165e6bbf3900/Screen'
], (cubeTexture) => {
    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileCubemapShader();

    envMap = pmremGenerator.fromCubemap(cubeTexture).texture;
    pmremGenerator.dispose();

    scene.environment = envMap;

    // Chain the promises for both globe and label system initialization
    globe.initialize(envMap)
        .then(() => {

            camera.position.set(-2.46, 2.02, 0.69);
            camera.lookAt(controls.target);
            camera.updateProjectionMatrix();

            // Important: Call update after changing camera or controls
            controls.update();
            scene.add(globe.container);
            // Access roughness texture and set up water coverage analysis

            waterCoverageAnalyzer = new WaterCoverageAnalyzer(
                globe.roughnessTexture.image,
                8192,
                4096
            );

            // Load city data and initialize label system
            return cityDataPromise;
        })
        .then(combinedData => {

            countryAccessLookup = combinedData.accessLookup;
            countryCodes = combinedData.countryCodesLookup;

            combinedData.cities.forEach(element => {

                if (element.city == 'London') {
                    // Intentionally hard coding london to give a better start
                    element.commonDestinations="LIS-DXB-AMS-ATH-WAW-HEL-OSL-VIE-BUD-CDG-CPH-DUB-SOF-MAD-NCE-PRG-VCE";
                }
                // Debug log to check what we're working with
                // Split destinations and add debug checks
                if (element.commonDestinations) {
                    element.commonDestinationsArray = element.commonDestinations.split('-').slice(0,14);

                } else {
                    element.commonDestinationsArray = [];
                }
                
                // Create airport lookup
                if (element.airportCode) {
                    if (!airportToCity[element.airportCode]) {
                        airportToCity[element.airportCode] = element.city;
                    }
                }
                
                cities[element.city] = element;

            });
            
            if (globeNew) {
                const departures = generateFlights(cities['London'].commonDestinationsArray, cities, globeState.currentCity, airportToCity);
                dealsTable.updateFromDepartures(departures);
            }

            updateBokehFocus();

            // Now proceed with loading globe data
            loadGlobeData().then(() => {

                tradingSystem.initialize(globeState, cities, countryCodes, (cardId, cardData) => {
                    // Handle fun card usage here
                        soundManager.playSound('satisfied');
                        globeState.setFunAccount(globeState.funAccount + cardData.fun);

                        console.log(`Card ${cardId} was used! Fun score: ${cardData.fun}`);
                    }, 
                    (card) => { soundManager.playSound('discard')                               
                                labelSystem.rebuildLabelTexture();
                    } 
                );

                tradingSystem.initializeInventoryButton('inventory-button-container');
                initializePreviewSystem();

                labelSystem = new GlobeLabelSystem(
                    combinedData.cities,
                    combinedData.accessLookup,
                    globe.radius,
                    globe.container,
                    renderer,
                    camera,
                    (cityData) => {
                        console.log('City clicked:', cityData);
                    }
                );

                scoreSystem.setCitiesData(cities);


                pulseSystem = new SphericalPulseSystem({
                    globeRadius: globe.radius,
                    container: globe.container,
                    globeMesh: globe.mesh,
                    normalMap: globe.normalTexture,
                    maxMarkers: 30,              // Maximum number of simultaneous markers
                    maxRadius: 0.055,              // Maximum radius of circles (radians)
                    animationDuration: 3.5,        // Animation duration in seconds
                    numRings: 5,                 // Number of concentric rings
                    color: '#ffffff',            // Default color of rings
                    opacity: 1,                // Maximum opacity
                    ringWidth: 0.055              // Width of each ring
                });

                pulseSystem.material.uniforms.opacity.value = 1.0;
                showDealMarkers();
                pulseSystem.startAnimation();
          /*      
                    // Create the marker system
                markerSystem = new Globe3DMarkerSystem({
                    globeRadius: 1,
                    container: globe.container,  // your Three.js scene or globe container
                    markerUrl: '/assets/map_pointer_3d_icon.glb',
                    markerScale: 0.4,
                    markerHeight: 0.015,  // 10% of globe radius above surface
                    spinSpeed: 0.5,
                    bounceHeight: 0.02,
                    bounceSpeed: 1.5
                });     
                */     
            });

        })
        .catch(error => {
            console.error('Error in initialization:', error);
        });

});

const autorotate = new GlobeAutorotate(
    threeJs.camera,
    threeJs.controls,
    threeJs.bokehPass,
    {
        inactivityTimeout: 15000,     // 30 seconds before autorotation starts
        transitionDuration: 10000,     // 5 seconds per transition
        pauseBetweenViews: 200,      // 4 seconds pause at each view
        captureKey: 'v'               // Press 'v' to capture current view
        // No need to specify views - it will use the default ones
    }
);


// Add event listeners for control changes
controls.addEventListener('end', updateBokehFocus);
controls.addEventListener('change', () => {
    // Optional: update focus during movement for smoother transitions
    if (!autorotate.isAutoRotating()) {
        updateBokehFocus();
    }
});


// Create the manager after setting up your OrbitControls

controls.addEventListener('start', () => {
    if (isTransitioning) {
        isTransitioning = false;
    }
});

// Renderer DOM element is already managed by ThreeJsSetup

let mouseDownPosition = new THREE.Vector2();
let lastPlacedMarker = null;

let previewPin = null;
let previewRibbon = null;
let isMouseMovedSinceLastClick = false;

const radius = 1;
let isTransitioning = false;
let targetPosition, targetTarget;


let isGlobeRolledOut = false;
let isRollingGlobe = false; // New flag to track globe rolling state

let originalRotation = new THREE.Euler();
let originalPosition = new THREE.Vector3();
let originalControlsState = {
    target: new THREE.Vector3(),
    position: new THREE.Vector3(),
    zoom: 1
};
const rotationAngle = Math.PI / 3; // 60 degrees

// Define default camera position and target
const defaultCameraPosition = new THREE.Vector3(0, 0, 3.5);
const defaultTarget = new THREE.Vector3(0, 0, 0);

// Define the amount to move the globe
const globeOffset = 2; // window.innerWidth > 768 ? 2 : 1.5; // Adjust these values as needed



/*

Functions I haven't managed to move out to separate classes:

*/
// Create a raycaster for distance checking
const raycaster = new THREE.Raycaster();
raycaster.camera = camera; // Set camera for sprite intersection

function updateBokehFocus(duringAuto) {
    if (ongoingFocusSearch || !globe || !globe.mesh ) { return };
    threeJs.updateBokehFocus(globe, ongoingFocusSearch,duringAuto);
}

let ongoingFocusSearch = 0;

function dramaticFocusSearch() {
    if (!globe || !globe.mesh || ongoingFocusSearch) return;

    ongoingFocusSearch = 1;

    // Use the ThreeJsSetup version instead
    const cancelFunc = threeJs.dramaticFocusSearch(globe);

    // When done, reset the flag
    setTimeout(() => {
        ongoingFocusSearch = 0;
    }, 3500);
}

function hasCompletedAllQuizzesPerfectly(cityName, globeData) {
    const state = globeData instanceof GlobeState ? globeData.getState() : globeData;
    const totalQuizzes = state.quizCount[cityName] || 0;
    if (totalQuizzes === 0) return false;

    const cityQuizzes = Object.keys(state.results || {})
        .filter(key => key.startsWith(cityName + '-'));

    // Must have attempted all quizzes and gotten them all perfect
    return cityQuizzes.length === totalQuizzes && cityQuizzes.every(quizKey => {
        const result = state.results[quizKey];
        return result && result.correctAnswers === 10;
    });
}


function determinePinHeadType(cityName, globeData) {
    const state =  globeState.getState();
    
    // Check if they made a friend
    const hasMadeFriend = globeState.getModifier(cityName, 'made_friend') || false;
    if (hasMadeFriend) {
        return 'made_friend';
    }
    
    // Check quiz completion status
    const totalQuizzes = globeState.quizCount[cityName] || 0;
    if (totalQuizzes === 0) return null;

    const cityQuizzes = Object.keys(globeState.results || {})
        .filter(key => key.startsWith(cityName + '-'));

    // Count attempted quizzes
    let attemptedCount = 0;
    
    cityQuizzes.forEach(quizKey => {
        const result = globeState.results[quizKey];
        if (result && typeof result.correctAnswers === 'number') {
            attemptedCount++;
        }
    });

    // All quizzes attempted - this is what was previously returning 'finished' or 'completed'
    if (attemptedCount >= totalQuizzes) {
        return 'finished';
    }
    
    // Otherwise, no special pin head
    return null;
}

function getQuizStatus(cityName, globeData) {
    const state = globeData instanceof GlobeState ? globeData.getState() : globeData;
    const totalQuizzes = state.quizCount[cityName] || 0;
    if (totalQuizzes === 0) return 'unfinished';

    const cityQuizzes = Object.keys(state.results || {})
        .filter(key => key.startsWith(cityName + '-'));

    // Count quizzes with attempts and perfect scores
    let attemptedCount = 0;
    let perfectCount = 0;

    cityQuizzes.forEach(quizKey => {
        const result = state.results[quizKey];
        if (result && typeof result.correctAnswers === 'number') {
            attemptedCount++;
            if (result.correctAnswers === 10) {
                perfectCount++;
            }
        }
    });

    // No attempts yet
    if (attemptedCount === 0) return 'unfinished';

    // Some attempts, but not all quizzes attempted
    if (attemptedCount < totalQuizzes) return 'started';

    // All attempted with perfect scores
    if (perfectCount === totalQuizzes) return 'completed';

    // All attempted but not all perfect
    return 'finished';
}


// Enhanced ribbon creation with state persistence
function createAndSaveRibbon(startPoint, endPoint, ribbonStyle = 'economy') {

    const ribbon = createOrUpdateRibbon(startPoint, endPoint, false, null, ribbonStyle);
    globe.container.add(ribbon);
    ribbons.push(ribbon);

    // Just set the dataId to match what was saved in handleCityTravel
    ribbon.dataId = Date.now().toString();

    return ribbon;
}

function createOrUpdateRibbon(startPoint = null, endPoint = null, updateExisting = false, existingRibbon = null, ribbonStyle = "economy") {
    const segments = 50;
    const minHeight = 0.01;
    const maxHeight = 0.4;
    const minWidth = 0.004;
    const maxWidth = 0.025;
    const positions = [];
    const normals = [];
    const uvs = [];
    const indices = [];

    const pathLength = startPoint && endPoint ? startPoint.distanceTo(endPoint) : null;
    const normalizedLength = startPoint && endPoint ? Math.min(pathLength / (2 * radius), 1) : null;

    if (startPoint && endPoint) {
        const points = [];

        const curveHeight = minHeight + (maxHeight - minHeight) * Math.pow(normalizedLength, 2);
        const ribbonWidth = minWidth + (maxWidth - minWidth) * normalizedLength;

        // Create initial points array with proper anchoring
        points.push(startPoint.clone());

        // Generate intermediate points
        for (let i = 1; i < segments; i++) {
            const t = i / segments;
            const p = new THREE.Vector3().lerpVectors(startPoint, endPoint, t);
            const height = Math.sin(t * Math.PI) * curveHeight;
            p.normalize().multiplyScalar(radius + height);
            points.push(p);
        }

        points.push(endPoint.clone());

        const curve = new THREE.CatmullRomCurve3(points);
        curve.tension = 0.5; // Adjust for flexibility
        const geometryPoints = curve.getPoints(segments);

        // Calculate ribbon geometry with proper normal orientation
        for (let i = 0; i < geometryPoints.length; i++) {
            const point = geometryPoints[i];
            const tangent = curve.getTangentAt(i / segments);
            const up = point.clone().normalize();

            // Ensure consistent binormal direction
            const binormal = new THREE.Vector3().crossVectors(tangent, up).normalize();
            const normal = new THREE.Vector3().crossVectors(binormal, tangent).normalize();

            const v1 = point.clone().add(binormal.clone().multiplyScalar(ribbonWidth / 2));
            const v2 = point.clone().sub(binormal.clone().multiplyScalar(ribbonWidth / 2));

            positions.push(v1.x, v1.y, v1.z, v2.x, v2.y, v2.z);
            normals.push(normal.x, normal.y, normal.z, normal.x, normal.y, normal.z);
            uvs.push(i / segments, 0, i / segments, 1);

            if (i < geometryPoints.length - 1) {
                const base = i * 2;
                indices.push(base, base + 1, base + 2, base + 1, base + 3, base + 2);
            }
        }
    }

    if (updateExisting && existingRibbon) {
        existingRibbon.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        existingRibbon.geometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
        existingRibbon.geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
        existingRibbon.geometry.setIndex(indices);

        existingRibbon.geometry.computeBoundingSphere();
        existingRibbon.visible = positions.length > 0;

        // Re-initialize physics after geometry update
        if (existingRibbon.animator) {
            existingRibbon.animator.points = [];  // Clear existing points
            existingRibbon.animator.originalPositions = [];  // Clear original positions
            existingRibbon.animator.initializePhysics();  // Re-initialize with new geometry
        }

        return existingRibbon;
    } else {
        const ribbonGeometry = new THREE.BufferGeometry();
        ribbonGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        ribbonGeometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
        ribbonGeometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
        ribbonGeometry.setIndex(indices);

        ribbonGeometry.computeBoundingSphere();

        const lightPos = new THREE.Vector3(-5, 0, 5);
        // Transform it by the camera's matrix to get its position in the current view
        lightPos.applyMatrix4(camera.matrixWorld);

        let material;
        switch (ribbonStyle) {
            case 'first':
                material = createGoldMaterial(lightPos);
                break;
            case 'comfort':
                material = createComfortMaterial();
                break
            case 'free':
                material = createDashedMaterial(10 + (normalizedLength * 45));
                break;
            default:
                material = createPaperMaterial(lightPos);
        }

        const ribbon = new THREE.Mesh(ribbonGeometry, material);
        ribbon.visible = positions.length > 0;

        // Shadow settings
        ribbon.castShadow = true;
        ribbon.receiveShadow = true;
        ribbon.frustumCulled = false;

        ribbon.animator = new RibbonAnimator(ribbon, {
            springStiffness: 10.0,
            damping: 0.4,
            dragFactor: 0.5
        }, camera);

        ribbon.animator.initializePhysics();

        return ribbon;
    }
}

function xcreateOrUpdateRibbon(startPoint = null, endPoint = null, updateExisting = false, existingRibbon = null, ribbonStyle = "economy") {
    const segments = 50;
    const minHeight = 0.01;
    const maxHeight = 0.4;
    const minWidth = 0.004;
    const maxWidth = 0.025;
    const positions = [];
    const normals = [];
    const uvs = [];
    const indices = [];

    const pathLength = startPoint && endPoint ? startPoint.distanceTo(endPoint) : null;
    const normalizedLength = startPoint && endPoint ? Math.min(pathLength / (2 * radius), 1) : null;

    if (startPoint && endPoint) {
        const points = [];

        const curveHeight = minHeight + (maxHeight - minHeight) * Math.pow(normalizedLength, 2);
        const ribbonWidth = minWidth + (maxWidth - minWidth) * normalizedLength;

        points.push(startPoint.clone());

        for (let i = 1; i < segments; i++) {
            const t = i / segments;
            const p = new THREE.Vector3().lerpVectors(startPoint, endPoint, t);
            const height = Math.sin(t * Math.PI) * curveHeight;
            p.normalize().multiplyScalar(radius + height);
            points.push(p);
        }

        points.push(endPoint.clone());

        const curve = new THREE.CatmullRomCurve3(points);
        curve.tension = 0.5;
        const geometryPoints = curve.getPoints(segments);

        for (let i = 0; i < geometryPoints.length; i++) {
            const point = geometryPoints[i];
            const tangent = curve.getTangentAt(i / segments);
            const up = point.clone().normalize();

            const binormal = new THREE.Vector3().crossVectors(tangent, up).normalize();
            const normal = new THREE.Vector3().crossVectors(binormal, tangent).normalize();

            // Calculate tapered width at this point
            const t = i / (geometryPoints.length - 1);
            const taperFactor =  1 - Math.pow((t - 0.5)*2, 4); // Creates smooth taper from 0 to 1 to 0
            const currentWidth = ribbonWidth * taperFactor;

            const v1 = point.clone().add(binormal.clone().multiplyScalar(currentWidth / 2));
            const v2 = point.clone().sub(binormal.clone().multiplyScalar(currentWidth / 2));

            positions.push(v1.x, v1.y, v1.z, v2.x, v2.y, v2.z);
            normals.push(normal.x, normal.y, normal.z, normal.x, normal.y, normal.z);
            uvs.push(i / segments, 0, i / segments, 1);

            if (i < geometryPoints.length - 1) {
                const base = i * 2;
                indices.push(base, base + 1, base + 2, base + 1, base + 3, base + 2);
            }
        }
    }

    if (updateExisting && existingRibbon) {
        existingRibbon.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        existingRibbon.geometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
        existingRibbon.geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
        existingRibbon.geometry.setIndex(indices);

        existingRibbon.geometry.computeBoundingSphere();
        existingRibbon.visible = positions.length > 0;

        // Re-initialize physics after geometry update
        if (existingRibbon.animator) {
            existingRibbon.animator.points = [];  // Clear existing points
            existingRibbon.animator.originalPositions = [];  // Clear original positions
            existingRibbon.animator.initializePhysics();  // Re-initialize with new geometry
        }

        return existingRibbon;
    } else {
        const ribbonGeometry = new THREE.BufferGeometry();
        ribbonGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        ribbonGeometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
        ribbonGeometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
        ribbonGeometry.setIndex(indices);

        ribbonGeometry.computeBoundingSphere();

        const lightPos = new THREE.Vector3(-8, 3, 2);
        // Transform it by the camera's matrix to get its position in the current view
        lightPos.applyMatrix4(camera.matrixWorld);

        let material;
        switch (ribbonStyle) {
            case 'first':
                material = createGoldMaterial(lightPos);
                break;
            case 'comfort':
                material = createComfortMaterial();
                break
            case 'free':
                material = createDashedMaterial(15 + (normalizedLength * 40));
                break;
            default:
                material = createPaperMaterial(lightPos);
        }

        const ribbon = new THREE.Mesh(ribbonGeometry, material);
        ribbon.visible = positions.length > 0;

        // Shadow settings
        ribbon.castShadow = true;
        ribbon.receiveShadow = true;
        ribbon.frustumCulled = false;

        ribbon.animator = new RibbonAnimator(ribbon, {
            springStiffness: 10.0,
            damping: 0.4,
            dragFactor: 0.5
        }, camera);

        ribbon.animator.initializePhysics();

        return ribbon;
    }
}
// Function to create initial London pin
function createLondonPin() {
    const londonLat = 51.5074;
    const londonLon = -0.1278 + 180;
    const position = latLongToVector3(londonLat, londonLon, radius)
        .sub(globe.container.position);

    const pin = createCityPin(position, 0.035, pinHeadMaterials['london']);
    pin.lookAt(globe.container.position);
    pin.rotateX(Math.PI / 2);

    globe.container.add(pin);
    pins.push(pin);

    const pinData = {
        id: '0',
        location: 'London',
        lat: londonLat,
        lon: londonLon,
        size: 0.02
    };
    globeState.addPin(pinData.id, pinData);
    pin.dataId = pinData.id;

    animatePinPushIn(globe, pin, position);

    return position;
}

// Create preview pin
function createPreviewPin() {
    const pin = createCityPin(new THREE.Vector3(), 0.04);
    pin.visible = false;
    pin.isPreviewPin = true;
    globe.container.add(pin);
    return pin;
}

function goToLocation(lat, lon) {

    if (!lat) {
        lat = cities[globeState.currentCity].lat;
        lon = cities[globeState.currentCity].lng - 180;
    }

    const phi = THREE.MathUtils.degToRad(90 - lat);
    const theta = THREE.MathUtils.degToRad(-lon);
    const radius = globe.mesh.geometry.parameters.radius;

    // Calculate target point on the globe's surface
    const x = radius * Math.sin(phi) * Math.cos(theta);
    const y = radius * Math.cos(phi);
    const z = radius * Math.sin(phi) * Math.sin(theta);
    const targetPoint = new THREE.Vector3(x, y, z);

    // Calculate tangent vectors at the target point
    const normal = targetPoint.clone().normalize();
    const worldUp = new THREE.Vector3(0, 1, 0);
    let right = new THREE.Vector3().crossVectors(normal, worldUp);

    // Handle poles where normal and worldUp are colinear
    if (right.length() < 0.0001) {
        right.set(1, 0, 0);
    }
    right.normalize();
    const up = new THREE.Vector3().crossVectors(right, normal).normalize();

    // Offset calculations
    const offsetScale = 0.10;
    const rightOffset = right.multiplyScalar(-radius * offsetScale);
    const downOffset = up.multiplyScalar(-radius * offsetScale);

    // Keep pivot at origin
    targetTarget = new THREE.Vector3(0, 0, 0);

    // Calculate target position that will look at desired point
    const lookDirection = targetPoint.clone().normalize();
    const basePosition = lookDirection.multiplyScalar(radius * 1.6);
    targetPosition = basePosition.add(rightOffset).add(downOffset);

    camera.lookAt(targetPoint);
    isTransitioning = true;
}

function initializePreviewSystem(startingCity = 'London') {
    previewPin = createPreviewPin();

    // Initialize starting point and preview objects
    const londonPosition = createLondonPin();
    lastPlacedMarker = londonPosition;
    previewPin = createPreviewPin();

    // If we have saved data, find the last placed pin
    if (globeState.currentCity) {
        const cityData = cities[globeState.currentCity];

        if (cityData) {
            const position = latLongToVector3(cityData.lat, cityData.lng, radius)
                .sub(globe.container.position);
            lastPlacedMarker = position;
        }
    }

    // If no saved data, create London pin
    if (!lastPlacedMarker) {
        const londonPosition = createLondonPin();
        lastPlacedMarker = londonPosition;
    }

    // Create preview ribbon
    const previewEndPoint = lastPlacedMarker.clone().add(new THREE.Vector3(0.1, 0, 0));
    previewRibbon = createOrUpdateRibbon(lastPlacedMarker, previewEndPoint);
    setRibbonToImpossible(previewRibbon);

    previewRibbon.animator = new RibbonAnimator(previewRibbon, {
        springStiffness: 12.0,
        damping: 0.85,
        dragFactor: 2.0
    }, camera);

    globe.container.add(previewRibbon);
}

// Initialize physics on first mousemove instead of immediately
let physicsInitialized = false;



function setRibbonToImpossible(ribbon) {
    if (!ribbon || !ribbon.material) return;

    ribbon.material.updateColor(new THREE.Color(0.8, 0, 0));  // Red
    ribbon.material.updateOpacity(0.4);
    ribbon.material.transparent = true;
}

function setRibbonToValid(ribbon) {
    if (!ribbon || !ribbon.material) return;

    ribbon.material.updateColor(new THREE.Color(1, 1, 1));  // White
    ribbon.material.updateOpacity(1.0);
    ribbon.material.transparent = false;
}

function updatePreviewObjects(intersectionPoint) {

    if (!intersectionPoint || !isMouseMovedSinceLastClick) {
        previewPin.visible = false;
        previewRibbon.visible = false;
        return;
    }

    // Get the end point relative to the globe container
    const endPoint = hoveredPin ?
        hoveredPin.position :
        intersectionPoint.clone().sub(globe.container.position);

    if (hoveredPin) {
        previewPin.visible = false;
    } else {
        previewPin.position.copy(endPoint);
        previewPin.lookAt(globe.container.position);
        previewPin.rotateX(Math.PI / 2);
        previewPin.visible = true;
    }

    if (lastPlacedMarker) {
        createOrUpdateRibbon(lastPlacedMarker, endPoint, true, previewRibbon);
        previewRibbon.visible = true;

        // Update ribbon material and lighting
        if (previewRibbon.material) {
            const lightPos = new THREE.Vector3(-5, 0, 5).applyMatrix4(camera.matrixWorld);
            previewRibbon.material.updateLightPosition(lightPos);
        }
    } else {
        previewRibbon.visible = false;
    }
}


let hoveredPin = null;

// Update the checkPinHover function to use the pins array
function checkPinHover(mouse) {
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(pins, true);

    for (let intersect of intersects) {
        let objectToCheck = intersect.object.type === 'Group' ? intersect.object : intersect.object.parent;
        if (objectToCheck.isCustomPin && !objectToCheck.isPreviewPin) {
            return objectToCheck;
        }
    }
    return null;

}


// Add this to your GlobeState class or module

// Keep track of the loading process

// Function to start loading early
export function startLoadingGlobeData(db, globeId) {
    if (!globeDataPromise) {
        // Initialize database if needed
        Checkpoint.mark('start loading globe');
        
        globeState.initializeDatabase(db, globeId);
        
        // Start the loading process and store the promise
        globeDataPromise = globeState.loadState();

        Checkpoint.mark('stopped loading globe');

    }
    return globeDataPromise;
}

// Modified loadGlobeData function
async function loadGlobeData() {
    try {
        
        // Wait for the data if loading has started, otherwise start loading now
        const stateLoaded = globeDataPromise ? 
            await globeDataPromise : 
            await startLoadingGlobeData(db, globeId);
            
        if (stateLoaded) {

            if (globeState.metadata && globeState.metadata.orbitControls) {
                applyOrbitControlsSettings();
            }

            const stateData = globeState.getState();
            globeState.setState(stateData);

            console.log(globeState);
            // Clear existing visual elements
            pins.forEach(pin => globe.container.remove(pin));
            ribbons.forEach(ribbon => globe.container.remove(ribbon));
            pins = [];
            ribbons = [];

            const departures = generateFlights(cities[globeState.currentCity].commonDestinationsArray, cities, globeState.currentCity, airportToCity);
            dealsTable.updateFromDepartures(departures);

            // Render saved state
            return renderGlobeData(0);
        } else {
            // Initialize new globe
            await globeState.saveState();

            return Promise.resolve();
        }

    } catch (error) {
        console.error("Error loading globe data:", error);
        return Promise.reject(error);
    }
}

async function applyOrbitControlsSettings() {
    const metadata = globeState.metadata;
    if (metadata && metadata.orbitControls) {
        const { target, position } = metadata.orbitControls;

        if (target && position) {
            targetPosition = new THREE.Vector3(position.x, position.y, position.z);
            targetTarget = new THREE.Vector3(target.x, target.y, target.z);
            isTransitioning = true;
        }
    }
}

function renderGlobeData(delay = 0) {
    return new Promise(async (resolve) => {
        // Combine pins and ribbons into a single timeline array
        const timelineItems = [
            ...Object.entries(globeState.pins).map(([timestamp, data]) => ({
                timestamp: parseInt(timestamp),
                type: 'pin',
                data
            })),
            ...Object.entries(globeState.ribbons).map(([timestamp, data]) => ({
                timestamp: parseInt(timestamp),
                type: 'ribbon',
                data
            }))
        ].sort((a, b) => a.timestamp - b.timestamp);

        // Process each item in chronological order
        for (const item of timelineItems) {
            if (item.type === 'pin') {
                const pinData = item.data;

                if (pinLookup[pinData.location]) {
                    continue;
                }

                const position = latLongToVector3(pinData.lat, pinData.lon, radius);
                const pin = createCityPin(
                    position,
                    0.035,
                    pinHeadMaterials[ determinePinHeadType( pinData.location, globeState.getState()) ]
//                    getQuizStatus(pinData.location, globeState.getState()) == 'finished'
  //                      ? pinHeadMaterials['finished']
    //                    : null
                );
                pinLookup[pinData.location] = pin;

                const newPinPosition = position.clone().sub(globe.container.position);

                if (pinData.location != 'London') {
                    globe.container.add(pin);
                    pins.push(pin);
                }

                animatePinPushIn(globe, pin, newPinPosition);
            } else if (item.type === 'ribbon') {
                const ribbonData = item.data;
                const startPoint = latLongToVector3(ribbonData.startLat, ribbonData.startLon, radius);
                const endPoint = latLongToVector3(ribbonData.endLat, ribbonData.endLon, radius);

                startPoint.sub(globe.container.position);
                endPoint.sub(globe.container.position);

                const ribbon = createOrUpdateRibbon(startPoint, endPoint, false, null, ribbonData.class);
                ribbon.dataId = ribbonData.id;
                globe.container.add(ribbon);
                ribbons.push(ribbon);

                // Connect ribbon to pins
                const startPin = pins.find(pin => pin.dataId === ribbonData.startPinId);
                const endPin = pins.find(pin => pin.dataId === ribbonData.endPinId);
                if (startPin) {
                    startPin.connectedRibbons = startPin.connectedRibbons || [];
                    startPin.connectedRibbons.push(ribbon);
                }
                if (endPin) {
                    endPin.connectedRibbons = endPin.connectedRibbons || [];
                    endPin.connectedRibbons.push(ribbon);
                }
            }

            if (globeState.funAccount == 0) {
                threeJs.dim();
            }

            if (delay > 0) {
                await new Promise(resolve => setTimeout(resolve, delay));
            }
        }

        resolve();
    });
}


function rollGlobeOutAndShowText() {
    if (isGlobeRolledOut) return;

    // Store the original states
    originalRotation.copy(scene.rotation);
    originalPosition.copy(globe.container.position);
    originalControlsState.target.copy(controls.target);
    originalControlsState.position.copy(controls.object.position);
    originalControlsState.zoom = controls.zoom;

    // Disable controls during animation
    controls.enabled = false;

    labelSystem.hidePopup();

    // Animate the scene rotation, globe position, and controls
    new TWEEN.Tween({
        rotationY: scene.rotation.y,
        globeX: globe.container.position.x,
        targetX: controls.target.x,
        targetY: controls.target.y,
        targetZ: controls.target.z,
        cameraX: controls.object.position.x,
        cameraY: controls.object.position.y,
        cameraZ: controls.object.position.z,
        zoom: controls.zoom
    })
        .to({
            rotationY: originalRotation.y + rotationAngle,
            globeX: globeOffset,
            targetX: defaultTarget.x + globeOffset,
            targetY: defaultTarget.y,
            targetZ: defaultTarget.z,
            cameraX: defaultCameraPosition.x + globeOffset,
            cameraY: defaultCameraPosition.y,
            cameraZ: defaultCameraPosition.z,
            zoom: 1
        }, 1500)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onUpdate((obj) => {
            scene.rotation.y = obj.rotationY;
            globe.container.position.x = obj.globeX;
            controls.target.set(obj.targetX, obj.targetY, obj.targetZ);
            controls.object.position.set(obj.cameraX, obj.cameraY, obj.cameraZ);
            controls.zoom = obj.zoom;
            controls.update();
        })
        .onComplete(() => {
            controls.enabled = true;
        })
        .start();

    // Show text
    document.getElementById('about-text').style.opacity = 1;


    isGlobeRolledOut = true;

    addEventBlocker();

}

function rollGlobeBackIn(event) {
    if (!isGlobeRolledOut) return;

    // Prevent default action and stop propagation
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }

    //   resetRibbonCreation();

    // Disable controls during animation
    controls.enabled = false;

    // Animate everything back to original state
    new TWEEN.Tween({
        rotationY: scene.rotation.y,
        globeX: globe.container.position.x,
        targetX: controls.target.x,
        targetY: controls.target.y,
        targetZ: controls.target.z,
        cameraX: controls.object.position.x,
        cameraY: controls.object.position.y,
        cameraZ: controls.object.position.z,
        zoom: controls.zoom
    })
        .to({
            rotationY: originalRotation.y,
            globeX: originalPosition.x,
            targetX: originalControlsState.target.x,
            targetY: originalControlsState.target.y,
            targetZ: originalControlsState.target.z,
            cameraX: originalControlsState.position.x,
            cameraY: originalControlsState.position.y,
            cameraZ: originalControlsState.position.z,
            zoom: originalControlsState.zoom
        }, 1500)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onUpdate((obj) => {
            scene.rotation.y = obj.rotationY;
            globe.container.position.x = obj.globeX;
            controls.target.set(obj.targetX, obj.targetY, obj.targetZ);
            controls.object.position.set(obj.cameraX, obj.cameraY, obj.cameraZ);
            controls.zoom = obj.zoom;
            controls.update();
        })
        .onComplete(() => {
            controls.enabled = true;
        })
        .start();

    // Hide text
    document.getElementById('about-text').style.opacity = 0;
    document.getElementById('about-text').style.pointerEvents = 'none';

    isGlobeRolledOut = false;

    removeEventBlocker();

}

function addEventBlocker() {
    const blocker = document.createElement('div');
    blocker.id = 'event-blocker';
    blocker.style.position = 'fixed';
    blocker.style.top = '0';
    blocker.style.left = '0';
    blocker.style.width = '100%';
    blocker.style.height = '100%';
    blocker.style.zIndex = '1000';
    blocker.style.cursor = 'pointer';

    blocker.addEventListener('click', rollGlobeBackIn);
    document.body.appendChild(blocker);
}

function removeEventBlocker() {
    const blocker = document.getElementById('event-blocker');
    if (blocker) {
        blocker.removeEventListener('click', rollGlobeBackIn);
        document.body.removeChild(blocker);
    }
}


function hasCircumnavigatedGlobe(pins) {
    // If passed a GlobeState instance, use its pins
    if (pins instanceof GlobeState) {
        pins = pins.pins;
    }

    // Convert pins object to ordered array based on keys (timestamps)
    const orderedPins = Object.entries(pins)
        .sort(([a], [b]) => parseInt(a) - parseInt(b))
        .map(([_, pin]) => pin);

    if (orderedPins.length < 4) return false;

    // Function to normalize longitude to [-180, 180]
    const normalizeLon = (lon) => {
        lon = lon % 360;
        if (lon > 180) lon -= 360;
        if (lon < -180) lon += 360;
        return lon;
    };

    // Calculate total angular distance traveled in longitude
    let totalLonChange = 0;
    let prevLon = orderedPins[0].lon;

    for (let i = 1; i < orderedPins.length; i++) {
        const currentLon = orderedPins[i].lon;

        // Calculate the shortest angular distance between longitudes
        let lonDiff = normalizeLon(currentLon - prevLon);

        // Handle dateline crossing
        if (Math.abs(lonDiff) > 180) {
            if (lonDiff > 0) {
                lonDiff -= 360;
            } else {
                lonDiff += 360;
            }
        }

        totalLonChange += lonDiff;
        prevLon = currentLon;
    }

    // Add the final segment back to start if not already there
    if (orderedPins[0].lat !== orderedPins[orderedPins.length - 1].lat ||
        orderedPins[0].lon !== orderedPins[orderedPins.length - 1].lon) {
        const lonDiff = normalizeLon(orderedPins[0].lon - prevLon);
        if (Math.abs(lonDiff) > 180) {
            totalLonChange += (lonDiff > 0) ? lonDiff - 360 : lonDiff + 360;
        } else {
            totalLonChange += lonDiff;
        }
    }

    // Calculate number of revolutions
    const revolutions = Math.abs(totalLonChange / 360);

    // Check if path approximately completes at least one revolution
    // Using a more lenient tolerance of 0.8 (288 degrees) to account for GPS inaccuracies
    return revolutions >= 0.8;
}

async function createScoreModal(gameData, citiesData) {
    const modal = new CardSpreadModal();

    // If passed globeState, get the state object
    const stateData = gameData instanceof GlobeState ? gameData.getState() : gameData;

    // Calculate score and generate receipt
    const score = await scoreSystem.calculateScore(stateData, citiesData);
    const receipt = scoreSystem.generateReceipt(score);

    const cards = [
        // Title card
        {
            type: 'title',
            title: 'Trailmarks',
            gradient: 'linear-gradient(135deg, rgb(137 137 168) 0%, rgb(175 157 174) 100%)',
            backgroundImage: 'assets/guidebooks.jpg',

        },

        {
            type: 'quiz',
            title: '<nobr>Current Score</nobr>',
            //  category: 'Journey Summary',
            customContent: `
          <div class="fc-score-receipt">
            ${receipt}
          </div>
        `,
        }];

    cards.push({
        type: 'confirm',
        title: 'Continue Your Journey',
        message: 'There are still more places to explore!',
        buttonText: 'Keep Going',
        confirmCallback: () => modal.close()
    });


    modal.show(cards);
    return modal;
}


async function createLondonModal(hasCircumnavigated, totalDistance, gameData, citiesData) {
    const modal = new CardSpreadModal({three: threeJs});

    // If passed globeState, get the state object
    const stateData = gameData instanceof GlobeState ? gameData.getState() : gameData;

    // Calculate score and generate receipt
    const score = await scoreSystem.calculateScore(globeState, citiesData);
    const receipt = scoreSystem.generateReceipt(score);

    const cards = [
        // Title card
        {
            type: 'title',
            title: 'Trailmarks',
            gradient: 'linear-gradient(135deg, rgb(137 137 168) 0%, rgb(175 157 174) 100%)',
            backgroundImage: 'assets/guidebooks.jpg',

        },

        // Status card
        {
            type: 'start',
            title: 'London',
            category: 'Trailmarks Head Office',
            customContent: "",
            message: `${hasCircumnavigated ?
                "Congratulations! You've successfully circumnavigated the globe!" :
                "Keep exploring! You haven't circumnavigated the globe yet."}
          \n\n<br/>You've traveled ${Math.round(totalDistance).toLocaleString()} kilometers!`
        },
        {
            type: 'quiz',
            title: '<nobr>Current Score</nobr>',
            //  category: 'Journey Summary',
            customContent: `
          <div class="fc-score-receipt">
            ${receipt}
          </div>
        `,
        }];

    if (hasCircumnavigated) {
        cards.push({
            type: 'quiz',
            title: 'Complete your journey',
            //  category: 'Journey Summary',
            customContent: `
          You've circumnavigated the planet, and can finish your journey.
        `,
            buttonText: 'Finish Journey',
            info: 'Saves to high score list and finishes the game',
            onButtonClick:
                async () => {
                    await modal.close();
                    rollGlobeOutAndShowText();

                    // Update the about text
                    const aboutText = document.getElementById('about-text');
                    aboutText.innerHTML = `
              <h2>Journey Complete!</h2>
              <p>Total Distance: ${Math.round(totalDistance).toLocaleString()} km</p>
              <p>You've successfully circumnavigated the globe, visiting fascinating places and learning interesting facts along the way.</p>
              <div class="high-scores">
                <h3>Your Journey Statistics</h3>
                ${receipt}
              </div>
            `;
                } // Disabled button if not circumnavigated
        });
    }

    cards.push({
        type: 'confirm',
        title: 'Continue Your Journey',
        message: 'There are still more places to explore!',
        buttonText: 'Keep Going',
        confirmCallback: () => modal.close()
    });

    soundManager.playSound('shuffle-in');

    modal.show(cards);
    return modal;
}


// GlobeLabelSystem
//
// This places city labels out on the planet and adds interactivity to them. 
//

class GlobeLabelSystem {
    constructor(citiesData, accessLookup, radius, globeContainer, renderer, camera, onCityClick) {
        this.renderer = renderer;
        this.camera = camera;
        this.raycaster = new THREE.Raycaster();
        this.accessLookup = accessLookup;
        this.currentCountry = globeState.currentCountry;
        this.radius = radius;
        this.globeContainer = globeContainer;
        this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        // Create spatial index
        this.GRID_COLS = 512;
        this.GRID_ROWS = 256;
        this.labelGrid = new Array(this.GRID_ROWS);
        for (let i = 0; i < this.GRID_ROWS; i++) {
            this.labelGrid[i] = new Array(this.GRID_COLS);
            for (let j = 0; j < this.GRID_COLS; j++) {
                this.labelGrid[i][j] = new Set();
            }
        }

        // Create canvas for labels
        const { canvas: regularCanvas, ctx: regularCtx } = createOverlayTexture(8192, 4096);
        
        this.labelHitBoxes = new Map();
        this.hoveredLabel = null;

        // Setup context
        regularCtx.imageSmoothingEnabled = true;
        regularCtx.imageSmoothingQuality = 'high';
        regularCtx.fillStyle = 'rgba(0, 0, 0, 0.8)';
        regularCtx.strokeStyle = 'rgba(255, 255, 255, 0.5)';
        regularCtx.lineWidth = 1;

        // Create collision detector
        this.collisionDetector = createPreciseCollisionDetector(regularCanvas.width, regularCanvas.height);

        // Process city data
        const labels = [];
        citiesData.forEach(city => {
            const { lat, lng: lon, population, city: cityName, capital: cityType, iso2 } = city;
            const { importance, size: dotSize } = calculateDotSize(population);

            let x = ((lon - 180) / 360) * regularCanvas.width;
            let y = ((90 - lat) / 180) * regularCanvas.height;

            if (x >= regularCanvas.width) x -= regularCanvas.width;
            if (x < 0) x += regularCanvas.width;

            let fontSize = (cityType === 'primary'
                ? Math.max(22, Math.min(15, importance * 22))
                : 2 + Math.max(14, Math.min(18, importance * 20)))
                / (8192 / regularCanvas.width);

            if (fontSize < 14) { fontSize = 14; }

            fontSize = scaleEquirectangular(fontSize, x, y, regularCanvas.height);

            regularCtx.font = `600 ${fontSize}px montserrat, sans-serif`;

            const { width, height } = getTextBoundingBox(regularCtx,
                cityName.toUpperCase(),
                (cityType == "primary") ? `900 ${fontSize}px montserrat, sans-serif` : `600 ${fontSize}px montserrat, sans-serif`);

            labels.push({
                x, y, dotSize, fontSize, width, height,
                lat, lng: lon, population, name: cityName,
                cityType, cityOrig: city, importance, iso2
            });
        });

        // Sort labels - primary cities first, then admin cities
        const primaryCities = labels.filter(label => label.cityType === 'primary');
        const adminCities = labels.filter(label => label.cityType !== 'primary');
        const sortedLabels = [...primaryCities, ...adminCities];

        // Place labels with collision detection
        for (const label of sortedLabels) {
            const squareSize = Math.max(6, (label.cityType === 'primary' ? 14 : (label.dotSize * 400) + 3) / (8192 / regularCanvas.width));
            const rightMargin = 0 / (8192 / regularCanvas.width);
            const leftMargin = 0 / (8192 / regularCanvas.width);
            const verticalOffset = Math.max(
                10 / (8192 / regularCanvas.width),
                (label.fontSize * 0.4) + (squareSize * 0.3)
            );

            const positionTmps = [
                { dx: -(label.width + squareSize + leftMargin), dy: label.height / 2 }, // Left
                { dx: squareSize + rightMargin, dy: label.height / 2 }, // Right
                { dx: -label.width / 2, dy: -Math.max(3, label.fontSize / 2) }, // Top
                { dx: -label.width / 2, dy: label.height + Math.max(4, label.fontSize / 2) } // Bottom
            ];

            let positions = label.name != "Gaza" 
                ? waterCoverageAnalyzer.sortPositionsByWaterCoverage(
                    positionTmps, label.x, label.y, label.width, label.height
                ) 
                : positionTmps;

            for (const pos of positions) {
                const testX = label.x + pos.dx;
                const testY = label.y + pos.dy;

                if (!this.collisionDetector.checkCollision(testX, testY - label.height, label.width, label.height)) {
                    this.drawDot(regularCtx, label);

                    label.labelX = testX;
                    label.labelY = testY;

                    this.drawLabel(regularCtx, label);

                    this.collisionDetector.markOccupied(testX, testY - label.height, label.width, label.height);

                    this.labelHitBoxes.set(label.name, {
                        x: testX,
                        y: testY - label.height,
                        width: label.width,
                        height: label.height,
                        originalX: label.x,
                        originalY: label.y,
                        lat: label.lat,
                        lng: label.lng,
                        iso2: label.iso2,
                        ...label
                    });

                    const addToGrid = (x, y, w, h) => {
                        const gridStartCol = Math.floor((x / 8192) * this.GRID_COLS);
                        const gridEndCol = Math.floor(((x + w) / 8192) * this.GRID_COLS);
                        const gridStartRow = Math.floor((y / 4096) * this.GRID_ROWS);
                        const gridEndRow = Math.floor(((y + h) / 4096) * this.GRID_ROWS);

                        for (let row = gridStartRow; row <= gridEndRow; row++) {
                            for (let col = gridStartCol; col <= gridEndCol; col++) {
                                const wrappedCol = ((col % this.GRID_COLS) + this.GRID_COLS) % this.GRID_COLS;
                                if (row >= 0 && row < this.GRID_ROWS) {
                                    this.labelGrid[row][wrappedCol].add(label.name);
                                }
                            }
                        }
                    };

                    addToGrid(label.x - squareSize / 2, label.y - squareSize / 2, squareSize, squareSize);
                    addToGrid(testX, testY - label.height, label.width, label.height);

                    break;
                }
            }
        }

        // Remove cities that couldn't be placed
        Object.keys(cities).forEach(cityName => {
            if (!this.labelHitBoxes.has(cityName)) {
                delete cities[cityName];
            }
        });

        this.regularOverlay = this.createOverlayMesh(regularCanvas, radius * 1.0005);
        globeContainer.add(this.regularOverlay);
        this.setupInteraction(onCityClick);

        // Create overlay div for popups
        this.overlay = document.createElement('div');
        this.overlay.id = "popupo";
        this.overlay.style.cssText = `
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            width: 100%;
            height: 100%;
        `;
        this.renderer.domElement.parentElement.appendChild(this.overlay);
    }

    isCountryAccessible(targetCountry) {
        if (!this.currentCountry) return true;  // First placement is always allowed
        if (!targetCountry || !this.currentCountry) return false;

        // Check if player has a valid visa
        const tradingSystem = TradingCardSystem.getInstance();

        if (tradingSystem.isBanned(targetCountry)) {
            return false;
        }

        if (tradingSystem.hasValidVisa(targetCountry)) {
            return true;
        }
        
        const must_go = globeState.get('must_go_country');
        if (must_go) {
            return must_go == targetCountry;
        }
        
        // Fall back to regular access lookup
        return this.accessLookup[this.currentCountry]?.[targetCountry] === true;
    }

    getLabelColor(countryCode) {
        if (globeState.get('must_go_country') == countryCode) {
            return 'rgba(0, 0, 0, 1)';
        }

        if (!this.currentCountry) return 'rgba(0, 0, 0, 1)';  // Default black

        // Check if country is banned
        const tradingSystem = TradingCardSystem.getInstance();
        if (tradingSystem.bannedCountries.has(countryCode)) {
            return 'rgb(255, 0, 0)';  // Red for banned countries
        }

        return this.isCountryAccessible(countryCode) ?
            'rgba(0, 0, 0, 0.95)' :
            'rgba(90, 0, 0, 0.95)';  // Red for inaccessible
    }

    _calculateDrawInfo(labelDataFromHitbox, canvasWidth) {
        const {
            labelX, labelY, width, height, fontSize,
            name, cityType, iso2, x, y
        } = labelDataFromHitbox;

        const text = name.toUpperCase();
        const EDGE_THRESHOLD = 20;
        const color = this.getLabelColor(iso2);

        // Dot info
        let dotType = cityType === "primary" ? 'primary' : (cityType === "admin" ? 'admin' : 'other');
        let dotSizeValue;
        
        if (dotType === 'primary') {
            dotSizeValue = 2 + Math.max(4, fontSize * 0.5);
        } else if (dotType === 'admin') {
            dotSizeValue = Math.max(4, fontSize * 0.4);
        } else {
            dotSizeValue = Math.max(4, fontSize * 0.3);
        }
        
        const dotPositions = [{ x, y }];
        if (x < EDGE_THRESHOLD) {
            dotPositions.push({ x: x + canvasWidth, y });
        } else if (x > canvasWidth - EDGE_THRESHOLD) {
            dotPositions.push({ x: x - canvasWidth, y });
        }
        
        const dotInfo = { 
            type: dotType, 
            color, 
            size: dotSizeValue, 
            positions: dotPositions 
        };

        // Label styling info
        const tradingSystem = TradingCardSystem.getInstance();
        const isMustGo = globeState.get('must_go_country') == iso2;
        const isBannedBySystem = tradingSystem.bannedCountries.has(iso2);
        const isEffectivelyBanned = !isMustGo && isBannedBySystem;
        const hasDeal = !isEffectivelyBanned && dealsTable.getPrice(globeState.currentCity, name);

        let fillStyle = color;
        let requiredFont = '';
        let drawBackground = false;
        let backgroundStyle = '';
        let backgroundPadding = 0;
        let applyLabelShadow = false;
        let labelShadowColor = '';
        let labelShadowBlur = 0;
        let labelSortKey = '';

        // Determine styling based on state
        if (isEffectivelyBanned) {
            requiredFont = cityType === "primary" ? `900 ${fontSize}px montserrat, sans-serif` : `600 ${fontSize}px montserrat, sans-serif`;
            backgroundPadding = cityType === "primary" ? 2 : 1;
            backgroundStyle = cityType === "primary" ? 'rgb(255, 0, 0)' : 'rgba(255, 0, 0, 0.8)';
            fillStyle = 'rgb(0, 0, 0)';
            labelShadowColor = 'rgb(236, 229, 130)';
            labelShadowBlur = 7;
            drawBackground = true;
            applyLabelShadow = true;
            labelSortKey = `banned-${cityType}-${requiredFont}-${labelShadowColor}-${labelShadowBlur}`;
        } else if (0 && hasDeal) {
            requiredFont = cityType === "primary" ? `900 ${fontSize}px montserrat, sans-serif` : `600 ${fontSize}px montserrat, sans-serif`;
            backgroundPadding = cityType === "primary" ? 2 : 1;
            backgroundStyle = 'rgba(0, 255, 0, 0.7)';
            drawBackground = true;
            applyLabelShadow = false;
            labelSortKey = `deal-${cityType}-${requiredFont}`;
        } else { // Normal
            if (cityType === 'primary') {
                requiredFont = `900 ${fontSize}px montserrat, sans-serif`;
                labelShadowColor = 'rgb(130, 130, 130)';
                labelShadowBlur = 0;
                applyLabelShadow = true;
                labelSortKey = `normal-primary-${requiredFont}-${labelShadowColor}-${labelShadowBlur}`;
            } else {
                requiredFont = `600 ${fontSize}px montserrat, sans-serif`;
                labelShadowColor = 'rgb(236, 229, 130)';
                labelShadowBlur = 0;
                applyLabelShadow = true;
                labelSortKey = `normal-secondary-${requiredFont}-${labelShadowColor}-${labelShadowBlur}`;
            }
            backgroundPadding = 0;
            drawBackground = false;
        }

        const labelInfo = {
            labelX, labelY, width, height, fontSize, text,
            calculatedTextColor: fillStyle, requiredFont,
            drawBackground, backgroundStyle, backgroundPadding,
            applyLabelShadow, labelShadowColor, labelShadowBlur,
            labelSortKey,
        };

        return { dotInfo, labelInfo };
    }

    drawLabel(ctx, label) {
        const { x, y, labelX, labelY, fontSize, name, cityType, iso2 } = label;
        const canvasWidth = ctx.canvas.width;
        const canvasHeight = ctx.canvas.height;
        const text = name.toUpperCase();

        // State variables
        const textColor = this.getLabelColor(iso2);
        const tradingSystem = TradingCardSystem.getInstance();
        const isMustGo = globeState.get('must_go_country') == iso2;
        const isBannedBySystem = tradingSystem.bannedCountries.has(iso2);
        const isEffectivelyBanned = !isMustGo && isBannedBySystem;
        const hasDeal = !isEffectivelyBanned && dealsTable.getPrice(globeState.currentCity, name);

        // Reset shadow properties
        try {
            ctx.letterSpacing = "0px";
        } catch (e) {
            // Handle lack of letterSpacing support
        }
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 0;

        // Determine styling
        let fillStyle = textColor;
        let currentFont = '';
        let drawBackground = false;
        let backgroundStyle = '';
        let backgroundPadding = 0;
        let applyShadow = false;
        let shadowColor = '';
        let shadowBlur = 0;

        if (isEffectivelyBanned) {
            drawBackground = true;
            backgroundPadding = cityType === "primary" ? 2 : 1;
            backgroundStyle = cityType === "primary" ? 'rgb(255, 0, 0)' : 'rgba(255, 0, 0, 0.8)';
            fillStyle = 'rgb(0, 0, 0)';
            currentFont = cityType === "primary" ? `900 ${fontSize}px montserrat, sans-serif` : `600 ${fontSize}px montserrat, sans-serif`;
            applyShadow = true;
            shadowColor = 'rgb(236, 229, 130)';
            shadowBlur = 7;
        } else if (0 && hasDeal) {
            drawBackground = true;
            backgroundPadding = cityType === "primary" ? 2 : 1;
            backgroundStyle = 'rgba(0, 255, 0, 0.7)';
            fillStyle = textColor;
            currentFont = cityType === "primary" ? `900 ${fontSize}px montserrat, sans-serif` : `600 ${fontSize}px montserrat, sans-serif`;
            applyShadow = false;
        } else {
            if (cityType === 'primary') {
                currentFont = `900 ${fontSize}px montserrat, sans-serif`;
                fillStyle = textColor;
                applyShadow = true;
                shadowColor = 'rgb(130, 130, 130)';
                shadowBlur = 0;
            } else {
                currentFont = `600 ${fontSize}px montserrat, sans-serif`;
                fillStyle = textColor;
                applyShadow = true;
                shadowColor = 'rgb(236, 229, 130)';
                shadowBlur = 0;
            }
        }

        // Apply font and measure text
        ctx.font = currentFont;
        const metrics = ctx.measureText(text);
        const textWidth = metrics.width;
        const textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

        // Core drawing function
        const drawContent = (renderX, renderY) => {
            if (applyShadow) {
                ctx.shadowColor = shadowColor;
                ctx.shadowBlur = shadowBlur;
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
            } else {
                ctx.shadowBlur = 0;
                ctx.shadowColor = 'rgba(0,0,0,0)';
            }

            if (drawBackground) {
                ctx.fillStyle = backgroundStyle;
                ctx.fillRect(
                    renderX - backgroundPadding,
                    renderY - metrics.actualBoundingBoxAscent - backgroundPadding,
                    textWidth + backgroundPadding + 1,
                    textHeight + backgroundPadding * 2
                );
            }

            ctx.fillStyle = fillStyle;
            ctx.fillText(text, renderX, renderY);
        };

        // Handle wrapping logic
        const startX = labelX;
        const endX = labelX + textWidth;

        if (startX >= 0 && endX <= canvasWidth) {
            // Fully visible
            drawContent(labelX, labelY);
        } else {
            ctx.save();

            // Draw visible portion
            ctx.beginPath();
            ctx.rect(0, 0, canvasWidth, canvasHeight);
            ctx.clip();
            drawContent(labelX, labelY);

            // Handle wrapping
            if (startX < 0) {
                // Wraps off left edge
                const wrappedX = labelX + canvasWidth;
                if (wrappedX < canvasWidth) {
                    ctx.restore();
                    ctx.save();
                    ctx.beginPath();
                    ctx.rect(wrappedX - textWidth, 0, textWidth, canvasHeight);
                    ctx.clip();
                    drawContent(wrappedX, labelY);
                }
            } else { // endX > canvasWidth
                // Wraps off right edge
                const wrappedX = labelX - canvasWidth;
                if (wrappedX + textWidth > 0) {
                    ctx.restore();
                    ctx.save();
                    ctx.beginPath();
                    ctx.rect(0, 0, wrappedX + textWidth, canvasHeight);
                    ctx.clip();
                    drawContent(wrappedX, labelY);
                }
            }

            ctx.restore();
        }

        // Reset lingering state if needed
        if (startX >= 0 && endX <= canvasWidth && applyShadow) {
            ctx.shadowBlur = 0;
            ctx.shadowColor = 'rgba(0,0,0,0)';
        }
    }

    drawDot(ctx, label) {
        const { x, y, dotSize, cityType, fontSize, iso2 } = label;
        const canvasWidth = ctx.canvas.width;
        const MIN_SIZE = 4;

        const drawShape = (x, y) => {
            const color = this.getLabelColor(iso2);

            ctx.shadowColor = 'rgba(20, 20, 20, 0.5)';
            ctx.shadowBlur = 1;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 0;

            if (cityType === "primary") {
                const size = 2 + Math.max(MIN_SIZE, fontSize * 0.5);
                ctx.fillStyle = 'white';
                ctx.strokeStyle = color;
                ctx.lineWidth = 3;
                ctx.beginPath();
                ctx.arc(x, y, size / 2, 0, Math.PI * 2);
                ctx.fill();
                ctx.stroke();
            } else if (cityType === "admin") {
                const size = Math.max(MIN_SIZE, fontSize * 0.4);
                ctx.fillStyle = color;
                ctx.beginPath();
                ctx.rect(x - size / 2, y - size / 2, size, size);
                ctx.fill();
            } else {
                const size = Math.max(MIN_SIZE, fontSize * 0.3);
                ctx.fillStyle = color;
                ctx.beginPath();
                ctx.arc(x, y, size / 2, 0, Math.PI * 2);
                ctx.fill();
            }
        };

        // Draw the main shape
        drawShape(x, y);

        // Draw on the other side if near the edge
        const EDGE_THRESHOLD = 20;
        if (x < EDGE_THRESHOLD) {
            drawShape(x + canvasWidth, y);
        } else if (x > canvasWidth - EDGE_THRESHOLD) {
            drawShape(x - canvasWidth, y);
        }
    }

    rebuildLabelTexture() {
        // Canvas setup
        const { canvas: regularCanvas, ctx: regularCtx } = createOverlayTexture(8192, 4096);
        const canvasWidth = regularCanvas.width;
        const canvasHeight = regularCanvas.height;
    
        // Initial context settings
        regularCtx.imageSmoothingEnabled = true;
        regularCtx.imageSmoothingQuality = 'high';
        try { regularCtx.letterSpacing = "0px"; } catch (e) { /* Ignore */ }
        regularCtx.shadowOffsetX = 0;
        regularCtx.shadowOffsetY = 0;
    
        // Phase 1: Calculate styling info
        const drawingQueue = [];
        for (const labelData of this.labelHitBoxes.values()) {
            drawingQueue.push(this._calculateDrawInfo(labelData, canvasWidth));
        }
    
        // Phase 2: Batch draw dots
        console.time("drawDots");
        regularCtx.shadowColor = 'rgba(20, 20, 20, 0.5)';
        regularCtx.shadowBlur = 1;
        
        let currentDotType = null;
        let currentDotColor = null;
        
        drawingQueue.forEach(({ dotInfo }) => {
            const { type, color, size, positions } = dotInfo;
            
            // Minimize state changes
            if (type !== currentDotType) {
                currentDotType = type;
                currentDotColor = color;
                
                if (type === 'primary') {
                    regularCtx.strokeStyle = color;
                    regularCtx.lineWidth = 3;
                } else {
                    regularCtx.fillStyle = color;
                }
            } else if (color !== currentDotColor) {
                currentDotColor = color;
                
                if (type === 'primary') {
                    regularCtx.strokeStyle = color;
                } else {
                    regularCtx.fillStyle = color;
                }
            }
            
            // Draw dots
            positions.forEach(pos => {
                regularCtx.beginPath();
                
                if (type === "primary") {
                    regularCtx.arc(pos.x, pos.y, size / 2, 0, Math.PI * 2);
                    regularCtx.fillStyle = 'white';
                    regularCtx.fill();
                    regularCtx.stroke();
                } else if (type === "admin") {
                    regularCtx.rect(pos.x - size / 2, pos.y - size / 2, size, size);
                    regularCtx.fill();
                } else {
                    regularCtx.arc(pos.x, pos.y, size / 2, 0, Math.PI * 2);
                    regularCtx.fill();
                }
            });
        });
        console.timeEnd("drawDots");
    
        // Reset shadows before labels
        regularCtx.shadowColor = 'rgba(0,0,0,0)';
        regularCtx.shadowBlur = 0;
    
        // Phase 3: Batch draw labels
        console.time("drawLabels");
        // Sort by style key for batching
        drawingQueue.sort((a, b) => a.labelInfo.labelSortKey.localeCompare(b.labelInfo.labelSortKey));
    
        // Track font and shadow state for optimization
        let currentFont = null;
        let currentLabelShadowColor = null;
        let currentLabelShadowBlur = -1;
        let currentApplyLabelShadow = null;
    
        drawingQueue.forEach(({ labelInfo }) => {
            const {
                labelX, labelY, text, width, calculatedTextColor, requiredFont,
                fontSize, drawBackground, backgroundStyle, backgroundPadding,
                applyLabelShadow, labelShadowColor, labelShadowBlur
            } = labelInfo;
    
            // Minimize font state changes
            let fontWasSet = false;
            if (requiredFont !== currentFont) {
                regularCtx.font = requiredFont;
                currentFont = requiredFont;
                fontWasSet = true;
            }
    
            // Minimize shadow state changes
            if (applyLabelShadow !== currentApplyLabelShadow || 
                (applyLabelShadow && (labelShadowColor !== currentLabelShadowColor || 
                                     labelShadowBlur !== currentLabelShadowBlur))) {
                if (applyLabelShadow) {
                    regularCtx.shadowColor = labelShadowColor;
                    regularCtx.shadowBlur = labelShadowBlur;
                } else {
                    regularCtx.shadowColor = 'rgba(0,0,0,0)';
                    regularCtx.shadowBlur = 0;
                }
                currentApplyLabelShadow = applyLabelShadow;
                currentLabelShadowColor = labelShadowColor;
                currentLabelShadowBlur = labelShadowBlur;
            }
    
            // Drawing function with targeted font re-assertion
            const performDraw = (targetX, targetY) => {
                let forceFontReset = false;
    
                if (drawBackground) {
                    regularCtx.fillStyle = backgroundStyle;
                    regularCtx.fillRect(
                        targetX - backgroundPadding,
                        targetY - fontSize + backgroundPadding,
                        width + backgroundPadding * 2,
                        fontSize + backgroundPadding + 1
                    );
                    // Force font reset if needed
                    if (!fontWasSet) {
                        forceFontReset = true;
                    }
                }
    
                regularCtx.fillStyle = calculatedTextColor;
    
                // Re-assert font if needed
                if (forceFontReset) {
                    regularCtx.font = requiredFont;
                }
    
                regularCtx.fillText(text, targetX, targetY);
            };
    
            // Handle wrapping
            const startX = labelX;
            const endX = labelX + width;
    
            if (startX >= 0 && endX <= canvasWidth) {
                performDraw(labelX, labelY);
            } else {
                regularCtx.save();
                regularCtx.beginPath();
                regularCtx.rect(0, 0, canvasWidth, canvasHeight);
                regularCtx.clip();
                performDraw(labelX, labelY);
                regularCtx.restore();
                
                let wrappedX;
                let clipRectArgs;
                
                if (startX < 0) {
                    // Wraps left
                    wrappedX = labelX + canvasWidth;
                    if (wrappedX < canvasWidth) {
                        clipRectArgs = [canvasWidth - (-startX), 0, -startX + 1, canvasHeight];
                    }
                } else {
                    // Wraps right
                    wrappedX = labelX - canvasWidth;
                    if (wrappedX + width > 0) {
                        clipRectArgs = [0, 0, endX - canvasWidth + 1, canvasHeight];
                    }
                }
                
                if (clipRectArgs) {
                    regularCtx.save();
                    regularCtx.beginPath();
                    regularCtx.rect(...clipRectArgs);
                    regularCtx.clip();
                    performDraw(wrappedX, labelY);
                    regularCtx.restore();
                }
            }
        });
        console.timeEnd("drawLabels");
    
        // Update Three.js texture
        if (this.regularOverlay.material.map) {
            this.regularOverlay.material.map.dispose();
        }
        this.regularOverlay.material.map = new THREE.CanvasTexture(regularCanvas);
        this.regularOverlay.material.map.needsUpdate = true;
    }


    createInfoPopup(cityData, position) {
        const screenVec = position.clone();
        screenVec.project(this.camera);

        const rendererRect = this.renderer.domElement.getBoundingClientRect();
        const screenPosition = {
            x: rendererRect.left + (screenVec.x + 1) * 0.5 * rendererRect.width,
            y: rendererRect.top + (-screenVec.y + 1) * 0.5 * rendererRect.height
        };

        const existing = this.overlay.querySelector('.city-popup');
        if (existing) existing.remove();

        const isBanned = tradingSystem.bannedCountries.has(cityData.iso2);
        const isAccessible = this.isCountryAccessible(cityData.iso2);

        const hasFriend = globeState.getModifier(cityData.name, 'made_friend') || false;

        let popupClass = isAccessible ? 'city-popup' : 'city-popup inaccessible';
        if (isBanned) popupClass += ' banned';

        const popup = document.createElement('div');
        popup.className = popupClass;

        // Add compass icon
        const compassIcon = document.createElement('div');
        compassIcon.className = 'compass-icon';
        compassIcon.innerHTML = '&#8689;';
        popup.appendChild(compassIcon);

        // Create content structure
        let content = `<h2 class="destination">${cityData.name}</h2>`;
        content += `<p class="country">${countryCodes[cityData.iso2].name || ''}</p>`;

        const travelClass = getTravelClass(globeState.currentCity, globeState);
        const priceMultiplier = travelClass == 'comfort' ? 3 : 1.75;

        let distance = 0;
        let priceFlight = 0;
        // Distance calculation
        if (lastPlacedMarker) {
            const startLatLon = vector3ToLatLong(lastPlacedMarker.clone().add(this.globeContainer.position), this.globeContainer);
            const startLon = ((startLatLon.lon + 180) % 360);
            const endLon = ((cityData.lng + 90) % 360);

            distance = calculateGreatCircleDistance(
                startLatLon.lat, startLon,
                cityData.lat, endLon,
                6378
            );

            let standardPrice = hasFriend ? 0 : Math.round(distance * priceMultiplier);
            let dealPrice = dealsTable.getPrice(globeState.currentCity,  cityData.name);
            priceFlight = dealPrice || standardPrice;

            // JavaScript
            content += `<div class="distance-container">
                        <span class="distance">✈ ${Math.round(distance).toLocaleString()} km</span>
                        <span class=" ${priceFlight > globeState.travelAccount ? 'distance-price-toomuch' : 'distance-price'} ${dealPrice ? 'price-strike-out' : ''}">$${standardPrice.toLocaleString()}</span>
                        </div>`;
            content +=    dealPrice ? 
                        `<div class="distance-container">
                          <div class="price-wrapper">

                        <span class="deal-price-pre">now</span>
                        <span class="deal-price">$${priceFlight.toLocaleString()}</span>
                        </div></div>`
                        : '';
            }
        

        content += `<div class="divider"></div>`;
        content += `<div class="key-info">`;

        if (cityData.name == globeState.currentCity) {

            content += `<div class="info-item">
                <span class="info-icon plain-info">!</span>
                <span class="">You are here</span>
            </div>`;

        } else {
            if (globeState.get('must_go_country') && !(globeState.get('must_go_country') == cityData.iso2) ) {
                content += `<div class="info-item">
                <span class="info-icon visa-required">!</span>
                <span class="info-blocker">Next city must be in ${countryCodes[globeState.get('must_go_country')].name}</span>
                </div>`;
            }
            if (priceFlight > globeState.travelAccount && !hasFriend) {
                content += `<div class="info-item">
                <span class="info-icon visa-required">$</span>
                <span class="info-blocker">Insufficient funds</span>
                </div>`;
            }
            if (globeState.funAccount == 0 && !hasFriend) {
                content += `<div class="info-item">
                <span class="info-icon fun-required">:(</span>
                <span>Can't travel, too sad</span>
                </div>`;
            }
            if (!(travelClass == "economy") && !hasFriend) {
                content += `<div class="info-item">
                <span class="info-icon class-required">⚜</span>
                <span>Flying ${travelClass} class ($x2)</span>
                </div>`;
            }
        }

        if (hasFriend) {
            content += `<div class="info-item">
            <span class="info-icon fun-required">:)</span>
            <span>Free travel to visit friend</span>
            </div>`;
        }

        if (cityData.iso2 != this.currentCountry) {

            content += `<div class="info-item">
                <span class="info-icon plain-info">!</span>
                <span class="">Border Crossing</span>
            </div>`;

            // Visa status
            if (isBanned) {
                content += `<div class="info-item">
                    <span class="info-icon visa-required">X</span>
                    <span class="banned-warning">Persona non grata</span>
                </div>`;
            } else if (this.currentCountry) {
                const tradingSystem = TradingCardSystem.getInstance();
                const hasVisa = tradingSystem.hasValidVisa(cityData.iso2);
                const normalAccess = this.accessLookup[this.currentCountry]?.[cityData.iso2] === true;

                if (!normalAccess && !hasVisa) {
                    content += `<div class="info-item">
                        <span class="info-icon visa-required">V</span>
                        <span class="info-blocker">Visa required</span>
                    </div>`;
                } else if (hasVisa && !normalAccess) {
                    content += `<div class="info-item">
                        <span class="info-icon visa-free">V</span>
                        <span>Visa Active</span>
                    </div>`;
                } else {
                    content += `<div class="info-item">
                        <span class="info-icon visa-free">✓</span>
                        <span>Visa free travel</span>
                    </div>`;
                }
            }
        }

        content += `</div>`; // Close key-info div

        // Add placeholder for additional info
        // You can add price level, quizzes, tourism level, etc. here

        // Mobile button
      /*  if (this.isMobile) {
            content += `<button class="go-button">Go here</button>`;
        }
*/
        popup.innerHTML += content;

        // Position the popup
        popup.style.left = '-9999px';
        popup.style.top = '-9999px';
        this.overlay.appendChild(popup);

        const popupRect = popup.getBoundingClientRect();
        const viewportWidth = window.innerWidth;

        let adjustedX = screenPosition.x;
        let adjustedY = screenPosition.y - 30;

        // Handle edge cases for positioning
        if (adjustedX - popupRect.width / 2 < 0) {
            adjustedX = popupRect.width / 2 + 10;
        } else if (adjustedX + popupRect.width / 2 > viewportWidth) {
            adjustedX = viewportWidth - popupRect.width / 2 - 10;
        }

        // Flip popup if too close to top of screen
        if (adjustedY - popupRect.height < 0) {
            adjustedY = screenPosition.y + 30;
            popup.style.transform = 'translate(-50%, 0)';
            popup.style.setProperty('--after-top', '-6px');
            popup.style.setProperty('--after-bottom', 'auto');
            popup.style.setProperty('--after-transform', 'translateX(-50%) rotate(225deg)');
        }

        popup.style.left = `${adjustedX}px`;
        popup.style.top = `${adjustedY}px`;
        
        // Animate in
        requestAnimationFrame(() => {
            popup.style.opacity = '1';
            popup.style.transform = popup.style.transform.includes('translate(-50%, 0)')
                ? 'translate(-50%, 5px)'
                : 'translate(-50%, -105%)';
        });

        // Add event listener for mobile button
   //     if (this.isMobile) {
     //       const goButton = popup.querySelector('.go-button');
       //     if (goButton) {
         //       goButton.addEventListener('click', () => {
                    // Your existing travel logic here
             //   });
           // }
      //  }

        return popup;
    }
    createOverlayMesh(canvas, globeRadius) {
        const texture = new THREE.CanvasTexture(canvas);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.ClampToEdgeWrapping;
        texture.needsUpdate = true;
    
        // Create a canvas to work with the normal map
        const normalCanvas = document.createElement('canvas');
        const normalCtx = normalCanvas.getContext('2d', { willReadFrequently: true });
        normalCanvas.width = globe.normalTexture.image.width;
        normalCanvas.height = globe.normalTexture.image.height;
    
        // Draw the original normal map onto our canvas
        normalCtx.drawImage(globe.normalTexture.image, 0, 0);
    
        // Get image data once and create new arrays for better performance
        const labelCtx = canvas.getContext('2d', { willReadFrequently: true });
        const labelData = labelCtx.getImageData(0, 0, canvas.width, canvas.height);
        const normalData = normalCtx.getImageData(0, 0, normalCanvas.width, normalCanvas.height);
    
        const scaleX = normalCanvas.width / canvas.width;
        const scaleY = normalCanvas.height / canvas.height;
    
        const embossRadius = 2;  // Radius for edge detection
        const embossStrength = 20;  // Strength of embossing effect
        const terrainStrength = 0.7;  // Strength of terrain detail (0-1)
    
        // Create TypedArrays for better performance
        const normalArray = new Uint8ClampedArray(normalData.data.buffer);
        const labelArray = new Uint8ClampedArray(labelData.data.buffer);
    
        // Pre-calculate constants
        const canvasWidth = canvas.width;
        const normalCanvasWidth = normalCanvas.width;
        const canvasHeight = canvas.height;
    
        // First, create a copy of the original normal map to preserve terrain details
        const originalNormalArray = new Uint8ClampedArray(normalArray);
    
        // Process the normal map
        for (let y = 0; y < normalCanvas.height; y++) {
            const yOffset = y * normalCanvasWidth;
            const labelY = Math.floor(y / scaleY);
    
            if (labelY >= 0 && labelY < canvasHeight) {
                const labelYOffset = labelY * canvasWidth;
    
                for (let x = 0; x < normalCanvasWidth; x++) {
                    const labelX = Math.floor(x / scaleX);
    
                    if (labelX >= 0 && labelX < canvasWidth) {
                        const normalIdx = (yOffset + x) << 2;
                        const labelIdx = (labelYOffset + labelX) << 2;
    
                        const centerAlpha = labelArray[labelIdx + 3];
    
                        if (centerAlpha > 0) {
                            // Get surrounding alpha values for normal calculation
                            const leftAlpha = labelX > embossRadius ?
                                labelArray[(labelYOffset + (labelX - embossRadius)) << 2 | 3] : 0;
                            const rightAlpha = labelX < canvasWidth - embossRadius ?
                                labelArray[(labelYOffset + (labelX + embossRadius)) << 2 | 3] : 0;
                            const topAlpha = labelY > embossRadius ?
                                labelArray[((labelY - embossRadius) * canvasWidth + labelX) << 2 | 3] : 0;
                            const bottomAlpha = labelY < canvasHeight - embossRadius ?
                                labelArray[((labelY + embossRadius) * canvasWidth + labelX) << 2 | 3] : 0;
    
                            // Calculate sharp gradients for embossing
                            const dx = (rightAlpha - leftAlpha) * embossStrength / 255;
                            const dy = (bottomAlpha - topAlpha) * embossStrength / 255;
    
                            // Get original terrain normal from our copy (in -1 to 1 range)
                            const terrainX = (originalNormalArray[normalIdx] - 128) / 128;
                            const terrainY = (originalNormalArray[normalIdx + 1] - 128) / 128;
                            const terrainZ = (originalNormalArray[normalIdx + 2] - 128) / 128;
    
                            // Calculate blending factor based on text opacity
                            // Use sqrt for a more gradual transition
                            const textBlendFactor = Math.sqrt(centerAlpha / 255);
                            
                            // Combine embossing with terrain normals
                            // Start with 128 (neutral) and add both embossing and terrain influence
                            let newX = 128 + dx * textBlendFactor + terrainX * 128 * terrainStrength;
                            let newY = 128 + dy * textBlendFactor + terrainY * 128 * terrainStrength;
                            
                            // For height (Z component), use the embossing to enhance the terrain
                            const heightAddition = 40 * textBlendFactor; // Text height boost
                            let newZ = 128 + terrainZ * 128 * terrainStrength + heightAddition;
    
                            // Ensure values stay in valid range
                            normalArray[normalIdx] = Math.max(0, Math.min(255, newX));
                            normalArray[normalIdx + 1] = Math.max(0, Math.min(255, newY));
                            normalArray[normalIdx + 2] = Math.max(0, Math.min(255, newZ));
                        }
                    }
                }
            }
        }
    
        // Update the normal map
        normalCtx.putImageData(normalData, 0, 0);
    
        const modifiedNormalMap = new THREE.CanvasTexture(normalCanvas);
        modifiedNormalMap.wrapS = THREE.RepeatWrapping;
        modifiedNormalMap.wrapT = THREE.ClampToEdgeWrapping;
        modifiedNormalMap.needsUpdate = true;
    
        // Use the modified normal map
        const material = new THREE.MeshPhysicalMaterial({
            map: texture,
            normalMap: isMobile ? null : modifiedNormalMap,
            aoMap: isMobile ? null : globe.aoTexture,
            aoMapIntensity: 3,
            normalScale: new THREE.Vector2(-1.2, 1.2),
            clearcoat: 1.0,
            clearcoatRoughness: 0.0,
            transparent: true,
            roughness: 0.2,
            metalness: 0.0,
            iridescence: 1.0,
            iridescenceIOR: 1.5,
            transmission: 0.0,
            envMap: isMobile ? null : envMap,
            envMapIntensity: 0.6,
            ior: 1.5,
            thickness: 0.0,
        });
    
        const overlayMesh = new THREE.Mesh(
            new THREE.SphereGeometry(globeRadius, 48, 48),
            material
        );
    
        overlayMesh.castShadow = false;
        overlayMesh.receiveShadow = true;
        overlayMesh.rotation.y = Math.PI;

        return overlayMesh;
    }

    getTextureCoordinates(intersectionPoint) {
        const globalPosition = intersectionPoint.clone().sub(this.regularOverlay.parent.position);
        const normalizedPoint = globalPosition.normalize();

        const lat = 90 - (Math.acos(normalizedPoint.y) * 180 / Math.PI);
        let lon = Math.atan2(-normalizedPoint.z, normalizedPoint.x) * 180 / Math.PI;
        if (lon < 0) lon += 360;

        const canvasWidth = this.regularOverlay.material.map.image.width;
        const canvasHeight = this.regularOverlay.material.map.image.height;

        let x = ((lon) / 360) * canvasWidth;
        const y = ((90 - lat) / 180) * canvasHeight;

        return { x, y };
    }

    findIntersectingLabel(textureX, textureY) {
        const gridCol = Math.floor((textureX / 8192) * this.GRID_COLS);
        const gridRow = Math.floor((textureY / 4096) * this.GRID_ROWS);
        const wrappedCol = ((gridCol % this.GRID_COLS) + this.GRID_COLS) % this.GRID_COLS;

        if (gridRow < 0 || gridRow >= this.GRID_ROWS) return null;

        const potentialLabels = new Set();
        for (let rowOffset = -1; rowOffset <= 1; rowOffset++) {
            const checkRow = gridRow + rowOffset;
            if (checkRow >= 0 && checkRow < this.GRID_ROWS) {
                for (let colOffset = -1; colOffset <= 1; colOffset++) {
                    const checkCol = ((wrappedCol + colOffset) % this.GRID_COLS + this.GRID_COLS) % this.GRID_COLS;
                    this.labelGrid[checkRow][checkCol].forEach(label => potentialLabels.add(label));
                }
            }
        }

        const normalizedX = ((textureX % 8192) + 8192) % 8192;

        for (const cityName of potentialLabels) {
            const hitBox = this.labelHitBoxes.get(cityName);

            const normalizedLabelX = ((hitBox.x % 8192) + 8192) % 8192;
            const normalizedMouseX = ((normalizedX % 8192) + 8192) % 8192;

            const directDistX = Math.abs(normalizedMouseX - normalizedLabelX);
            const wrappedDistX = Math.min(
                Math.abs(normalizedMouseX - (normalizedLabelX + 8192)),
                Math.abs(normalizedMouseX - (normalizedLabelX - 8192))
            );
            const effectiveDistX = Math.min(directDistX, wrappedDistX);

            const distY = Math.abs(textureY - hitBox.y);

            if (effectiveDistX <= hitBox.width && distY <= hitBox.height) {
                return cityName;
            }
        }

        const DOT_SIZE = 10;
        for (const cityName of potentialLabels) {
            const hitBox = this.labelHitBoxes.get(cityName);
            const dotX = ((hitBox.originalX % 8192) + 8192) % 8192;

            if (Math.abs(normalizedX - dotX) <= DOT_SIZE &&
                Math.abs(textureY - hitBox.originalY) <= DOT_SIZE) {
                return cityName;
            }
        }

        return null;
    }

    hidePopup() {
        const existing = this.overlay.querySelector('.city-popup');
        if (existing) {
            existing.style.opacity = '0';
            setTimeout(() => existing.remove(), 200);
        }
    }

    setCurrentCountry(iso2) {
        this.currentCountry = iso2;
    }

    executeTravelHop(fromPinPosition, newPinPosition, clickedLabel, travelClass) {
        // Rebuild label texture with new accessibility states
        soundManager.playSound('seatbelts');

        //
        // Calculate Deals Here
        const departures = generateFlights(cities[clickedLabel].commonDestinationsArray, cities, clickedLabel, airportToCity);

        dealsTable.updateFromDepartures(departures);
        //

        const cityData = this.labelHitBoxes.get(clickedLabel);
        this.setCurrentCountry(cityData.iso2);  // Update current country
        this.rebuildLabelTexture();

        // Create ribbon with the specified style
        const ribbon = createAndSaveRibbon(fromPinPosition, newPinPosition, travelClass);
        this.globeContainer.add(ribbon);
        ribbons.push(ribbon);

        globeState.removeModifier(globeState.currentCity, 'seen_itinerary');

        previewRibbon.visible = false;

    }

    executeDelayedTravelActions(newPinPosition, clickedLabel, distance, ribbonStyle = 'economy') {

        threeJs.startRender();
        goToLocation(  );

        sparkleSystem.addEffect(
            newPinPosition,
            this.globeContainer,
            {
                particleCount: 30,
                minScale: 0.01,
                maxScale: 0.02,
                upwardBias: 0.7
            }
        );
        soundManager.playSound('sparkle');

//        soundManager.pauseCurrentMusic(1500);
        soundManager.playBackgroundMusic('tm-foreground-bossa', 1000); // 1 second fade in

        // If pin exists, remove it from scene and pins array before creating new one
        if (pinLookup[clickedLabel]) {
            const oldPin = pinLookup[clickedLabel];
            globe.container.remove(oldPin);
            const index = pins.indexOf(oldPin);
            if (index > -1) {
                pins.splice(index, 1);
            }
        }

     //   const hasFriend = globeState.getModifier(clickedLabel, 'made_friend') || false;
    //    let pinToUse = createCityPin(newPinPosition, 0.035, getQuizStatus(clickedLabel, globeState) == 'finished' ? pinHeadMaterials['finished'] : null);
    
        let pinToUse = createCityPin(newPinPosition, 0.035, pinHeadMaterials[ determinePinHeadType(clickedLabel, globeState.getState()) ] );
        pinLookup[clickedLabel] = pinToUse;

        this.globeContainer.add(pinToUse);
        pins.push(pinToUse);
        animatePinPushIn(globe, pinToUse, newPinPosition);

        lastPlacedMarker = newPinPosition;

        createOrUpdateRibbon(lastPlacedMarker, lastPlacedMarker, true, previewRibbon, ribbonStyle);
        previewRibbon.visible = false;

        return pinToUse;
    }

    setupInteraction(onCityClick) {
        const mouse = new THREE.Vector2();

        this.raycaster.setFromCamera(mouse, camera);

        this.renderer.domElement.addEventListener('mouseup', this.handleMouseUp.bind(this));

        const eventType = this.isMobile ? 'touchstart' : 'mousemove';

        this.renderer.domElement.addEventListener(eventType, (event) => {
            isMouseMovedSinceLastClick = true;
            if (isMobile) { this.hidePopup(); }

            const coords = //this.isMobile ?
              //  { x: event.touches[0].clientX, y: event.touches[0].clientY } :
                { x: event.clientX, y: event.clientY };

            const rect = this.renderer.domElement.getBoundingClientRect();
            mouse.x = ((coords.x - rect.left) / rect.width) * 2 - 1;
            mouse.y = -((coords.y - rect.top) / rect.height) * 2 + 1;

            if (!physicsInitialized && previewRibbon && previewRibbon.animator) {
                previewRibbon.animator.initializePhysics();
                physicsInitialized = true;
            }

            hoveredPin = checkPinHover(mouse);

            this.raycaster.setFromCamera(mouse, this.camera);
            const globeIntersects = this.raycaster.intersectObject(globe.mesh);
            const labelIntersects = this.raycaster.intersectObject(this.regularOverlay);

            if (hoveredPin) {
                updatePreviewObjects(hoveredPin.position);
            } else if (globeIntersects.length > 0) {
                updatePreviewObjects(globeIntersects[0].point);
            } else {
                updatePreviewObjects(null);
            }

            let intersectedLabel = null;
            if (labelIntersects.length > 0) {
                const textureCoords = this.getTextureCoordinates(labelIntersects[0].point);
                intersectedLabel = this.findIntersectingLabel(textureCoords.x, textureCoords.y);
            }

            if (intersectedLabel !== this.hoveredLabel) {
                const existing = this.overlay.querySelector('.city-popup');
                if (existing) {
                    existing.style.opacity = '0';
                    setTimeout(() => existing.remove(), 200);
                }

                if (intersectedLabel || hoveredPin) {
                    const cityData = intersectedLabel ? this.labelHitBoxes.get(intersectedLabel) : null;
                    const position = cityData ?
                        latLongToVector3(cityData.lat, cityData.lng, globeRadius) :
                        hoveredPin.position.clone().add(this.globeContainer.position);

                    if (lastPlacedMarker) {
                        const startLatLon = vector3ToLatLong(lastPlacedMarker.clone().add(this.globeContainer.position), this.globeContainer);
                        const endLatLon = vector3ToLatLong(position, this.globeContainer);

                        const startLon = ((startLatLon.lon + 180) % 360);
                        const endLon = ((endLatLon.lon + 180) % 360);

                        const distance = calculateGreatCircleDistance(
                            startLatLon.lat, startLon,
                            endLatLon.lat, endLon,
                            6378
                        );

                        const travelClass = getTravelClass(globeState.currentCity, globeState);
                        const priceMultiplier = travelClass == 'comfort' ? 3 : 1.75;
                        const hasFriend = cityData ? globeState.getModifier(cityData.name, 'made_friend') || false : false;
                        const standardPrice = hasFriend ? 0 : Math.round(distance * priceMultiplier);
                        const priceFlight = cityData ? dealsTable.getPrice(globeState.currentCity, cityData.name) || standardPrice : false;

                        // Check both distance and country accessibility
                        const isAccessible = cityData ? this.isCountryAccessible(cityData.iso2) : true;
                        if (priceFlight <= globeState.travelAccount && isAccessible && globeState.funAccount > 0) {
                            setRibbonToValid(previewRibbon);
                        } else {
                            setRibbonToImpossible(previewRibbon);
                        }
                    } else {
                        // First placement is always valid
                        setRibbonToValid(previewRibbon);
                    }

                    if (cityData) {
                        this.createInfoPopup(cityData, position);
                    }
                    
                } else {
                    setRibbonToImpossible(previewRibbon);
                }
                this.hoveredLabel = intersectedLabel;
            }
        });
    }

    async handleMouseUp(event) {

//        console.log('we got a mouse up');
        
        globeState.updateCameraState(controls, camera);

        const wasClick = (Date.now() - mouseDownTime) < CLICK_TIME_THRESHOLD;
        if (isGlobeRolledOut || isRollingGlobe || !wasClick) return;

        const mouse = new THREE.Vector2(
            (event.clientX / window.innerWidth) * 2 - 1,
            -(event.clientY / window.innerHeight) * 2 + 1
        );

        this.raycaster.setFromCamera(mouse, this.camera);
        const intersects = this.raycaster.intersectObject(globe.mesh);

        if (intersects.length > 0) {
            const textureCoords = this.getTextureCoordinates(intersects[0].point);
            const clickedLabel = this.findIntersectingLabel(textureCoords.x, textureCoords.y);

            // Handle invalid clicks
            if (!clickedLabel && !hoveredPin) {
                if (previewRibbon && previewRibbon.animator) {
                    soundManager.playSound('wubb-2');
                    previewRibbon.animator.shake(0.03);
                }
                return;
            }

            let newPinPosition;
            if (clickedLabel) {
                const cityData = this.labelHitBoxes.get(clickedLabel);
                newPinPosition = latLongToVector3(cityData.lat, cityData.lng, this.radius)
                    .sub(this.globeContainer.position);

                // Check if travel is allowed to this country
                if (this.currentCountry && !this.isCountryAccessible(cityData.iso2)) {
                    if (previewRibbon.animator) {
                        soundManager.playSound('wubb-2');
                        previewRibbon.animator.shake(0.03);
                    }
                    return;
                }
            } else if (hoveredPin) {
                newPinPosition = hoveredPin.position.clone();
            }

            if (clickedLabel === 'London') {
                const hasCircumnavigated = hasCircumnavigatedGlobe(globeState.pins);
                const modal = createLondonModal(hasCircumnavigated, globeState.totalDistanceTraveled, globeState, cities);

            }
            else if (clickedLabel && lastPlacedMarker) {
                const startLatLon = vector3ToLatLong(lastPlacedMarker.clone().add(this.globeContainer.position), this.globeContainer);
                const endLatLon = vector3ToLatLong(newPinPosition.clone().add(this.globeContainer.position), this.globeContainer);

                const startLon = ((startLatLon.lon + 180) % 360);
                const endLon = ((endLatLon.lon + 180) % 360);

                const distance = calculateGreatCircleDistance(
                    startLatLon.lat, startLon,
                    endLatLon.lat, endLon,
                    6378
                );

                const travelClass = getTravelClass(globeState.currentCity, globeState);
                const priceMultiplier = travelClass == 'comfort' ? 3 : 1.75;
                const hasFriend = globeState.getModifier(clickedLabel, 'made_friend') || false;
                const standardPrice = hasFriend ? 0 : Math.round(distance * priceMultiplier);
                const priceFlight = dealsTable.getPrice(globeState.currentCity, clickedLabel) || standardPrice;

                if (priceFlight > globeState.travelAccount || globeState.funAccount == 0 && !(clickedLabel == globeState.currentCity)) {
                    if (previewRibbon.animator) {
                        soundManager.playSound('wubb-2');
                        previewRibbon.animator.shake(0.03);
                    }

                    if (isMobile) {
                        const cityData = this.labelHitBoxes.get(clickedLabel);
                        const position = cityData ?
                        latLongToVector3(cityData.lat, cityData.lng, globeRadius) :
                        hoveredPin.position.clone().add(this.globeContainer.position);

                        this.createInfoPopup(cityData, position);
                    }
                    return;
                }

                try {
                    this.hidePopup();

                    const executeTravelCallback = (pinPos, cityLabel, dist, style = null) => {
                        this.executeDelayedTravelActions(pinPos, cityLabel, dist, style);
                    };

                    const getPinPositionCallback = (cityData) => {
                        return latLongToVector3(cityData.lat, cityData.lng, this.radius)
                            .sub(this.globeContainer.position);
                    };

                    const result = await handleCityTravel(
                        clickedLabel,
                        distance,
                        false,
                        executeTravelCallback,
                        getPinPositionCallback
                    );

                    if (result.success) {
                        if (!result.isFreeTravel) {
                            executeTravelCallback(newPinPosition, clickedLabel, distance, result.travelClass);
                        }
                    } else {
                        if (previewRibbon.animator) {
                         //   soundManager.playSound('wubb-2');
                            previewRibbon.animator.shake(0.03);
                        }
                    }
                } catch (error) {
                    console.error('Error during city travel:', error);
                    if (previewRibbon.animator) {
                        soundManager.playSound('wubb-2');
                        previewRibbon.animator.shake(0.03);
                    }
                }
            }
        }

        previewPin.visible = false;
        previewRibbon.visible = false;
        isMouseMovedSinceLastClick = false;
    }
}

// Add this to your JavaScript

async function getFacts(source) {
    // If source is already an object, return it directly
    if (typeof source === 'object' && source !== null) {
        return source;
    }

    // Otherwise treat it as a file path
    return loadFacts(source);
}


// Using async/await - recommended modern approach
async function loadFacts(factfile) {
    try {
        const response = await fetch(factfile);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error loading facts:', error);
    }
}


function submitQuizScore(quizId, correctCount, uid) {
    if (!uid) {
        return Promise.reject(new Error("User ID is required"));
    }

    // We can directly check if this uid exists as a key
    return db.collection("tm_quiz_submissions")
        .doc(quizId)
        .get()
        .then(doc => {
            if (doc.exists && doc.data()[uid]) {
                console.log("User already submitted for this quiz");
                return null;
            }

            const update = {
                [uid]: {
                    correctCount: correctCount,
                    timestamp: Date.now() // keeping timestamp for reference
                }
            };

            return db.collection("tm_quiz_submissions")
                .doc(quizId)
                .set(update, { merge: true });
        })
        .then(() => {
            console.log("Score submitted successfully");
        })
        .catch(error => {
            console.error("Error submitting score:", error);
            throw error;
        });
}

// Function to start calculating statistics and return a promise
function prepareQuizStatistics(quizId, uid) {
    // Start the calculation immediately and store the promise
    const statsPromise = db.collection("tm_quiz_submissions")
        .doc(quizId)
        .get()
        .then(doc => {
            if (!doc.exists) {
                return { scores: {} };
            }

            const submissions = doc.data();
            const scores = {};

            // Aggregate all submissions
            Object.values(submissions).forEach(submission => {
                const count = submission.correctCount;
                if (!scores[count]) {
                    scores[count] = {
                        correct_count: count,
                        user_count: 0
                    };
                }
                scores[count].user_count++;
            });

            // Pre-calculate formatted stats
            const totalParticipants = Object.values(scores)
                .reduce((sum, score) => sum + score.user_count, 0);

            const formattedScores = Object.values(scores)
                .sort((a, b) => b.correct_count - a.correct_count)
                .map(score => ({
                    score: score.correct_count,
                    count: score.user_count,
                    percentage: ((score.user_count / totalParticipants) * 100).toFixed(1) + '%'
                }));

            return {
                scores: formattedScores,
                totalParticipants,
                lastCalculated: new Date()
            };
        });

    // Return an object with methods to work with the promise
    return {
        // Method to wait for the result
        await: () => statsPromise,

        // Method to check if calculation is done
        isDone: () => statsPromise.then(() => true).catch(() => false),

        // Optional: Method to add a callback
        then: (callback) => statsPromise.then(callback)
    };
}

async function engageSuddenDeath() {
    soundManager.playSound('sudden-death');

    document.querySelectorAll('.card-spread-sudden-death').forEach(el => {
        el.style.display = 'block';
        // Force a reflow to ensure the animation triggers
        void el.offsetHeight;
        el.style.animation = 'none';
        void el.offsetHeight;
        el.style.animation = null;
      });
}

async function updateTradingCards(clickedLabel) {

    const globalState     = tradingSystem.globeData.getState();
    const isInventoryFull = (globalState.inventory || []).length >= 3;
    const playableCards = tradingSystem.getPlayableCards(clickedLabel, cities[clickedLabel].iso2);
    const availableCards = tradingSystem.getAvailableCards(clickedLabel);

    availableCards.forEach(card => {
        // Find the card footer element
        const cardFooter = document.getElementById(card.id);
        if (!cardFooter) return; // Skip if element not found
        
        // Update discount information if applied
        if (card.discountApplied) {
          let discountDiv = cardFooter.querySelector('.trading-card-discount');
          
          if (discountDiv) {
            // Update existing discount div
            discountDiv.querySelector('.original-price').textContent = `$${card.originalBuyPrice.toLocaleString()}`;
            discountDiv.querySelector(`.new-price${card.feeType}`).textContent = `$${card.buyPrice.toLocaleString()}`;
            discountDiv.querySelector(`.discount-label${card.feeType}`).textContent = card.discountMessage;
          } else {
            // Create new discount div
            discountDiv = document.createElement('div');
            discountDiv.className = 'trading-card-discount';
            discountDiv.innerHTML = `
              <span class="original-price">$${card.originalBuyPrice.toLocaleString()}</span>
              <span class="new-price${card.feeType}">$${card.buyPrice.toLocaleString()}</span>
              <div class="discount-label${card.feeType}">${card.discountMessage}</div>
            `;
            
            let priceSpan = cardFooter.querySelector('.trading-card-price');
            priceSpan.remove();

            // Insert the discount div at the beginning of the footer
            cardFooter.prepend(discountDiv);
          }
        } else {
          // Remove discount div if discount is not applied
          const discountDiv = cardFooter.querySelector('.trading-card-discount');
          if (discountDiv) {
            discountDiv.remove();
          }
        }
    });

    document.querySelectorAll('.trading-card-button').forEach(button => {
        const cardContainer = button.closest('.trading-card-container');

        const priceElement = cardContainer.querySelector('.trading-card-price') || cardContainer.querySelector('.new-price');
        // Extract numeric price value, safely handling formatted strings with $ and commas
        const price = priceElement ? parseInt(priceElement.textContent.replace(/[^0-9]/g, '')) : null;

        // Skip price check for sell buttons
        if (button.innerText == 'Sell Item') {
            button.disabled = false;
        } else {
            // For buy buttons, check if player can afford it
            button.disabled = (price !== null && globeState.travelAccount < price) || isInventoryFull;
            
            // Update tooltip if needed
            if (isInventoryFull) {
                button.title = 'Inventory Full';
            } else if (price !== null && globeState.travelAccount < price) {
                button.title = 'Cannot Afford';
            } else {
                button.title = '';
            }
        }
    });

    document.querySelectorAll('.sell-button').forEach(button => {
        button.disabled = false;
    });

}

function showDealMarkers() {

    pulseSystem.clearMarkers();

    if (dealsTable) {
        console.log(dealsTable);

        Object.keys(dealsTable.getRoutesFrom(globeState.currentCity)).forEach(element => {
            let city = cities[element];

            if (city) {
                const markerId = pulseSystem.addMarker(city.lat, city.lng);
            }
        });
}
}

// Move the class determination function outside the callback
function getTravelClass(fromCity, stateData) {
    if (stateData.get('nextTravelClass')) {
        const next_class = stateData.get('nextTravelClass');
        return next_class;
    }
    if (hasCompletedAllQuizzesPerfectly(fromCity, stateData)) {
        return 'first';
    }
    return 'economy';
};

async function handleCityTravel(clickedLabel, distance, isFreeTravel = false, executeTravelCallback = null, getPinPositionCallback = null) {
    return new Promise((resolve, reject) => {
        const outputFile = clickedLabel.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '_quiz.json';

        getFacts('/assets/quiz_output/' + outputFile)
            .then(data => {
                let description = data.meta.description;

                const modal = new CardSpreadModal({ maxRotation: 3, countryCodes: countryCodes, three: threeJs });
                let hasConfirmedTravel = false;

                globeState.setQuizCount(clickedLabel, data.questionSets.length);
                // Add close handler to resolve promise if modal is closed after travel confirmation

                // Postmodal
                const originalClose = modal.close;
                modal.close = async function () {
                    await originalClose.call(this);

                    const travelClass = getTravelClass(clickedLabel, globeState)

                    if (hasConfirmedTravel) {
                        resolve({ success: true, distance: distance, travelClass: travelClass, isFreeTravel });
                    } else {
                        resolve({ success: false });
                    }

                    showDealMarkers();
                    // Add a marker


                //    labelSystem.rebuildLabelTexture();
                };

                const showFullSpread = (centered = 0) => {

                    soundManager.playBackgroundMusic('tm-background-bossa', 500); // 1 second fade in

                    if (!data.questionSets) {
                        data.questionSets = [{
                            ...data,
                            type: 'generic'
                        }];
                    }

                    // Trading cards
                    const tradingSystem = TradingCardSystem.getInstance();

                    // Get available cards in this city
                    const availableCards = tradingSystem.getAvailableCards(clickedLabel);

                    const tradingCards = availableCards.map(card => {
                        const canAfford = globeState.travelAccount >= card.buyPrice;
                        return {
                            ...card,
                            info: `$${card.buyPrice}`,
                            disabled: !canAfford || card.disabled, // Consider both price and inventory constraints
                            buttonTooltip: card.disabled ? 'Inventory Full' : (!canAfford ? 'Cannot Afford' : undefined),
                            onButtonClick: async () => {

                                // Check if player has enough money before allowing purchase
                                if (globeState.travelAccount < card.buyPrice) {
                                    console.log("Not enough money to buy this item!");
                                    return;
                                }

                                soundManager.playSound('rustle');

                                // This is all to fade out the card: 
                                const cardElement = event.target.closest('.card-spread-card');
                                if (!cardElement) return;

                                cardElement.style.transition = 'all 0.5s ease-out';
                                cardElement.style.transform = 'perspective(1000px) rotateY(90deg)';
                                cardElement.style.opacity = '0';
                                cardElement.style.maxHeight = cardElement.offsetHeight + 'px';

                                await new Promise(resolve => setTimeout(resolve, 250));

                                cardElement.style.maxHeight = '0';
                                cardElement.style.margin = '0';
                                cardElement.style.padding = '0';

                                // Remove item from city's available items
                                tradingSystem.removeAvailableCard(clickedLabel, card.id, card.buyPrice);

                                // Add to inventory
                                tradingSystem.addCard(card.id);
                                globeState.setTravelAccount(globeState.travelAccount - card.buyPrice);
                                globeState.set('trade_spend', globeState.get('trade_spend')+card.buyPrice);

                                await new Promise(resolve => setTimeout(resolve, 250));
                                cardElement.remove();
                                
                                if (card.type == 'visa-card') {
                                    labelSystem.rebuildLabelTexture();
                                }

                                // Update other cards' disabled states
                                updateTradingCards(clickedLabel);

                            }
                        };
                    });

                    // Get playable cards in this city
                    const playableCards = tradingSystem.getPlayableCards(clickedLabel, cities[clickedLabel].iso2);
                    const sellCards = playableCards.map(card => {

                        const sellPrice = card.salesPrice[cities[clickedLabel].iso2] || card.salesPrice["default"] || 0;
                        return {
                            ...card,
                            type: 'trading-card',
                            subType: 'sell',
                            category: 'Sell Opportunity',
                            info: `+$${sellPrice}`,
                            buttonText: 'Sell Item',
                            onButtonClick: async () => {

                                const cardElement = event.target.closest('.card-spread-card');
                                if (!cardElement) return;

                                soundManager.playSound('kaching');
                                cardElement.style.transition = 'all 0.5s ease-out';
                                cardElement.style.transform = 'perspective(1000px) rotateY(-90deg)';
                                cardElement.style.opacity = '0';
                                cardElement.style.maxHeight = cardElement.offsetHeight + 'px';

                                await new Promise(resolve => setTimeout(resolve, 250));

                                cardElement.style.maxHeight = '0';
                                cardElement.style.margin = '0';
                                cardElement.style.padding = '0';

                                tradingSystem.removeCard(card.id);
                                globeState.setTravelAccount(globeState.travelAccount + sellPrice);
                                globeState.set('trade_earn', globeState.get('trade_earn')+sellPrice);

                                document.querySelectorAll('.trading-card-button').forEach(button => {
                                    const cardContainer = button.closest('.trading-card-container');
                                    const priceElement = cardContainer.querySelector('.trading-card-price') || cardContainer.querySelector('.new-price');
                                    const price = priceElement ? parseInt(priceElement.textContent) : null;
                                    button.disabled = globeState.travelAccount < price;
                                    // No need to check inventory, we just sold something
                                });

                                await new Promise(resolve => setTimeout(resolve, 250));
                                cardElement.remove();
                            }
                        };
                    });

                    // Free Cities

                    let freeCities = [];
                    const seededRandom = Math.abs((clickedLabel + new Date().toISOString().split('T')[0]).split('').reduce((a, c) => ((a << 5) - a) + c.charCodeAt(0) | 0, 0)) / 2147483647;
                    const numFreeCities = Math.floor(seededRandom < 0.1 ? 2 : seededRandom < 0.3 ? 1 : 0);
                    const nearbyCities = numFreeCities > 0 ? findCitiesInLongitudeBand(clickedLabel, numFreeCities, 15, cities) : [];
                    nearbyCities.forEach(freeCity => {
                        freeCities.push({
                            type: 'boarding-pass',
                            fromCityName: clickedLabel,
                            fromCityCode: cities[clickedLabel].airportCode || null,
                            fromIso2: cities[clickedLabel].iso2,
                            toCityName: freeCity.city,
                            toIso2: cities[freeCity.city].iso2,
                            toCityCode: cities[freeCity.city].airportCode || null,
                            distance: Math.floor(freeCity.distance) + " km",
                            status: 'FREE FLIGHT',
                            buttonText: 'Free!',
                            confirmCallback: async () => {
                                // Get correct starting point (current position)
                                const currentCityData = cities[freeCity.city];

                                const startPosition = getPinPositionCallback(cities[clickedLabel]);
                                const endPosition = getPinPositionCallback(cities[freeCity.city]);

                                // Calculate positions for ribbon data using correct start position
                                const startLatLon = vector3ToLatLong(startPosition.clone().add(globe.container.position), globe.container);
                                const endLatLon = vector3ToLatLong(endPosition.clone().add(globe.container.position), globe.container);

                                globeState.setCurrentCity(freeCity.city);
                                globeState.setCurrentCountry(currentCityData.iso2);

                                const ribbonData = {
                                    id: Date.now().toString(),
                                    location: globeState.currentCity,
                                    startLat: startLatLon.lat,
                                    startLon: startLatLon.lon,
                                    endLat: endLatLon.lat,
                                    endLon: endLatLon.lon,
                                    distance: Math.floor(freeCity.distance),
                                    class: "free"  // Make sure this is set to "free"
                                };

                                globeState.addRibbon(ribbonData.id, ribbonData);
                                globeState.set('nextTravelClass', null);

                                // Add pin data to state
                                const pinData = {
                                    id: Date.now().toString(),
                                    location: globeState.currentCity,
                                    lat: endLatLon.lat,
                                    lon: endLatLon.lon,
                                    size: 0.02
                                };

                                globeState.addPin(pinData.id, pinData);

                                hasConfirmedTravel = true;

                                globeState.setTotalDistanceTraveled(globeState.totalDistanceTraveled + freeCity.distance);
                                globeState.setFunAccount(globeState.funAccount - freeCity.distance * funMultiplierFreeCity);
                                globeState.addToLog(globeState.currentCity);

                                labelSystem.executeTravelHop(startPosition, endPosition, freeCity.city, 'free');

                                goToLocation(endLatLon.lat, endLatLon.lon);

                                // saveState();

                                try {
                                    await modal.close();

                                    // First handle the next city travel - this sets up state for the next step
                                    const newResult = await handleCityTravel(
                                        freeCity.city,
                                        freeCity.distance,
                                        true,
                                        executeTravelCallback,
                                        getPinPositionCallback
                                    );

                                    // Then execute the travel callback - it will use the state that was just set up
                                    if (executeTravelCallback) {
                                        executeTravelCallback(endPosition, freeCity.city, freeCity.distance, 'free');
                                    }

                                    resolve(newResult);
                                } catch (err) {
                                    console.error('Error during free city travel:', err);
                                    resolve({ success: false });
                                }
                            }
                        });
                    });

                    const quizCards = data.questionSets.map((questionSet, index) => ({
                        type: globeState.results.hasOwnProperty(clickedLabel + '-' + questionSet.name) ? 'quiz-done' : 'quiz',
                        category: 'Fact Check',
                        title: questionSet.type === 'generic' ? 'Test Your Knowledge' : `${questionSet.name}`,
                        message: questionSet.type === 'generic'
                            ? 'Complete the quiz to earn extra travel distance!'
                            : questionSet.description,
                        info: globeState.results.hasOwnProperty(clickedLabel + '-' + questionSet.name) ? globeState.results[clickedLabel + '-' + questionSet.name].correctAnswers + '/10 correct' : '',
                        buttonText: globeState.results.hasOwnProperty(clickedLabel + '-' + questionSet.name) ? 'See Results' : 'Start Quiz',
                        suddenDeath: globeState.getModifier(globeState.currentCountry, 'quiz_sudden_death') || globeState.getModifier(globeState.currentCity, 'local_sudden_death') || false,
                        onButtonClick: async () => {
                            soundManager.playSound('shuffle-out');
                            await modal.hide(index + 2);
                            const gameResult = await factGame(clickedLabel + '-' + questionSet.name, distance, questionSet);

                            globeState.addQuizResult(clickedLabel + '-' + questionSet.name, gameResult.result);

                            // saveState();

                            if (modal.elements.modalContainer.parentNode) {
                                modal.unhide();
                                showFullSpread(index + 2);

                            }
                        }
                    }));

                    // Fisher-Yates shuffle function
                    const shuffleArray = (array) => {
                        for (let i = array.length - 1; i > 0; i--) {
                            const j = Math.floor(Math.random() * (i + 1));
                            [array[i], array[j]] = [array[j], array[i]];
                        }
                        return array;
                    };

                    let eventCards = [];

                    if (data.events) {
                        const itinerary = new CityExplorer(data.events, clickedLabel, globeState, cities).generateItinerary();
                        console.log(itinerary);
                        
                        console.log(cities);

                        itinerary.title ='TRAILMARKS';
                        itinerary.subtitle = `Grab the travel guide and take a day out to explore <b class="itinerary-city">${clickedLabel}</b>. Print your personal itinerary with the Trailmarks itinerary generator: Fun is guaranteed!`;
                        itinerary.buttonText = 'PRINT ITINERARY ';
                        itinerary.cardTitle = `${clickedLabel} Itinerary`,
                        itinerary.backgroundImage = "assets/city-map.jpg",
                        itinerary.showBackside = globeState.getModifier(globeState.currentCity, 'seen_itinerary') ? true : false;
                        itinerary.onPreSpin = (results) => {
                            soundManager.playSound('laser-buzz-3', { delay: 200 });
                        }
                        itinerary.onSpin = (results) => {

                            console.log(results);
                            
                            globeState.setModifier(globeState.currentCity, 'seen_itinerary', 'true' );
                            globeState.setFunAccount(globeState.funAccount + results.numeric.fun);
                            globeState.setTravelAccount(globeState.travelAccount + (results.numeric.account || 0) );
                            globeState.addModifier(globeState.currentCountry, 'quiz_bonus', results.numeric.quiz_bonus);

                            // Very unlikely either of these are multiples but if they are, handle it:
                            [...Array(Math.min(3, Math.floor(results.numeric['random_item'] ?? 0)))].forEach((_, i) => {
                                tradingSystem.addRandom();
                            });
                            [...Array(Math.min(3, Math.floor(results.numeric['lost_item'] ?? 0)))].forEach((_, i) => {
                                tradingSystem.removeRandom();
                            }); 
                            
                            // add_item handling goes here

                            if (results.numeric['persona_non_grata'] == 1) {
                                tradingSystem.addBan(globeState.currentCountry);
                                labelSystem.rebuildLabelTexture();
                            }

                            if (results.special['next_class'] == 'first') {
                                globeState.set('nextTravelClass','first');
                            }

                            // Not implemented ATM:
                            if (results.special['next_class'] == 'comfort') {
                                globeState.set('nextTravelClass','comfort');
                            }
                            if (results.numeric['quiz_sudden_death']) {
                                globeState.addModifier(globeState.currentCountry, 'quiz_sudden_death', true);
                                engageSuddenDeath();
                            }
                            if (results.numeric['local_sudden_death']) {
                                globeState.addModifier(globeState.currentCity, 'local_sudden_death', true);
                                engageSuddenDeath();
                            }
                            if (results.numeric['made_friend']) {
                                globeState.addModifier(globeState.currentCity, 'made_friend', true);
                            }
                            if (results.special['must_go_country']) {
                                globeState.set('must_go_country', results.special['must_go_country'][0]);
                                labelSystem.rebuildLabelTexture();
                            }
                            if (results.special['must_go_nearby']) {
                                console.log('must go nearby it says');
                                console.log(results);
                                globeState.set('must_go_country', results.special['must_go_nearby'][0]);
                                labelSystem.rebuildLabelTexture();
                            }
                            if (results.special['must_go_city']) {
                                globeState.set('must_go_city', results.special['must_go_city'][0]);
                                labelSystem.rebuildLabelTexture();
                            }
                            updateTradingCards(clickedLabel);
                        };

                        eventCards.push(itinerary);
                    }

                    const departures = generateFlights(cities[clickedLabel].commonDestinationsArray, cities, clickedLabel, airportToCity);
                    const departureCard = {
                        type: 'departure-board',
                        flights: departures
                    };

                    dealsTable.updateFromDepartures(departures);

                 //       buttonText: 'Book Now', // Optional
                   //     onButtonClick: () => { /* Button action */ } // Optional
                      //};

                    console.log('DEPARTURE CARD');
                    console.log(departureCard);

                    const shuffledCards = shuffleArray([...[
                        ...quizCards.slice(1),
                        ...freeCities,
                        ...tradingCards,
                        ...eventCards,
                        
                    ]]);

                    threeJs.stopRender();
                    pulseSystem.clearMarkers();

                    soundManager.playSound('shuffle-in');
                    modal.show([
                        {
                            type: 'title',
                            title: data.images.length > 0 ? null : clickedLabel,
                            gradient: 'linear-gradient(135deg, rgb(132 112 149) 0%, rgb(195 214 221) 100%)',
                            backgroundImage: data.images.length > 0 ? imgString + data.images[0].src + imgStringPost : null,

                        },
                        {
                            type: 'start',
                            category: data.meta.country,
                            title: clickedLabel,
                            customContent: "",
                            message: description,
                            info: `Distance: ${Math.floor(distance)} km`
                        },
                        ...sellCards,
                        quizCards[0],
                        ...shuffledCards,
                        departureCard
                    ], centered);
                };

                if (isFreeTravel) {
                    hasConfirmedTravel = true;
                    globeState.setTotalDistanceTraveled(globeState.totalDistanceTraveled + distance);
                    globeState.addToLog(outputFile);
                    showFullSpread();

                } else if (clickedLabel === globeState.currentCity) {
                    hasConfirmedTravel = true;
                    showFullSpread();

                } else {

                    // Determine travel class before showing the boarding pass
                    const travelClass = getTravelClass(globeState.currentCity, globeState);
                    const priceMultiplier = travelClass == 'comfort' ? 3 : 1.75;
                    const hasFriend = globeState.getModifier(clickedLabel, 'made_friend') || false;

                    soundManager.playSound('double-ding');
                    soundManager.playSound('airport-announcement');

                    let flightPrice = dealsTable.getPrice(globeState.currentCity,clickedLabel,Math.floor(distance * priceMultiplier));

                    modal.show([{
                        type: 'boarding-pass',
                        fromCityName: globeState.currentCity,
                        fromIso2: cities[globeState.currentCity].iso2,
                        fromCityCode: cities[globeState.currentCity].airportCode || null,
                        toCityName: clickedLabel,
                        toIso2: cities[clickedLabel].iso2,
                        toCityCode: cities[clickedLabel].airportCode || null,
                        buttonText: hasFriend ? "Free Friend Visit" : "$" + flightPrice,
                        distance: Math.floor(distance) + " km",
                        status: travelClass.toUpperCase(), // === 'first' ? 'FIRST CLASS' : 'ECONOMY',
                        confirmCallback: async () => {
                            
                            // Check border crossing before allowing travel
                            const fromCountry = cities[globeState.currentCity].iso2;
                            const toCountry = cities[clickedLabel].iso2;

                            if (fromCountry !== toCountry) {
                                // In your travel code where you check border crossing:
                                const borderCheck = tradingSystem.checkBorderCrossing(fromCountry, toCountry);

                                if (!borderCheck.allowed) {
                                    soundManager.playSound('denied');

                                    if (borderCheck.reason === 'PERSONA_NON_GRATA') {
                                        const deniedModal = new CardSpreadModal({ three: threeJs});

                                        deniedModal.show([{
                                            type: 'start',
                                            category: 'Entry Denied',
                                            title: 'Access Denied',
                                            message: 'You are not allowed to enter this country!'
                                        }]);
                                        return;
                                    }

                                    // Show border check failure modal
                                    const failureModal = new CardSpreadModal({
                                        onClose: async () => {
                                            // Continue with travel after items confiscated
                                            globeState.setTravelAccount(globeState.travelAccount - flightPrice);

                                            globeState.setFunAccount(globeState.funAccount - (distance * funMultiplierBorderFailure) - funConstantBorderFailure);
                                            tradingSystem.updateInventoryButton();
                                            modal.close();
                                        }, 
                                        three: threeJs
                                    });

                                    failureModal.show([{
                                        type: 'start',
                                        category: 'Border Check',
                                        title: 'Border Check Failed',
                                        message: `Your items were confiscated at the border and you were sent back!${borderCheck.failures.some(f => f.penalty === 'ban')
                                            ? '\n\nYou are persona non grata in this country - you cannot travel here!'
                                            : ''
                                            }`,
                                    }]);
                                    return;
                                } else {
                                    // Handle tax if present
                                    if (borderCheck.tax > 0) {
                                        soundManager.playSound('denied');

                                        const taxModal = new CardSpreadModal({
                                            onClose: () => {
                                                globeState.setTravelAccount(globeState.travelAccount - borderCheck.tax);
                                                globeState.set('trade_spend', globeState.get('trade_spend')+borderCheck.tax);

                                                globeState.setFunAccount(globeState.funAccount - (borderCheck.tax * funMultiplierTax) - funConstantTax);
                                                if (borderCheck.visa) {
                                                    // Show visa acceptance after tax modal closes
                                                    const visaModal = new CardSpreadModal({
                                                        onClose: () => proceedWithTravel()
                                                    });
                                                    visaModal.show([{
                                                        type: 'start',
                                                        category: 'Border Check',
                                                        title: 'Visa Accepted',
                                                        message: 'Entry granted - Your visa has been accepted'
                                                    }]);
                                                } else {
                                                    proceedWithTravel();
                                                }
                                            }, 
                                            three: threeJs
                                        });

                                        taxModal.show([{
                                            type: 'start',
                                            category: 'Border Check',
                                            title: 'Import Tax',
                                            message: `Border tax collected:\n\n${borderCheck.taxedItems.map(item =>
                                                `${item.name}: ${item.amount}`
                                            ).join('\n')}\n\nTotal: ${borderCheck.tax}`
                                        }]);
                                        return;
                                    } else if (borderCheck.visa) {
                                        const visaModal = new CardSpreadModal({
                                            three: threeJs,
                                            onClose: () => proceedWithTravel()
                                        });

                                        soundManager.playSound('denied');

                                        visaModal.show([{
                                            type: 'start',
                                            category: 'Border Check',
                                            title: 'Visa Accepted',
                                            message: 'Entry granted - Your visa has been accepted'
                                        }]);
                                        return;
                                    }
                                }
                            }
                            proceedWithTravel();
                        }
                    }
                    ]);

                    function proceedWithTravel() {

                        const travelClass = getTravelClass(globeState.currentCity, globeState);
                        const priceMultiplier = travelClass == 'comfort' ? 3 : 1.75;
         
                        const hasFriend = globeState.getModifier(clickedLabel, 'made_friend') || false;

                        let standardPrice = hasFriend ? 0 : Math.round(distance * priceMultiplier);
                        let priceFlight = dealsTable.getPrice(globeState.currentCity, clickedLabel) || standardPrice;

                        globeState.setTravelAccount(globeState.travelAccount - (priceFlight));
                        globeState.setTotalDistanceTraveled(globeState.totalDistanceTraveled + distance);

                        globeState.setCurrentCity(clickedLabel);
                        globeState.setCurrentCountry(cities[clickedLabel].iso2);

                        const startLatLon = vector3ToLatLong(lastPlacedMarker.clone().add(globe.container.position), globe.container);
                        const endPosition = getPinPositionCallback(cities[clickedLabel]);
                        const endLatLon = vector3ToLatLong(endPosition.clone().add(globe.container.position), globe.container);

                        const ribbonData = {
                            id: Date.now().toString(),
                            location: globeState.currentCity,
                            startLat: startLatLon.lat,
                            startLon: startLatLon.lon,
                            endLat: endLatLon.lat,
                            endLon: endLatLon.lon,
                            distance: Math.floor(distance),
                            class: travelClass // Use the previously determined class
                        };

                        const pinData = {
                            id: Date.now().toString(),
                            location: globeState.currentCity,
                            lat: endLatLon.lat,
                            lon: endLatLon.lon,
                            size: 0.02
                        };

                        globeState.addRibbon(ribbonData.id, ribbonData);
                        globeState.addPin(pinData.id, pinData);
                        globeState.set('nextTravelClass', null);

                        hasConfirmedTravel = true;

   
                        globeState.remove('must_go_country');
                        globeState.remove('must_go_city');

                        // Calculate fun impact based on travel class
                        const funAdjustment = travelClass == 'economy' ?
                            (distance * funMultiplierEconomy) + funConstantEconomy :
                            (distance * funMultiplierFirst) + funConstantFirst;

                        globeState.setFunAccount(globeState.funAccount - funAdjustment);

                        globeState.addToLog(outputFile);

                        labelSystem.executeTravelHop(lastPlacedMarker, endPosition, clickedLabel, travelClass);

                        goToLocation(endLatLon.lat, endLatLon.lon);
                        // saveState();
                        showFullSpread();

                    }

                }


            })
            .catch(reject);
    });
}


async function factGame(clickedLabel, distance, existingData = null) {
    return new Promise((resolve, reject) => {
        const outputFile = clickedLabel.replace(/[^a-z0-9]/gi, '_').toLowerCase() + `_quiz.json`;

        // Use existing data or load from file
        const source = existingData || '/assets/quiz_output/' + outputFile;

        getFacts(source).then(data => {
            let description = data.description;
            if (description && description.length > 250) {
                const sentences = description.match(/[^.!?]+[.!?]+\s*/g) || [description];
                description = sentences.slice(0, -1).join('').trim();
            }

            let gameCompleted = false;
            let correctAnswers = 0;
            let playerTravelled = false;

            
            const isSuddenDeath = globeState.getModifier(globeState.currentCountry, 'quiz_sudden_death') || 
            globeState.getModifier(globeState.currentCity, 'local_sudden_death') || 
            false;

            // First randomize the questions
            data.questions = data.questions
            .sort(() => Math.random() - 0.5)
            .slice(0, Math.min(10, data.questions.length));

            // Then apply rewards to the randomized set
            data.questions = isSuddenDeath  
            ? distributeDoubledRewards(data.questions, 1) 
            : distributeRewards(data.questions, data.questions.length*20, 5, 5);
            
            const statsCalculator = prepareQuizStatistics(clickedLabel, globeId);

            threeJs.stopRender();
            const game = new FactCheck({
            stats: statsCalculator,
            statements: data.questions,
            results: globeState.results[clickedLabel], //quiz_memory[outputFile],
            thanks: "THANK YOU FOR VISITING<br/>" + clickedLabel.toUpperCase(),
            suddenDeath: isSuddenDeath,
                title: existingData.type == 'generic' ? "Results" : existingData.name,
                onComplete: (result) => {
                    threeJs.startRender();

                    if (playerTravelled) {
                        gameCompleted = true;

                        if (!globeState.results[clickedLabel]) {
                            // This should only be ran if it's the first completion - don't do this every time the user looks at he score
                            const funAdjustment = (Math.max(result.correctAnswers - 7, -5) * funMultiplierQuiz) + funConstantQuiz;
                            globeState.setFunAccount(globeState.funAccount + funAdjustment);

                            submitQuizScore(clickedLabel, result.correctAnswers, globeId);
                        }
                    }
                },
                onClose: (result) => {
                    threeJs.startRender();

                    if (!gameCompleted) {

                        if (!globeState.results[clickedLabel]) {
                            const funAdjustment = (Math.max(result.correctAnswers - 7, -5) * funMultiplierQuiz) + funConstantQuiz;
                            globeState.setFunAccount(globeState.funAccount + funAdjustment);
                            submitQuizScore(clickedLabel, result.correctAnswers, globeId);
                        }

                        resolve({ success: playerTravelled, result: result });
                    }
                },
                onExplanation: ({ statement, userAnswer, isCorrect, comment, reward }) => {
                    if (isCorrect) {
                        soundManager.playSound('stamp');
                        globeState.setTravelAccount(globeState.travelAccount + reward);
                        correctAnswers++;
                    } else {
                        soundManager.playSound('pen-scratch', { delay: 250 } );

                    }
                }
            });
        }).catch(reject);
    });
}

function createPreciseCollisionDetector(width, height) {
    // Store actual rectangles instead of a grid
    const occupiedRects = [];
    
    return {
      checkCollision(x, y, w, h, padding = 2) {
        // Create the rectangle with padding
        const rect = {
          x: x - padding,
          y: y - padding,
          right: x + w + padding,
          bottom: y + h + padding
        };
        
        // Check for collision with any existing rectangle
        for (const occupied of occupiedRects) {
          if (!(rect.right < occupied.x || 
                rect.x > occupied.right || 
                rect.bottom < occupied.y || 
                rect.y > occupied.bottom)) {
            return true; // Collision detected
          }
        }
        
        return false; // No collision
      },
      
      markOccupied(x, y, w, h, padding = 2) {
        // Store the actual rectangle
        occupiedRects.push({
          x: x - padding,
          y: y - padding,
          right: x + w + padding,
          bottom: y + h + padding
        });
      },
      
      reset() {
        occupiedRects.length = 0;
      }
    };
  }
function checkCanvasCollision(ctx, x, y, width, height, extraPadding = 6) {
    const padding = extraPadding; // Increased padding for more accurate detection

    width = Math.max(1, Math.abs(width));
    height = Math.max(1, Math.abs(height));

    const startX = Math.max(0, x - padding);
    const startY = Math.max(0, y - height - padding);
    const adjustedWidth = Math.min(ctx.canvas.width - startX, width + 2 * padding);
    const adjustedHeight = Math.min(ctx.canvas.height - startY, height + 2 * padding);

    if (adjustedWidth <= 0 || adjustedHeight <= 0) {
        return true; // Treat as collision to avoid drawing in this case
    }

    try {
        const imageData = ctx.getImageData(startX, startY, adjustedWidth, adjustedHeight);
        const pixels = imageData.data;

        for (let i = 0; i < pixels.length; i += 4) {
            if (pixels[i + 3] !== 0) {  // Check alpha channel for collision
                return true; // Collision detected
            }
        }
    } catch (error) {
        return true; // Treat as collision in case of error
    }

    return false; // No collision
}

//
// EVENTS
//


document.getElementById('link').addEventListener('click', function (e) {
    e.stopPropagation();
    navigator.clipboard.writeText(window.location.href)
        .then(() => statusManager.show('Link copied to clipboard!'))
        .catch(err => console.error('Could not copy text: ', err));
});

// Handle window resize
// Window resize is now handled by ThreeJsSetup

threeJs.getDomElement().addEventListener('mousedown', (event) => {
    mouseDownTime = Date.now();
    mouseDownPosition.set(event.clientX, event.clientY);
});

// Enable pointer events on controls
document.getElementById('controls').style.pointerEvents = 'auto';


//
// ANIMATION LOOP
//

const clock = new THREE.Clock();
const lightWorldPosition = new THREE.Vector3();


// Animate and render loop
function animate() {
    sunLight.getWorldPosition(lightWorldPosition);

    const deltaTime = clock.getDelta();

    TWEEN.update(); // Add this line to update TWEEN

    if (isTransitioning) {
        // Interpolate camera position
        controls.object.position.lerp(targetPosition, 0.05);

        // Interpolate target
        controls.target.lerp(targetTarget, 0.05);

        // Check if we're close enough to end the transition
        if (controls.object.position.distanceTo(targetPosition) < 0.01 &&
            controls.target.distanceTo(targetTarget) < 0.01) {
            isTransitioning = false;
            updateBokehFocus();
        }
    }

    // Update all ribbons' matrices
 //   globe.container.updateMatrixWorld(true);  // Force update of world matrix

    
    ribbons.forEach(ribbon => {
        ribbon.updateMatrixWorld(true);
        if (ribbon.animator) {
            ribbon.animator.update(deltaTime);
        }
        if (ribbon.material.uniforms) {
            ribbon.material.uniforms.globeCenter.value.copy(globe.container.position);
        }

        ribbon.material.updateLightPosition(lightWorldPosition);
    });


    if (previewRibbon && previewRibbon && previewRibbon.animator) {
        previewRibbon.animator.update(deltaTime);  // deltaTime is the time since last frame in seconds
        previewRibbon.material.updateLightPosition(lightWorldPosition);
    }

    sparkleSystem.update(deltaTime);

    // Update bokeh focus during auto-rotation
    if (autorotate.isAutoRotating()) {
        updateBokehFocus(1);
    }
}

// Start the animation loop using the ThreeJS setup
threeJs.startAnimationLoop(animate);

document.addEventListener('keydown', function(event) {
    // Check if the key pressed is 'P' (keyCode 80) and Shift key is held down
    if (event.key === 'P' && event.shiftKey) {
      // Run your code when Shift+P is pressed
      globeState.setTravelAccount(globeState.travelAccount + 10000);
      
      // Prevent default behavior (like opening print dialog)
      event.preventDefault();
    }
  });