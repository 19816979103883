export class GlobeState {
    constructor() {
        // Default state values
        this._defaults = {
            pins: {},
            ribbons: {},
            metadata: {
                orbitControls: {
                    target: { x: 0, y: 0, z: 0 },
                    position: { x: -2.46, y: 2.02, z: 0.69 }
                }
            },
            results: {},
            travelAccount: 2000,
            totalDistanceTraveled: 0,
            funAccount: 50,
            currentCity: 'London',
            currentCountry: 'GB',
            history: [],
            log: [],
            quizCount: {},
            modifiers: {},
            trade_spend: 0,
            trade_earn: 0,
            // New property with default value
            nextTravelClass: null
        };

        // Initialize data with defaults
        this._data = { ...this._defaults };

        this._callbacks = {
            onStateChange: [],
            onFunAccountChange: [],
            onFunAccountZero: [],
            onFunAccountNotZero: [],
            onFunAccountMax: [],
            onSaveComplete: [],
            onSaveError: []
        };

        this._db = null;
        this._globeId = null;
    }

    // Dynamic property getter with fallback to defaults
    get(property) {
        return this._data[property] !== undefined ? this._data[property] : this._defaults[property];
    }
    // Dynamic property setter with notification
    set(property, value) {
        const oldValue = this._data[property];
        this._data[property] = value;
        this._notifyPropertyChange(property, oldValue, value);
        return value;
    }
    // Dynamic property remover with notification
    remove(property) {
        if (this._data.hasOwnProperty(property)) {
            const oldValue = this._data[property];
            delete this._data[property];
            this._notifyPropertyChange(property, oldValue, undefined);
            return true;
        }
        return false;
    }
    // Backwards compatibility getters
    get pins() { return this.get('pins'); }
    get ribbons() { return this.get('ribbons'); }
    get metadata() { return this.get('metadata'); }
    get results() { return this.get('results'); }
    get travelAccount() { return this.get('travelAccount'); }
    get totalDistanceTraveled() { return this.get('totalDistanceTraveled'); }
    get funAccount() { return this.get('funAccount'); }
    get currentCity() { return this.get('currentCity'); }
    get currentCountry() { return this.get('currentCountry'); }
    get history() { return this.get('history'); }
    get log() { return this.get('log'); }
    get quizCount() { return this.get('quizCount'); }
    get modifiers() { return this.get('modifiers'); }

    // Get entire state object (for saving)
    getState() {
        return { ...this._data };
    }

    getModifier(location, type) {
        if (!location) return null;
        const locationKey = location.toLowerCase();
        return this.get('modifiers')[locationKey]?.[type] || null;
    }

    // Set the state from a saved object, merging with defaults for backward compatibility
    setState(newState) {
        const oldState = { ...this._data };
        // Merge with defaults to ensure all properties exist
        this._data = { ...this._defaults, ...newState };

        this._notifyStateChange(oldState, this._data);

        // Update counter displays after state is loaded
        if (this._counterManager) {
            this._counterManager.setTravelled(this._data.totalDistanceTraveled);
            this._counterManager.setAccount(this._data.travelAccount);
            this._counterManager.setFun(this._data.funAccount);
            this._calcScore();
        }
    }

    // Set the counter manager for display updates
    setCounterManager(counterManager) {
        this._counterManager = counterManager;
    }
    // Set the counter manager for display updates
    setScoreSystem(scoreSystem) {
        this._scoreSystem = scoreSystem;
    }
    // Specialized state update methods (for backward compatibility)
    setTravelAccount(value) {
        if (value === null || value === undefined || isNaN(Number(value))) {
            console.warn(`Invalid travel account value: ${value}, keeping existing value`);
            return;
        }

        value = Math.max(0, Number(value));
        this.set('travelAccount', value);

        if (this._counterManager) {
            console.log('setting account to '+value);
            
            this._counterManager.setAccount(value);
        }
    }

    setTotalDistanceTraveled(value) {
        if (value === null || value === undefined || isNaN(Number(value))) {
            console.warn(`Invalid distance traveled value: ${value}, keeping existing value`);
            return;
        }

        value = Math.max(0, Number(value));
        this.set('totalDistanceTraveled', value);

        if (this._counterManager) {
            this._counterManager.setTravelled(value);
        }
    }

    setFunAccount(value) {
        if (value === null || value === undefined || isNaN(Number(value))) {
            console.warn(`Invalid fun account value: ${value}, keeping existing value`);
            return;
        }

        const oldValue = this._data.funAccount;
        value = Math.max(0, Math.min(100, Number(value)));
        this._data.funAccount = value;

        if (this._counterManager) {
            this._counterManager.setFun(value);
        }

        this._notifyPropertyChange('funAccount', oldValue, value);

        // Fire specific callbacks for fun account
        if (oldValue !== value) {
            this._callbacks.onFunAccountChange.forEach(callback => callback(value, oldValue));

            // Check for zero or max
            if (value === 0 && oldValue !== 0) {
                this._callbacks.onFunAccountZero.forEach(callback => callback());
            } else if (value === 100 && oldValue !== 100) {
                this._callbacks.onFunAccountMax.forEach(callback => callback());
            } else if (oldValue === 0 && value !== 0) {
                this._callbacks.onFunAccountNotZero.forEach(callback => callback());
            }
        }
    }

    setModifier(location, type, value) {
        const locationKey = location.toLowerCase();
        const modifiers = this.get('modifiers');
        if (!modifiers[locationKey]) {
            modifiers[locationKey] = {};
        }
        modifiers[locationKey][type] = value;
        this.set('modifiers', modifiers);
    }

    addModifier(location, type, value) {
        const locationKey = location.toLowerCase();
        const currentValue = this.getModifier(locationKey, type) || 0;
        this.setModifier(locationKey, type, currentValue + (value || 0));
        return currentValue + (value || 0);
    }

    removeModifier(location, type) {
        const locationKey = location.toLowerCase();
        const modifiers = this.get('modifiers');
        
        if (modifiers[locationKey] && modifiers[locationKey][type] !== undefined) {
            delete modifiers[locationKey][type];
            this.set('modifiers', modifiers);
            return true;
        }
        
        return false;
    }

    setCurrentCity(value) {
        this.set('currentCity', value);
    }

    setCurrentCountry(value) {
        this.set('currentCountry', value);
    }

    // Add a pin to the state
    addPin(id, pinData) {
        const pins = this.get('pins');
        pins[id] = pinData;
        this.set('pins', pins);
    }

    // Add a ribbon to the state
    addRibbon(id, ribbonData) {
        const ribbons = this.get('ribbons');
        ribbons[id] = ribbonData;
        this.set('ribbons', ribbons);
    }

    // Add quiz result
    addQuizResult(quizId, result) {
        const results = this.get('results');
        results[quizId] = result;
        this.set('results', results);
    }

    // Add to history
    addToHistory(location) {
        const history = [...this.get('history'), location];
        this.set('history', history);
    }

    // Add to log
    addToLog(entry) {
        const log = [...this.get('log'), entry];
        this.set('log', log);
    }

    // Set quiz count for a city
    setQuizCount(city, count) {
        const quizCount = this.get('quizCount');
        quizCount[city] = count;
        this.set('quizCount', quizCount);
    }

    // Callback registration methods
    onStateChange(callback) {
        this._callbacks.onStateChange.push(callback);
        return () => this._removeCallback('onStateChange', callback);
    }

    onFunAccountChange(callback) {
        this._callbacks.onFunAccountChange.push(callback);
        return () => this._removeCallback('onFunAccountChange', callback);
    }

    onFunAccountZero(callback) {
        this._callbacks.onFunAccountZero.push(callback);
        return () => this._removeCallback('onFunAccountZero', callback);
    }

    onFunAccountNotZero(callback) {
        this._callbacks.onFunAccountNotZero.push(callback);
        return () => this._removeCallback('onFunAccountNotZero', callback);
    }

    onFunAccountMax(callback) {
        this._callbacks.onFunAccountMax.push(callback);
        return () => this._removeCallback('onFunAccountMax', callback);
    }

    // Helper methods for callback management
    _removeCallback(type, callback) {
        const index = this._callbacks[type].indexOf(callback);
        if (index !== -1) {
            this._callbacks[type].splice(index, 1);
        }
    }

    _notifyStateChange(oldState, newState) {
        this.saveState();
        this._callbacks.onStateChange.forEach(callback => callback(newState, oldState));
    }

    _notifyPropertyChange(property, oldValue, newValue) {
        this.saveState();

        this._calcScore();

        this._callbacks.onStateChange.forEach(callback =>
            callback(this._data, { ...this._data, [property]: oldValue }));
    }

    async _calcScore() {
    
        const score = await this._scoreSystem.calculateScore(this); // .then(() => {;

        if (score.totalScore) {
            this._counterManager.setScore(score.totalScore); // Update score to 1000
        }

    }

    // Initialize database connection
    initializeDatabase(db, globeId) {
        this._db = db;
        this._globeId = globeId;
    }

    // Update camera state
    updateCameraState(controls, camera) {
        const metadata = this.get('metadata');
        metadata.orbitControls = {
            target: {
                x: controls.target.x,
                y: controls.target.y,
                z: controls.target.z
            },
            position: {
                x: camera.position.x,
                y: camera.position.y,
                z: camera.position.z
            }
        };
        this.set('metadata', metadata);
    }

    // Save state to database
    async saveState() {
        if (!this._db || !this._globeId) {
            console.error("Database not initialized. Call initializeDatabase first.");
            return Promise.reject(new Error("Database not initialized"));
        }
        try {
            await this._db.collection('globes').doc(this._globeId).set(this._data);
            this._callbacks.onSaveComplete.forEach(callback => callback());
            return true;
        } catch (error) {
            console.error("Error saving state:", error);
            this._callbacks.onSaveError.forEach(callback => callback(error));
            return false;
        }
    }

    // Load state from database
    async loadState() {
        if (!this._db || !this._globeId) {
            console.error("Database not initialized. Call initializeDatabase first.");
            return Promise.reject(new Error("Database not initialized"));
        }

        try {
            const doc = await this._db.collection('globes').doc(this._globeId).get();

            if (doc.exists) {
                const data = doc.data();
                this.setState(data);
                return true;
            }
            return false;
        } catch (error) {
            console.error("Error loading state:", error);
            return false;
        }
    }

    // Callback for save events
    onSaveComplete(callback) {
        this._callbacks.onSaveComplete.push(callback);
        return () => this._removeCallback('onSaveComplete', callback);
    }

    onSaveError(callback) {
        this._callbacks.onSaveError.push(callback);
        return () => this._removeCallback('onSaveError', callback);
    }
}

// Singleton instance
let globeStateInstance = null;

export function getGlobeState() {
    if (!globeStateInstance) {
        globeStateInstance = new GlobeState();
    }
    return globeStateInstance;
}