import * as THREE from 'three';

/**
 * Creates an overlay texture with the largest possible canvas size.
 * @param {number} desiredWidth - The desired width of the canvas (not used in current implementation).
 * @param {number} desiredHeight - The desired height of the canvas (not used in current implementation).
 * @returns {Object|null} An object containing the canvas and its 2D context, or null if creation fails.
 */

export function createOverlayTexture(desiredWidth, desiredHeight) {
    const sizes = [
        { width: 8192, height: 4096 },
        { width: 4096, height: 2048 },
        { width: 2048, height: 1024 },
        { width: 1024, height: 512 }
    ];

    for (const size of sizes) {
        try {
            const canvas = document.createElement('canvas');
            canvas.width = size.width;
            canvas.height = size.height;
            const ctx = canvas.getContext('2d');

            // Test writing to and reading from the canvas
            ctx.clearRect(0, 0, 1, 1); // Ensure transparency
            ctx.fillStyle = 'rgba(255, 0, 0, 0.5)'; // Semi-transparent red
            ctx.fillRect(0, 0, 1, 1);
            
            // Read the pixel we just wrote
            const imageData = ctx.getImageData(0, 0, 1, 1);
            const [r, g, b, a] = imageData.data;

            // Check if the pixel matches what we wrote
            if (r === 255 && g === 0 && b === 0 && a === 128) {
                console.log(`Successfully created canvas: ${canvas.width}x${canvas.height}`);
                return { canvas, ctx };
            } else {
                throw new Error("Canvas pixel test failed");
            }
        } catch (error) {
            console.warn(`Failed to create canvas at size ${size.width}x${size.height}:`, error);
            // If this size failed, we'll try the next (smaller) size
        }
    }

    // If all sizes failed, we return null or throw an error
    console.error("Failed to create canvas at any supported size");
    return null;
}

// Function to convert lat/long to x/y/z on the sphere
export function latLongToVector3(lat, lon, radius) {
    const phi = (90 - lat) * (Math.PI / 180); // Convert latitude to radians
    const theta = (lon + 180) * (Math.PI / 180); // Convert longitude to radians

    const x = -(radius * Math.sin(phi) * Math.cos(theta)); // Corrected sign for X
    const y = radius * Math.cos(phi);
    const z = radius * Math.sin(phi) * Math.sin(theta);

    return new THREE.Vector3(x, y, z);
}

export function vector3ToLatLong(vector,globeContainer) {
    const globalPosition = vector.clone().sub(globeContainer.position);
    const radius = globalPosition.length();
    
    // Latitude calculation remains the same
    const lat = 90 - (Math.acos(globalPosition.y / radius) * 180 / Math.PI);
    
    // Adjusted longitude calculation
    let lon = (Math.atan2(-globalPosition.z, globalPosition.x) * 180 / Math.PI);
    
    // Ensure longitude is in the range -180 to 180
    lon = ((lon + 180) % 360) - 180;

    return { lat, lon };
}


export function calculateDotSize(population) {
    const minSize = 0.001; // Reduced minimum size
    const maxSize = 0.008; // Reduced maximum size

    const minPop = 350000;
    const maxPop = 37732000;

    const logMin = Math.log(minPop);
    const logMax = Math.log(maxPop);
    const logPop = Math.log(Math.max(population, minPop));

    const normalizedSize = (logPop - logMin) / (logMax - logMin);
    const size = minSize + Math.pow(normalizedSize, 1.2) * (maxSize - minSize); // Adjusted power for more gradual scaling

    return { size, importance: normalizedSize };
}


export function loadTexture(url) {
    return new Promise((resolve, reject) => {
        new THREE.TextureLoader().load(url, resolve, undefined, reject);
    });
}

export function setupTexture(texture, anisotropy, offsetX = 0.5, offsetY = 0) {
//    texture.encoding = THREE.sRGBEncoding;
    texture.colorSpace = THREE.SRGBColorSpace;


    texture.anisotropy = anisotropy;
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(1, 1);
    texture.offset.set(offsetX, offsetY);
    return texture;
}