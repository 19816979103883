/**
 * Flight pricing lookup table with safe read/write operations
 */
export class FlightPriceTable {
    constructor() {
      this.priceTable = {};
    }
  
    /**
     * Set a price for a specific route
     * @param {string} from - Origin airport code
     * @param {string} to - Destination airport code
     * @param {number} price - Flight price
     */
    setPrice(from, to, price) {
      // Initialize the "from" object if it doesn't exist
      if (!this.priceTable[from]) {
        this.priceTable[from] = {};
      }
      
      // Set the price
      this.priceTable[from][to] = price;
    }
  
    /**
     * Get price for a specific route
     * @param {string} from - Origin airport code
     * @param {string} to - Destination airport code
     * @param {number|null} defaultValue - Value to return if route not found (default: null)
     * @returns {number|null} Flight price or defaultValue if not found
     */
    getPrice(from, to, defaultValue = null) {
      // Check if the "from" key exists
      if (!this.priceTable[from]) {
        return defaultValue;
      }
      
      // Check if the "to" key exists under "from"
      return this.priceTable[from][to] !== undefined 
        ? this.priceTable[from][to] 
        : defaultValue;
    }
  
    /**
     * Update table with multiple route prices
     * @param {Array} departures - Array of objects with from, to, and price properties
     */
    updateFromDepartures(departures) {
      departures.forEach(element => {
        this.setPrice(element.from, element.to, element.price);
      });
    }
  
    /**
     * Get all routes from a specific origin
     * @param {string} from - Origin airport code
     * @returns {Object|null} Object with destinations as keys and prices as values, or null if origin not found
     */
    getRoutesFrom(from) {
      return this.priceTable[from] || []; // null;
    }
  
    /**
     * Check if a route exists
     * @param {string} from - Origin airport code
     * @param {string} to - Destination airport code
     * @returns {boolean} True if the route exists
     */
    hasRoute(from, to) {
      return Boolean(this.priceTable[from] && this.priceTable[from][to] !== undefined);
    }
  
    /**
     * Get the raw price table (for advanced usage)
     * @returns {Object} The internal price table
     */
    getRawTable() {
      return this.priceTable;
    }
  }
  
  // Example usage:
  /*
  // Create a new table
  const flightPrices = new FlightPriceTable();
  
  // Set individual prices
  flightPrices.setPrice("JFK", "LAX", 350);
  flightPrices.setPrice("LAX", "SFO", 150);
  
  // Bulk update from departures data
  const departures = [
    { from: "JFK", to: "SFO", price: 400 },
    { from: "LAX", to: "JFK", price: 370 }
  ];
  flightPrices.updateFromDepartures(departures);
  
  // Safe reading
  const price = flightPrices.getPrice("JFK", "LAX"); // Returns 350
  const unknownPrice = flightPrices.getPrice("JFK", "UNKNOWN"); // Returns null
  
  // Check if route exists
  if (flightPrices.hasRoute("JFK", "LAX")) {
    console.log("Route exists!");
  }
  */
  
  /**
   * Simple function alternative for those who prefer not using classes
   */
  function createFlightPriceTable() {
    const priceTable = {};
    
    return {
      setPrice: (from, to, price) => {
        if (!priceTable[from]) priceTable[from] = {};
        priceTable[from][to] = price;
      },
      
      getPrice: (from, to, defaultValue = null) => {
        if (!priceTable[from]) return defaultValue;
        return priceTable[from][to] !== undefined ? priceTable[from][to] : defaultValue;
      },
      
      updateFromDepartures: (departures) => {
        departures.forEach(element => {
          if (!priceTable[element.from]) priceTable[element.from] = {};
          priceTable[element.from][element.to] = element.price;
        });
      },
      
      getRawTable: () => priceTable
    };
  }