export class CardSpreadModal {
  constructor(options = {}) {
    this.options = {
      maxRotation: options.maxRotation || 5,
      showRemoveButton: options.showRemoveButton || false,
      onClose: options.onClose || null, // Add onClose to options
      countryCodes: options.countryCodes,
      ...options
    };
    this.elements = {};
    this.cards = [];
    this.initialized = false;
    this.confirmed = false;
    this.three = options.three || null; 

  }

  createElements() {
    // Create modal container
    this.elements.modalContainer = document.createElement('div');
    this.elements.modalContainer.className = 'card-spread-modal-container';

    // Create close button
    this.elements.closeButton = document.createElement('button');
    this.elements.closeButton.className = 'card-spread-close-button';
    this.elements.closeButton.innerHTML = '×';
    this.elements.closeButton.onclick = () => this.close();

    // Create card scroller
    this.elements.cardScroller = document.createElement('div');
    this.elements.cardScroller.className = 'card-spread-scroller';

    // Create card track
    this.elements.cardTrack = document.createElement('div');
    this.elements.cardTrack.className = 'card-spread-track';

    // Assemble the elements
    this.elements.cardScroller.appendChild(this.elements.cardTrack);
    this.elements.modalContainer.appendChild(this.elements.closeButton);
    this.elements.modalContainer.appendChild(this.elements.cardScroller);
  }

  renderCard(card, index) {
    const cardElement = document.createElement('div');
    cardElement.className = 'card-spread-card';


    // Store rotation for later use in animation
    if (!card.type || card.type !== 'title') {
      const direction = index % 2 === 0 ? 1 : -1;
      const baseRotation = this.options.maxRotation / 2;
      const rotation = direction * baseRotation;
      cardElement.dataset.rotation = rotation;
    }

    // Add specific card type class
    if (card.type) {
      cardElement.classList.add(`card-spread-${card.type}-card`);
    }

    // Handle title card gradient
    if (card.type === 'title') {
      cardElement.style.background = card.gradient || 'linear-gradient(135deg, rgb(137 137 168) 0%, rgb(175 157 174) 100%)';
    }

    // Render different card types
    switch (card.type) {
      // Add this case to the renderCard method's switch statement
      // Add this case to your switch statement in renderCard:
      case 'departure-board':
        // Format price to display properly on board
        const formatPriceDisplay = (price) => {
          // Handle null or undefined
          if (price == null) return '';
          
          // If price is a number, format it with $ and commas
          if (typeof price === 'number') {
            return `$${price.toLocaleString()}`;
          }
          
          // If price is a string that can be parsed as a number
          if (typeof price === 'string') {
            // If the string already has a $ sign, return it as is with formatting
            if (price.startsWith('$')) {
              const numericPart = price.substring(1).replace(/,/g, '');
              const parsedPrice = parseFloat(numericPart);
              if (!isNaN(parsedPrice)) {
                return `$${parsedPrice.toLocaleString()}`;
              }
              return price;
            }
            
            // Try to parse the string as a number
            const parsedPrice = parseFloat(price);
            if (!isNaN(parsedPrice)) {
              return `$${parsedPrice.toLocaleString()}`;
            }
          }
          
          // Return original if can't parse or format
          return price;
        };
      
        // Generate status class (for styling) based on status text
        const getStatusClass = (status) => {
          if (!status) return 'status-default';
          
          const statusLower = status.toLowerCase();
          if (statusLower.includes('boarding')) return 'status-boarding';
          if (statusLower.includes('delayed')) return 'status-delayed';
          if (statusLower.includes('cancelled')) return 'status-cancelled';
          if (statusLower.includes('departed')) return 'status-departed';
          if (statusLower.includes('on time')) return 'status-on-time';
          if (statusLower.includes('final call')) return 'status-final-call';

          return 'status-default';
        };

        const ensureRowCount = (flights, targetCount = 14) => {
          const flightData = [...(flights || [])];
          
          // Limit to target count if we have more flights
          if (flightData.length > targetCount) {
            return flightData.slice(0, targetCount);
          }
          
          // Pad with empty objects if we have fewer flights
          while (flightData.length < targetCount) {
            flightData.push({});
          }
          
          return flightData;
        };
      
        // Generate HTML for the departure board
        cardElement.innerHTML = `
          <div class="departure-board">
            <div class="departure-board-header">
              <h2>DEPARTURES</h2>
              <div class="departure-board-time" id="current-time">${new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</div>
            </div>
            
            <div class="departure-board-table">
              <div class="departure-board-row header-row">
                <div class="departure-column departure-price">PRICE</div>
                <div class="departure-column departure-flight">FLIGHT</div>
                <div class="departure-column departure-destination">DESTINATION</div>
                <!-- div class="departure-column departure-gate">GATE</div -->
                <div class="departure-column departure-status">STATUS</div>
              </div>
              
              <div class="departure-board-content" id="departure-board-${card.id || 'main'}">
                ${ensureRowCount(card.flights).map(flight => `
                  <div class="departure-board-row flight-row" data-destination="${flight.destination || ''}">
                    <div class="departure-column departure-price">${formatPriceDisplay(flight.price)}</div>
                    <div class="departure-column departure-flight">${flight.flight || ''}</div>
                    <div class="departure-column departure-destination">${flight.destination || ''}</div>
                    <!-- div class="departure-column departure-gate">${flight.gate || ''}</div -->
                    <div class="departure-column departure-status ${getStatusClass(flight.status)}">
                      <span class="status-text">${flight.status || ''}</span>
                    </div>
                  </div>
                `).join('')}
              </div>
            </div>
            
            ${card.buttonText ? `
              <div class="departure-board-footer">
                <button class="departure-board-button">${card.buttonText}</button>
              </div>
            ` : ''}
          </div>
        `;
      
        // Update the clock every minute
        const clockElement = cardElement.querySelector('#current-time');
        if (clockElement) {
          const updateClock = () => {
            clockElement.textContent = new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
          };
          
          // Store interval ID on the element itself to clear when card is removed
    //      cardElement.clockInterval = setInterval(updateClock, 60000);
          updateClock();    
          // Make sure to clear the interval when the card is removed
          // (You might need to add this in your card removal function)
          // if (cardElement.clockInterval) clearInterval(cardElement.clockInterval);
        }
      
        // Add click handler for the button if it exists
        const boardButton = cardElement.querySelector('.departure-board-button');
        if (boardButton && card.onButtonClick) {
          boardButton.addEventListener('click', (e) => {
            e.stopPropagation();
            card.onButtonClick();
          });
        }
        
        break;
       case 'visa-card':
        // Constants for standard visa elements
        const VISA_DEFAULTS = {
          visaType: card.title ? card.title : 'NONIMMIGRANT VISA',
          category: 'B-1/B-2',
          entries: 'MULTIPLE',
          issueDate: '01 FEB 2025',
          expiryDate: '01 FEB 2035',
          controlNumber: '20250205123456',
          visaNumber: '25123456',
          stampRotation: -12,
          stampLeft: 4,
          stampBottom: 3
        };

        cardElement.innerHTML = `
            <div class="visa-card-wrapper">
              <div class="visa-card-container">
                  <div class="visa-card-background">
                      ${generateVisaBackground()}
                  </div>
                  <div class="visa-card-content">
                      <div class="visa-card-header">
                          <h1 class="visa-country">${card.country.toUpperCase()}</h1>
                          <p class="visa-type">${VISA_DEFAULTS.visaType}</p>
                      </div>
                      
                      <div class="visa-main-section">
                          <div class="visa-photo-area">
                              ${card.image ?
            `<img src="${card.image}" alt="Visa Photo">` :
            `<div class="placeholder-photo">PHOTO</div>`
          }
                          </div>
                          <div class="visa-details">
                              <div class="visa-detail">
                                  <span class="detail-label">Visa Type</span>
                                  <span class="detail-value">${VISA_DEFAULTS.category}</span>
                              </div>
                              <div class="visa-detail">
                                  <span class="detail-label">Length of Stay</span>
                                  <span class="detail-value">Unlimited</span>
                              </div>
                              <div class="visa-detail">
                                  <span class="detail-label">Entries</span>
                                  <span class="detail-value">${VISA_DEFAULTS.entries}</span>
                              </div>
                          </div>
                      </div>
      
                      <div class="visa-dates">
                          <div class="visa-detail">
                              <span class="detail-label">Issue Date</span>
                              <span class="detail-value">${VISA_DEFAULTS.issueDate}</span>
                          </div>
                          <div class="visa-detail">
                              <span class="detail-label">Expiration</span>
                              <span class="detail-value">${VISA_DEFAULTS.expiryDate}</span>
                          </div>
                      </div>
      
                      <div class="visa-detail">
                          <span class="detail-label">Notes</span>
                          <span class="detail-value">${card.message}</span>
                      </div>
                                            
                      <div class="visa-footer">
                          <div class="control-number">Control Number: ${VISA_DEFAULTS.controlNumber}</div>
                          <div class="visa-number">${VISA_DEFAULTS.visaNumber}</div>
                          <div class="visa-stamp" style="
                              transform: rotate(${card.stamp[0] ?? VISA_DEFAULTS.stampRotation}deg);
                              left: ${card.stamp[1] ?? VISA_DEFAULTS.stampLeft}rem;
                              bottom: ${card.stamp[2] ?? VISA_DEFAULTS.stampBottom}rem;
                          "></div>
                      </div>

                <div class="visa-card-action" ${card.buttonText ? "" : "style='display:none'"}>
                    <button class="fc-button visa-claim-button" ${card.disabled ? 'disabled' : ''}>
                        ${card.buttonText || 'Claim Visa'}
                    </button>
                </div>

                </div>


                </div>

            </div>
          `;

        // Add click handler for the button
        const claimButton = cardElement.querySelector('.visa-claim-button');

        if (claimButton && card.onButtonClick && !card.disabled) {
          console.log('got a clicker');
          claimButton.addEventListener('click', (e) => {
            console.log('clickyclick');
            e.stopPropagation();
            card.onButtonClick();
          });
        }
        break;
      case 'trading-card':
        cardElement.innerHTML = `
              <div class="trading-card-container">
                  <div class="trading-card-content">
                      <div class="trading-card-header">
                          <span class="trading-card-category">${card.category}</span>
                          <h3 class="trading-card-title">${card.title}</h3>
                      </div>
                      ${card.image ?
            `<div class="trading-card-image">
                              <img src="${card.image}" alt="${card.title}">
                          </div>` :
            `<div class="trading-card-image no-image">
                              <div class="item-title">${card.title}</div>
                          </div>`
          }
                      <div class="trading-card-info-block">
                          <p class="trading-card-description">${card.message}</p>
                          <p class="trading-card-potential"><b>POTENTIAL:</b> ${card.priceRange}</p>
                          <p class="trading-card-market"><b>MARKET:</b> ${card.sellsIn}</p>
                          ${card.confiscateIn ? `<p class="trading-card-market"><b>BANNED:</b> ${card.confiscateIn}</p>` : ""}
                          ${card.taxIn ? `<p class="trading-card-market"><b>TAXED:</b> ${card.taxIn}</p>` : ""}
                          ${card.funScore ? `<p class="trading-card-market"><b>FUN:</b> <span class="fun-score">${card.funScore}<span></p>` : ''}
                      </div>
                  </div>
      
                  <div class="trading-card-footer" id="${card.id}" ${!card.buttonText && !card.onRemove && !card.onUse ? "style='display:none;'" : ""}>
                      ${card.info && !card.discountApplied ? '<span class="trading-card-price">' + card.info + '</span>' : ''}
                      ${card.discountApplied ? `
                          <div class="trading-card-discount">
                              <span class="original-price">$${card.originalBuyPrice.toLocaleString()}</span>
                              <span class="new-price${card.feeType}">$${card.buyPrice.toLocaleString()}</span>
                              <div class="discount-label${card.feeType}">${card.discountMessage}</div>
                          </div>
                      ` : ''}
                      ${card.buttonText ? `
                          <button class="trading-card-button ${card.subType == "sell" ? 'sell-button' : ""}" ${card.disabled && !(card.subType == "sell") ? 'disabled' : ''}>${card.buttonText}</button>
                      ` : ''}
                      ${card.onUse ? `
                          <button class="trading-card-button use-button">
                              USE
                          </button>
                      ` : ''}
                      ${card.onRemove ? `
                          <button class="trading-card-button remove-button">
                              DISCARD
                          </button>
                      ` : ''}
                  </div>
              </div>
          `;

        // Add click handlers for all buttons
        const tradeButton = cardElement.querySelector('.trading-card-button:not(.use-button):not(.remove-button)');
        if (tradeButton && card.onButtonClick) {
          tradeButton.addEventListener('click', (e) => {
            e.stopPropagation();
            card.onButtonClick();
          });
        }

        const useButton = cardElement.querySelector('.use-button');
        if (useButton && card.onUse) {
          useButton.addEventListener('click', (e) => {
            e.stopPropagation();
            card.onUse();
          });
        }

        const removeButton = cardElement.querySelector('.remove-button');
        if (removeButton && card.onRemove) {
          removeButton.addEventListener('click', (e) => {
            e.stopPropagation();
            card.onRemove();
          });
        }
        break;

        
        case 'itinerary-card':
          cardElement.classList.add('card-spread-lucky-spin');

          // Utility functions for value formatting
          const formatters = {
            fun: (value) => {
              if (value === 0) return '';
              const emoji = value > 0 ? '☺' : '☹';
              const count = Math.abs(Math.floor(value / 5));
              return emoji.repeat(Math.max(1, count));
            },
            account: (value) => value >= 0 ? `+$${value}` : `-$${Math.abs(value)}`,
            quiz_bonus: (value, key) => ({
              label: 'Local costs',
              value: `${value > 0 ? '-' : '+'}${Math.abs(value)}%`
            }),
            quiz_sudden_death: (value, key) => ({
              label: 'Sudden Death Rules',
              value: `Country`
            }),
            local_sudden_death: (value, key) => ({
              label: 'Sudden Death Rules',
              value: `City`
            }),
            must_go_nearby: (value, key) => ({
              label: 'Must go to',
              value: this.options.countryCodes[value].name
            }),
            default: (value) => `${value > 0 ? '+' : ''}${value}`
          };
        
          // Preprocess results to handle any transformations
          const processResults = (results) => {
            return (results || []).map(result => ({
              ...result,
              key: result.key === 'quiz_bonus' ? 'discount' : result.key
            }));
          };
        
          // Format metric value and get appropriate CSS classes
          const formatMetric = (key, value) => {
            const formatter = formatters[key.toLowerCase()] || formatters.default;
            const formattedValue = formatter(value, key);

            let valueClass = value > 0 ? 'positive' : value < 0 ? 'negative' : '';
            if (key === "quiz_sudden_death" || key === "local_sudden_death") {
              valueClass = "negative"
            }

            return {
              label: formattedValue.label // key.toLowerCase() === 'quiz_bonus' 
                ? formattedValue.label 
                : key.replace(/_/g, ' '),
              value: typeof formattedValue === 'object' ? formattedValue.value : formattedValue,
              valueClass
            };
          };
        
          // Generate metrics HTML
          const generateMetricsHTML = (metrics) => {
            if (!metrics) return '';
            
            return Object.entries(metrics)
              .map(([key, value]) => {
                const { label, value: formattedValue, valueClass } = formatMetric(key, value);
                return `
                  <div class="lucky-spin-metric">
                    <span class="lucky-spin-metric-key lucky-spin-metric-key-${key}">${label}:</span>
                    <span class="lucky-spin-metric-value lucky-spin-metric-value-${key} ${valueClass}">
                      ${formattedValue}
                    </span>
                  </div>`;
              })
              .join('');
          };
        
          // Generate result block HTML
          const generateResultBlockHTML = (result, index) => {
            const title = result?.title || `Result ${index + 2}`;
            const description = result?.description || '';
            const metricsHTML = generateMetricsHTML(result?.metrics);
            const separatorClass = index < 2 ? 'with-separator' : '';
        
            return `
              <div class="lucky-spin-result-block ${separatorClass}">
                <h3 class="lucky-spin-result-title">${title}</h3>
                <p class="lucky-spin-result-description">${description}</p>
                <div class="lucky-spin-result-metrics">${metricsHTML}</div>
              </div>`;
          };
        
          // Process results
          const processedResults = processResults(card.results);
        
          // Generate final HTML
          cardElement.innerHTML = `
            <div class="lucky-spin-container">
              <div class="lucky-spin-card-inner ${card.showBackside ? 'flipped' : ''}">
                <div class="lucky-spin-front">
                  <h2 class="lucky-spin-title">${card.title || 'Lucky Spin'}</h2>
                  <div class="lucky-spin-subtitle">
                    ${card.subtitle || ''}
                    ${!card.showBackside ? `<button class="lucky-spin-button">${card.buttonText || 'Spin'}</button>` : ''}
                  </div>
                </div>
                
                <div class="lucky-spin-back">
                  <div class="lucky-spin-results">
                    <div class="lucky-spin-result-main-block with-separator">
                      <h3 class="lucky-spin-result-main-title">${card.cardTitle || 'Itinerary'}</h3>
                    </div>
                    ${processedResults.map((result, index) => generateResultBlockHTML(result, index)).join('')}
                  </div>
                </div>
              </div>
            </div>`;

        /* Simple JS for the scanner effect */
        const spinButton = cardElement.querySelector('.lucky-spin-button');
        if (spinButton && card.onSpin && !card.showBackside) {
          spinButton.addEventListener('click', (e) => {
            e.stopPropagation(); // Prevent card click

            if (card.onPreSpin) {
              card.onPreSpin();
            }
            
            // Toggle flip class to trigger the card flip animation
            const container = cardElement.querySelector('.lucky-spin-card-inner');
            container.classList.add('flipped');

            // Get the back side of the card
            const backSide = cardElement.querySelector('.lucky-spin-back');

            // Add a full white background to cover the content
            const backgroundCover = document.createElement('div');
            backgroundCover.style.position = 'absolute';
            backgroundCover.style.top = '0';
            backgroundCover.style.left = '0';
            backgroundCover.style.right = '0';
            backgroundCover.style.bottom = '0';
            backgroundCover.style.background = 'white';
            backgroundCover.style.zIndex = '5';

            // Create scanner overlay
            const scannerOverlay = document.createElement('div');
            scannerOverlay.className = 'scanner-overlay';

            backSide.appendChild(backgroundCover);
            backSide.appendChild(scannerOverlay);

            // Wait for card flip before starting animation
            setTimeout(() => {
              const cardHeight = backSide.offsetHeight;
              let startTime = null;

              const sections = [
                { start: 0, end: 0.3, speed: 2 },
                { start: 0.3, end: 0.44, speed: 0.3 },
                { start: 0.49, end: 0.5, speed: 0.05 },
                { start: 0.55, end: 0.70, speed: 0.3 },
                { start: 0.74, end: 0.75, speed: 0.05 },
                { start: 0.80, end: 0.85, speed: 0.3 }
              ];

              function animateScanner(timestamp) {
                if (!startTime) startTime = timestamp;

                const baseSpeed = 0.0003;
                const timeElapsed = timestamp - startTime;

                let progress = 0;
                let currentTime = 0;

                for (let pos = 0; pos < 1.01; pos += 0.001) {
                  const activeSection = sections.find(s => pos >= s.start && pos < s.end);
                  const currentSpeed = activeSection ? baseSpeed * activeSection.speed : baseSpeed;

                  const timeForStep = 0.001 / currentSpeed;
                  currentTime += timeForStep;

                  if (currentTime > timeElapsed) {
                    progress = pos;
                    break;
                  }
                }

                scannerOverlay.style.transform = `translateY(${progress * cardHeight}px)`;
                backgroundCover.style.clipPath = `polygon(0% ${progress * 100}%, 100% ${progress * 100}%, 100% 100%, 0% 100%)`;

                if (progress < 1) {
                  requestAnimationFrame(animateScanner);
                } else {
                  backSide.removeChild(backgroundCover);
                  backSide.removeChild(scannerOverlay);
                  card.onSpin(card.totalImpacts || []);
                }
              }

              requestAnimationFrame(animateScanner);
            }, 600);
          });
        }
        break;
      case 'boarding-pass':
        // Generate random seat
        const row = Math.floor(Math.random() * 50) + 1;
        const seat = String.fromCharCode(65 + Math.floor(Math.random() * 5));
        const seatNumber = `${row}${seat}`;

        // Generate random flight number
        const flightNumber = 'TM' + Math.floor(Math.random() * 9000 + 1000);

        // Generate current and destination times
        const now = new Date();
        const destTime = new Date(now.getTime() + (1 * 1000)); // Rough approximation

        const formatTime = (date) => {
          return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });
        };

        const formatDate = (date) => {
          return date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          });
        };

        // Generate airport codes if not provided
        const fromCode = card.fromCityCode || generateAirportCode(card.fromCityName);
        const toCode = card.toCityCode || generateAirportCode(card.toCityName);

        cardElement.classList.add('card-spread-boarding-pass');
        cardElement.innerHTML = `
            <div class="boarding-pass-main">
              <div class="boarding-pass-header">
                Boarding Pass
              </div>
              
              <div class="boarding-pass-content">
                
                <div class="time-info">
                  <div>
                    <div class="info-label">Date</div>
                    <div class="info-value">${formatDate(now)}</div>
                  </div>
                  <div>
                    <div class="info-label">Flight</div>
                    <div class="info-value">${flightNumber}</div>
                  </div>
                </div>
                
                <div class="flight-info">
                  <div class="departure">
                    <div class="city-code">${fromCode}</div>
                    <div class="city-name">${card.fromCityName}, ${card.fromIso2}</div>
                    <div class="info-value">${formatTime(now)}</div>
                  </div>
                  
                  <div class="flight-path">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M4 12h16M16 6l6 6-6 6" stroke="currentColor" stroke-width="2"/>
                    </svg>
                  </div>
                  
                  <div class="arrival">
                    <div class="city-code">${toCode}</div>
                    <div class="city-name">${card.toCityName}, ${card.toIso2}</div>
                    <div class="info-value">${formatTime(destTime)}</div>
                  </div>
                </div>
                
                <div class="passenger-info">
                  <div class="info-row">

                    <div>
                      <div class="info-label">Terminal</div>
                      <div class="info-value">1</div>
                    </div>
                    <div>
                      <div class="info-label">Gate</div>
                      <div class="info-value">${Math.floor(Math.random() * 22)}</div>
                    </div>
                    <div>
                      <div class="info-label">Seat</div>
                      <div class="info-value">${seatNumber}</div>
                    </div>
                  </div>
                </div>

                <div class="passenger-info">
                  <div class="info-row">
                    <div>
                      <div class="info-label">Status</div>
                      <div class="info-value">${card.status ? card.status : 'Basic'}</div>
                    </div>
                    <div>
                      <div class="info-label">Distance</div>
                      <div class="info-value">${card.distance}</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
                    
            <div class="boarding-pass-stub">
              <div class="stub-content">
                <div class="stub-info">
                  <span class="stub-label">From</span>
                  <span class="stub-value">${card.fromCityName}, ${card.fromIso2}</span>
                </div>
                <div class="stub-info">
                  <span class="stub-label">To</span>
                  <span class="stub-value">${card.toCityName}, ${card.toIso2}</span>
                </div>
                <div class="stub-info">
                  <span class="stub-label">Status</span>
                  <span class="stub-value">${card.status ? card.status : 'Basic'}</span>
                </div>
                <div class="stub-info">
                  <span class="stub-label">Distance</span>
                  <span class="stub-value">${card.distance}</span>
                </div>
              </div>
              
              <button class="confirm-travel-btn">${card.buttonText} <span style="font-size:20px">✈</span></button>
              
            </div>
          `;

        // Add click handler for the confirm button
        const tmconfirmButton = cardElement.querySelector('.confirm-travel-btn');
        if (tmconfirmButton && card.confirmCallback) {
          tmconfirmButton.addEventListener('click', (e) => {
            e.stopPropagation(); // Prevent card click
            card.confirmCallback();
          });
        }
        break;
      case 'empty-slot':
        cardElement.innerHTML = `
          <div class="empty-inventory-slot">
              <div class="empty-slot-content">
                  <div class="empty-slot-icon">+</div>
                  <div class="empty-slot-text">Empty Slot</div>
              </div>
          </div>
      `;
        break;
      case 'confirm':
        cardElement.innerHTML = `
            <div class="card-spread-start-page">
              <span>
                ${card.title ? `<h2 class="card-spread-start-title">${card.title}</h2>` : ''}
                ${card.category ? `<h3 class="card-spread-start-category">${card.category}</h3>` : ''}
              </span>
              ${card.message ? `<p class="card-spread-start-description">${card.message}</p>` : ''}
              ${card.info ? `<p class="card-spread-start-info">${card.info}</p>` : ''}
              <button class="card-spread-quiz-button">Let's go ✈️</button>
            </div>
          `;

        const confirmButton = cardElement.querySelector('.card-spread-quiz-button');
        if (confirmButton && card.confirmCallback) {
          confirmButton.addEventListener('click', card.confirmCallback);
        }
        break;

      case 'title':
        // Handle background image if provided
        let backgroundHtml = '';
        if (card.backgroundImage) {
          const imageClass = 'background-image';
          backgroundHtml = `<img src="${card.backgroundImage}" class="${imageClass}" alt="">`;

        }

        // Use gradient as fallback if no image and gradient is specified
        const style = !card.backgroundImage && card.gradient ?
          `style="background: ${card.gradient}"` : '';

        cardElement.innerHTML = `
              ${backgroundHtml}
              <div class="card-spread-vertical-title">${card.title || ''}</div>
            `;
        break;

      case 'start':
        cardElement.innerHTML = `
            <div class="card-spread-start-page">
              <span>
                ${card.title ? `<h2 class="card-spread-start-title">${card.title}</h2>` : ''}
                ${card.category ? `<h3 class="card-spread-start-category">${card.category}</h3>` : ''}
              </span>
              ${card.message ? `<p class="card-spread-start-description">${card.message}</p>` : ''}
            </div>
          `;
        break;

        case 'info':
          cardElement.innerHTML = `
              <div class="card-spread-info-page">
                <span>
                  ${card.title ? `<h2 class="card-spread-info-title">${card.title}</h2>` : ''}
                  ${card.category ? `<h3 class="card-spread-info-category">${card.category}</h3>` : ''}
                </span>
                ${card.message ? `<p class="card-spread-info-description">${card.message}</p>` : ''}
              </div>
            `;
          break;
      case 'stats':
        cardElement.innerHTML = `
            <div class="card-spread-quiz-card">
              <span>
                ${card.category ? `<h3 class="card-spread-start-category">${card.category}</h3>` : ''}
                ${card.title ? `<h2 class="card-spread-start-title">${card.title}</h2>` : ''}
              </span>
              ${card.message ? `<p class="card-spread-start-description">${card.message}</p>` : ''}
              ${card.customContent || ''}
              ${card.info ? `<p class="card-spread-start-info">${card.info}</p>` : ''}
              ${card.buttonText ? `<button class="card-spread-quiz-button"><svg xmlns="http://www.w3.org/2000/svg" class="icons" width="16" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="display: inline-block; vertical-align: top; margin-right: 4px;">
    <circle cx="12" cy="12" r="10"/>
    <polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"/>
  </svg> ${card.buttonText}</button>` : ''}
            </div>
          `;
        break;
      case 'quiz':
        cardElement.innerHTML = `
            <div class="card-spread-quiz-card">
              <div class="card-spread-sudden-death" ${ card.suddenDeath ? '' : 'style="display:none"'}>★ Sudden Death Rules ★</div>
              <span>
                ${card.category ? `<h3 class="card-spread-start-category">${card.category}</h3>` : ''}
                ${card.title ? `<h2 class="card-spread-start-title">${card.title}</h2>` : ''}
              </span>
              ${card.message ? `<p class="card-spread-start-description">${card.message}</p>` : ''}
              ${card.customContent ? `<div class="card-spread-custom-content">${card.customContent}</div>` : '' }
              ${card.info ? `<p class="card-spread-start-info">${card.info}</p>` : ''}
              ${card.buttonText ? `<button class="card-spread-quiz-button"><svg xmlns="http://www.w3.org/2000/svg" class="icons" width="16" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="display: inline-block; vertical-align: top; margin-right: 4px;">
    <circle cx="12" cy="12" r="10"/>
    <polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"/>
  </svg> ${card.buttonText}</button>` : ''}
            </div>
          `;
        break;
      case 'quiz-done':
        cardElement.innerHTML = `
              <div class="card-spread-quiz-card">
                <span><span class="fc-checkmark" style="font-size: 24px; color: green">&#x2713;</span><br/><br/>
                  ${card.category ? `<h3 class="card-spread-start-category">${card.category}</h3>` : ''}
                  ${card.title ? `<h2 class="card-spread-start-title">${card.title}</h2>` : ''}
                </span>
                ${card.info ? `<p class="card-spread-start-info">${card.info}</p>` : ''}
                ${card.buttonText ? `<button class="card-spread-quiz-button card-spread-quiz-detailsx">${card.buttonText}</button>` : ''}
              </div>
            `;
        break;
      default:
        cardElement.innerHTML = `
            <div class="card-spread-card-content">
              ${card.icon ? `<div class="card-spread-card-icon">${card.icon}</div>` : ''}
              <h3 class="card-spread-card-title">${card.title || ''}</h3>
              <p class="card-spread-card-description">${card.description || ''}</p>
              ${card.customContent || ''}
            </div>
          `;
    }


    /*
    // Add remove button if in inventory mode
    if (this.options.showRemoveButton && card.onRemove) {

      const removeButton = document.createElement('button');
      removeButton.className = 'card-remove-button';
      removeButton.innerHTML = 'discard';
      removeButton.onclick = (e) => {
        e.stopPropagation();
        card.onRemove();
      };
      cardElement.appendChild(removeButton);
    }
      */

    // Add click handlers
    cardElement.addEventListener('click', (e) => {
      if (e.target.classList.contains('card-spread-quiz-button')) {
        if (card.onButtonClick) {
          card.onButtonClick(card, index);
        }
        return;
      }

      if (card.onClick) {
        card.onClick(card, index);
      }
    });

    return cardElement;
  }

  close() {
    return new Promise((resolve) => {
      this.elements.modalContainer.classList.add('closing');
      if ( this.three ) { this.three.startRender(); }

      setTimeout(() => {
        if (this.elements.modalContainer.parentNode) {
          this.elements.modalContainer.parentNode.removeChild(this.elements.modalContainer);
        }
        // Call onClose callback if it exists
        if (this.options.onClose) {
          this.options.onClose();
        }
        resolve();
      }, 300);
    });
  }

  hide(selectedCardIndex = null) {

    if ( this.three ) { this.three.startRender(); }

    return new Promise((resolve) => {
      const cards = Array.from(this.elements.cardTrack.children);

      // If we have a selected card, bring it to the front
      if (selectedCardIndex !== null) {
        const selectedCard = cards[selectedCardIndex];
        selectedCard.style.zIndex = cards.length + 1;
      }

      // First remove spread class so cards don't transition position immediately
      cards.forEach(card => {
        card.classList.remove('spread');
      });

      // Force a reflow to ensure the removal of spread is processed
      void this.elements.cardTrack.offsetHeight;

      // Now set all cards to gather at center
      cards.forEach(card => {
        card.classList.add('gathering');
        card.style.setProperty('--spread-x', '-50%');  // Center position
        card.style.setProperty('--rotation', '0deg');  // Reset rotation
      });

      // Wait for gather animation to complete
      setTimeout(() => {
        this.elements.modalContainer.style.display = 'none';
        // Clean up classes
        cards.forEach(card => {
          card.classList.remove('gathering');
        });
        resolve();
      }, 500); // Match the CSS transition duration
    });
  }
  // Add a new method to re-show and spread the cards
  unhide() {
    this.elements.modalContainer.style.display = '';
    /*
    // Reset card positions after a small delay to ensure display change is processed
    setTimeout(() => {
      const cards = Array.from(this.elements.cardTrack.children);
      const cardWidth = 300;
      const spacing = 10;
      const startX = -(cardWidth / 2);
  
      cards.forEach((card, index) => {
        const spreadX = startX + (index * (cardWidth + spacing));
        card.style.setProperty('--spread-x', `${spreadX}px`);
        const rotation = card.dataset.rotation || '0';
        card.style.setProperty('--rotation', `${rotation}deg`);
      });
    }, 50);
    */
  }


  // Add method to update cards without closing modal
  updateCards(cards) {
    this.cards = cards;
    this.elements.cardTrack.innerHTML = '';
    cards.forEach((card, index) => {
      const cardElement = this.renderCard(card, index);
      this.elements.cardTrack.appendChild(cardElement);
    });
  }
  
  show(cards) {
    if ( this.three ) { this.three.stopRender(); }

    if (cards) {
      this.cards = cards;
    }
  
    if (!this.initialized) {
      this.createElements();
      this.initialized = true;
    }
  
    if (this.elements.modalContainer.classList.contains('hiding')) {
      this.elements.modalContainer.classList.remove('hiding');
      this.elements.modalContainer.style.display = '';
      requestAnimationFrame(() => {
        this.elements.modalContainer.classList.add('visible');
      });
      return;
    }
  
    this.elements.cardTrack.innerHTML = '';
  
    const cardWidth = 300;
    const spacing = 10;
    const startX = -(cardWidth / 2);
  
    this.cards.forEach((card, index) => {
      if (card) {
        const cardElement = this.renderCard(card, index);
        cardElement.style.zIndex = this.cards.length - index;
        const spreadX = startX + (index * (cardWidth + spacing));
        cardElement.dataset.spreadX = `${spreadX}`;
        cardElement.style.setProperty('--spread-x', '-50%');
        cardElement.style.setProperty('--rotation', (cardElement.dataset.rotation || 0) + 'deg');
        this.elements.cardTrack.appendChild(cardElement);
      }
    });
  
    if (!this.elements.modalContainer.parentNode) {
      document.body.appendChild(this.elements.modalContainer);
    }
  
    requestAnimationFrame(() => {
      this.elements.modalContainer.classList.add('visible');
      
      setTimeout(() => {
        Array.from(this.elements.cardTrack.children).forEach(card => {
          card.style.setProperty('--spread-x', card.dataset.spreadX + 'px');
          card.classList.add('spread');
        });
        
        // Listen for the end of animations
        const lastCard = this.elements.cardTrack.lastChild;
        if (lastCard) {
          const handleAnimEnd = () => {
            // Force Safari to recognize touch events after animation completes
            if (0 && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
              // Force reflow by temporarily changing overflow property
              const originalOverflow = this.elements.cardScroller.style.overflow;
              this.elements.cardScroller.style.overflow = 'hidden';
              void this.elements.cardScroller.offsetHeight; // Force reflow
              this.elements.cardScroller.style.overflow = originalOverflow;
              
              // Add subtle "twitch" to force Safari to recalculate the scrollable area
              this.elements.cardScroller.scrollLeft += 1;
              setTimeout(() => { 
                this.elements.cardScroller.scrollLeft -= 1;
              }, 0);
            }
            
            lastCard.removeEventListener('animationend', handleAnimEnd);
          };
          
          lastCard.addEventListener('animationend', handleAnimEnd);
          
          // Backup timeout in case animation events aren't fired properly
          setTimeout(() => {
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
              // Same reflow logic as a backup
              const originalOverflow = this.elements.cardScroller.style.overflow;
              this.elements.cardScroller.style.overflow = 'hidden';
              void this.elements.cardScroller.offsetHeight;
              this.elements.cardScroller.style.overflow = originalOverflow;
              this.elements.cardScroller.scrollLeft += 1;
              setTimeout(() => { this.elements.cardScroller.scrollLeft -= 1; }, 0);
            }
          }, 800); // Set timeout slightly longer than animation duration (700ms)
        }
      }, 100);
    });
  }

}

function generateAirportCode(cityName) {
  // Remove special characters and spaces, convert to uppercase
  const cleanName = cityName.replace(/[^a-zA-Z]/g, '').toUpperCase();

  if (cleanName.length < 3) {
    // If name is too short, pad with X
    return (cleanName + 'XXX').slice(0, 3);
  }

  const firstLetter = cleanName[0];
  const lastLetter = cleanName[cleanName.length - 1];

  // Find a middle consonant or vowel
  const middleSection = cleanName.slice(1, -1);
  const consonants = middleSection.match(/[BCDFGHJKLMNPQRSTVWXZ]/g);
  const vowels = middleSection.match(/[AEIOU]/g);

  // Prefer consonants, fall back to vowels, then to middle letter
  let middleLetter;
  if (consonants && consonants.length > 0) {
    middleLetter = consonants[0];
  } else if (vowels && vowels.length > 0) {
    middleLetter = vowels[0];
  } else {
    middleLetter = middleSection[Math.floor(middleSection.length / 2)] || 'X';
  }

  return `${firstLetter}${middleLetter}${lastLetter}`;
}

function generateVisaBackground() {
  return `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 600" class="visa-background">
          <defs>
              <!-- Guilloche Pattern -->
              <pattern id="guilloche" x="0" y="0" width="50" height="50" patternUnits="userSpaceOnUse">
                  <path d="M25,25 m-20,0 a20,20 0 1,0 40,0 a20,20 0 1,0 -40,0" 
                        fill="none" stroke="#0066cc" stroke-width="0.5" opacity="0.1"/>
                  <path d="M25,25 m-15,0 a15,15 0 1,0 30,0 a15,15 0 1,0 -30,0" 
                        fill="none" stroke="#003366" stroke-width="0.5" opacity="0.1"/>
              </pattern>

              <!-- Wavy Line Pattern -->
              <pattern id="wavyLines" x="0" y="0" width="100" height="20" patternUnits="userSpaceOnUse">
                  <path d="M0,10 Q25,0 50,10 T100,10" 
                        fill="none" stroke="#000066" stroke-width="0.5" opacity="0.1"/>
                  <path d="M0,15 Q25,5 50,15 T100,15" 
                        fill="none" stroke="#000066" stroke-width="0.5" opacity="0.1"/>
              </pattern>

              <!-- Micro Text Pattern -->
              <pattern id="microtext" x="0" y="0" width="100" height="100" patternUnits="userSpaceOnUse">
                  <text x="0" y="10" font-size="3" fill="#000066" opacity="0.1">VISA SECURE DOCUMENT</text>
                  <text x="20" y="20" font-size="3" fill="#000066" opacity="0.1">OFFICIAL USE ONLY</text>
              </pattern>
          </defs>

          <!-- Background Fills -->
          <rect width="100%" height="100%" fill="url(#guilloche)"/>
          <rect width="100%" height="100%" fill="url(#wavyLines)"/>
          <rect width="100%" height="100%" fill="url(#microtext)"/>

          <!-- Corner Ornaments -->
          <g class="corner-ornaments">
              ${Array(4).fill(0).map((_, i) => `
                  <path d="M${i < 2 ? 20 : 380},${i % 2 === 0 ? 20 : 480} 
                          q${i < 2 ? 10 : -10},0 ${i < 2 ? 10 : -10},${i % 2 === 0 ? 10 : -10}
                          q0,${i % 2 === 0 ? 10 : -10} ${i < 2 ? 10 : -10},${i % 2 === 0 ? 10 : -10}"
                        fill="none" stroke="#000066" stroke-width="1" opacity="0.2"/>
              `).join('')}
          </g>

          <!-- Central Design Element -->
          <g transform="translate(200,300)">
              ${Array(8).fill(0).map((_, i) => `
                  <path d="M0,0 l${30 * Math.cos(i * Math.PI / 4)},${30 * Math.sin(i * Math.PI / 4)}"
                        stroke="#000066" stroke-width="0.5" opacity="0.1"/>
              `).join('')}
          </g>
      </svg>
  `;
}