import * as THREE from 'three';

class GlobeAutorotate {
    constructor(camera, controls, bokehPass, params = {}) {
        // Core components
        this.camera = camera;
        this.controls = controls;
        this.bokehPass = bokehPass;
        
        // Configuration options
        this.inactivityTimeout = params.inactivityTimeout || 30000; // 30 seconds
        this.transitionDuration = params.transitionDuration || 5000; // 5 seconds per transition
        this.blurLerpSpeed = params.blurLerpSpeed || 0.01;
        this.pauseBetweenViews = params.pauseBetweenViews || 4000; // Wait time at each view
        this.captureKey = params.captureKey || 'v'; // Key to capture current view
        this.returnDuration = params.returnDuration || 1000; // 1 second to return to original position
        
        // Globe parameters
        this.globeRadius = params.globeRadius || 1.0; // Default radius of 1
        this.safetyMargin = params.safetyMargin || 0.1; // Extra safety margin to prevent clipping
        
        // Blur effect parameters
        this.targetApertureValue = params.targetApertureValue || 0.015;
        this.targetMaxBlurValue = params.targetMaxBlurValue || 1.2;
        
        // Store goToLocation for reference (if provided)
        this.goToLocation = params.goToLocation;
        
        // Initialize with default views if none provided
        this.views = params.views || this.getDefaultViews();
        this.currentViewIndex = 0;
        this.nextViewIndex = 0;
        
        // Animation state
        this.isActive = false;
        this.isTransitioning = false;
        this.lastActivityTime = performance.now();
        this.transitionStartTime = 0;
        this.waitStartTime = 0;
        this.isWaiting = false;
        this.isReturning = false;
        this.returnStartTime = 0;
        this.tourComplete = false;
        
        // Position state tracking
        this.startPosition = new THREE.Vector3();
        this.targetPosition = new THREE.Vector3();
        this.startTarget = new THREE.Vector3();
        this.targetTarget = new THREE.Vector3();
        this.startUp = new THREE.Vector3();
        this.targetUp = new THREE.Vector3();
        
        // Interpolation path points
        this.interpolationPath = [];
        this.pathSegments = params.pathSegments || 100; // Number of segments for path calculation
        
        // Original camera state (for reset at tour end only)
        this.originalCameraPosition = new THREE.Vector3();
        this.originalControlsTarget = new THREE.Vector3();
        this.originalCameraUp = new THREE.Vector3();
        
        // Blur effect state variables
        this.originalAperture = 0;
        this.targetAperture = 0;
        this.originalMaxBlur = 0;
        this.targetMaxBlur = 0;
        
        // Bind methods
        this.handleActivity = this.handleActivity.bind(this);
        this.checkInactivity = this.checkInactivity.bind(this);
        this.animate = this.animate.bind(this);
        this.captureCurrentView = this.captureCurrentView.bind(this);
        
        // Initialize
        this.initEventListeners();
        this.saveOriginalState();
    }
    
    saveOriginalState() {
        // Store initial state to return to after full tour completion
        this.originalCameraPosition.copy(this.camera.position);
        this.originalControlsTarget.copy(this.controls.target);
        this.originalCameraUp.copy(this.camera.up);
        
        if (this.bokehPass) {
            this.originalAperture = this.bokehPass.uniforms["aperture"].value;
            this.originalMaxBlur = this.bokehPass.uniforms["maxblur"].value;
        }

        const existing = document.querySelector('.city-popup');
        if (existing) {
            existing.style.opacity = '0';
            setTimeout(() => existing.remove(), 200);
        }
    }
    
    initEventListeners() {
        // User activity events
        const events = ['mousemove', 'mousedown', 'wheel', 
                       'touchstart', 'touchmove', 'keydown'];
        
        events.forEach(event => {
            window.addEventListener(event, this.handleActivity);
        });
        
        // Add view capture key listener
        window.addEventListener('keydown', (event) => {
            if (event.key.toLowerCase() === this.captureKey.toLowerCase()) {
                this.captureCurrentView();
            }
        });
        
        // Check for inactivity periodically
        this.inactivityCheckInterval = setInterval(this.checkInactivity, 1000);
    }
    
    cleanup() {
        // Remove all event listeners
        const events = ['mousemove', 'mousedown', 'wheel', 
                       'touchstart', 'touchmove', 'keydown'];
        
        events.forEach(event => {
            window.removeEventListener(event, this.handleActivity);
        });
        
        // Remove key capture listener
        window.removeEventListener('keydown', (event) => {
            if (event.key.toLowerCase() === this.captureKey.toLowerCase()) {
                this.captureCurrentView();
            }
        });
        
        // Stop the inactivity checker
        if (this.inactivityCheckInterval) {
            clearInterval(this.inactivityCheckInterval);
        }
    }
    
    handleActivity() {
        // Record activity time
        this.lastActivityTime = performance.now();
        
        // If we're in auto-touring state, stop and return to original position
        if (this.isActive) {
            this.initiateReturn();
        }
    }
    
    // Initiate return to original position only at end of tour
    initiateReturn() {
        // Don't interrupt if already returning
        if (this.isReturning) return;
        
        // Capture current state as starting point for return animation
        this.startPosition.copy(this.camera.position);
        this.startTarget.copy(this.controls.target);
        this.startUp.copy(this.camera.up);
        
        if (this.bokehPass) {
            this.startAperture = this.bokehPass.uniforms["aperture"].value;
            this.startMaxBlur = this.bokehPass.uniforms["maxblur"].value;
        }
        
        // Calculate a safe path for returning
        this.calculateSafePath(this.startPosition, this.originalCameraPosition);
        
        // Mark as returning and set up animation
        this.isReturning = true;
        this.isActive = false;  // No longer in auto-rotate mode
        this.isTransitioning = false;
        this.isWaiting = false;
        this.tourComplete = false;
        this.returnStartTime = performance.now();
        
        // Start the return animation
        requestAnimationFrame(this.animate);
    }
    
    checkInactivity() {
        const now = performance.now();
        const idle = now - this.lastActivityTime;
        
        // Start tour if we've been idle for long enough and not already touring
        if (idle >= this.inactivityTimeout && !this.isActive && !this.isReturning && this.views.length > 0) {
            this.start();
        }
    }
    
    // Capture the current camera view
    captureCurrentView() {
        const view = {
            position: this.camera.position.clone(),
            target: this.controls.target.clone(),
            up: this.camera.up.clone(),
            aperture: this.bokehPass ? this.bokehPass.uniforms["aperture"].value : 0.01,
            maxblur: this.bokehPass ? this.bokehPass.uniforms["maxblur"].value : 1.0
        };
        
        // Add to views array
        this.views.push(view);
        
        // Log the view data for easy copy-paste
        console.log('View captured:', JSON.stringify({
            position: {
                x: view.position.x.toFixed(3),
                y: view.position.y.toFixed(3),
                z: view.position.z.toFixed(3)
            },
            target: {
                x: view.target.x.toFixed(3),
                y: view.target.y.toFixed(3),
                z: view.target.z.toFixed(3)
            },
            up: {
                x: view.up.x.toFixed(3),
                y: view.up.y.toFixed(3),
                z: view.up.z.toFixed(3)
            },
            aperture: view.aperture.toFixed(4),
            maxblur: view.maxblur.toFixed(4)
        }, null, 2));
        
        return view;
    }
    
    // Add a predefined view
    addView(view) {
        // Validate view has required properties
        if (!view.position || !view.target) {
            console.error('View must have position and target properties');
            return;
        }
        
        // Convert to Vector3 if plain objects were passed
        if (!(view.position instanceof THREE.Vector3)) {
            view.position = new THREE.Vector3(view.position.x, view.position.y, view.position.z);
        }
        
        if (!(view.target instanceof THREE.Vector3)) {
            view.target = new THREE.Vector3(view.target.x, view.target.y, view.target.z);
        }
        
        if (view.up && !(view.up instanceof THREE.Vector3)) {
            view.up = new THREE.Vector3(view.up.x, view.up.y, view.up.z);
        } else if (!view.up) {
            view.up = new THREE.Vector3(0, 1, 0);
        }
        
        // Default blur values if not provided
        view.aperture = view.aperture !== undefined ? view.aperture : this.targetApertureValue;
        view.maxblur = view.maxblur !== undefined ? view.maxblur : this.targetMaxBlurValue;
        
        this.views.push(view);
    }
    
    // Clear all views
    clearViews() {
        this.views = [];
    }
    
    // Start the auto rotation
    start() {
        if (this.isActive || this.views.length === 0) return;
        
        // Save original state if not already saved
        this.saveOriginalState();
        
        // Set active state
        this.isActive = true;
        this.isTransitioning = false;
        this.isWaiting = false;
        this.tourComplete = false;
        
        // Shuffle views for randomized order
        this.shuffleViews();
        
        // Set initial position as starting point (this is key - NOT returning to original between views)
        this.currentViewIndex = 0;
        
        // Start the tour from current position to first view
        this.startNextTransition();
    }
    
    // Randomize the view order
    shuffleViews() {
        // Fisher-Yates shuffle algorithm
        for (let i = this.views.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [this.views[i], this.views[j]] = [this.views[j], this.views[i]];
        }
    }
    
    // Calculate a safe path from start to end position that doesn't go inside the globe
    calculateSafePath(startPos, endPos) {
        // Clear previous path
        this.interpolationPath = [];
        
        // Add starting point
        this.interpolationPath.push(startPos.clone());
        
        // Check if direct path is safe
        if (this.isPathSafe(startPos, endPos)) {
            // If direct path is safe, use simple linear interpolation
            this.interpolationPath.push(endPos.clone());
            return;
        }
        
        // If direct path isn't safe, we need to create an orbital path
        this.createOrbitalPath(startPos, endPos);
    }
    
    // Check if direct path from start to end doesn't go inside the globe
    isPathSafe(startPos, endPos) {
        // The minimum safe distance should be globe radius + safety margin
        const safeDist = this.globeRadius + this.safetyMargin;
        
        // Test points along the path
        for (let t = 0; t <= 1; t += 0.01) {
            const point = new THREE.Vector3();
            point.lerpVectors(startPos, endPos, t);
            
            // Check distance from globe center
            if (point.length() < safeDist) {
                return false; // Path would go inside the globe
            }
        }
        
        return true; // Path is safe
    }
    
    // Create an orbital path that goes around the globe instead of through it
    createOrbitalPath(startPos, endPos) {
        const startDir = startPos.clone().normalize();
        const endDir = endPos.clone().normalize();
        
        // Find the angle between start and end directions
        let angle = startDir.angleTo(endDir);
        
        // Create a rotation axis (perpendicular to both directions)
        const rotAxis = new THREE.Vector3().crossVectors(startDir, endDir).normalize();
        
        // If vectors are parallel or anti-parallel, we need a different rotation axis
        if (rotAxis.lengthSq() < 0.001) {
            // Find any perpendicular axis
            rotAxis.set(startDir.y, -startDir.x, 0).normalize();
            if (rotAxis.lengthSq() < 0.001) {
                rotAxis.set(0, startDir.z, -startDir.y).normalize();
            }
        }
        
        // Get distances from center
        const startDist = startPos.length();
        const endDist = endPos.length();
        
        // Use the larger of the two distances for the arc radius
        // to ensure we don't go too close to the globe
        const arcRadius = Math.max(startDist, endDist);
        
        // Calculate intermediary points along the great circle path
        const steps = this.pathSegments;
        const quaternion = new THREE.Quaternion();
        
        for (let i = 0; i <= steps; i++) {
            const t = i / steps;
            
            // Use ease-in-out interpolation
            const smoothT = t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
            
            // Create rotation quaternion
            quaternion.setFromAxisAngle(rotAxis, angle * smoothT);
            
            // Start with normalized direction vector
            const dirVector = startDir.clone();
            
            // Apply rotation
            dirVector.applyQuaternion(quaternion);
            
            // Interpolate distance
            const distance = THREE.MathUtils.lerp(startDist, endDist, smoothT);
            
            // Scale to desired distance
            dirVector.multiplyScalar(distance);
            
            // Add to path
            this.interpolationPath.push(dirVector);
        }
    }
    
    // Get position along the path
    getPositionAlongPath(progress) {
        if (this.interpolationPath.length < 2) return null;
        
        // Find the appropriate segment based on progress
        const segments = this.interpolationPath.length - 1;
        const segmentProgress = progress * segments;
        const segmentIndex = Math.min(Math.floor(segmentProgress), segments - 1);
        const segmentT = segmentProgress - segmentIndex;
        
        // Get the points that bound this segment
        const p0 = this.interpolationPath[segmentIndex];
        const p1 = this.interpolationPath[segmentIndex + 1];
        
        // Interpolate between these points
        const result = new THREE.Vector3();
        result.lerpVectors(p0, p1, segmentT);
        
        return result;
    }
    
    // Begin transitioning to the next view
    startNextTransition() {
        if (!this.isActive) return;
        
        // Get current camera state as starting point
        this.startPosition.copy(this.camera.position);
        this.startTarget.copy(this.controls.target);
        this.startUp.copy(this.camera.up);
        
        if (this.bokehPass) {
            this.startAperture = this.bokehPass.uniforms["aperture"].value;
            this.startMaxBlur = this.bokehPass.uniforms["maxblur"].value;
        }
        
        // Get the target view
        const targetView = this.views[this.currentViewIndex];
        
        // Set up target state
        this.targetPosition.copy(targetView.position);
        this.targetTarget.copy(targetView.target);
        this.targetUp.copy(targetView.up);
        
        // Ensure all target positions are outside the globe
        this.ensureSafeDistance(this.targetPosition);
        
        // Calculate safe interpolation path
        this.calculateSafePath(this.startPosition, this.targetPosition);
        
        // Set target blur values
        if (this.bokehPass) {
            this.targetAperture = targetView.aperture;
            this.targetMaxBlur = targetView.maxblur;
        }
        
        // Start transition animation
        this.isTransitioning = true;
        this.transitionStartTime = performance.now();
        
        requestAnimationFrame(this.animate);
    }
    
    // Ensure a position is outside the globe's radius
    ensureSafeDistance(position) {
        const minDist = this.globeRadius + this.safetyMargin;
        const currentDist = position.length();
        
        if (currentDist < minDist) {
            // Scale the position to be at least minDist from center
            position.normalize().multiplyScalar(minDist);
        }
    }
    
    // Stop auto-rotation and reset to original state
    stop() {
        // Reset state flags
        this.isActive = false;
        this.isTransitioning = false;
        this.isWaiting = false;
        this.isReturning = false;
        this.tourComplete = false;
        
        // Reset camera and controls to initial state immediately
        this.camera.position.copy(this.originalCameraPosition);
        this.controls.target.copy(this.originalControlsTarget);
        this.camera.up.copy(this.originalCameraUp);
        
        // Update controls
        this.controls.update();
        
        // Reset blur if needed
        if (this.bokehPass) {
            this.bokehPass.uniforms["aperture"].value = this.originalAperture;
            this.bokehPass.uniforms["maxblur"].value = this.originalMaxBlur;
        }
    }
    
    // Animation loop
    animate() {
        if (!this.isActive && !this.isReturning) return;
        
        const now = performance.now();
        
        // Handle transition between views
        if (this.isTransitioning) {
            const elapsed = now - this.transitionStartTime;
            const progress = Math.min(elapsed / this.transitionDuration, 1.0);
            
            // Use cubic ease-in-out for smooth motion
            const easedProgress = progress < 0.5
                ? 4 * progress * progress * progress
                : 1 - Math.pow(-2 * progress + 2, 3) / 2;
            
            // Get position along safe path
            if (this.interpolationPath.length >= 2) {
                const newPosition = this.getPositionAlongPath(easedProgress);
                if (newPosition) {
                    this.camera.position.copy(newPosition);
                }
            } else {
                // Fallback - direct interpolation (should never happen if path calculation works)
                this.camera.position.lerpVectors(
                    this.startPosition,
                    this.targetPosition,
                    easedProgress
                );
                
                // Safety check - ensure camera never goes inside globe
                this.ensureSafeDistance(this.camera.position);
            }
            
            // Interpolate target (look-at point)
            this.controls.target.lerpVectors(
                this.startTarget,
                this.targetTarget,
                easedProgress
            );
            
            // Interpolate up vector for camera orientation
            this.camera.up.lerpVectors(
                this.startUp,
                this.targetUp,
                easedProgress
            );
            
            // Apply blur effects if available
            if (this.bokehPass) {
                // Use the same eased progress for blur effects as we do for the camera
                // This ensures blur transitions match the movement speed
                
                // Interpolate aperture directly using eased progress
                this.bokehPass.uniforms["aperture"].value = THREE.MathUtils.lerp(
                    this.startAperture,
                    this.targetAperture,
                    easedProgress
                );
                
                // Interpolate maxblur directly using eased progress
                this.bokehPass.uniforms["maxblur"].value = THREE.MathUtils.lerp(
                    this.startMaxBlur,
                    this.targetMaxBlur,
                    easedProgress
                );
            }
            
            // Update controls to ensure camera movement is applied
            this.controls.update();
            
            // Check if transition is complete
            if (progress >= 1.0) {
                this.isTransitioning = false;
                this.isWaiting = true;
                this.waitStartTime = now;
            }
            
            requestAnimationFrame(this.animate);
        } 
        // Handle waiting period at each view
        else if (this.isWaiting) {
            const waitElapsed = now - this.waitStartTime;
            
            if (waitElapsed >= this.pauseBetweenViews) {
                this.isWaiting = false;
                
                // Move to the next view index
                this.currentViewIndex = (this.currentViewIndex + 1) % this.views.length;
                
                // If we've completed a full loop of all views
                if (this.currentViewIndex === 0) {
                    // Either reshuffle for variety and continue or end the tour
                    if (Math.random() < 0.5) {  // 50% chance to reshuffle and continue
                        this.shuffleViews();
                        this.startNextTransition();
                    } else {
                        // End the tour and return to original position
                        this.initiateReturn();
                    }
                } else {
                    // Continue to the next view
                    this.startNextTransition();
                }
            } else {
                requestAnimationFrame(this.animate);
            }
        }
        // Handle return to original position
        else if (this.isReturning) {
            const returnElapsed = now - this.returnStartTime;
            const returnProgress = Math.min(returnElapsed / this.returnDuration, 1.0);
            
            // Use cubic ease-out for quick start, smooth finish
            const easedProgress = 1 - Math.pow(1 - returnProgress, 3);
            
            // Get position along safe return path
            if (this.interpolationPath.length >= 2) {
                const newPosition = this.getPositionAlongPath(easedProgress);
                if (newPosition) {
                    this.camera.position.copy(newPosition);
                }
            } else {
                // Fallback - direct interpolation
                this.camera.position.lerpVectors(
                    this.startPosition,
                    this.originalCameraPosition,
                    easedProgress
                );
                
                // Safety check - ensure camera never goes inside globe
                this.ensureSafeDistance(this.camera.position);
            }
            
            // Interpolate target (look-at point) back to original
            this.controls.target.lerpVectors(
                this.startTarget,
                this.originalControlsTarget,
                easedProgress
            );
            
            // Interpolate up vector back to original
            this.camera.up.lerpVectors(
                this.startUp,
                this.originalCameraUp,
                easedProgress
            );
            
            // Apply blur effects if available
            if (this.bokehPass) {
                // Use the same eased progress for blur effects as we do for the camera
                // in the return animation too
                
                // Interpolate aperture back to original
                this.bokehPass.uniforms["aperture"].value = THREE.MathUtils.lerp(
                    this.startAperture,
                    this.originalAperture,
                    easedProgress
                );
                
                // Interpolate maxblur back to original
                this.bokehPass.uniforms["maxblur"].value = THREE.MathUtils.lerp(
                    this.startMaxBlur,
                    this.originalMaxBlur,
                    easedProgress
                );
            }
            
            // Update controls to ensure camera movement is applied
            this.controls.update();
            
            // Check if return is complete
            if (returnProgress >= 1.0) {
                this.isReturning = false;
                
                // Make sure we're exactly at the original position
                this.camera.position.copy(this.originalCameraPosition);
                this.controls.target.copy(this.originalControlsTarget);
                this.camera.up.copy(this.originalCameraUp);
                
                if (this.bokehPass) {
                    this.bokehPass.uniforms["aperture"].value = this.originalAperture;
                    this.bokehPass.uniforms["maxblur"].value = this.originalMaxBlur;
                }
                
                this.controls.update();
            } else {
                requestAnimationFrame(this.animate);
            }
        }
    }
    
    // Initialize with a set of predefined views
    loadViews(viewsArray) {
        this.views = [];
        
        viewsArray.forEach(view => {
            this.addView(view);
        });
    }
    // Get default views
    getDefaultViews() {
        // A collection of aesthetically pleasing views
        const defaultViews = [
            // Front view slightly elevated
            {
                "position": {
                  "x": "-1.827",
                  "y": "0.972",
                  "z": "-0.034"
                },
                "target": {
                  "x": "0.235",
                  "y": "0.414",
                  "z": "0.231"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.1300",
                "maxblur": "0.1350"
              },
            // Left side angled view
            {
                "position": {
                  "x": "-1.456",
                  "y": "-2.445",
                  "z": "-2.021"
                },
                "target": {
                  "x": "-0.007",
                  "y": "0.030",
                  "z": "-0.023"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0500",
                "maxblur": "0.1350"
              } ,
            // Top-down view slightly angled
            {
                "position": {
                  "x": "-0.255",
                  "y": "0.108",
                  "z": "-2.044"
                },
                "target": {
                  "x": "0.036",
                  "y": "0.465",
                  "z": "-0.616"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.1300",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "-1.017",
                  "y": "0.023",
                  "z": "1.895"
                },
                "target": {
                  "x": "-0.654",
                  "y": "-0.045",
                  "z": "-0.890"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0300",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "1.475",
                  "y": "-1.117",
                  "z": "1.421"
                },
                "target": {
                  "x": "0.177",
                  "y": "-0.065",
                  "z": "0.107"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0400",
                "maxblur": "0.0550"
              },
              {
                "position": {
                  "x": "-1.066",
                  "y": "1.359",
                  "z": "1.537"
                },
                "target": {
                  "x": "-0.626",
                  "y": "0.213",
                  "z": "0.462"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0500",
                "maxblur": "0.2350"
              },
              {
                "position": {
                  "x": "0.289",
                  "y": "1.063",
                  "z": "-1.974"
                },
                "target": {
                  "x": "0.094",
                  "y": "0.567",
                  "z": "-0.572"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0800",
                "maxblur": "0.1350"
              },
              {
                "position": {
                  "x": "-0.972",
                  "y": "0.699",
                  "z": "-1.619"
                },
                "target": {
                  "x": "0.094",
                  "y": "0.567",
                  "z": "-0.572"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.100",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "0.843",
                  "y": "-1.396",
                  "z": "0.100"
                },
                "target": {
                  "x": "0.586",
                  "y": "0.081",
                  "z": "0.041"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.2500",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "-1.318",
                  "y": "0.640",
                  "z": "0.348"
                },
                "target": {
                  "x": "0.869",
                  "y": "0.868",
                  "z": "-0.482"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.200",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "-0.333",
                  "y": "-0.900",
                  "z": "-1.262"
                },
                "target": {
                  "x": "-0.112",
                  "y": "0.398",
                  "z": "-0.543"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.2300",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "2.293",
                  "y": "-0.125",
                  "z": "0.382"
                },
                "target": {
                  "x": "0.102",
                  "y": "0.009",
                  "z": "-0.112"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0001",
                "maxblur": "0.0050"
              },
              {
                "position": {
                  "x": "0.578",
                  "y": "0.943",
                  "z": "1.609"
                },
                "target": {
                  "x": "0.019",
                  "y": "0.005",
                  "z": "-0.094"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.005",
                "maxblur": "0.0150"
              },
              {
                "position": {
                  "x": "1.162",
                  "y": "0.494",
                  "z": "1.421"
                },
                "target": {
                  "x": "-0.094",
                  "y": "0.565",
                  "z": "-0.145"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0800",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "0.192",
                  "y": "-0.057",
                  "z": "1.810"
                },
                "target": {
                  "x": "0.421",
                  "y": "0.064",
                  "z": "-0.069"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.1000",
                "maxblur": "0.0550"
              },
              {
                "position": {
                  "x": "-1.056",
                  "y": "0.881",
                  "z": "0.262"
                },
                "target": {
                  "x": "0.361",
                  "y": "0.807",
                  "z": "-0.225"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.150",
                "maxblur": "0.1850"
              },
              { // madagscar
                "position": {
                  "x": "-0.961",
                  "y": "-0.940",
                  "z": "0.994"
                },
                "target": {
                  "x": "-0.279",
                  "y": "0.410",
                  "z": "0.303"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.1800",
                "maxblur": "0.0550"
              },
              {
                "position": {
                  "x": "-1.616",
                  "y": "-1.820",
                  "z": "0.503"
                },
                "target": {
                  "x": "-0.109",
                  "y": "-0.035",
                  "z": "0.104"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0300",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "-0.756",
                  "y": "3.307",
                  "z": "-0.613"
                },
                "target": {
                  "x": "0.015",
                  "y": "-0.024",
                  "z": "0.009"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0300",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "-0.943",
                  "y": "-0.035",
                  "z": "3.657"
                },
                "target": {
                  "x": "0.053",
                  "y": "0.129",
                  "z": "0.027"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0030",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "-1.128",
                  "y": "-0.366",
                  "z": "-1.372"
                },
                "target": {
                  "x": "0.081",
                  "y": "-0.814",
                  "z": "-0.208"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.300",
                "maxblur": "0.0650"
              },
              {
                "position": {
                  "x": "1.748",
                  "y": "-2.984",
                  "z": "0.911"
                },
                "target": {
                  "x": "0.341",
                  "y": "-0.693",
                  "z": "0.253"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0003",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "0.497",
                  "y": "-2.193",
                  "z": "-0.717"
                },
                "target": {
                  "x": "0.497",
                  "y": "0.115",
                  "z": "-0.717"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0300",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "-0.002",
                  "y": "0.677",
                  "z": "-1.093"
                },
                "target": {
                  "x": "-1.064",
                  "y": "0.722",
                  "z": "1.537"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.1300",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "1.100",
                  "y": "0.605",
                  "z": "0.417"
                },
                "target": {
                  "x": "-1.258",
                  "y": "0.615",
                  "z": "0.881"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0300",
                "maxblur": "0.0350"
              },
              {
                "position": {
                  "x": "-1.507",
                  "y": "0.305",
                  "z": "-1.305"
                },
                "target": {
                  "x": "-0.772",
                  "y": "0.257",
                  "z": "0.002"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.1300",
                "maxblur": "0.0450"
              },
              {
                "position": {
                  "x": "0.544",
                  "y": "0.169",
                  "z": "-1.924"
                },
                "target": {
                  "x": "-0.780",
                  "y": "0.145",
                  "z": "-0.005"
                },
                "up": {
                  "x": "0.000",
                  "y": "1.000",
                  "z": "0.000"
                },
                "aperture": "0.0800",
                "maxblur": "0.0350"
              }
        ];

        return defaultViews;
    }
    
    // Reset to default views, optionally keeping existing views
    resetToDefaultViews(keepExisting = false) {
        const defaultViews = this.getDefaultViews();
        
        if (keepExisting) {
            // Add defaults to existing views
            defaultViews.forEach(view => {
                this.addView(view);
            });
        } else {
            // Replace with defaults
            this.views = defaultViews;
        }
    }
    
    // Check if auto-rotation is active
    isAutoRotating() {
        return this.isActive;
    }
}

export default GlobeAutorotate;