import * as THREE from 'three';

class RibbonAnimator {
    constructor(ribbon, params = {}) {
        this.ribbon = ribbon;
        
        // Physics parameters
        this.params = {
            springStiffness: params.springStiffness || 8.0,   // Reduced for gentler snapping
            damping: params.damping || 0.9,                   // Increased for less bouncing
            dragFactor: params.dragFactor || 2.0              // Reduced for gentler movement
        };
        
        this.points = [];
        this.originalPositions = [];
        this.lastCameraPosition = new THREE.Vector3();
        this.isFirstFrame = true;
    }

    initializePhysics() {
        if (!this.ribbon?.geometry?.attributes?.position) return;
        
        const positions = this.ribbon.geometry.attributes.position;
        
        for (let i = 0; i < positions.count; i += 2) {
            const pos1 = new THREE.Vector3(
                positions.getX(i),
                positions.getY(i),
                positions.getZ(i)
            );
            const pos2 = new THREE.Vector3(
                positions.getX(i + 1),
                positions.getY(i + 1),
                positions.getZ(i + 1)
            );
            
            const center = pos1.clone().add(pos2).multiplyScalar(0.5);
            this.originalPositions.push(center.clone());
            
            this.points.push({
                position: center.clone(),
                oldPosition: center.clone(),
                width: pos2.clone().sub(pos1).length()
            });
        }
    }

    update(deltaTime) {
        if (this.points.length === 0 || !this.ribbon?.geometry) return;

        const camera = this.ribbon.parent.parent.children.find(child => child instanceof THREE.PerspectiveCamera);
        if (!camera) return;

        if (this.isFirstFrame) {
            this.lastCameraPosition.copy(camera.position);
            this.isFirstFrame = false;
            return;
        }

        // Simple camera movement calculation
        const cameraMovement = new THREE.Vector3().subVectors(camera.position, this.lastCameraPosition);
        
        // Update each point
        for (let i = 1; i < this.points.length - 1; i++) {
            const point = this.points[i];
            const originalPos = this.originalPositions[i];
            
            // Calculate ribbon length (distance between endpoints)
            const ribbonLength = this.originalPositions[this.points.length - 1].distanceTo(this.originalPositions[0]);
            const globeRadius = this.originalPositions[0].length(); // Use first point's distance from center as globe radius
            const normalizedLength = ribbonLength / (2 * globeRadius); // Scale based on globe size
            
            // Maximum displacement in middle, zero at endpoints, scaled by ribbon length
            const t = i / (this.points.length - 1);
            const flexibility = Math.sin(t * Math.PI) * normalizedLength;

            // Move point based on camera movement
            const displacement = cameraMovement.clone().multiplyScalar(flexibility * this.params.dragFactor);
            
            // Spring force pulls back to original position
            const toOriginal = originalPos.clone().sub(point.position);
            const springForce = toOriginal.multiplyScalar(this.params.springStiffness * deltaTime);

            // Update position
            const temp = point.position.clone();
            point.position
                .add(point.position.clone().sub(point.oldPosition).multiplyScalar(this.params.damping))
                .add(displacement)
                .add(springForce);

            // Keep point at correct distance from globe center
            const radius = originalPos.length();
            point.position.normalize().multiplyScalar(radius);
            
            point.oldPosition.copy(temp);
        }

        this.updateRibbonGeometry();
        this.lastCameraPosition.copy(camera.position);
    }

    updateRibbonGeometry() {
        const positions = this.ribbon.geometry.attributes.position;
        const normals = this.ribbon.geometry.attributes.normal;
        
        for (let i = 0; i < this.points.length; i++) {
            const point = this.points[i];
            const vertIndex = i * 2;
            
            if (vertIndex + 1 < positions.count) {
                // Get ribbon direction from points
                const tangent = new THREE.Vector3();
                if (i < this.points.length - 1) {
                    tangent.subVectors(this.points[i + 1].position, point.position);
                } else if (i > 0) {
                    tangent.subVectors(point.position, this.points[i - 1].position);
                }

                // Calculate width vector perpendicular to both tangent and radius
                const normal = point.position.clone().normalize();
                const widthVector = new THREE.Vector3()
                    .crossVectors(tangent.normalize(), normal)
                    .normalize()
                    .multiplyScalar(point.width * 0.5);

                // Set positions
                const pos1 = point.position.clone().sub(widthVector);
                const pos2 = point.position.clone().add(widthVector);
                positions.setXYZ(vertIndex, pos1.x, pos1.y, pos1.z);
                positions.setXYZ(vertIndex + 1, pos2.x, pos2.y, pos2.z);

                // Set normals
                normals.setXYZ(vertIndex, normal.x, normal.y, normal.z);
                normals.setXYZ(vertIndex + 1, normal.x, normal.y, normal.z);
            }
        }

        positions.needsUpdate = true;
        normals.needsUpdate = true;
        this.ribbon.geometry.computeVertexNormals();
    }
}

export { RibbonAnimator };