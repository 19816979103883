import { CardSpreadModal } from './modal-system.js';
import { cardDatabase } from './card-database.js';
import { Checkpoint } from './utils.js';

class TradingCardSystem {
    // Constructor update
    constructor() {
        this.cardDatabase = cardDatabase;
        this.todaysItems = new Map();
        this.currentDate = null;
        this.onCardUsed = null; // New callback for fun card usage
        this.onCardDiscard = null;

        // Configuration for country quiz discount
        this.quizDiscountConfig = {
            percentPerCorrectAnswer: 1,     // 1% off per correct quiz answer
            roundToNearest: 5,              // Round discount to nearest 5%
            maxDiscountPercent: 50          // Maximum 50% discount
        };
    }

    // Complete initialize function
    initialize(globeState, cities, countryLookup = {}, onCardUsed = null, onCardDiscard = null) {

        this.globeData = globeState; // Keep reference as globeData for compatibility
        this.cities = cities;
        this.countryLookup = countryLookup;
        this.onCardUsed = onCardUsed;
        this.onCardDiscard = onCardDiscard;

        // Get current state 
        const state = globeState.getState();

        // Initialize tracking data with defaults if needed
        if (!state.inventory) {
            state.inventory = [];
            globeState.setState({ ...state, inventory: [] });
        }
        if (!state.bannedCountries) {
            state.bannedCountries = [];
            globeState.setState({ ...state, bannedCountries: [] });
        }
        if (!state.boughtItems) {
            state.boughtItems = {};
            globeState.setState({ ...state, boughtItems: {} });
        }

        // Initialize bannedCountries set for faster lookups
        this.bannedCountries = new Set(state.bannedCountries);

     //   this.updateCardMetadata();
     setTimeout(() => {
        this.updateCardMetadata();
        // No need to call generateDailyItems here as it's now on-demand
    }, 0);

    }

    // Complete updateCardMetadata function
    updateCardMetadata() {
        // Update all cards in the database with price ranges and sell locations
        Object.entries(this.cardDatabase).forEach(([cardId, card]) => {
            // Calculate price range
            const validPrices = Object.values(card.salesPrice).filter(price => price !== undefined && price !== null);
            const minPrice = Math.min(...validPrices);
            const maxPrice = Math.max(...validPrices);

            // Format price range nicely
            let priceRange;
            if (!validPrices.length) {
                priceRange = 'Not for sale';
            } else if (minPrice === maxPrice) {
                priceRange = `$${minPrice.toLocaleString()}`;
            } else {
                priceRange = `$${minPrice.toLocaleString()} - $${maxPrice.toLocaleString()}`;
            }

            // Create human-readable sellsIn attribute
            const destinations = [];

            // Add country destinations
            if (card.specificCountries?.destination?.length) {
                const countries = card.specificCountries.destination
                    .map(code => this.countryLookup[code]?.name || code)
                    .filter(name => name);
                if (countries.length > 0) {
                    destinations.push(`${countries.join(', ')}`);
                }
            }

            // Add city destinations
            if (card.specificCities?.destination?.length) {
                const cities = card.specificCities.destination.filter(city => city);
                if (cities.length > 0) {
                    destinations.push(`${cities.join(', ')}`);
                }
            }

            // If no specific destinations, show all countries with valid prices
            if (destinations.length === 0) {
                const validCountries = Object.entries(card.salesPrice)
                    .filter(([code, price]) => code !== 'default' && price > 0)
                    .map(([code]) => this.countryLookup[code]?.name || code)
                    .filter(name => name);

                if (validCountries.length > 0) {
                    destinations.push(`${validCountries.join(', ')}`);
                }
            }

            // Generate confiscation and tax location lists
            const confiscateLocations = [];
            const taxLocations = [];

            // Process border restrictions
            if (card.borderRestrictions) {
                // If specific countries are defined for the restrictions
                if (card.borderRestrictions.countries?.length) {
                    const restrictedCountries = card.borderRestrictions.countries
                        .map(code => this.countryLookup[code]?.name || code)
                        .filter(name => name);

                    if (card.borderRestrictions.penalty === 'confiscate') {
                        confiscateLocations.push(...restrictedCountries);
                    } else if (card.borderRestrictions.penalty === 'tax') {
                        taxLocations.push(...restrictedCountries);
                    }
                }
            }

            // Format fun score if present
            let funScore;
            if (card.fun !== undefined && card.fun !== null) {
                const plusSigns = Math.ceil(card.fun / 5);
                funScore = '☺'.repeat(plusSigns);
            }

            const truncateWithSuffix = (str, maxLength) => {
                if (!str || str.length <= maxLength) return str;
                const lastDelimiter = str.lastIndexOf(', ', maxLength - 4);
                if (lastDelimiter === -1) return str.slice(0, maxLength - 4) + ", ++";
                return str.slice(0, lastDelimiter) + ", ++";
            };

            // Update the card in the database with proper defaults for empty lists
            this.cardDatabase[cardId] = {
                ...card,
                priceRange,
                funScore,
                sellsIn: destinations.length ? truncateWithSuffix(destinations.join('; '), 150) : undefined,
                confiscateIn: confiscateLocations.length ? truncateWithSuffix(confiscateLocations.join(', '), 150) : undefined,
                taxIn: taxLocations.length ? truncateWithSuffix(taxLocations.join(', '), 150) : undefined,
                image:  card.image? card.image : "https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/" + cardId + ".jpg/Screen",
            };
        });
    }

    // Complete openInventoryModal function
    openInventoryModal() {
        const modal = new CardSpreadModal({
            showRemoveButton: true
        });

        // Get current inventory from state
        const state = this.globeData.getState();
        const inventory = state.inventory || [];

        let cards = inventory.map(cardId => {
            const cardData = this.cardDatabase[cardId];
            const canUse = cardData.fun !== undefined && cardData.fun > 0;

            return {
                ...cardData,
                id: cardId,
                buttonText: "✗ Discard",
                type: cardData.type || 'trading-card',
                onButtonClick: () => {
                    this.removeCard(cardId);
                    this.onCardDiscard(cardData);
                    modal.close();
                },
                onUse: canUse ? () => {
                    if (this.onCardUsed) {
                        this.onCardUsed(cardId, cardData);
                        this.removeCard(cardId);
                        modal.close();
                    }
                } : undefined
            };
        });

        // Fill remaining slots with empty cards
        while (cards.length < 3) {
            cards.push({
                type: 'empty-slot',
                title: 'Empty Pocket',
                message: 'This inventory slot is empty'
            });
        }

        modal.show(cards);
    }
    static instance = null;

    static getInstance() {
        if (!TradingCardSystem.instance) {
            TradingCardSystem.instance = new TradingCardSystem();
        }
        return TradingCardSystem.instance;
    }

    generateDailyItems(cityName = null) {
        const today = new Date().toDateString();

        // Reset all items if date has changed
        if (this.currentDate !== today) {
            this.currentDate = today;
            this.todaysItems.clear();
        }

        // If no specific city requested, or we've already processed this city today, return early
        if (!cityName || this.todaysItems.has(cityName)) {
            return;
        }

        // Get city data for the requested city
        const cityData = this.cities[cityName];
        if (!cityData) {
            return; // City doesn't exist in our data
        }
        
        // Get current state for checking bought items
        const state = this.globeData.getState();
        const boughtItems = state.boughtItems || {};

        let seedIncrement = 0;
        const cityItems = [];

        // Calculate city size bonus for spawn rate
        const populationBonus = Math.min(0.3, Math.log10(cityData.population) / 20);
        const capitalBonus = cityData.capital === 'primary' ? 0.2 : 0;

        // Calculate seed for today's random generation
        const dateSeed = new Date().getFullYear() * 10000 +
            (new Date().getMonth() + 1) * 100 +
            new Date().getDate();

        // Process cards only for this specific city
        Object.entries(this.cardDatabase).forEach(([cardId, card]) => {
            if (this.canAcquireCardInLocation(card, cityData)) {
                const seedValue = dateSeed + cityName.length + seedIncrement++;
                const randomValue = this.seededRandom(seedValue);

                // Adjust spawn rate based on city characteristics
                const adjustedRarity = card.rarity * (1 + populationBonus + capitalBonus);

                if (randomValue < adjustedRarity) {
                    const boughtKey = `${cityName}-${cardId}-${today}`;
                    if (!boughtItems[boughtKey]) {
                        // Create a copy of cityData with flag set to apply discount
                        const cityDataWithDiscount = {
                            ...cityData,
                            _applyQuizDiscount: true  // Set flag to apply quiz discount
                        };

                        // Adjust prices based on city characteristics and quiz discounts
                        const priceModifier = this.calculatePriceModifier(cityDataWithDiscount);
                        const adjustedCard = {
                            id: cardId,
                            ...card,
                            buyPrice: Math.round(card.buyPrice * priceModifier)
                        };

                        cityItems.push(adjustedCard);
                    }
                }
            }
        });

        // Only store city items if there are any
        if (cityItems.length > 0) {
            this.todaysItems.set(cityName, cityItems);
        }
    }

    // Calculate discount based on quiz performance in a country
    calculateCountryQuizDiscount(countryCode) {
        // Get current state

        const state = this.globeData.getState();
        const results = state.results || {};

        // Count total correct answers in this country
        let totalCorrectAnswers = 0;

        // Loop through all quiz results
        Object.entries(results).forEach(([key, result]) => {
            // Quiz results are stored as cityName-quizName
            const cityName = key.split('-')[0];

            // Find the city's country
            if (this.cities[cityName] && this.cities[cityName].iso2 === countryCode) {
                // Add correct answers to the total for this country
                totalCorrectAnswers += result.correctAnswers || 0;
            }
        });

        // Calculate raw discount percentage (1% per correct answer)
        let discountPercent = totalCorrectAnswers * this.quizDiscountConfig.percentPerCorrectAnswer;

        // Add any quiz bonus from modifiers
        discountPercent += this.globeData.getModifier(countryCode, 'quiz_bonus') || 0;;
        discountPercent += this.globeData.getModifier(this.globeData.currentCity, 'quiz_bonus') || 0;

        // Cap at maximum discount
        discountPercent = Math.min(discountPercent, this.quizDiscountConfig.maxDiscountPercent);

        return discountPercent;
    }

    // New helper method for price calculations
    calculatePriceModifier(cityData) {
        let modifier = 1.0;

        // Capital cities have higher prices
        if (cityData.capital === 'primary') {
            modifier *= 1.2; // 20% increase in capitals
        }

        // Population affects prices (larger cities slightly higher prices)
        const populationMod = Math.min(0.15, Math.log10(cityData.population) / 30);
        modifier *= (1 + populationMod);
        
        // Apply quiz discount if available - only happens in getAvailableCards
    /*
        if (cityData.iso2 && cityData._applyQuizDiscount) {
            const discountPercent = this.calculateCountryQuizDiscount(cityData.iso2);
            if (discountPercent > 0) {
                modifier *= (1 - discountPercent / 100);
            }
        }
    */

        return modifier;
    }

    seededRandom(seed) {
        // A good minimal PRNG based on mulberry32
        seed = Math.floor(seed);
        let t = seed += 0x6D2B79F5;
        t = Math.imul(t ^ t >>> 15, t | 1);
        t ^= t + Math.imul(t ^ t >>> 7, t | 61);
        return ((t ^ t >>> 14) >>> 0) / 4294967296;
    }
    
    generateCityCards() {
        const today = new Date();
        const dateSeed = today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate();

        // Clear previous day's available items
        this.globeData.availableItems = {};

        Object.entries(this.cities).forEach(([cityName, cityData]) => {
            let seedIncrement = 0;
            const cityItemIds = [];

            Object.entries(this.cardDatabase).forEach(([cardId, card]) => {
                const canAcquireHere = this.canAcquireCardInLocation(card, cityData);
                const seedValue = dateSeed + cityName.length + seedIncrement++;
                const randomValue = this.seededRandom(seedValue);

                if (canAcquireHere && randomValue < card.rarity) {
                    cityItemIds.push(cardId);
                }
            });

            if (cityItemIds.length > 0) {
                this.globeData.availableItems[cityName] = cityItemIds;
            }
        });
    }

    canAcquireCardInLocation(card, cityData) {
        // Check city-specific restrictions
        if (card.specificCities?.origin) {
            if (!card.specificCities.origin.includes(cityData.city)) {
                return false;
            }
        }

        // Check country-specific restrictions
        if (card.specificCountries?.origin) {
            if (!card.specificCountries.origin.includes(cityData.iso2)) {
                return false;
            }
        }

        return true;
    }

    getAvailableCards(cityName) {
        // Make sure we have today's items
        this.generateDailyItems(cityName);

        // Get current state
        const state = this.globeData.getState();
        const inventory = state.inventory || [];
        const boughtItems = state.boughtItems || {};

        // Return items for the city (filtering out any that were bought)
        const items = this.todaysItems.get(cityName) || [];
        const isInventoryFull = inventory.length >= 3;

        // Get the city data to access country code
        const cityData = this.cities[cityName];
        if (!cityData) {
            return [];
        }

        // Calculate quiz discount percentage for this country
        const countryCode = cityData.iso2;
        const discountPercent = this.calculateCountryQuizDiscount(countryCode);

        return items.filter(item => {
            const boughtKey = `${cityName}-${item.id}-${this.currentDate}`;
            return !boughtItems[boughtKey];
        }).map(item => {
            // Clone the original item
            const enhancedItem = {
                ...item,
                disabled: isInventoryFull,
                buttonTooltip: isInventoryFull ? 'Inventory Full' : undefined
            };

            // Apply discount if available
            if (discountPercent > 0) {
                // Store original price for strikethrough display
                enhancedItem.originalBuyPrice = item.buyPrice;

                // Calculate discounted price 
                enhancedItem.buyPrice = Math.round(item.buyPrice * (1 - discountPercent / 100));

                // Add discount information
                enhancedItem.discountPercent = discountPercent;
                enhancedItem.discountApplied = true;
                enhancedItem.discountMessage = `${discountPercent}% Local Discount`;
                enhancedItem.feeType = '';

            }
            // Apply discount if available
            if (discountPercent < 0) {
                // Store original price for strikethrough display
                enhancedItem.originalBuyPrice = item.buyPrice;

                // Calculate discounted price 
                enhancedItem.buyPrice = Math.round(item.buyPrice * (1 - discountPercent / 100));

                // Add discount information
                enhancedItem.discountPercent = discountPercent;
                enhancedItem.discountApplied = true;
                enhancedItem.discountMessage = `+${Math.abs(discountPercent)}% Local Fees`;
                enhancedItem.feeType = '-fees';
            }

            return enhancedItem;
        });
    }

    removeAvailableCard(cityName, cardId, soldPrice) {
        // Get current state
        const state = this.globeData.getState();
        const boughtItems = state.boughtItems || {};

        // Mark item as bought
        const boughtKey = `${cityName}-${cardId}-${this.currentDate}`;
        const updatedBoughtItems = { ...boughtItems, [boughtKey]: soldPrice };

        // Update state
        this.globeData.setState({ ...state, boughtItems: updatedBoughtItems });

        // Also remove from today's items
        const cityItems = this.todaysItems.get(cityName);
        if (cityItems) {
            const updatedItems = cityItems.filter(item => item.id !== cardId);
            if (updatedItems.length > 0) {
                this.todaysItems.set(cityName, updatedItems);
            } else {
                this.todaysItems.delete(cityName);
            }
        }
    }

    initializeInventoryButton(containerId) {
        this.inventoryButtonContainer = document.getElementById(containerId);
        if (!this.inventoryButtonContainer) {
            console.error('Inventory button container not found');
            return;
        }

        // Add the CSS for the shake animation
        const style = document.createElement('style');
        style.textContent = `
            @keyframes shakeSlot {
                0% { 
                    transform: rotate(var(--base-rotation)) scale(1);
                }
                10% { 
                    transform: rotate(calc(var(--base-rotation) - 4deg)) scale(1.1);
                }
                20% { 
                    transform: rotate(calc(var(--base-rotation) + 4deg)) scale(1.1);
                }
                30% { 
                    transform: rotate(calc(var(--base-rotation) - 4deg)) scale(1.1);
                }
                40% { 
                    transform: rotate(calc(var(--base-rotation) + 4deg)) scale(1.1);
                }
                50% { 
                    transform: rotate(calc(var(--base-rotation) - 4deg)) scale(1.1);
                }
                60% { 
                    transform: rotate(calc(var(--base-rotation) + 4deg)) scale(1.1);
                }
                70% { 
                    transform: rotate(calc(var(--base-rotation) - 3deg)) scale(1.05);
                }
                80% { 
                    transform: rotate(calc(var(--base-rotation) + 2deg)) scale(1.05);
                }
                90% { 
                    transform: rotate(calc(var(--base-rotation) - 1deg)) scale(1.02);
                }
                100% { 
                    transform: rotate(var(--final-rotation)) scale(1);
                }
            }
            
            .inventory-slot {
                --base-rotation: 0deg;
                --final-rotation: 0deg;
                transition: background-color 0.3s ease;
                transform: rotate(var(--base-rotation));
            }
            
            .inventory-slot:nth-child(1) { --base-rotation: -5deg; }
            .inventory-slot:nth-child(2) { --base-rotation: 2deg; }
            .inventory-slot:nth-child(3) { --base-rotation: 3.5deg; }
            
            .shake-animation {
                animation: shakeSlot 0.5s cubic-bezier(0.36, 0, 0.66, -0.56);
                animation-fill-mode: forwards;
            }
        `;
        document.head.appendChild(style);

        // Create the inventory button wrapper
        this.inventoryButton = document.createElement('div');
        this.inventoryButton.className = 'inventory-button';
        this.inventoryButton.innerHTML = `
            <div class="inventory-slots">
                <div class="inventory-slot empty"></div>
                <div class="inventory-slot empty"></div>
                <div class="inventory-slot empty"></div>
            </div>
        `;

        // Add click handler
        this.inventoryButton.addEventListener('click', () => {
            const state = this.globeData.getState();
            const inventory = state.inventory || [];

            if (inventory.length > 0) {
                this.openInventoryModal();
            }
        });

        this.inventoryButtonContainer.appendChild(this.inventoryButton);

        // Initial update
        this.updateInventoryButton();
    }

    updateInventoryButton() {
        if (!this.inventoryButton) return;

        // Get current state
        const state = this.globeData.getState();
        const inventory = state.inventory || [];

        const slots = this.inventoryButton.querySelectorAll('.inventory-slot');

        slots.forEach((slot, index) => {
            const wasFilled = slot.classList.contains('filled');
            const shouldBeFilled = index < inventory.length;

            if (wasFilled !== shouldBeFilled) {
                // Remove existing animation class if present
                slot.classList.remove('shake-animation');

                // Generate random final rotation (-5 to 5 degrees)
                const finalRotation = (Math.random() * 10 - 5).toFixed(2);
                slot.style.setProperty('--final-rotation', `${finalRotation}deg`);

                // Reset the animation by forcing a reflow
                void slot.offsetWidth;

                // Add the shake animation class
                slot.classList.add('shake-animation');
            }

            // Update the slot state
            slot.className = `inventory-slot ${shouldBeFilled ? 'filled' : 'empty'} ${slot.classList.contains('shake-animation') ? 'shake-animation' : ''}`;
        });
    }

    // Modify the existing addCard and removeCard methods to update the button
    addCard(cardId) {
        // Get current state
        const state = this.globeData.getState();
        const inventory = state.inventory || [];

        if (inventory.length >= 3) {
            return false; // Return false to indicate failure
        }

        if (!this.cardDatabase[cardId]) {
            return false;
        }

        // Update inventory
        const newInventory = [...inventory, cardId];
        this.globeData.setState({ ...state, inventory: newInventory });

        this.updateInventoryButton();
        return true;
    }

    removeCard(cardId) {
        // Get current state
        const state = this.globeData.getState();
        const inventory = state.inventory || [];
        const index = inventory.indexOf(cardId);
        if (index !== -1) {

            // Create new inventory array with the item removed
            const newInventory = [...inventory.slice(0, index), ...inventory.slice(index + 1)];
            this.globeData.setState({ ...state, inventory: newInventory });

            this.updateInventoryButton();
            return true;
        }
        return false;
    }

    addRandom() {
        // Get current state
        const state = this.globeData.getState();
        const inventory = state.inventory || [];
    
        // Check if inventory is already full
        if (inventory.length >= 3) {
            return false; // Return false to indicate failure
        }
    
        // Filter the card database to get only trade goods cards
        const tradeGoodsCards = Object.keys(this.cardDatabase).filter(cardId => 
            this.cardDatabase[cardId].card_type === 'trade_goods'
        );
    
        // If no trade goods cards exist, return false
        if (tradeGoodsCards.length === 0) {
            return false;
        }
    
        // Select a random trade goods card
        const randomIndex = Math.floor(Math.random() * tradeGoodsCards.length);
        const randomCardId = tradeGoodsCards[randomIndex];
    
        // Update inventory
        const newInventory = [...inventory, randomCardId];
        this.globeData.setState({ ...state, inventory: newInventory });
    
        this.updateInventoryButton();
        return true;
    }
    
    removeRandom() {
        // Get current state
        const state = this.globeData.getState();
        const inventory = state.inventory || [];
    
        // Check if inventory is empty
        if (inventory.length === 0) {
            return false; // Return false to indicate failure
        }
    
        // Select a random card from inventory
        const randomIndex = Math.floor(Math.random() * inventory.length);
        
        // Create new inventory array with the random item removed
        const newInventory = [...inventory.slice(0, randomIndex), ...inventory.slice(randomIndex + 1)];
        this.globeData.setState({ ...state, inventory: newInventory });
    
        this.updateInventoryButton();
        return true;
    }
    
    getPlayableCards(cityName, countryCode) {
        // Get current inventory from state
        const state = this.globeData.getState();
        const inventory = state.inventory || [];

        return inventory.map(cardId => {
            const cardData = this.cardDatabase[cardId];
            return { id: cardId, ...cardData };
        }).filter(card => {
            // Check if card can be used in this country
            const countryAccepted = card.acceptedIn.includes(countryCode);

            // First check if the country is in acceptedIn before checking price
            // Get sale price for this country (use default if not specifically set)
            let salePrice = card.salesPrice[countryCode];
            if (salePrice === undefined) {
                salePrice = card.salesPrice.default ?? 0;
            }
            const canBeSoldHere = salePrice > 0;

            // Check country-specific destination restrictions
            const countryDestinationAccepted = !card.specificCountries?.destination ||
                card.specificCountries.destination.includes(countryCode);

            // Check city-specific destination restrictions
            const cityAccepted = !card.specificCities?.destination ||
                card.specificCities.destination.includes(cityName);

            return canBeSoldHere && countryAccepted && countryDestinationAccepted && cityAccepted;
        });
    }

    addBan(iso2) {
        this.bannedCountries.add(iso2);
        const newBannedCountries = Array.from(this.bannedCountries);
        const state = this.globeData.getState();
        this.globeData.setState({ ...state, bannedCountries: newBannedCountries });
    }

    hasValidVisa(countryCode) {
        // Get current inventory from state
        const state = this.globeData.getState();
        const inventory = state.inventory || [];

        return inventory.some(cardId => {
            const cardData = this.cardDatabase[cardId];
            return cardData.card_type === 'travel_documents' &&
                cardData.acceptedIn.includes(countryCode) &&
                cardData.borderRestrictions.permitEntry;
        });
    }

    isBanned(iso2){
        if (this.bannedCountries.has(iso2)) {
            return true;
        }
        return false;
    }
    // Update the checkBorderCrossing method in TradingCardSystem
    checkBorderCrossing(fromCountry, toCountry) {
        if (this.bannedCountries.has(toCountry)) {
            return {
                allowed: false,
                reason: 'PERSONA_NON_GRATA',
                message: 'You are not allowed to enter this country'
            };
        }

        const failures = [];
        let totalTax = 0;
        const taxedItems = [];

        // Get current state
        const state = this.globeData.getState();
        const inventory = state.inventory || [];

        // Check each card in inventory
        inventory.forEach(cardId => {
            const cardData = this.cardDatabase[cardId];
            const restrictions = cardData.borderRestrictions;

            if (!restrictions) { return; }

            // Check if border restrictions apply to this country
            const appliesHere = !restrictions.countries ||
                restrictions.countries.includes(toCountry);

            if (appliesHere && Math.random() < restrictions.probability) {
                if (restrictions.penalty === 'tax') {
                    totalTax += restrictions.penaltyAmount;
                    taxedItems.push({
                        name: cardData.title,
                        amount: restrictions.penaltyAmount
                    });
                } else {
                    failures.push({
                        cardId,
                        penalty: restrictions.penalty,
                        amount: restrictions.penaltyAmount
                    });
                }
            }
        });

        if (failures.length > 0) {
            // Clear inventory on failure by setting it to an empty array
            this.globeData.setState({ ...state, inventory: [] });

            // If any cards had 'ban' penalty, add country to banned list
            if (failures.some(f => f.penalty === 'ban')) {
                this.bannedCountries.add(toCountry);

                // Update bannedCountries in state
                const newBannedCountries = Array.from(this.bannedCountries);
                this.globeData.setState({ ...state, bannedCountries: newBannedCountries });
            }
        }

        return {
            allowed: failures.length === 0,
            failures,
            visa: this.hasValidVisa(toCountry),
            tax: totalTax,
            taxedItems: taxedItems
        };
    }



}

export default TradingCardSystem;