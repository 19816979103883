// water-coverage-analyzer.js

/**
 * Analyzes water coverage in roughness textures for optimal label placement
 * Only considers pixels darker than a specified threshold as water
 */
export class WaterCoverageAnalyzer {
    /**
     * @param {HTMLImageElement} roughnessImage - The roughness texture image
     * @param {number} labelTextureWidth - Width of the label texture (e.g., 8192)
     * @param {number} labelTextureHeight - Height of the label texture (e.g., 4096)
     * @param {number} threshold - Brightness threshold (0-255), pixels darker than this are considered water (default: 68, equivalent to #444444)
     */
    constructor(roughnessImage, labelTextureWidth, labelTextureHeight, threshold = 68) {
        // Create canvas once
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');
        this.canvas.width = roughnessImage.width;
        this.canvas.height = roughnessImage.height;
        
        // Draw image and get data once
        this.ctx.drawImage(roughnessImage, 0, 0);
        this.imageData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
        
        // Store scaling factors
        this.scaleX = this.canvas.width / labelTextureWidth;
        this.scaleY = this.canvas.height / labelTextureHeight;
        this.labelTextureWidth = labelTextureWidth;
        
        // Set darkness threshold (pixels darker than this value are considered water)
        // #444444 in RGB is (68, 68, 68), so we use 68 as the default threshold
        this.threshold = threshold;

        // Pre-bind the method so we don't create new function instances on each call
        this.sortPositionsByWaterCoverage = this.sortPositionsByWaterCoverage.bind(this);
    }

    /**
     * Calculate water coverage for a given region by only considering pixels darker than threshold
     * @private
     */
    calculateBlackness(x, y, w, h) {
        const shiftX = this.labelTextureWidth / 2;
        x = (x + this.labelTextureWidth/2) % this.labelTextureWidth;

        // Scale and wrap coordinates
        const startX = Math.max(0, Math.floor(((x + shiftX) % this.labelTextureWidth) * this.scaleX));
        const startY = Math.max(0, Math.floor(y * this.scaleY));
        const endX = Math.min(this.canvas.width, Math.ceil(((x + w + shiftX) % this.labelTextureWidth) * this.scaleX));
        const endY = Math.min(this.canvas.height, Math.ceil((y + h) * this.scaleY));

        let totalWaterPixels = 0;
        let pixelCount = 0;

        // Sample in smaller steps for more accurate measurement
        const step = 1;  // Adjust this value to change sampling density
        for (let py = startY; py < endY; py += step) {
            for (let px = startX; px < endX; px += step) {
                const wrappedPx = px % this.canvas.width;
                const i = (py * this.canvas.width + wrappedPx) * 4;
                const value = this.imageData.data[i];
                
                // Only count pixel as water if it's darker than the threshold
                if (value < this.threshold) {
                    // Pixels darker than threshold contribute to score based on how dark they are
                    totalWaterPixels += (this.threshold - value);
                }
                
                pixelCount++;
            }
        }

        // Return normalized water coverage score (0-255 range)
        return pixelCount > 0 ? totalWaterPixels / pixelCount : 0;
    }

    /**
     * Set a new threshold for water detection
     * @param {number} threshold - New brightness threshold (0-255)
     */
    setThreshold(threshold) {
        this.threshold = threshold;
    }

    /**
     * Convert hex color to brightness value (0-255)
     * @param {string} hexColor - Hex color code (e.g. "#444444")
     * @returns {number} Brightness value
     */
    static hexToBrightness(hexColor) {
        // Remove # if present
        const hex = hexColor.replace('#', '');
        // Convert to RGB
        const r = parseInt(hex.substring(0, 2), 16);
        // For grayscale, just return any channel
        return r;
    }

    /**
     * Sort positions by their water coverage
     * @param {Array<{dx: number, dy: number}>} positions - Array of position offsets to test
     * @param {number} labelX - Base X coordinate
     * @param {number} labelY - Base Y coordinate
     * @param {number} width - Width of the region to test
     * @param {number} height - Height of the region to test
     * @returns {Array<{dx: number, dy: number, blackness: number}>} Sorted positions with blackness scores
     */
    sortPositionsByWaterCoverage(positions, labelX, labelY, width, height) {
        return positions
            .map(pos => ({
                ...pos,
                blackness: this.calculateBlackness(
                    labelX + pos.dx,
                    labelY + pos.dy,
                    width,
                    height
                )
            }))
            .sort((a, b) => b.blackness - a.blackness);
    }

    /**
     * Clean up resources
     */
    dispose() {
        this.canvas.width = 0;
        this.canvas.height = 0;
        this.canvas = null;
        this.ctx = null;
        this.imageData = null;
    }
}