// sparkleSystem.js

import * as THREE from 'three';

/**
 * Configuration options for the sparkle system
 * @typedef {Object} SparkleSystemOptions
 * @property {number} [particleCount=20] - Number of particles to create
 * @property {number} [minScale=0.01] - Minimum particle scale
 * @property {number} [maxScale=0.02] - Maximum particle scale
 * @property {number} [upwardBias=0.5] - How much to bias particles upward (0-1)
 * @property {number} [speedNormal=0.005] - Speed in normal direction
 * @property {number} [speedTangent=0.003] - Speed in tangent directions
 * @property {number} [rotationSpeed=0.1] - Maximum rotation speed
 * @property {number} [fadeRate=0.97] - Rate at which particles fade (0-1)
 * @property {number} [scaleGrowth=1.005] - Rate at which particles grow
 * @property {Object} [colors] - Particle gradient colors
 */

/**
 * Creates and manages a sparkle particle effect system
 */
export class SparkleSystem {
    constructor() {
        this.activeSystems = new Set();
        this.textureCache = null;
    }

    /**
     * Creates a texture for the sparkle particles
     * @private
     * @returns {THREE.Texture}
     */
    createSparkleTexture() {
        if (this.textureCache) return this.textureCache;

        const canvas = document.createElement('canvas');
        canvas.width = 64;
        canvas.height = 64;
        const ctx = canvas.getContext('2d');
        
        // Create radial gradient for the main glow
        const gradient = ctx.createRadialGradient(32, 32, 0, 32, 32, 32);
        gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
        gradient.addColorStop(0.1, 'rgba(255, 240, 220, 0.8)');
        gradient.addColorStop(0.4, 'rgba(255, 220, 180, 0.2)');
        gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
        
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, 64, 64);
        
        // Add cross-shaped bright center
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.8)';
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(24, 32);
        ctx.lineTo(40, 32);
        ctx.moveTo(32, 24);
        ctx.lineTo(32, 40);
        ctx.stroke();

        const texture = new THREE.CanvasTexture(canvas);
        texture.needsUpdate = true;
        
        this.textureCache = texture;
        return texture;
    }

    /**
     * Creates a particle system at the specified position
     * @param {THREE.Vector3} position - World position for the particles
     * @param {SparkleSystemOptions} [options] - Configuration options
     * @returns {THREE.Group} The particle system
     */
    createParticleSystem(position, options = {}) {
        const {
            particleCount = 20,
            minScale = 0.01,
            maxScale = 0.02,
            upwardBias = 0.5,
            speedNormal = 0.005,
            speedTangent = 0.003,
            rotationSpeed = 0.1,
            fadeRate = 0.97,
            scaleGrowth = 1.005
        } = options;

        const sparkleTexture = this.createSparkleTexture();
        const particles = new THREE.Group();
        
        // Create basis vectors for particle distribution
        const normal = position.clone().normalize();
        const tangent = new THREE.Vector3(1, 0, 0);
        if (Math.abs(normal.y) !== 1) {
            tangent.crossVectors(normal, new THREE.Vector3(0, 1, 0)).normalize();
        }
        const bitangent = new THREE.Vector3().crossVectors(normal, tangent);
        
        for(let i = 0; i < particleCount; i++) {
            const sprite = new THREE.Sprite(
                new THREE.SpriteMaterial({
                    map: sparkleTexture,
                    transparent: true,
                    blending: THREE.AdditiveBlending,
                    depthWrite: false
                })
            );
            
            const scale = minScale + Math.random() * (maxScale - minScale);
            sprite.scale.set(scale, scale, scale);
            sprite.rotation.z = Math.random() * Math.PI * 2;
            sprite.position.copy(position);
            
            const angle = Math.random() * Math.PI * 2;
            const upAmount = Math.random() * upwardBias + (1 - upwardBias);
            
            const velocity = new THREE.Vector3()
                .addScaledVector(normal, speedNormal * upAmount)
                .addScaledVector(tangent, speedTangent * Math.cos(angle))
                .addScaledVector(bitangent, speedTangent * Math.sin(angle));
            
            sprite.userData.velocity = velocity;
            sprite.userData.rotationSpeed = (Math.random() - 0.5) * rotationSpeed;
            sprite.userData.life = 1.0;
            
            particles.add(sprite);
        }
        
        particles.userData.update = (delta) => {
            let allDead = true;
            particles.children.forEach(sprite => {
                sprite.position.add(sprite.userData.velocity);
                
                sprite.userData.velocity.add(new THREE.Vector3(
                    (Math.random() - 0.5) * 0.0001,
                    (Math.random() - 0.5) * 0.0001,
                    (Math.random() - 0.5) * 0.0001
                ));
                
                sprite.userData.velocity.multiplyScalar(0.97);
                sprite.rotation.z += sprite.userData.rotationSpeed;
                sprite.userData.life *= fadeRate;
                sprite.material.opacity = sprite.userData.life;
                sprite.scale.multiplyScalar(scaleGrowth);
                
                if (sprite.userData.life > 0.01) allDead = false;
            });
            
            return allDead;
        };
        
        return particles;
    }

    /**
     * Add a particle system to a scene at a specific position
     * @param {THREE.Vector3} position - World position for the effect
     * @param {THREE.Object3D} parent - Parent object to add particles to
     * @param {SparkleSystemOptions} [options] - Configuration options
     */
    addEffect(position, parent, options = {}) {
        const particles = this.createParticleSystem(position, options);
        parent.add(particles);
        this.activeSystems.add(particles);
    }

    /**
     * Update all active particle systems
     * @param {number} deltaTime - Time since last frame
     */
    update(deltaTime) {
        for (const system of this.activeSystems) {
            const isDead = system.userData.update(deltaTime);
            if (isDead) {
                system.parent?.remove(system);
                system.children.forEach(sprite => {
                    sprite.material.dispose();
                });
                this.activeSystems.delete(system);
            }
        }
    }

    /**
     * Clean up all particle systems
     */
    dispose() {
        for (const system of this.activeSystems) {
            system.parent?.remove(system);
            system.children.forEach(sprite => {
                sprite.material.dispose();
            });
        }
        this.activeSystems.clear();
        if (this.textureCache) {
            this.textureCache.dispose();
            this.textureCache = null;
        }
    }
}

// Example usage:
/*
import { SparkleSystem } from './sparkleSystem';

const sparkleSystem = new SparkleSystem();

// Add effect when needed:
sparkleSystem.addEffect(
    pinPosition, 
    globeContainer,
    {
        particleCount: 30,
        minScale: 0.01,
        maxScale: 0.02,
        upwardBias: 0.7
    }
);

// In animation loop:
sparkleSystem.update(deltaTime);

// Cleanup when done:
sparkleSystem.dispose();
*/