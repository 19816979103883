// status-manager.js

export class StatusManager {
    constructor() {
        this.statusElement = document.getElementById('status-update');
        this.timeout = null;
    }

    show(text, duration = 1500) {
        // Clear any existing timeout
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        // Set the text and show the status
        this.statusElement.textContent = text;
        this.statusElement.style.opacity = '1';

        // Set a timeout to start fading out the status
        this.timeout = setTimeout(() => {
            this.statusElement.style.opacity = '0';

            // Remove the text after the fade-out transition (0.5s)
            setTimeout(() => {
                this.statusElement.textContent = '';
            }, 500);
        }, duration);
    }
}

// Create and export a singleton instance
export const statusManager = new StatusManager();