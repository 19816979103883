export class FactCheck {
  constructor(options = {}) {
    this.statements = options.statements || [];
    this.onComplete = options.onComplete || (() => {});
    this.onClose = options.onClose || (() => {});
    this.onExplanation = options.onExplanation || (() => {});
    this.onStart = options.onStart || (() => {});

    this.title = options.title || "";
    this.stats = options.stats;
    this.thanks = options.thanks;

    // Add new start page metadata options
    this.startPageMeta = options.startPageMeta || null;

    this.currentIndex = 0;
    this.progress = [];
    this.times = [];
    this.amounts = [];

    this.gameStartTime = Date.now();
    this.questionStartTime = Date.now();
    
    this.flipCount = 0;
    this.currentSide = 'front';
    this.isAnimating = false;

    this.suddenDeath = options.suddenDeath !== undefined ? options.suddenDeath : true;

    if (options.results) {
      const { progress, totalTime, questionTimes, questionAmounts } = options.results;
      this.progress = progress || [];
      this.times = questionTimes || [];
      this.amounts = questionAmounts || [];
      this.gameStartTime = Date.now() - totalTime;
    }

    this.initialize();
  }

  initialize() {
    this.modalContainer = document.createElement('div');
    this.modalContainer.className = 'fc-modal-container';
    
    this.modalOuterWrapper = document.createElement('div');
    this.modalOuterWrapper.className = 'fc-modal-outer-wrapper';

    this.modalWrapper = document.createElement('div');
    this.modalWrapper.className = 'fc-modal-wrapper';

    this.modalWrapper.setAttribute('data-flip-count', '0');
    
    this.modalFront = this.createModal();
    this.modalBack = this.createModal();
    
    this.modalFront.classList.add('fc-modal-front');
    this.modalBack.classList.add('fc-modal-back');
    
    this.modalWrapper.appendChild(this.modalFront);
    this.modalWrapper.appendChild(this.modalBack);

    this.closeButton = document.createElement('button');
    this.closeButton.className = 'fc-close-button';
    this.closeButton.innerHTML = '×';
    this.closeButton.onclick = () => this.close();
    
    this.modalContainer.appendChild(this.closeButton);
    this.modalOuterWrapper.appendChild(this.modalWrapper);
    this.modalContainer.appendChild(this.modalOuterWrapper);

    document.body.appendChild(this.modalContainer);

    this.modalWrapper.addEventListener('transitionend', (e) => {
      if (e.propertyName === 'transform' || e.propertyName === 'opacity') {
        this.isAnimating = false;
        const buttons = document.querySelectorAll('.fc-button');
        buttons.forEach(button => button.disabled = false);
      }
    });

    // If we have progress from results, go straight to game over
    if (this.progress.length > 0) {
      this.gameOver(true);
    } 
    // If we have start page metadata, show the start page first
    else if (this.startPageMeta) {
      this.renderStartPage(this.modalFront);
      this.modalContainer.addEventListener('click', this.handleStartPageClick.bind(this));
    }
    // Otherwise proceed with original behavior
    else {
      this.renderQuestion(this.modalFront);
      this.modalContainer.addEventListener('click', this.handleClick.bind(this));
    }
  }

  renderStartPage(modalElement) {
    const content = modalElement.querySelector('.fc-content');
    modalElement.querySelector('.fc-progress-bar').style.display = 'none';
    
    content.innerHTML = `
      <div class="fc-start-page">
        <span><h2 class="fc-start-title">${this.startPageMeta.title || this.title}</h2>
        <h3 class="fc-start-category">${this.startPageMeta.category || this.category}</h3></span>
        ${this.startPageMeta.description ? `<p class="fc-start-description">${this.startPageMeta.description}</p>` : ''}
        
        <span>${this.startPageMeta.info ? `<p class="fc-start-info">${this.startPageMeta.info}</p>` : ''}
        <button class="fc-button fc-start-button">
          ${this.startPageMeta.buttonText || 'Start Quiz'}
        </button>
        </span>
      </div>
    `;
  }

  handleStartPageClick(event) {
    const elements = document.elementsFromPoint(event.clientX, event.clientY);
    const button = elements.find(el => el.classList.contains('fc-start-button'));
    
    if (button) {
      event.preventDefault();
      
      // Remove start page click handler
      this.modalContainer.removeEventListener('click', this.handleStartPageClick.bind(this));
      
      // Add regular game click handler
      this.modalContainer.addEventListener('click', this.handleClick.bind(this));
      
      // Flip to first question
      this.isAnimating = true;
      this.currentSide = 'back';
      this.flipCount++;
      
      const rotation = this.flipCount * -180;
      this.modalWrapper.style.transform = `rotateY(${rotation}deg)`;
      this.modalWrapper.setAttribute('data-flip-count', this.flipCount % 2);
      
      // Render first question on the back card
      this.renderQuestion(this.modalBack);
      
      // Reset game start time
      this.gameStartTime = Date.now();
      this.questionStartTime = Date.now();

      this.onStart({
        
      });      
    }
  }

  handleClick(event) {
    const elements = document.elementsFromPoint(event.clientX, event.clientY);
    const button = elements.find(el => el.classList.contains('fc-button'));
  
    if (button) {
      event.preventDefault();
      if (button.classList.contains('fc-true')) {
        this.handleAnswer(true);
      } else if (button.classList.contains('fc-false')) {
        this.handleAnswer(false);
      }
    }
  }

  renderQuestion(modalElement) {
    const currentQuestion = this.statements[this.currentIndex];
    const content = modalElement.querySelector('.fc-content');

    modalElement.querySelector('.fc-progress-bar').style.display = 'none';
    
    const formattedQuestion = currentQuestion.question.replace(
        /\*\*(.*?)\*\*/g, 
        '<strong>$1</strong>'
    );
    
    content.innerHTML = `
    <div class="fc-question-counter">Question ${this.currentIndex + 1} of ${this.statements.length}</div>
        <div class="fc-question-reward" id="fc-question-reward-item"><span class="fc-reward-amount" id="fc-reward-item">$${currentQuestion.reward}</span></div>

    <div class="fc-statement"><span>${formattedQuestion}</span></div>
    <div class="fc-buttons">
      <button class="fc-button fc-true"><span class="fc-checkmark">&#x2713;</span> True</button>
      <button class="fc-button fc-false">&#x274C; False</button>
    </div>
  `;
  }


  renderCelebration(isQuestionWithPoints) {
    if (this.isCelebrating) return;
    this.isCelebrating = true;
    
    const modalElement = this.currentSide === 'front' ? this.modalFront : this.modalBack;
    
    const stamp = document.createElement('div');
    stamp.className = 'confirmed-stamp';
    
    stamp.innerHTML = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <!-- Outer circle with distressed effect -->
  <circle cx="50" cy="50" r="46" fill="none" stroke="#2E8B57" stroke-width="2.5" opacity="0.9"/>
  <circle cx="50" cy="50" r="30" fill="none" stroke="#2E8B57" stroke-width="1.5"/>
  <circle cx="50" cy="50" r="28" fill="none" stroke="#2E8B57" stroke-width="1" opacity="0.8"/>
  
  <!-- Define two separate paths for top and bottom text -->
  <defs>
    <!-- Top arc path - semi-circle for top text -->
    <path id="topPath" 
          d="M 15,52 A 35,35 0 0,1 85,50"
          fill="none"/>
          
    <!-- Bottom arc path - semi-circle for bottom text -->
    <path id="bottomPath" 
          d="M 85,50 A 35.03,35 0 0,1 15,48"
          fill="none"/>
  </defs>
  
  <!-- Background rectangle for center text - extended and rounded -->
  <rect x="0" y="41" width="100" height="18" fill="#2E8B57" rx="3" ry="3"/>
  
  <!-- Top text -->
  <text style="font-size: 10px; font-family: 'Arial Black', Arial, sans-serif; font-weight: 900; letter-spacing: 1px; fill: #2E8B57;">
    <textPath href="#topPath" startOffset="50%" text-anchor="middle">
      TRAILMARKS
    </textPath>
  </text>
  
  <!-- Bottom text -->
  <text style="font-size: 12px; font-family: 'Arial Black', Arial, sans-serif; font-weight: 900; letter-spacing: 2px; fill: #2E8B57;">
    <textPath href="#bottomPath" startOffset="50%" text-anchor="middle">
      VERIFIED
    </textPath>
  </text>
  
  <!-- Center text - adjusted vertical position -->
  <text x="50" y="55" 
        style="font-size: 14px; font-family: 'Arial Black', Arial, sans-serif; font-weight: 900; letter-spacing: 2px; fill: white; text-anchor: middle;">
    VERIFIED
  </text>
</svg>
    `;
    
    // Random positioning
    const randomOffsetX = (Math.random() - 0.5) * 100;
    const randomOffsetY = (Math.random() - 0.5) * 100;
    const randomRotation = (Math.random() - 0.5) * 90;
    
    stamp.style.left = `calc(50% + ${randomOffsetX}px)`;
    stamp.style.top = `calc(50% + ${randomOffsetY}px)`;
    modalElement.style.setProperty('--stamp-rotation', `${randomRotation}deg`);
    
    modalElement.appendChild(stamp);

    setTimeout(() => {
      this.isCelebrating = false;
    }, 300);
  }



  // Add this helper method to FactCheck class:
cleanupStamp() {
  const stamp = document.querySelector('.confirmed-stamp');
  if (stamp && stamp.parentNode) {
    stamp.parentNode.removeChild(stamp);
  }
}

renderFeedback(modalElement, question) {
  const content = modalElement.querySelector('.fc-content');
  
  content.innerHTML = `
    <div class="fc-feedback fc-feedback-wrong">
      <div class="fc-feedback-icon">✗</div>
      <!-- div class="fc-feedback-title">Incorrect</div -->
      ${question.explanation ? 
        `<div class="fc-feedback-explanation">${question.explanation.replace(
        /\*\*(.*?)\*\*/g, 
        '<strong>$1</strong>')}</div>` : 
        ''}
      <button class="fc-button fc-continue-button">Continue</button>
    </div>
  `;
}


// 3. Modify the handleAnswer method to better manage animation states
handleAnswer(answer) {
  if (this.isAnimating || this.isCelebrating) return;
  
  const buttons = document.querySelectorAll('.fc-button');
  buttons.forEach(button => button.disabled = true);
  
  const currentQuestion = this.statements[this.currentIndex];
  const isCorrect = String(answer).toLowerCase() === String(currentQuestion.correct).toLowerCase();

  this.recordTime();
  this.recordAmount(isCorrect ? currentQuestion.reward : 0);
  
  this.onExplanation({
    question: currentQuestion.question,
    userAnswer: answer,
    isCorrect,
    comment: currentQuestion.comment,
    reward: currentQuestion.reward
  });
  
  this.progress.push(isCorrect);
  this.updateProgressBar();
  
  const isLastQuestion = this.currentIndex === this.statements.length - 1;
  const shouldEndGame = (this.suddenDeath && !isCorrect) || isLastQuestion;

  if (isCorrect) {
    this.isAnimating = true;
    this.renderCelebration(currentQuestion.points);
    
    document.querySelectorAll('.fc-question-reward').forEach(element => {
      element.classList.add('win');
    });
    
    // Use requestAnimationFrame for smoother transitions
    requestAnimationFrame(() => {
      setTimeout(() => {
        if (shouldEndGame) {
          this.gameOver();
          this.cleanupStamp(); // Add this line
        } else {
          this.proceedToNextQuestion();
        }
        this.isAnimating = false;
      }, 600);
    });

  } else {
    // Show feedback card and wait for user
    this.isAnimating = true;
    this.currentSide = this.currentSide === 'front' ? 'back' : 'front';
    const feedbackCard = this.currentSide === 'front' ? this.modalFront : this.modalBack;
    
    this.flipCount++;
    const rotation = this.flipCount * -180;
    
    this.modalWrapper.style.transform = `rotateY(${rotation}deg)`;
    this.modalWrapper.setAttribute('data-flip-count', this.flipCount % 2);
    
    // Add shake animation
    const outerWrapper = this.modalOuterWrapper;
    outerWrapper.classList.add('shake');
    setTimeout(() => {
      outerWrapper.classList.remove('shake');
    }, 500);

    // Render feedback with continue button
    this.renderFeedback(feedbackCard, currentQuestion);
    
    // Set up continue button listener
    const continueButton = feedbackCard.querySelector('.fc-continue-button');
    const handleContinue = () => {
      if (shouldEndGame) {
        this.gameOver();
      } else {
        this.proceedToNextQuestion();
      }
      continueButton.removeEventListener('click', handleContinue);
    };
    continueButton.addEventListener('click', handleContinue);
  }
}

proceedToNextQuestion() {
  this.isAnimating = true;
  this.currentIndex++;
  
  this.currentSide = this.currentSide === 'front' ? 'back' : 'front';
  const nextCard = this.currentSide === 'front' ? this.modalFront : this.modalBack;
  
  this.flipCount++;
  const rotation = this.flipCount * -180;
  this.modalWrapper.style.transform = `rotateY(${rotation}deg)`;
  this.modalWrapper.setAttribute('data-flip-count', this.flipCount % 2);

  // Add back the wobbly flip animation
  const outerWrapper = this.modalOuterWrapper;
  outerWrapper.classList.add('wobble-flip');
  setTimeout(() => {
      outerWrapper.classList.remove('wobble-flip');
      this.cleanupStamp(); // Add this line

  }, 600);


  this.renderQuestion(nextCard);
}

  createModal() {
    const modal = document.createElement('div');
    modal.className = 'fc-modal';
    
    const progressBar = document.createElement('div');
    progressBar.className = 'fc-progress-bar';
    progressBar.style.display = 'none';
    
    const content = document.createElement('div');
    content.className = 'fc-content';
    
    modal.appendChild(content);
    modal.appendChild(progressBar);
    
    return modal;
  }

// In the FactCheck class, update the updateProgressBar method:

updateProgressBar() {
  [this.modalFront, this.modalBack].forEach(modal => {
    const progressBar = modal.querySelector('.fc-progress-bar');
    progressBar.innerHTML = '';
    const maxTime = Math.max(...this.times) || 0;
    
    for (let i = 0; i < this.statements.length; i++) {
      const indicator = document.createElement('div');
      indicator.className = 'fc-progress-indicator';
      
      if (i < this.progress.length) {
        // Create wrapper for indicator and time bar
        const wrapper = document.createElement('div');
        wrapper.className = 'fc-indicator-wrapper';
        
        // Time visualization bar
        const timeBar = document.createElement('div');
        timeBar.className = 'fc-time-bar';
        const timeHeight = (this.times[i] / maxTime) * 100;
        timeBar.style.height = `${timeHeight}%`;
        
        // Main indicator dot
        const dot = document.createElement('div');
        dot.className = 'fc-dot';
        
        // Apply correct/incorrect classes to dot
        if (this.progress[i] === true) {
          dot.classList.add('fc-user-correct');
        } else {
          dot.classList.add('fc-user-incorrect');
        }
        
        // Tooltip with time info
        const tooltip = document.createElement('div');
        tooltip.className = 'fc-tooltip';
        tooltip.textContent = `${Math.floor(this.times[i] / 1000)}s`;
        
        // Assembly
        wrapper.appendChild(timeBar);
        wrapper.appendChild(dot);
        wrapper.appendChild(tooltip);
        indicator.appendChild(wrapper);
      } else {
        // Future questions get empty dots
        const dot = document.createElement('div');
        dot.className = 'fc-dot fc-future';
        indicator.appendChild(dot);
      }
      
      progressBar.appendChild(indicator);
    }
  });
}

  recordTime() {
    const timeSpent = Date.now() - this.questionStartTime;
    this.times.push(timeSpent);
    this.questionStartTime = Date.now();
  }
 
  recordAmount(amount) {
    this.amounts.push(amount);
  }

  gameOver(displayOnly) {
    this.isAnimating = true;
    const result = {
      correctAnswers: this.progress.filter(p => p === true).length,
      questionTimes: this.times,
      questionAmounts: this.amounts,
      statements: this.statements.length,
      progress: this.progress
    };
    
    if (!displayOnly) {
      this.onComplete(result);
    }

    // Force one final flip if we're on the front
    // This ensures we're always in a consistent state for the summary cards
    if (this.currentSide === 'front') {
      this.flipCount++;
      this.currentSide = 'back';
    }

    // Define histogram dots function - for question times

    const maxTime = Math.max(...this.times.filter(t => Number.isFinite(t)))/1000 || 1; // fallback to 1 to avoid division by zero

    const getHistogramDots = (timeMs, total) => {

    //  console.log(timeMs, total, maxTime);
      const proportionalDots = Math.ceil((timeMs / total) * 14);
      const maxPossibleDots = Math.round(timeMs);
      const dotCount = Math.min(proportionalDots, maxPossibleDots);
      const dots = '·'.repeat(dotCount);
      const spaces = ' '.repeat(14 - dotCount);
      return dots + spaces;
    };

    // Handle the regular game over card flip first
    const nextCard = this.currentSide === 'front' ? this.modalBack : this.modalFront;
    const content = nextCard.querySelector('.fc-content');
  
    // Create container for both cards
    const gameOverContainer = document.createElement('div');
    gameOverContainer.className = 'fc-game-over-container';
    
    // Add game-over class to modal wrapper for positioning
    this.modalWrapper.classList.add('game-over');
    
    // Regular card content
    content.innerHTML = `
      <div class="fc-receipt">
        <div class="fc-receipt-header">
          <h2>FACT CHECK</h2>
            ${ this.suddenDeath ? '<div class="fc-receipt-sudden-death">Sudden Death Rules</div>' : ''}
          <div class="fc-receipt-id">${this.title}</div>

        </div>
  
        <div class="fc-receipt-score">
          <div class="fc-score-num">${result.correctAnswers}/${this.statements.length}</div>
          <div class="fc-score-label">CORRECT ANSWERS</div>
        </div>
  
        <div class="fc-receipt-timeline">
          ${this.progress.map((correct, i) => `
            <div class="fc-timeline-row">
              <span class="fc-q-num">#${String(i + 1).padStart(2, '0')}</span>
              <span class="fc-q-result ${correct ? 'fc-q-correct' : 'fc-q-wrong'}">
                ${correct ? '✓' : '×'}
              </span>
              <span class="fc-dots">${getHistogramDots(this.times[i]/1000, maxTime)}</span>
              <span class="fc-q-time">${this.amounts[i] ? `$${this.amounts[i]}` : ''}</span>
            </div>
          `).join('')}
        </div>
               <div class="fc-stat-row" ${result.correctAnswers == 7 ? 'style="display:none"' : ''} >
            <span class="fc-stat-label">Total reward: <span class="fun-score">
              $${ this.amounts.reduce((total, current) => total + current, 0) }
            </span></span>
        </div>

       <div class="fc-stat-row" ${result.correctAnswers == 7 ? 'style="display:none"' : ''} >
            <span class="fc-stat-label">Fun Bonus: <span class="fun-score">
              ${(Math.max(result.correctAnswers - 7, -5)) > 0 
                ? '+'.repeat(Math.max(result.correctAnswers - 7, -5))
                : '-'.repeat(Math.abs(Math.max(result.correctAnswers - 7, -5)))}
            </span></span>
        </div>
        <div class="fc-receipt-footer">
          ~ ~ ~ ~
          <br>
          ${this.thanks ? this.thanks : `THANK YOU FOR PLAYING
          <br>
          QAIZ.APP`}
        </div>
      </div>
    `;
  
    // Stats card
    const statsCard = document.createElement('div');
    statsCard.className = 'fc-stats-modal';

    this.stats.then(stats => {
      const userScore = result.correctAnswers;
      const totalQuestions = this.statements.length;
      
      // Create array of all possible scores
      const allScores = Array.from({ length: totalQuestions + 1 }, (_, i) => ({
        score: i,
        count: 0,
        percentage: '0.0%'
      }));
      
      if (! displayOnly) {
        stats.totalParticipants = stats.totalParticipants ? stats.totalParticipants+1 : 1;
      }
  
      // Fill in actual scores
      if (Array.isArray(stats.scores) && stats.scores.length > 0) {
          stats.scores.forEach(stat => {
              // Also add safety checks for the stat object and its properties
              if (stat && typeof stat.score === 'number' && typeof stat.count === 'number') {
                  allScores[stat.score] = {
                      score: stat.score,
                      count: stat.count,
                      percentage: ((stat.count / (stats.totalParticipants || 1)) * 100).toFixed(1) + '%'
                  };
              }
          });
      }

      if (! displayOnly) {
        allScores[userScore].count++;
      }

      // Find the maximum count
      const maxCount = Math.max(...allScores.map(score => score.count));

      
      statsCard.innerHTML = `
      <div class="fc-receipt">
        <div class="fc-receipt-header">
          <h2>GLOBAL SCORES</h2>
          <div class="fc-receipt-id">${stats.totalParticipants.toLocaleString()} TOTAL ATTEMPTS</div>
        </div>
                  
        <div class="fc-stats-rows">
            <div class="fc-timeline-row">
Correct</div>
          ${allScores.map(stat => `
            <div class="${stat.score === userScore ? 'fc-timeline-row-bold' : 'fc-timeline-row'}">
              <span class="fc-q-num">${String(stat.score).padStart(2, ' ')}</span>
              <span class="fc-dots">${getHistogramDots(stat.count, maxCount)}</span>
              <span class="fc-q-time">${stat.count || ''}</span>
              ${stat.score === userScore ? ' <= YOU' : ''}
            </div>
          `).join('')}
        </div>

        <div class="fc-stats-summary">
        - - - - - -

        <div class="fc-stat-row">
            <span class="fc-stat-label">Average Score</span>
            <span class="fc-stat-value">${(allScores.reduce((acc, s) => acc + (s.score * s.count), 0) / stats.totalParticipants).toFixed(1)}</span>
        </div>
        <div class="fc-stat-row">
            <span class="fc-stat-label">Your Percentile</span>
            <span class="fc-stat-value">${calculatePercentile(userScore, allScores)}%</span>
        </div>
        <div class="fc-stat-row">
            <span class="fc-stat-label">Perfect Scores</span>
            <span class="fc-stat-value">${((allScores[totalQuestions]?.count || 0) / stats.totalParticipants * 100).toFixed(1)}%</span>
        </div>
        </div>
        <div class="fc-receipt-footer">
          
        </div>
      </div>
    `;
    });

    // Move the modal wrapper into the game over container and add stats card
    gameOverContainer.appendChild(this.modalWrapper);
    gameOverContainer.appendChild(statsCard);
    
    // Replace the content in the outer wrapper
    this.modalOuterWrapper.appendChild(gameOverContainer);

// Perform the final card flip
    this.flipCount++;
    const flipClass = this.flipCount % 2 === 0 ? 'flip-even' : 'flip-odd';
    this.modalWrapper.classList.remove('flip-even', 'flip-odd');
    this.modalWrapper.style.transform = ``;
    this.modalWrapper.classList.add(flipClass);

  this.modalWrapper.setAttribute('data-flip-count', this.flipCount % 2);
  
    setTimeout(() => {
      this.isAnimating = false;
    }, 600);
  }
  
  reset() {
    this.currentIndex = 0;
    this.progress = [];
    this.times = [];
    this.gameStartTime = Date.now();
    this.questionStartTime = Date.now();
    this.flipCount = 0;
    this.currentSide = 'front';
    this.modalWrapper.setAttribute('data-flip-count', 0);
    this.updateProgressBar();
    this.renderQuestion(this.modalFront);
  }

  close() {
    this.modalContainer.classList.add('closing');
    this.modalWrapper.classList.add('closing');
    
    const result = {
      correctAnswers: this.progress.filter(p => p === true).length,
      questionTimes: this.times,
      questionAmounts: this.amounts,
      statements: this.statements.length,
      progress: this.progress  // Add this line to store the actual progress array
    };

    setTimeout(() => {
      this.modalContainer.remove();
      this.onClose(result);
    }, 300);
  }

}

function calculatePercentile(userScore, allScores) {
  // Calculate total participants from actual data
  const totalParticipants = allScores.reduce((sum, s) => sum + s.count, 0);
  
  // Count scores below user's score
  const belowUser = allScores
      .filter(s => s.score < userScore)
      .reduce((sum, s) => sum + s.count, 0);
  
  // Get count at user's score
  const atUserScore = allScores[userScore]?.count || 0;
  
  // Include half of the people with the same score as the user
  // This gives a more accurate percentile representation
  return 100 - Math.round((belowUser + (atUserScore / 2)) / totalParticipants * 100);
}


  