// Default configuration object
const DEFAULT_SCORE_CONFIG = {
  distance: {
    pointsPerKm: 1,
    minimumDistance: 100,
    classMultipliers: {
      free: 0,
      "economy": 1,
      "first": 3
    }
  },
  cities: {
    basePoints: 500,
    difficultyMultipliers: {
      EASY: 1.0,
      MEDIUM: 1.5,
      HARD: 2.0,
      EXPERT: 3.0
    }
  },
  quizzes: {
    correctAnswerPoints: 100,
    wrongAnswerPenalty: -50
  },
  
    "titles": {
      "ranges": [
        { "min": 0, "max": 5000, "title": "Coffee Runner" },
        { "min": 5001, "max": 15000, "title": "Junior Intern" },
        { "min": 15001, "max": 25000, "title": "Intern" },
        { "min": 25001, "max": 50000, "title": "Assistant" },
        { "min": 50001, "max": 75000, "title": "Junior Fact Checker" },
        { "min": 75001, "max": 100000, "title": "Fact Checker" },
        { "min": 100001, "max": 150000, "title": "Staff Writer" },
        { "min": 150001, "max": 200000, "title": "Feature Writer" },
        { "min": 200001, "max": 250000, "title": "Senior Fact Checker" },
        { "min": 250001, "max": 300000, "title": "Investigation Lead" },
        { "min": 300001, "max": 350000, "title": "Regional Editor" },
        { "min": 350001, "max": 400000, "title": "Bureau Chief" },
        { "min": 400001, "max": 450000, "title": "Travel Bureau Chief" },
        { "min": 450001, "max": 500000, "title": "Foreign Correspondent" },
        { "min": 500001, "max": 600000, "title": "Global Correspondent" },
        { "min": 600001, "max": 750000, "title": "Senior Global Correspondent" },
        { "min": 750001, "max": 1000000, "title": "Editor-in-Chief" },
        { "min": 1000001, "max": 1250000, "title": "Pathfinder" },
        { "min": 1250001, "max": 1500000, "title": "Master Adventurer" },
        { "min": 1500001, "max": 1750000, "title": "Expedition Scout" },
        { "min": 1750001, "max": 2000000, "title": "Master Explorer" },
        { "min": 2000001, "max": 2250000, "title": "Wayfarer" },
        { "min": 2250001, "max": 2500000, "title": "Pioneer" },
        { "min": 2500001, "max": 3000000, "title": "Living Legend" },
        { "min": 3000001, "max": 3500000, "title": "Mythseeker" },
        { "min": 3500001, "max": 4000000, "title": "Chronicle Master" },
        { "min": 4000001, "max": 5000000, "title": "Lorekeeper" },
        { "min": 5000001, "max": Infinity, "title": "Sage of Stories" }
      ]
    }
  
};

class GameScoreSystem {
  constructor(db, scoreConfig = DEFAULT_SCORE_CONFIG) {
    this.db = db;
    this.config = scoreConfig;
    this._citiesData = null;
  }

  setCitiesData(citiesData) {
    this._citiesData = citiesData;
  }

  async calculateScore(gameData) {

    if (!gameData) { return 0; }
    if (!this._citiesData) { return 0 }

    const scoreDetails = {
      distance: this._calculateDistanceScore(gameData.ribbons),
      cities: this._calculateCitiesScore(gameData.pins),
      quizzes: this._calculateQuizScore(gameData.results),
      profits: this._calculateProfits(gameData || []),
      achievements: this._calculateAchievements(gameData.achievements || [])
    };

    const totalScore = Object.values(scoreDetails).reduce(
      (sum, detail) => sum + (detail.points || 0),
      0
    );

    return {
      totalScore,
      details: scoreDetails,
      title: this._calculateTitle(totalScore)
    };
  }

  _calculateDistanceScore(ribbons) {
    const distancesByClass = {};
    
    Object.values(ribbons).forEach(ribbon => {
      const distance = Number(ribbon.distance) || 0;
      const validClass = (ribbon.class || 'free').toLowerCase();
      if (!distancesByClass[validClass]) {
        distancesByClass[validClass] = 0;
      }
      distancesByClass[validClass] += distance;
    });

    let totalPoints = 0;
    const breakdown = [];
    
    Object.entries(distancesByClass).forEach(([className, distance]) => {
      const multiplier = this.config.distance.classMultipliers[className] || 0;
      const points = Math.floor(distance * this.config.distance.pointsPerKm * multiplier);
      totalPoints += points;
      
      breakdown.push({
        class: className,
        distance,
        multiplier,
        points
      });
    });

    return {
      points: totalPoints,
      breakdown
    };
  }

  _calculateCitiesScore(pins) {
    const visitedCities = Object.values(pins);
    let totalPoints = 0;
    const breakdown = [];

    visitedCities.forEach(pin => {
      const cityData = this._citiesData[pin.location];
      const multiplier = this.config.cities.difficultyMultipliers[cityData.difficulty];
      const cityPoints = Math.floor(this.config.cities.basePoints * multiplier);
      totalPoints += cityPoints;
      breakdown.push({
        city: pin.location,
        points: cityPoints,
        multiplier
      });
    });

    return {
      points: totalPoints,
      breakdown
    };
  }

  _calculateQuizScore(results) {
    let correctPoints = 0;
    let wrongPoints = 0;
    const breakdown = [];

    Object.entries(results).forEach(([quizName, result]) => {
      const correct = result.correctAnswers;
      const wrong = result.progress.length - correct;

      const quizCorrectPoints = correct * this.config.quizzes.correctAnswerPoints;
      const quizWrongPoints = wrong * this.config.quizzes.wrongAnswerPenalty;

      correctPoints += quizCorrectPoints;
      wrongPoints += quizWrongPoints;

      breakdown.push({
        quiz: quizName,
        correct,
        wrong,
        correctPoints: quizCorrectPoints,
        wrongPoints: quizWrongPoints
      });
    });

    return {
      points: correctPoints + wrongPoints,
      correctPoints,
      wrongPoints,
      breakdown
    };
  }

  _calculateArtifactsScore(artifacts) {
    const points = artifacts.reduce((sum, artifact) => sum + (artifact.value || 0), 0);
    return { points };
  }

  _calculateAchievements(achievements) {
    const points = achievements.reduce((sum, achievement) => sum + (achievement.value || 0), 0);
    return { points };
  }

  _calculateProfits(gameData) {
    const points = gameData.get('trade_earn'); // - gameData.get('trade_spend');
    return { points };
  }

  _calculateTitle(score) {
    const range = this.config.titles.ranges.find(
      range => score >= range.min && score <= range.max
    );
    return range ? range.title : "Unknown";
  }

  generateReceipt(scoreData) {
    const cityCount = scoreData.details.cities.breakdown.length;
    const citiesByDifficulty = scoreData.details.cities.breakdown.reduce((acc, city) => {
      const diffLevel = Object.entries(this.config.cities.difficultyMultipliers)
        .find(([_, mult]) => mult === city.multiplier)?.[0] || 'UNKNOWN';
      acc[diffLevel] = (acc[diffLevel] || 0) + 1;
      return acc;
    }, {});

    const quizDetails = scoreData.details.quizzes;
    const totalCorrect = quizDetails.breakdown.reduce((sum, quiz) => sum + quiz.correct, 0);
    const totalQuestions = quizDetails.breakdown.reduce((sum, quiz) => sum + quiz.correct + quiz.wrong, 0);
    
    return `
      <div class="fc-receipt">
        <div class="fc-receipt-header">
          <div class="fc-receipt-id">TOTAL POINTS</div>
        </div>

        <div class="fc-receipt-score">
          <div class="fc-score-num">${scoreData.totalScore.toLocaleString()}</div>
        </div>

        <div class="fc-receipt-timeline">
          <div class="fc-timeline-row-bold">
            <span class="fc-q-title">DISTANCE</span>
          </div>

          ${scoreData.details.distance.breakdown.map(leg => `
            <div class="fc-timeline-row">
              <span class="fc-q-result">${Math.round(leg.distance).toLocaleString()}</span>
              <span class="fc-q-result">km (${leg.class})</span>
              <span class="fc-q-time">${leg.points.toLocaleString()}</span>
            </div>
          `).join('')}

          <div class="fc-timeline-row-bold">
            <span class="fc-q-title">CITIES</span>
          </div>

          ${Object.entries(citiesByDifficulty)
            .sort(([a], [b]) => 
              (this.config.cities.difficultyMultipliers[b] || 0) - 
              (this.config.cities.difficultyMultipliers[a] || 0)
            )
            .map(([diff, count]) => `
              <div class="fc-timeline-row">
                <span class="fc-q-result">${count}</span>
                <span class="fc-q-result">${diff.toLowerCase()} (×${this.config.cities.difficultyMultipliers[diff]})</span>
                <span class="fc-q-time">${(count * this.config.cities.basePoints * this.config.cities.difficultyMultipliers[diff]).toLocaleString()}</span>
              </div>
            `).join('')}

          <div class="fc-timeline-row-bold">
            <span class="fc-q-title">QUIZ</span>
          </div>

          <div class="fc-timeline-row">
            <span class="fc-q-result">${totalCorrect}</span>
            <span class="fc-q-result">correct</span>
            <span class="fc-q-time">${quizDetails.correctPoints.toLocaleString()}</span>
          </div>

          <div class="fc-timeline-row">
            <span class="fc-q-result">${totalQuestions - totalCorrect}</span>
            <span class="fc-q-result">wrong</span>
            <span class="fc-q-time">${quizDetails.wrongPoints.toLocaleString()}</span>
          </div>
<br/>
          ${scoreData.details.profits.points !== 0 ? `
          <div class="fc-timeline-row">
            <span class="fc-q-result"><b>PROFITS</b></span>
            <span class="fc-q-time">${scoreData.details.profits.points.toLocaleString()}</span>
          </div>` : ''}
<br/>
          <div class="fc-timeline-row">
            <span class="fc-q-result">ACHIEVEMENTS</span>
            <span class="fc-q-time">${scoreData.details.achievements.points.toLocaleString()}</span>
          </div>
        </div>

        <div class="fc-receipt-footer">
          ~ ~ ~ ~
          <br>
          LEVEL: ${scoreData.title.toUpperCase()}
          <br>
        </div>
      </div>
    `;
  }

  async saveHighScore(userId, username, score) {
    const scoreRef = this.db.collection('highscores');
    
    await scoreRef.doc(userId).set({
      userId,
      username,
      score: score.totalScore,
      title: score.title,
      timestamp: new Date()
    });
  }

  async getHighScores(userId, limit = 10) {
    const scoresRef = this.db.collection('highscores');
    
    const topScores = await scoresRef
      .orderBy('score', 'desc')
      .limit(limit)
      .get();

    let userScore = null;
    let aboveScore = null;
    let belowScore = null;

    if (!topScores.docs.some(doc => doc.data().userId === userId)) {
      const userScoreDoc = await scoresRef.doc(userId).get();
      if (userScoreDoc.exists) {
        userScore = userScoreDoc.data();

        const aboveScores = await scoresRef
          .orderBy('score', 'desc')
          .where('score', '>', userScore.score)
          .limit(1)
          .get();

        const belowScores = await scoresRef
          .orderBy('score', 'desc')
          .where('score', '<', userScore.score)
          .limit(1)
          .get();

        if (!aboveScores.empty) aboveScore = aboveScores.docs[0].data();
        if (!belowScores.empty) belowScore = belowScores.docs[0].data();
      }
    }

    return {
      topScores: topScores.docs.map(doc => doc.data()),
      userContext: userScore ? {
        above: aboveScore,
        user: userScore,
        below: belowScore
      } : null
    };
  }
}

export default GameScoreSystem;