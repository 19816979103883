import * as THREE from 'three';

// import { PaperAirplane } from './paper-airplane.js';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';

// Import required post-processing passes
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

// Import Stats for performance monitoring
// import Stats from 'stats.js';

// ThreeJS setup class
export class ThreeJsSetup {
    constructor() {
        // Scene setup
        this.scene = new THREE.Scene();
        this.scene.background = null;
        
        THREE.ColorManagement.enabled = true;
        
        // Camera setup with defaults
        this.camera = new THREE.PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.1, 1000);
        
        // Lighting setup
        this.setupLights();
        
        // Renderer setup
        this.setupRenderer();
        
        // Post processing setup
        this.setupPostProcessing();
        
        // Controls setup
        this.setupControls();

        this.setupKeyboardControls();

        this.setupScene();

        // Initialize Stats.js
  //      this.stats = new Stats();
  //      this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
  //      this.stats.dom.style.cssText = 'position:absolute;top:0;left:0;';
  //      document.body.appendChild(this.stats.dom);

        let airplane = null;
        if (! airplane) {
        //    airplane = createPaperAirplane(1); // 1 is your globe radius
        }

        // Window resize handling
        window.addEventListener('resize', this.handleResize.bind(this));

        this.doRender = true;
        // Add these lines in your constructor after scene setup
      //  this.clock = new THREE.Clock();
    //    this.paperAirplane = new PaperAirplane(this.scene, 1); // 1 is your globe radius

        
    }
    
    stopRender() {
        console.log('stopping rendering');

        this.doRender = isMobile ? false : true; // Just do this for mobile, it's detrimental to the experience
    }
    
    startRender() {
        console.log('restarting rendering');
        this.doRender = true;
    }

    setupScene() {

        
    }

    setupLights() {
        const globeRadius = 1;
        const shadowScale = 4;
        
        // Main directional light (sun)
        this.sunLight = new THREE.DirectionalLight(0xF0F8FF, 2.5);
        this.sunLight.position.set(-8, 3, 5);
        this.sunLight.castShadow = true;
        this.sunLight.shadow.mapSize.width = 4096;
        this.sunLight.shadow.mapSize.height = 4096;
        this.sunLight.shadow.camera.near = 0.001;
        this.sunLight.shadow.camera.far = 12;
        this.sunLight.shadow.radius = 8;
        this.sunLight.color.setHSL(0.1, 0.3, 0.95);
        this.sunLight.intensity = 3;
        this.sunLight.shadow.camera.left = -globeRadius * shadowScale;
        this.sunLight.shadow.camera.right = globeRadius * shadowScale;
        this.sunLight.shadow.camera.top = globeRadius * shadowScale;
        this.sunLight.shadow.camera.bottom = -globeRadius * shadowScale;
        this.sunLight.shadow.bias = -0.001;
        
        const targetObject = new THREE.Object3D();
        targetObject.position.set(2,0,1); // Set the position where you want the light to point

        // Set the light's target
        this.sunLight.target = targetObject;

        // Important: Add the target to the scene for it to work
        this.scene.add(targetObject);

        // Secondary rim light
        this.rimLight = new THREE.DirectionalLight(0xffffff, 2);
        this.rimLight.position.set(5, -2, -10);
        this.rimLight.castShadow = true;
        this.rimLight.color.set(0xffffFF);
        this.rimLight.intensity = 0.4;
        
        // Ambient light
        this.ambientLight = new THREE.AmbientLight(0x707070, 0.3);
        this.ambientLight.color.set(0xFFFFFF);
        this.ambientLight.intensity = 0.3;
        
        // Hemisphere light
        this.hemiLight = new THREE.HemisphereLight(0xffffff, 0x222222, 0.4);
        this.hemiLight.groundColor.set(0x001133);
        this.hemiLight.intensity = 0.5;
        
        // Add lights to the scene
     //   this.scene.add(this.hemiLight);
        
        // Attach lights to camera
        this.camera.add(this.sunLight);
      //  this.camera.add(this.ambientLight);
    

        // Add camera to scene
        this.scene.add(this.camera);
    }
    
    setupRenderer() {
        this.renderer = new THREE.WebGLRenderer({
            antialias: false,
            alpha: true,
        });
        
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.outputColorSpace = THREE.SRGBColorSpace;
        this.renderer.shadowMap.autoUpdate = true;
        this.renderer.setClearColor(0xffffff, 1);
        
        this.renderer.physicallyCorrectLights = true;
        this.renderer.toneMappingExposure = 2;
        this.renderer.toneMapping = THREE.NoToneMapping;
        
        document.body.appendChild(this.renderer.domElement);
    }
    
    setupPostProcessing() {
        // Create EffectComposer
        this.composer = new EffectComposer(this.renderer);
        
        // Render pass
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.renderPass.clearColor = new THREE.Color(0xffffff);
        this.renderPass.clearAlpha = 1;
        this.composer.addPass(this.renderPass);
        
        // Bokeh pass for depth of field
        // Make it load out of focus
        this.bokehPass = new BokehPass(this.scene, this.camera, {
            focus: 2.5,
            aperture: 0.02, // 0.009,a
            maxblur: 0.035, // 0.035
        });
        this.composer.addPass(this.bokehPass);
        
        // Output pass
        this.outputPass = new OutputPass();
        this.composer.addPass(this.outputPass);

        this.setupSadEffects();

    }
    
    setupControls() {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.25;
        this.controls.maxDistance = 4;
        this.controls.enableZoom = true;
        this.controls.enablePan = true;
        this.controls.minDistance = 1.5;
        this.controls.autoRotate = 0;
        this.controls.target.set(-0.00, -0.02, -0.06);
        this.controls.update();
    }
    
    handleResize() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.composer.setSize(window.innerWidth, window.innerHeight);
    }
    
// Add this method to your ThreeJsSetup class
setupKeyboardControls() {
    // Track current values to ensure updates don't reset them
    this.bokehControls = {
        aperture: this.bokehPass.uniforms.aperture.value,
        maxblur: this.bokehPass.uniforms.maxblur.value
    };
    
    // Add keyboard event listener
    window.addEventListener('keydown', (event) => {
        const step = 0.001; // Adjustment step size
        
        switch(event.key) {
            case 'i': // Decrease aperture
                this.bokehControls.aperture = Math.max(0.001, this.bokehControls.aperture - step);
                this.bokehPass.uniforms.aperture.value = this.bokehControls.aperture;
                console.log('Aperture decreased to:', this.bokehControls.aperture);
                break;
                
            case 'k': // Increase aperture
                this.bokehControls.aperture = this.bokehControls.aperture + step;
                this.bokehPass.uniforms.aperture.value = this.bokehControls.aperture;
                console.log('Aperture increased to:', this.bokehControls.aperture);
                break;
                
            case 'o': // Decrease maxblur
                this.bokehControls.maxblur = Math.max(0.001, this.bokehControls.maxblur - step);
                this.bokehPass.uniforms.maxblur.value = this.bokehControls.maxblur;
                console.log('Maxblur decreased to:', this.bokehControls.maxblur);
                break;
                
            case 'l': // Increase maxblur
                this.bokehControls.maxblur = this.bokehControls.maxblur + step;
                this.bokehPass.uniforms.maxblur.value = this.bokehControls.maxblur;
                console.log('Maxblur increased to:', this.bokehControls.maxblur);
                break;
        }
    });
}

// Modified updateBokehFocus method - just adding one line to preserve manual settings
updateBokehFocus(globe, ongoingFocusSearch = false, duringAuto = 0) {
    if (ongoingFocusSearch || !globe || !globe.mesh) return;
    
    const raycaster = new THREE.Raycaster();
    const direction = this.controls.target.clone().sub(this.camera.position).normalize();
    raycaster.set(this.camera.position, direction);
    const intersects = raycaster.intersectObject(globe.mesh);
    
    if (intersects.length > 0) {
        const distance = intersects[0].distance;
        
        // Set focus exactly at intersection point
        this.bokehPass.uniforms.focus.value = distance;
        
        const globeRadius = 1;
        const targetDistance = 4;
        
        // Calculate how centered the view is
        const intersectionPoint = intersects[0].point;
        const centerOffset = intersectionPoint.distanceTo(globe.mesh.position);
        const isCentered = centerOffset < 0.1;
        
        // Zoom-based aperture calculation
        const baseAperture = 0.015;
        const distanceRatio = distance / targetDistance;
        
        // Increase aperture (narrow DoF) as we zoom in
        const zoomFactor = Math.max(0.5, 2 / distanceRatio);
        
        let adjustedAperture;
        if (isCentered) {
            // When centered, scale aperture based on zoom
            adjustedAperture = baseAperture * zoomFactor;
        } else {
            // For off-center views, include both zoom and distance effects
            const distanceFactor = Math.pow(distanceRatio, 2) * 0.7;
            adjustedAperture = baseAperture * (zoomFactor + distanceFactor);
        }
        
        if (!duringAuto) {
            // Only update aperture if we're not using keyboard controls
            // This is the key change: update bokehControls when auto-focus changes the aperture
            const newAperture = THREE.MathUtils.clamp(
                adjustedAperture,
                baseAperture * 0.25,  // Minimum aperture
                baseAperture * 2      // Increased maximum aperture for stronger close-up effect
            );
            this.bokehPass.uniforms.aperture.value = newAperture;
            
            // Track this change in our bokehControls so keyboard controls are aware of it
            if (this.bokehControls) this.bokehControls.aperture = newAperture;
        }
    }
}
    

// Modify your startAnimationLoop method
startAnimationLoop(renderCallback) {
    const animate = () => {
        if (this.controls) this.controls.update();
        
        // Only render if doRender is true
        if (this.doRender) {
            if (renderCallback) {
                renderCallback();
            }
            
            // Move this inside the if(this.doRender) block
            this.composer.render(this.scene, this.camera);
        }
        
        requestAnimationFrame(animate);
    };
    
    animate();
}
    
    // Getter for the renderer domElement
    getDomElement() {
        return this.renderer.domElement;
    }
    
    // Method to animate camera transition
    transitionCameraTo(targetPosition, targetLookAt, duration = 1000) {
        const startPosition = this.camera.position.clone();
        const startTarget = this.controls.target.clone();
        const startTime = Date.now();
        
        const updateCamera = () => {
            const elapsed = Date.now() - startTime;
            const progress = Math.min(elapsed / duration, 1);
            
            // Ease function (cubic)
            const ease = t => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
            const t = ease(progress);
            
            // Interpolate position
            this.camera.position.lerpVectors(startPosition, targetPosition, t);
            
            // Interpolate controls target
            this.controls.target.lerpVectors(startTarget, targetLookAt, t);
            
            // Update controls
            this.controls.update();
            
            // Continue animation if not complete
            if (progress < 1) {
                requestAnimationFrame(updateCamera);
            }
        };
        
        updateCamera();
    }
    
    // Getter for camera position to world coordinates
    getWorldPosition(vector) {
        this.camera.getWorldPosition(vector);
        return vector;
    }


// Add these methods to your ThreeJsSetup class
setupSadEffects() {
    // Custom shader for color adjustment (desaturation and tinting)
    const colorAdjustShader = {
        uniforms: {
            "tDiffuse": { value: null },
            "saturation": { value: 1.0 },
            "brightness": { value: 1.0 },
            "contrast": { value: 1.0 },
            "tint": { value: new THREE.Color(1, 1, 1) },
            "tintStrength": { value: 0.0 }
        },
        vertexShader: `
            varying vec2 vUv;
            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `,
        fragmentShader: `
            uniform sampler2D tDiffuse;
            uniform float saturation;
            uniform float brightness;
            uniform float contrast;
            uniform vec3 tint;
            uniform float tintStrength;
            varying vec2 vUv;
            
            void main() {
                vec4 texel = texture2D(tDiffuse, vUv);
                
                // Apply brightness
                vec3 color = texel.rgb * brightness;
                
                // Apply contrast
                color = (color - 0.5) * contrast + 0.5;
                
                // Apply desaturation
                float luminance = dot(color, vec3(0.299, 0.587, 0.114));
                color = mix(vec3(luminance), color, saturation);
                
                // Apply tint
                color = mix(color, color * tint, tintStrength);
                
                gl_FragColor = vec4(color, texel.a);
            }
        `
    };
    
    // Create shader pass for color adjustment
    this.colorAdjustPass = new ShaderPass(colorAdjustShader);
    this.colorAdjustPass.enabled = false;
    
    // Bloom pass for subtle glow effect
    this.bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        0.0,    // strength 
        0.4,    // radius
        0.9     // threshold
    );
    this.bloomPass.enabled = false;
    
    // Add passes to composer after the existing passes but before the output pass
    const lastPassIndex = this.composer.passes.length - 1;
    const outputPass = this.composer.passes[lastPassIndex];
    
    // Remove output pass temporarily
    this.composer.passes.pop();
    
    // Add our new passes
    this.composer.addPass(this.colorAdjustPass);
    this.composer.addPass(this.bloomPass);
    
    // Add output pass back
    this.composer.addPass(outputPass);
    
    // Store normal scene values
    this.normalSceneValues = {
        ambientIntensity: this.ambientLight.intensity,
        hemiIntensity: this.hemiLight.intensity,
        sunIntensity: this.sunLight.intensity,
        rimIntensity: this.rimLight ? this.rimLight.intensity : 0,
        bokehAperture: this.bokehPass.uniforms.aperture.value,
        background: this.scene.background
    };
    
    // Initialize transition state
    this.isTransitioning = false;
    this.transitionProgress = 0;
    this.isDimmed = false;
}

// Method to dim the scene (make it sad and grey)
dim(duration = 2000) {
    if (this.isTransitioning || this.isDimmed) return;
    
    // Make sure sad effects are initialized
    if (!this.colorAdjustPass) {
        this.setupSadEffects();
    }
    
    // Enable shader passes
    this.colorAdjustPass.enabled = true;
    this.bloomPass.enabled = true;
    
    // Target sad values
    const sadValues = {
        // Color adjustment parameters
        saturation: 0.3,           // Reduce saturation for grey look
        brightness: 0.8,           // Slightly darker
        contrast: 0.9,             // Slightly lower contrast
        tintStrength: 0.4,         // Medium tint strength
        tint: new THREE.Color(0.7, 0.8, 1.0),  // Blue-grey tint
        
        // Reduce light intensities
        ambientIntensity: this.normalSceneValues.ambientIntensity * 0.5,
        hemiIntensity: this.normalSceneValues.hemiIntensity * 0.3,
        sunIntensity: this.normalSceneValues.sunIntensity * 0.6,
        rimIntensity: this.normalSceneValues.rimIntensity * 0.3,
        
        // Increase bokeh effect for dreamlike sad state
        bokehAperture: this.normalSceneValues.bokehAperture * 2.5,
        
        // Bloom settings
        bloomStrength: 0.4,
        bloomRadius: 0.7
    };
    
    // Start transition
    this.isTransitioning = true;
    this.transitionProgress = 0;
    const startTime = Date.now();
    
    // Store background color if it exists
    const startBackground = this.scene.background ? this.scene.background.clone() : null;
    const targetBackground = new THREE.Color(0x1a2a3f); // Dark blue-grey
    
    const updateTransition = () => {
        const elapsed = Date.now() - startTime;
        this.transitionProgress = Math.min(elapsed / duration, 1);
        
        // Ease function (cubic)
        const t = this.transitionProgress < 0.5 
            ? 4 * this.transitionProgress * this.transitionProgress * this.transitionProgress 
            : 1 - Math.pow(-2 * this.transitionProgress + 2, 3) / 2;
        
        // Interpolate color adjustment values
        this.colorAdjustPass.uniforms.saturation.value = THREE.MathUtils.lerp(
            1.0,
            sadValues.saturation,
            t
        );
        
        this.colorAdjustPass.uniforms.brightness.value = THREE.MathUtils.lerp(
            1.0,
            sadValues.brightness,
            t
        );
        
        this.colorAdjustPass.uniforms.contrast.value = THREE.MathUtils.lerp(
            1.0,
            sadValues.contrast,
            t
        );
        
        this.colorAdjustPass.uniforms.tintStrength.value = THREE.MathUtils.lerp(
            0.0,
            sadValues.tintStrength,
            t
        );
        
        // Interpolate tint color
        const currentTint = this.colorAdjustPass.uniforms.tint.value;
        currentTint.r = THREE.MathUtils.lerp(1.0, sadValues.tint.r, t);
        currentTint.g = THREE.MathUtils.lerp(1.0, sadValues.tint.g, t);
        currentTint.b = THREE.MathUtils.lerp(1.0, sadValues.tint.b, t);
        
        // Interpolate light intensities
        this.ambientLight.intensity = THREE.MathUtils.lerp(
            this.normalSceneValues.ambientIntensity,
            sadValues.ambientIntensity,
            t
        );
        
        this.hemiLight.intensity = THREE.MathUtils.lerp(
            this.normalSceneValues.hemiIntensity,
            sadValues.hemiIntensity,
            t
        );
        
        this.sunLight.intensity = THREE.MathUtils.lerp(
            this.normalSceneValues.sunIntensity,
            sadValues.sunIntensity,
            t
        );
        
        if (this.rimLight) {
            this.rimLight.intensity = THREE.MathUtils.lerp(
                this.normalSceneValues.rimIntensity,
                sadValues.rimIntensity,
                t
            );
        }
        
        // Interpolate bokeh aperture
        this.bokehPass.uniforms.aperture.value = THREE.MathUtils.lerp(
            this.normalSceneValues.bokehAperture,
            sadValues.bokehAperture,
            t
        );
        
        // Interpolate bloom values
        this.bloomPass.strength = THREE.MathUtils.lerp(0, sadValues.bloomStrength, t);
        this.bloomPass.radius = THREE.MathUtils.lerp(0, sadValues.bloomRadius, t);
        
        // Interpolate background color if it exists
        if (startBackground) {
            const currentColor = new THREE.Color();
            currentColor.lerpColors(startBackground, targetBackground, t);
            this.scene.background = currentColor;
        }
        
        // Continue animation if not complete
        if (this.transitionProgress < 1) {
            requestAnimationFrame(updateTransition);
        } else {
            this.isTransitioning = false;
            this.isDimmed = true;
        }
    };
    
    updateTransition();
}

// Method to undim the scene (return to normal)
undim(duration = 2000) {
    if (this.isTransitioning || !this.isDimmed) return;
    
    // Start transition
    this.isTransitioning = true;
    this.transitionProgress = 0;
    const startTime = Date.now();
    
    // Store current values
    const startValues = {
        saturation: this.colorAdjustPass.uniforms.saturation.value,
        brightness: this.colorAdjustPass.uniforms.brightness.value,
        contrast: this.colorAdjustPass.uniforms.contrast.value,
        tintStrength: this.colorAdjustPass.uniforms.tintStrength.value,
        tint: this.colorAdjustPass.uniforms.tint.value.clone(),
        bloomStrength: this.bloomPass.strength,
        bloomRadius: this.bloomPass.radius,
        background: this.scene.background ? this.scene.background.clone() : null
    };
    
    const updateTransition = () => {
        const elapsed = Date.now() - startTime;
        this.transitionProgress = Math.min(elapsed / duration, 1);
        
        // Ease function (cubic)
        const t = this.transitionProgress < 0.5 
            ? 4 * this.transitionProgress * this.transitionProgress * this.transitionProgress 
            : 1 - Math.pow(-2 * this.transitionProgress + 2, 3) / 2;
        
        // Interpolate color adjustment values back to normal
        this.colorAdjustPass.uniforms.saturation.value = THREE.MathUtils.lerp(
            startValues.saturation,
            1.0,
            t
        );
        
        this.colorAdjustPass.uniforms.brightness.value = THREE.MathUtils.lerp(
            startValues.brightness,
            1.0,
            t
        );
        
        this.colorAdjustPass.uniforms.contrast.value = THREE.MathUtils.lerp(
            startValues.contrast,
            1.0,
            t
        );
        
        this.colorAdjustPass.uniforms.tintStrength.value = THREE.MathUtils.lerp(
            startValues.tintStrength,
            0.0,
            t
        );
        
        // Interpolate tint color back to white (neutral)
        const currentTint = this.colorAdjustPass.uniforms.tint.value;
        currentTint.r = THREE.MathUtils.lerp(startValues.tint.r, 1.0, t);
        currentTint.g = THREE.MathUtils.lerp(startValues.tint.g, 1.0, t);
        currentTint.b = THREE.MathUtils.lerp(startValues.tint.b, 1.0, t);
        
        // Interpolate light intensities back to normal
        this.ambientLight.intensity = THREE.MathUtils.lerp(
            this.ambientLight.intensity,
            this.normalSceneValues.ambientIntensity,
            t
        );
        
        this.hemiLight.intensity = THREE.MathUtils.lerp(
            this.hemiLight.intensity,
            this.normalSceneValues.hemiIntensity,
            t
        );
        
        this.sunLight.intensity = THREE.MathUtils.lerp(
            this.sunLight.intensity,
            this.normalSceneValues.sunIntensity,
            t
        );
        
        if (this.rimLight) {
            this.rimLight.intensity = THREE.MathUtils.lerp(
                this.rimLight.intensity,
                this.normalSceneValues.rimIntensity,
                t
            );
        }
        
        // Interpolate bokeh aperture back to normal
        this.bokehPass.uniforms.aperture.value = THREE.MathUtils.lerp(
            this.bokehPass.uniforms.aperture.value,
            this.normalSceneValues.bokehAperture,
            t
        );
        
        // Interpolate bloom values back to normal
        this.bloomPass.strength = THREE.MathUtils.lerp(startValues.bloomStrength, 0, t);
        this.bloomPass.radius = THREE.MathUtils.lerp(startValues.bloomRadius, 0, t);
        
        // Interpolate background color if it exists
        if (startValues.background && this.normalSceneValues.background) {
            const currentColor = new THREE.Color();
            currentColor.lerpColors(startValues.background, this.normalSceneValues.background, t);
            this.scene.background = currentColor;
        } else if (startValues.background && !this.normalSceneValues.background) {
            // If target is null (transparent), gradually fade out
            const opacity = 1 - t;
            if (opacity <= 0.01) {
                this.scene.background = null;
            } else {
                // Fade out by making it more transparent
                this.scene.background = startValues.background.clone();
                this.scene.background.multiplyScalar(opacity);
            }
        }
        
        // Continue animation if not complete
        if (this.transitionProgress < 1) {
            requestAnimationFrame(updateTransition);
        } else {
            this.isTransitioning = false;
            this.isDimmed = false;
            
            // Disable passes
            this.colorAdjustPass.enabled = false;
            this.bloomPass.enabled = false;
            
            // Restore normal scene values
            this.scene.background = this.normalSceneValues.background;
        }
    };
    
    updateTransition();

}



}

// Create singleton instance
let threeSetupInstance = null;

export function getThreeJsSetup() {
    if (!threeSetupInstance) {
        threeSetupInstance = new ThreeJsSetup();
    }
    return threeSetupInstance;
}