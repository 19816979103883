import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { createOverlayTexture, calculateDotSize, vector3ToLatLong, latLongToVector3, loadTexture, setupTexture  } from './utils.js';
import { RibbonAnimator  } from './ribbonAnimator.js';

let globeMesh;
let pins = [];
let ribbons = [];

// Updated globeData structure
let globeData = {
    pins: {},
    ribbons: {},
    metadata: {
        orbitControls: {
            target: { x: 0, y: 0, z: 0 },
            position: { x: 0, y: 0, z: 0 }
        }
    }
};

// Scene setup
const scene = new THREE.Scene();
scene.background = null;

THREE.ColorManagement.enabled = true;

// Camera setup
const camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.z = 3.5; 
camera.position.y = 1;

//const sunLight = new THREE.DirectionalLight(0xFFFFF0, 2); 
const sunLight = new THREE.DirectionalLight(0xF0F8FF, 3.5);

sunLight.position.set(-5, 0, 5);
sunLight.castShadow = true;
sunLight.shadow.mapSize.width = 4096;
sunLight.shadow.mapSize.height = 4096;
sunLight.shadow.camera.near = 0.1;
sunLight.shadow.camera.far = 15;
sunLight.shadow.radius = 16; // Try an even larger value


const globeRadius = 1; 
const shadowScale = 6; // Increase this value
sunLight.shadow.camera.left = -globeRadius * shadowScale;
sunLight.shadow.camera.right = globeRadius * shadowScale;
sunLight.shadow.camera.top = globeRadius * shadowScale;
sunLight.shadow.camera.bottom = -globeRadius * shadowScale;
sunLight.shadow.bias = -0.001;

camera.add(sunLight); // Adding this to camera so we can mess around with orbitControls



const ambientLight = new THREE.AmbientLight(0xf4f4f4, 1); // Reduce intensity
camera.add(ambientLight);

scene.add(camera);

const globeContainer = new THREE.Object3D();
scene.add(globeContainer);

// Renderer setup
const renderer = new THREE.WebGLRenderer({ alpha: true, depth: true, antialias: true });
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap; 
renderer.outputColorSpace = THREE.SRGBColorSpace;
renderer.shadowMap.autoUpdate = true;


renderer.toneMappingExposure = 1.4;
renderer.setClearColor(0x000000, 0); 


///////////////// Athmospheric scattering
// Vertex shader
const atmosphereVertexShader = `
varying vec3 vNormal;
varying vec2 vUv;

void main() {
    vNormal = normalize(normalMatrix * normal);
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

const atmosphereFragmentShader = `
varying vec3 vNormal;
varying vec2 vUv;

uniform vec3 atmosphereColor;
uniform float atmospherePower;

float saturate(float x) {
    return clamp(x, 0.0, 1.0);
}

void main() {
    vec3 viewDir = normalize(cameraPosition);
    float viewing = dot(vNormal, vec3(0, 0, 1.0));
    
    // More stable viewing angle calculation
    float viewAngle = saturate(abs(viewing));
    
    // Smoother intensity calculation with better clamping
    float rawIntensity = saturate(0.75 - viewing);
    float intensity = pow(rawIntensity, atmospherePower);
    
    // Add view-dependent edge enhancement
    float edgePower = mix(atmospherePower, atmospherePower * 0.5, viewAngle);
    intensity *= pow(saturate(1.0 - viewAngle), edgePower);
    
    // Ensure intensity stays reasonable at extreme angles
    intensity = saturate(intensity);
    
    // Subtle color variation
    vec3 dawnColor = vec3(1.0, 0.8, 0.7);
    vec3 dayColor = atmosphereColor;
    float colorMix = pow(saturate(1.0 - abs(viewing)), 2.0);
    vec3 finalColor = mix(dayColor, dawnColor, colorMix * 0.15);
    
    // Apply final color with smooth falloff
    gl_FragColor = vec4(finalColor, 1.0) * intensity;
}
`;

// Update material creation
function createAtmosphere(radius) {
    const geometry = new THREE.SphereGeometry(radius * 1.05, 64, 64);
    
    const material = new THREE.ShaderMaterial({
        vertexShader: atmosphereVertexShader,  // Your existing vertex shader
        fragmentShader: atmosphereFragmentShader,
        blending: THREE.AdditiveBlending,
        side: THREE.BackSide,
        transparent: true,
        uniforms: {
            atmosphereColor: { value: new THREE.Color(0xbbeeff) },
            atmospherePower: { value: 3.0 }
        }
    });

    const atmosphere = new THREE.Mesh(geometry, material);
    atmosphere.name = 'atmosphere';
    return atmosphere;
}

// SECOND
const atmosphericFogVertexShader = `
varying vec3 vWorldPosition;
varying vec3 vNormal;

void main() {
    vec4 worldPosition = modelMatrix * vec4(position, 1.0);
    vWorldPosition = worldPosition.xyz;
    vNormal = normalize(normalMatrix * normal);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

const atmosphericFogFragmentShader = `
uniform vec3 globeCenter;
uniform float globeRadius;
uniform vec3 atmosphereColor;
uniform float fogDensity;

varying vec3 vWorldPosition;
varying vec3 vNormal;

void main() {
    // Calculate vectors for distance and angle calculations
    vec3 directionFromCenter = normalize(vWorldPosition - globeCenter);
    float distanceFromCenter = length(vWorldPosition - globeCenter);
    
    // Calculate view angle relative to surface
    vec3 viewDir = normalize(cameraPosition - vWorldPosition);
    float viewDot = abs(dot(viewDir, directionFromCenter));
    
    // Enhanced distance calculation that considers both radial and tangential distance
    float surfaceDistance = distanceFromCenter - globeRadius;
    float tangentialFactor = 1.0 - viewDot;
    
    // Combine distances for a more natural falloff
    float effectiveDistance = surfaceDistance * (1.0 + tangentialFactor * 2.0);
    
    // Smooth fog calculation
    float fogFactor = 1.0 - exp(-fogDensity * effectiveDistance);
    fogFactor *= smoothstep(0.0, 0.2, tangentialFactor);
    
    // Apply fog color
    gl_FragColor = vec4(atmosphereColor, fogFactor * 0.3);
}
`;

function createAtmosphericFog(radius) {
    if (!isFinite(radius) || radius <= 0) {
        console.warn('Invalid radius provided to createAtmosphericFog:', radius);
        return null;
    }

    const geometry = new THREE.SphereGeometry(radius * 1.2, 64, 64);
    
    // Validate geometry
    const positions = geometry.attributes.position.array;
    for (let i = 0; i < positions.length; i++) {
        if (!isFinite(positions[i])) {
            console.error('Found NaN in fog geometry positions at index:', i);
            return null;
        }
    }

    const material = new THREE.ShaderMaterial({
        defines: {
            USE_FOG: true
        },
        vertexShader: atmosphericFogVertexShader,
        fragmentShader: atmosphericFogFragmentShader,
        blending: THREE.CustomBlending,
        blendEquation: THREE.AddEquation,
        blendSrc: THREE.SrcAlphaFactor,
        blendDst: THREE.OneMinusSrcAlphaFactor,
        transparent: true,
        depthWrite: false,
        uniforms: {
            globeCenter: { value: new THREE.Vector3(0, 0, 0) },
            globeRadius: { value: radius },
            atmosphereColor: { value: new THREE.Color(0xccccff) },
            fogDensity: { value: 0.08 },  // Reduced density for smoother falloff
            cameraPosition: { value: new THREE.Vector3() }
        }
    });


    const fog = new THREE.Mesh(geometry, material);
    fog.name = 'atmosphericFog';
    
    // Validate final mesh
    fog.geometry.computeBoundingSphere();
    if (!fog.geometry.boundingSphere || !isFinite(fog.geometry.boundingSphere.radius)) {
        console.error('Invalid bounding sphere after fog creation');
    }
    
    return fog;
}

console.log('Creating fog with radius:', globeRadius, 
    'Globe container position:', globeContainer.position,
    'Camera position:', camera.position);


// Add this after your globe and original atmosphere are created:
const atmosphericFog = createAtmosphericFog(globeRadius);
globeContainer.add(atmosphericFog);

// Update the fog's center in your animation loop
function updateAtmosphericFog() {
    const fog = globeContainer.getObjectByName('atmosphericFog');
    if (fog) {
        const globePos = globeContainer.position.clone();
        const camPos = camera.position.clone();
        
        // Validate positions
        if (globePos.x !== globePos.x || // Check for NaN
            camPos.x !== camPos.x) {
            console.error('Invalid positions in updateAtmosphericFog:', {
                globePosition: globePos,
                cameraPosition: camPos
            });
            return;
        }
        
        fog.material.uniforms.globeCenter.value.copy(globePos);
        fog.material.uniforms.cameraPosition.value.copy(camPos);
        
        // Validate geometry after update
        fog.geometry.computeBoundingSphere();
        if (!fog.geometry.boundingSphere || !isFinite(fog.geometry.boundingSphere.radius)) {
            console.error('Invalid bounding sphere after fog update');
        }
    }
}


///////////// ATMOSCAT OVER



// Set the environment map for the entire scene
const envMapLoader = new THREE.CubeTextureLoader();
const envMap = envMapLoader.load([
    'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/f55e2c63-2017-4c1a-ef9b-64b32cdd7900/Screen',
    'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/12a97250-bd94-476b-0c1c-b9e7ee7c1f00/Screen',
    'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/b05382da-e85d-4bb6-cdb5-41950cb60800/Screen',
    'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/43746577-38b1-4382-6a66-4603d0525400/Screen',
    'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/d942f2cd-f9c3-448f-72fb-f67fd0487100/Screen',
    'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/66bab579-8586-46c3-53f4-165e6bbf3900/Screen'
]);
//scene.environment = envMap;

const loader = new GLTFLoader();

if (0) {
loader.load(
    'objects/crate.glb',
    function (gltf) {
        const model = gltf.scene;
        
        // Scale the model
        model.scale.set(0.00035, 0.00035, 0.00035);
        
        // Enable shadow casting and receiving for the entire model
        model.traverse((node) => {
            if (node.isMesh) {
                node.castShadow = true;
                node.receiveShadow = true;
            }
        });
        
        // Generate a random position on a sphere
        const phi = Math.random() * Math.PI * 2; // Random angle around Y axis
        const cosTheta = Math.random() * 2 - 1; // Random value from -1 to 1
        const theta = Math.acos(cosTheta); // Angle from Y axis
        
        // Calculate the position
        const x = Math.sin(theta) * Math.cos(phi);
        const y = Math.sin(theta) * Math.sin(phi);
        const z = Math.cos(theta);
        
        // Create a group to hold the model
        const group = new THREE.Group();
        group.add(model);
        
        // Position the group on the surface of the sphere
        group.position.set(x, y, z);
        
        // Rotate the group to face the center of the sphere
        group.lookAt(new THREE.Vector3(0, 0, 0));
        
        // Move the model up (in its local space) so it sits on the surface
        model.position.y = model.position.y + 0.5; // Adjust this value as needed
        
        scene.add(group);
    },
    function (xhr) {
        console.log((xhr.loaded / xhr.total * 100) + '% loaded');
    },
    function (error) {
        console.error('An error happened', error);
    }
);
}
class OrbitControlActivityManager {
    constructor(controls, options = {}) {
        this.controls = controls;
        
        // Configuration
        this.inactivityDelay = options.inactivityDelay || 5000; // ms before auto-rotation starts
        this.transitionDuration = options.transitionDuration || 3000; // ms for speed transition
        this.maxAutoRotateSpeed = options.maxAutoRotateSpeed || 2.5;
        
        // State
        this.isAutoRotating = false;
        this.lastActivityTime = Date.now();
        this.transitionStartTime = null;
        this.currentAutoRotateSpeed = 0;
        this.lastUpdateTime = Date.now();
        
        // Bind methods
        this.handleActivity = this.handleActivity.bind(this);
        
        // Setup event listeners
        this.setupEventListeners();
    }
    
    setupEventListeners() {
        // Track user interaction with controls
        this.controls.domElement.addEventListener('pointerdown', this.handleActivity);
        this.controls.domElement.addEventListener('pointermove', this.handleActivity);
        this.controls.domElement.addEventListener('wheel', this.handleActivity);
        
        // Track general user activity
        document.addEventListener('keydown', this.handleActivity);
        document.addEventListener('mousemove', this.handleActivity);
    }
    
    handleActivity() {
        this.lastActivityTime = Date.now();
        
        if (this.isAutoRotating) {
            this.isAutoRotating = false;
            this.transitionStartTime = Date.now();
        }
    }
    
    update() {
        const currentTime = Date.now();
        const deltaTime = (currentTime - this.lastUpdateTime) / 1000; // Convert to seconds
        this.lastUpdateTime = currentTime;
        
        const timeSinceActivity = currentTime - this.lastActivityTime;
        
        // Check if we should start auto-rotation
        if (!this.isAutoRotating && timeSinceActivity > this.inactivityDelay) {
            if (isGlobeRolledOut || isRollingGlobe) {} 
            else {
                this.isAutoRotating = true;
                this.transitionStartTime = currentTime;
            }
        }
        
        // Handle speed transitions
        if (this.transitionStartTime) {
            const timeSinceTransition = currentTime - this.transitionStartTime;
            const progress = Math.min(timeSinceTransition / this.transitionDuration, 1);
            
            if (this.isAutoRotating) {
                // Speeding up
                this.currentAutoRotateSpeed = this.maxAutoRotateSpeed * this.easeInOutCubic(progress);
            } else {
                // Slowing down
                this.currentAutoRotateSpeed = this.maxAutoRotateSpeed * (1 - this.easeInOutCubic(progress));
            }
            
            // Clear transition when complete
            if (progress === 1) {
                this.transitionStartTime = null;
            }
        }
        
        // Apply the current speed
        this.controls.autoRotate = this.currentAutoRotateSpeed > 0;
        this.controls.autoRotateSpeed = this.currentAutoRotateSpeed;
        
        // This is where you can add additional inactive behaviors
        if (this.isAutoRotating) {
            // Example: this.handleInactiveBehavior(deltaTime);
        }
        
        // Update the controls with deltaTime for frame-independent rotation
        this.controls.update(deltaTime);
    }
    
    // Smooth easing function
    easeInOutCubic(x) {
        return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
    }
    
    dispose() {
        // Clean up event listeners
        this.controls.domElement.removeEventListener('pointerdown', this.handleActivity);
        this.controls.domElement.removeEventListener('pointermove', this.handleActivity);
        this.controls.domElement.removeEventListener('wheel', this.handleActivity);
        document.removeEventListener('keydown', this.handleActivity);
        document.removeEventListener('mousemove', this.handleActivity);
    }
}


// Controls
const controls = new OrbitControls(camera, renderer.domElement);
controls.enableDamping = true;
controls.dampingFactor = 0.25;
controls.enableZoom = true;
controls.enablePan = true; // Disable panning
controls.minDistance = 1.8;
controls.autoRotate = 1;

// Create the manager after setting up your OrbitControls
const activityManager = new OrbitControlActivityManager(controls, {
    inactivityDelay: 3000,        // Start rotating after 5 seconds of inactivity
    transitionDuration: 2000,     // Take 2 seconds to transition speeds
    maxAutoRotateSpeed: 2.5       // Maximum rotation speed
});


controls.addEventListener('start', () => {
    orbitControlsMoved = true;
    if (isTransitioning) {
        console.log("User interaction detected, stopping transition");
        isTransitioning = false;
    }

});

document.body.appendChild(renderer.domElement);
let isMouseDown = false;
let mouseDownPosition = new THREE.Vector2();
let orbitControlsMoved = false;
let lastPlacedMarker = null;
let lastPin = null;
let previewPin = null;
let previewRibbon = null;
let isMouseMovedSinceLastClick = false;
let statusTimeout;
    
const radius = 1;
const widthSegments = 64;
const heightSegments = 32;
let isTransitioning = false;
let targetPosition, targetTarget;

// Create preview pin
function createPreviewPin() {
    const pin = createCityPin(new THREE.Vector3(), 0.04);
    pin.visible = false;
    pin.isPreviewPin = true;  // Set this property here
    globeContainer.add(pin);
    return pin;
}

function animatePinPushIn(pin, finalPosition) {
    const direction = finalPosition.clone().sub(globeContainer.position).normalize();
    const pushDistance = 0.007;
    const animationDuration = 200; // 2.5 seconds

    const startTime = Date.now();

    function animate() {
        const elapsedTime = Date.now() - startTime;
        const progress = Math.min(elapsedTime / animationDuration, 1);

        if (progress < 0.5) {
            // Push in
            const pushInProgress = progress * 2; // Rescale to 0-1
            const currentPushDistance = pushDistance * pushInProgress;
            pin.position.copy(finalPosition).sub(direction.clone().multiplyScalar(currentPushDistance));
        }

        pin.lookAt(globeContainer.position);
        pin.rotateX(Math.PI / 2);

        if (progress < 1) {
            requestAnimationFrame(animate);
        }
    }

    animate();
}

function createPaperMaterial() {  // Remove lightPosition parameter since it will be dynamic
    // Create paper fiber texture
    const textureSize = 256;
    const canvas = document.createElement('canvas');
    canvas.width = textureSize;
    canvas.height = textureSize;
    const ctx = canvas.getContext('2d');

    // Pure white base
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, textureSize, textureSize);

    // Subtle fibers
    for (let i = 0; i < 1000; i++) {
        const x = Math.random() * textureSize;
        const y = Math.random() * textureSize;
        const length = 2 + Math.random() * 3;
        const angle = Math.random() * Math.PI * 2;
        
        ctx.save();
        ctx.translate(x, y);
        ctx.rotate(angle);
        
        const alpha = Math.random() * 0.03;
        ctx.strokeStyle = `rgba(0, 0, 0, ${alpha})`;
        ctx.lineWidth = 0.5;
        
        ctx.beginPath();
        ctx.moveTo(-length/2, 0);
        ctx.lineTo(length/2, 0);
        ctx.stroke();
        
        ctx.restore();
    }

    const paperTexture = new THREE.CanvasTexture(canvas);
    paperTexture.wrapS = THREE.RepeatWrapping;
    paperTexture.wrapT = THREE.RepeatWrapping;

    const material = new THREE.ShaderMaterial({
        uniforms: {
            paperTexture: { value: paperTexture },
            lightPosition: { value: new THREE.Vector3() },  // Will be updated dynamically
            globeCenter: { value: new THREE.Vector3(0, 0, 0) },
            globeRadius: { value: 1.0 },
            ...THREE.UniformsLib.lights
        },
        vertexShader: `
            uniform vec3 globeCenter;
            uniform float globeRadius;
            
            varying vec2 vUv;
            varying vec3 vNormal;
            varying vec3 vWorldPosition;
            varying float vDistanceFromGlobe;
            #include <common>
            #include <shadowmap_pars_vertex>

            void main() {
                vUv = uv;
                vNormal = normalMatrix * normal;
                vec4 worldPosition = modelMatrix * vec4(position, 1.0);
                vWorldPosition = worldPosition.xyz;
                
                vec3 positionVec = worldPosition.xyz;
                vec3 directionFromCenter = normalize(positionVec - globeCenter);
                float distanceFromCenter = length(positionVec - globeCenter);
                vDistanceFromGlobe = (distanceFromCenter - globeRadius) / globeRadius;
                
                gl_Position = projectionMatrix * viewMatrix * worldPosition;
                
                #include <beginnormal_vertex>
                #include <defaultnormal_vertex>
                #include <shadowmap_vertex>
            }
        `,
        fragmentShader: `
            uniform sampler2D paperTexture;
            uniform vec3 lightPosition;
            uniform vec3 globeCenter;
            uniform float globeRadius;
            
            #include <common>
            #include <packing>
            #include <lights_pars_begin>
            #include <shadowmap_pars_fragment>
            #include <shadowmask_pars_fragment>

            varying vec2 vUv;
            varying vec3 vNormal;
            varying vec3 vWorldPosition;
            varying float vDistanceFromGlobe;

            void main() {
                float shadowMask = getShadowMask();
                
                vec3 normal = normalize(vNormal);
                vec3 lightDir = normalize(lightPosition - vWorldPosition);
                
                // Calculate basic lighting with more dramatic falloff
                float diffuse = max(dot(normal, lightDir), 0.0);
                diffuse = pow(diffuse, 2.5);
                diffuse = smoothstep(0.2, 1.0, diffuse);
                
                // Paper texture
                vec4 paper = texture2D(paperTexture, vUv);
                vec3 baseColor = vec3(1.0, 1.0, 1.0);
                vec3 color = baseColor * paper.rgb;
                
                // Add very subtle warm tint to lit areas and cool tint to shadows
                vec3 warmTint = vec3(1.0, 0.98, 0.95);
                vec3 coolTint = vec3(0.95, 0.97, 1.0);
                color *= mix(coolTint, warmTint, diffuse);
                
                // Calculate shadow from globe
                vec3 globeToPoint = normalize(vWorldPosition - globeCenter);
                float angleFactor = dot(globeToPoint, normalize(lightPosition));
                float planetShadow = smoothstep(-0.2, 0.2, angleFactor);
                
                float ambient = 0.95;
                float diffuseMult = 0.4;
                
                float mainLight = ambient + (diffuseMult * diffuse);
                float shadowEffect = mix(0.5, 1.0, shadowMask);
                float planetShadowEffect = mix(0.5, 1.0, planetShadow);
                
                float lighting = mainLight * shadowEffect * planetShadowEffect;
                lighting = mix(lighting, max(lighting, 0.8), step(0.8, shadowMask));
                
                vec3 finalColor = color * lighting;
                
                gl_FragColor = vec4(finalColor, 1.0);
            }
        `,
        lights: true,
        side: THREE.DoubleSide
    });

    // Add an update method to the material
    material.updateLightPosition = function(lightPos) {
        this.uniforms.lightPosition.value.copy(lightPos);
    };

    return material;
}

// Usage example:
/*
const paperMaterial = createPaperMaterial();

// In your animation loop or whenever the light moves:
function animate() {
    // Assuming 'light' is your THREE.Light object
    const lightWorldPosition = new THREE.Vector3();
    light.getWorldPosition(lightWorldPosition);
    
    // Update the material with new light position
    paperMaterial.updateLightPosition(lightWorldPosition);
    
    requestAnimationFrame(animate);
}
*/

function createOrUpdateRibbon(startPoint = null, endPoint = null, updateExisting = false, existingRibbon = null) {
    const segments = 50;
    const minHeight = 0.01;
    const maxHeight = 0.4;
    const minWidth = 0.004;
    const maxWidth = 0.025;
    const positions = [];
    const normals = [];
    const uvs = [];
    const indices = [];

    if (startPoint && endPoint) {
        const points = [];
        const pathLength = startPoint.distanceTo(endPoint);
        const normalizedLength = Math.min(pathLength / (2 * radius), 1);
        const curveHeight = minHeight + (maxHeight - minHeight) * Math.pow(normalizedLength, 2);
        const ribbonWidth = minWidth + (maxWidth - minWidth) * normalizedLength;

        // Create initial points array with proper anchoring
        points.push(startPoint.clone());
        
        // Generate intermediate points
        for (let i = 1; i < segments; i++) {
            const t = i / segments;
            const p = new THREE.Vector3().lerpVectors(startPoint, endPoint, t);
            const height = Math.sin(t * Math.PI) * curveHeight;
            p.normalize().multiplyScalar(radius + height);
            points.push(p);
        }
        
        points.push(endPoint.clone());

        const curve = new THREE.CatmullRomCurve3(points);
        curve.tension = 0.5; // Adjust for flexibility
        const geometryPoints = curve.getPoints(segments);

        // Calculate ribbon geometry with proper normal orientation
        for (let i = 0; i < geometryPoints.length; i++) {
            const point = geometryPoints[i];
            const tangent = curve.getTangentAt(i / segments);
            const up = point.clone().normalize();
            
            // Ensure consistent binormal direction
            const binormal = new THREE.Vector3().crossVectors(tangent, up).normalize();
            const normal = new THREE.Vector3().crossVectors(binormal, tangent).normalize();

            const v1 = point.clone().add(binormal.clone().multiplyScalar(ribbonWidth / 2));
            const v2 = point.clone().sub(binormal.clone().multiplyScalar(ribbonWidth / 2));

            positions.push(v1.x, v1.y, v1.z, v2.x, v2.y, v2.z);
            normals.push(normal.x, normal.y, normal.z, normal.x, normal.y, normal.z);
            uvs.push(i / segments, 0, i / segments, 1);

            if (i < geometryPoints.length - 1) {
                const base = i * 2;
                indices.push(base, base + 1, base + 2, base + 1, base + 3, base + 2);
            }
        }
    }

    if (updateExisting && existingRibbon) {
        existingRibbon.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        existingRibbon.geometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
        existingRibbon.geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
        existingRibbon.geometry.setIndex(indices);
        
        existingRibbon.geometry.computeBoundingSphere();
        existingRibbon.visible = positions.length > 0;
        return existingRibbon;
    } else {
        const ribbonGeometry = new THREE.BufferGeometry();
        ribbonGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        ribbonGeometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
        ribbonGeometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
        ribbonGeometry.setIndex(indices);
        
        ribbonGeometry.computeBoundingSphere();
        
        const lightPos = new THREE.Vector3(-5, 0, 5);
        // Transform it by the camera's matrix to get its position in the current view
        lightPos.applyMatrix4(camera.matrixWorld);

        const ribbon = new THREE.Mesh(ribbonGeometry, createPaperMaterial(lightPos));
        ribbon.visible = positions.length > 0;

        // Shadow settings
        ribbon.castShadow = true;
        ribbon.receiveShadow = true;
        ribbon.frustumCulled = false;

        ribbon.animator = new RibbonAnimator(ribbon, {
            springStiffness: 10.0,
            damping: 0.4,
            dragFactor: 0.5
        });

        ribbon.animator.initializePhysics();
        
        return ribbon;
    }
}

// Initialize preview objects
previewPin = createPreviewPin();
// Usage example to create an empty preview ribbon
previewRibbon = createOrUpdateRibbon(); // Create an empty ribbon with no start or endpoint
globeContainer.add(previewRibbon);



function updatePreviewObjects(intersectionPoint) {
    if (intersectionPoint && isMouseMovedSinceLastClick) {
        if (hoveredPin) {
            previewPin.visible = false;
        } else {
            previewPin.position.copy(intersectionPoint);
            previewPin.lookAt(globeContainer.position);
            previewPin.rotateX(Math.PI / 2);
            previewPin.visible = true;
        }

        if (lastPlacedMarker) {
            createOrUpdateRibbon(lastPlacedMarker, intersectionPoint, true, previewRibbon);
            previewRibbon.visible = true;
        } else {
            previewRibbon.visible = false;
        }
    } else {
        previewPin.visible = false;
        previewRibbon.visible = false;
    }
}

// Add or update the resetRibbonCreation function
function resetRibbonCreation() {
    lastPlacedMarker = null;
    lastPin = null;
    if (previewRibbon) {
        previewRibbon.visible = false;
    }
}

let hoveredPin = null;

// Update the checkPinHover function to use the pins array
function checkPinHover(mouse) {
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(pins, true);
    
    for (let intersect of intersects) {
        let objectToCheck = intersect.object.type === 'Group' ? intersect.object : intersect.object.parent;
        if (objectToCheck.isCustomPin && !objectToCheck.isPreviewPin) {
            return objectToCheck;
        }
    }
    return null;
}

renderer.domElement.addEventListener('mousemove', (event) => {
    isMouseMovedSinceLastClick = true;
    
    const mouse = new THREE.Vector2(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1
    );

    hoveredPin = checkPinHover(mouse);

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObject(globeMesh);

    if (hoveredPin) {
        updatePreviewObjects(hoveredPin.position);
    } else if (intersects.length > 0) {
        updatePreviewObjects(intersects[0].point);
    } else {
        updatePreviewObjects(null);
    }

    if (isMouseDown) {
        const mouseMovePosition = new THREE.Vector2(event.clientX, event.clientY);
        if (mouseMovePosition.distanceTo(mouseDownPosition) > 3) {
            orbitControlsMoved = true;
        }
    }
});


renderer.domElement.addEventListener('mousedown', (event) => {
    isMouseDown = true;
    mouseDownPosition.set(event.clientX, event.clientY);
    orbitControlsMoved = false;

});

// Updated updateFirebase function
function updateFirebase() {
    // Save current orbit controls state
    const controlsState = {
        target: controls.target.toArray(),
        position: controls.object.position.toArray()
    };

    globeData.metadata.orbitControls = controlsState;

    db.collection('globes').doc(globeId).set(globeData)
        .then(() => {
          hideUnsavedIndicator();
          showStatus('Globe state saved!');

        })
        .catch((error) => {
            console.error("Error updating globe data: ", error);
        });

}

// Updated loadGlobeData function
function loadGlobeData() {
    db.collection('globes').doc(globeId).get()
        .then((doc) => {
            if (doc.exists) {
                globeData = doc.data();
                renderGlobeData();
                applyOrbitControlsSettings();
            } else {
                // Initialize new globe document
                db.collection('globes').doc(globeId).set(globeData);
            }
        })
        .catch((error) => {
            console.error("Error loading globe data: ", error);
        });
}

function applyOrbitControlsSettings() {
    if (globeData.metadata && globeData.metadata.orbitControls) {
        const { target, position } = globeData.metadata.orbitControls;
        
        if (target && target.length === 3 && position && position.length === 3) {
            targetPosition = new THREE.Vector3().fromArray(position);
            targetTarget = new THREE.Vector3().fromArray(target);
            isTransitioning = true;
        } else {
        }
    } else {
    }
}

function renderGlobeData() {
    // Render pins
    Object.values(globeData.pins).forEach(pinData => {
        const position = latLongToVector3(pinData.lat, pinData.lon, radius);
        const pin = createCityPin(position, 0.04);
        
        // Calculate newPinPosition relative to globeContainer
        const newPinPosition = position.clone().sub(globeContainer.position);
        
        // Add pin to container and pins array
        globeContainer.add(pin);
        pins.push(pin);
        
        // Animate the pin pushing in from its position
        animatePinPushIn(pin, newPinPosition);
    });

    // Render ribbons
    Object.values(globeData.ribbons).forEach(ribbonData => {
        const startPoint = latLongToVector3(ribbonData.startLat, ribbonData.startLon, radius);
        const endPoint = latLongToVector3(ribbonData.endLat, ribbonData.endLon, radius);

        // Adjust start and end points relative to the globe container
        startPoint.sub(globeContainer.position);
        endPoint.sub(globeContainer.position);

        const ribbon = createOrUpdateRibbon(startPoint, endPoint);
        ribbon.dataId = ribbonData.id;
        globeContainer.add(ribbon);
        ribbons.push(ribbon);

        // Connect ribbon to pins
        const startPin = pins.find(pin => pin.dataId === ribbonData.startPinId);
        const endPin = pins.find(pin => pin.dataId === ribbonData.endPinId);
        if (startPin) {
            startPin.connectedRibbons = startPin.connectedRibbons || [];
            startPin.connectedRibbons.push(ribbon);
        }
        if (endPin) {
            endPin.connectedRibbons = endPin.connectedRibbons || [];
            endPin.connectedRibbons.push(ribbon);
        }
    });
}

let activeSparkleSystems = [];

// Create a sparkle texture using a canvas
const createSparkleTexture = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 64;
    canvas.height = 64;
    const ctx = canvas.getContext('2d');
    
    // Create radial gradient for the main glow
    const gradient = ctx.createRadialGradient(32, 32, 0, 32, 32, 32);
    gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
    gradient.addColorStop(0.1, 'rgba(255, 240, 220, 0.8)');
    gradient.addColorStop(0.4, 'rgba(255, 220, 180, 0.2)');
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
    
    // Draw the main glow
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 64, 64);
    
    // Add a cross-shaped bright center
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.8)';
    ctx.lineWidth = 2;
    ctx.beginPath();
    // Horizontal line
    ctx.moveTo(24, 32);
    ctx.lineTo(40, 32);
    // Vertical line
    ctx.moveTo(32, 24);
    ctx.lineTo(32, 40);
    ctx.stroke();

    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;
    return texture;
};

const createPinSparkles = (position, count = 20) => {
    const sparkleTexture = createSparkleTexture();
    const particles = new THREE.Group();
    
    // Get the normal vector at the pin position (normalized position for a sphere)
    const normal = position.clone().normalize();
    
    // Create a tangent space basis for the pin position
    const tangent = new THREE.Vector3(1, 0, 0);
    if (Math.abs(normal.y) !== 1) {
        tangent.crossVectors(normal, new THREE.Vector3(0, 1, 0)).normalize();
    }
    const bitangent = new THREE.Vector3().crossVectors(normal, tangent);
    
    for(let i = 0; i < count; i++) {
        const sprite = new THREE.Sprite(
            new THREE.SpriteMaterial({
                map: sparkleTexture,
                transparent: true,
                blending: THREE.AdditiveBlending,
                depthWrite: false
            })
        );
        
        // Make sparkles smaller
        const scale = 0.01 + Math.random() * 0.01; // Reduced from 0.05
        sprite.scale.set(scale, scale, scale);
        sprite.rotation.z = Math.random() * Math.PI * 2;
        
        // Position slightly randomized around the pin
        sprite.position.copy(position);
        
        // Create velocity in random direction relative to surface
        const angle = Math.random() * Math.PI * 2;
        const upAmount = Math.random() * 0.5 + 0.5; // Bias slightly outward from surface
        
        // Combine tangent space vectors to create velocity
        const velocity = new THREE.Vector3()
            .addScaledVector(normal, 0.005 * upAmount) // Reduced speed, was 0.02
            .addScaledVector(tangent, 0.003 * Math.cos(angle)) // Reduced speed
            .addScaledVector(bitangent, 0.003 * Math.sin(angle)); // Reduced speed
        
        sprite.userData.velocity = velocity;
        sprite.userData.rotationSpeed = (Math.random() - 0.5) * 0.1;
        sprite.userData.life = 1.0;
        
        particles.add(sprite);
    }
    
    particles.userData.update = (delta) => {
        let allDead = true;
        particles.children.forEach(sprite => {
            // Update position with smaller movements
            sprite.position.add(sprite.userData.velocity);
            
            // Add very subtle randomization to movement
            sprite.userData.velocity.add(new THREE.Vector3(
                (Math.random() - 0.5) * 0.0001,
                (Math.random() - 0.5) * 0.0001,
                (Math.random() - 0.5) * 0.0001
            ));
            
            // Slow down velocity slightly
            sprite.userData.velocity.multiplyScalar(0.97);
            
            sprite.rotation.z += sprite.userData.rotationSpeed;
            sprite.userData.life *= 0.97;
            sprite.material.opacity = sprite.userData.life;
            
            // Reduce or remove the scale increase if you want
            const scaleIncrease = 1.005; // Reduced from 1.01
            sprite.scale.multiplyScalar(scaleIncrease);
            
            if (sprite.userData.life > 0.01) allDead = false;
        });
        
        return allDead;
    };
    
    return particles;
};

// Usage in your main code:
const addSparklesAtPin = (pinPosition) => {
    const sparkles = createPinSparkles(pinPosition);
    globeContainer.add(sparkles);
    
    // Add to a list of active particle systems
    activeSparkleSystems.push(sparkles);
};

renderer.domElement.addEventListener('mouseup', (event) => {

    if (isGlobeRolledOut || isRollingGlobe) return; // Prevent pin creation when globe is rolled out or during roll animation

    if (isMouseDown && !orbitControlsMoved) {
        const mouse = new THREE.Vector2(
            (event.clientX / window.innerWidth) * 2 - 1,
            -(event.clientY / window.innerHeight) * 2 + 1
        );

        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObject(globeMesh);

        if (intersects.length > 0 || hoveredPin) {
            let newPinPosition;
            let pinToUse;

            if (hoveredPin) {
                newPinPosition = hoveredPin.position.clone();
                pinToUse = hoveredPin;

                if (lastPlacedMarker) {
                    addSparklesAtPin(newPinPosition);
                }
            } else {
                newPinPosition = intersects[0].point.clone().sub(globeContainer.position);
                pinToUse = createCityPin(newPinPosition, 0.04);

                globeContainer.add(pinToUse);
                pins.push(pinToUse);
                animatePinPushIn(pinToUse, newPinPosition);

                // Add pin data to globeData
                const globalPosition = newPinPosition.clone().add(globeContainer.position);
                const { lat, lon } = vector3ToLatLong(globalPosition, globeContainer);
                const pinData = {
                    id: Date.now().toString(),
                    lat: lat,
                    lon: lon,
                    size: 0.02
                };
                globeData.pins[pinData.id] = pinData;
                pinToUse.dataId = pinData.id;

                // updateFirebase();
                showUnsavedIndicator()
            }

            if (lastPlacedMarker && pinToUse) {
                const ribbon = createOrUpdateRibbon(lastPlacedMarker, newPinPosition);

                globeContainer.add(ribbon);
                ribbons.push(ribbon);

                // Connect the ribbon to both pins
                if (lastPin && lastPin.connectedRibbons) {
                    lastPin.connectedRibbons.push(ribbon);
                }
                if (pinToUse.connectedRibbons) {
                    pinToUse.connectedRibbons.push(ribbon);
                } else {
                    console.warn('pinToUse does not have connectedRibbons property');
                }

                // Add ribbon data to globeData
                const startLatLon = vector3ToLatLong(lastPlacedMarker.clone().add(globeContainer.position), globeContainer);
                const endLatLon = vector3ToLatLong(newPinPosition.clone().add(globeContainer.position), globeContainer);
                // Add ribbon data to globeData
                const ribbonData = {
                    id: Date.now().toString(),
                    startPinId: lastPin.dataId,
                    endPinId: pinToUse.dataId,
                    startLat: startLatLon.lat,
                    startLon: startLatLon.lon,
                    endLat: endLatLon.lat,
                    endLon: endLatLon.lon,
                    width: 0.01 // default width
                };
                globeData.ribbons[ribbonData.id] = ribbonData;
                ribbon.dataId = ribbonData.id;

                               // Connect ribbon to pins
                lastPin.connectedRibbons = lastPin.connectedRibbons || [];
                lastPin.connectedRibbons.push(ribbon);
                pinToUse.connectedRibbons = pinToUse.connectedRibbons || [];
                pinToUse.connectedRibbons.push(ribbon);

            }

           // updateFirebase();
           showUnsavedIndicator();

            if (hoveredPin && lastPlacedMarker) {
                resetRibbonCreation();
            } else {
                lastPin = pinToUse;
                lastPlacedMarker = newPinPosition;
            }
        } else {
            resetRibbonCreation();

        }

        previewPin.visible = false;
        previewRibbon.visible = false;
        isMouseMovedSinceLastClick = false;
    }
    isMouseDown = false;
});

renderer.domElement.addEventListener('dblclick', (event) => {
    const mouse = new THREE.Vector2(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1
    );

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);

    // Check for intersections with pins
    const intersects = raycaster.intersectObjects(pins, true);

    if (intersects.length > 0) {
        let pinToRemove = intersects[0].object;
        while (pinToRemove && !pinToRemove.isCustomPin) {
            pinToRemove = pinToRemove.parent;
        }

        if (pinToRemove && pinToRemove.isCustomPin) {
            removePinAndRibbons(pinToRemove);
           // updateFirebase(); // Update Firebase after removal
           showUnsavedIndicator();
        }
    }

    resetRibbonCreation();

});


function removePinAndRibbons(pin) {
    // Remove pin from globeData
    delete globeData.pins[pin.dataId];

    // Remove associated ribbons from globeData
    Object.keys(globeData.ribbons).forEach(ribbonId => {
        const ribbonData = globeData.ribbons[ribbonId];
        if (ribbonData.startPinId === pin.dataId || ribbonData.endPinId === pin.dataId) {
            delete globeData.ribbons[ribbonId];
            // Remove ribbon from the scene
            const ribbonToRemove = ribbons.find(r => r.dataId === ribbonId);
            if (ribbonToRemove) {
                globeContainer.remove(ribbonToRemove);
                ribbons = ribbons.filter(r => r !== ribbonToRemove);
            }
        }
    });

    // Remove pin from the scene
    globeContainer.remove(pin);
    pins = pins.filter(p => p !== pin);

   // updateFirebase();
   showUnsavedIndicator();
}

let sortPositionsByWaterCoverage;
let globalNormalTexture;

const textureUrls = {
    color: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/10a143d4-6576-4782-3e0b-71aaf3b1c800/Unchanged',
    normal: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/82a0ff7d-58f6-4ef8-6bd9-ee7ee22d1900/Unchanged',
    roughness: 'https://imagedelivery.net/GOoNg4NhV8MoeGv5ZMaXBA/2beae295-d1be-4309-4cef-ccdca907aa00/Unchanged'

};

const globeGeometry = new THREE.SphereGeometry(radius, widthSegments, heightSegments);


Promise.all([
    loadTexture(textureUrls.color),
    loadTexture(textureUrls.normal),
    loadTexture(textureUrls.roughness),
    new Promise(resolve => loadData('/assets/new_placed_cities.csv', resolve))
 // new Promise(resolve => loadData('/sketches/cities_all.csv', resolve))
])
//])
.then(([colorTexture, normalTexture, roughnessTexture, citiesData]) => {
    [colorTexture, normalTexture, roughnessTexture].forEach(texture => setupTexture(texture, renderer.capabilities.getMaxAnisotropy()));
    
    sortPositionsByWaterCoverage = prepareWaterCoverageAnalysis(roughnessTexture.image, 8192,4096);

    globalNormalTexture = normalTexture;
    createCityLabels(citiesData);
    loadGlobeData();

    colorTexture.anisotropy = renderer.capabilities.getMaxAnisotropy();
    normalTexture.anisotropy = renderer.capabilities.getMaxAnisotropy();
    roughnessTexture.anisotropy = renderer.capabilities.getMaxAnisotropy();

    const globeMaterial = new THREE.MeshStandardMaterial({
        map: colorTexture,
        normalMap: normalTexture,
        normalScale: new THREE.Vector2(-0.8, 0.8),
        roughnessMap: roughnessTexture,
        roughness: 0.8,
        metalness: 0,
        envMapIntensity: 0.2,
    });

    globeMesh = new THREE.Mesh(globeGeometry, globeMaterial);
    globeMesh.castShadow = true;

    globeMesh.receiveShadow = true;
    globeContainer.add(globeMesh);

    const atmosphere = createAtmosphere(globeRadius);
    globeContainer.add(atmosphere);
    // If you need to do anything else after the globe is added, do it here
    
})
.catch(error => {
    console.error('Error loading textures:', error);
});

// Sphere geometry (globe)

function prepareWaterCoverageAnalysis(roughnessImage, labelTextureWidth, labelTextureHeight) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = roughnessImage.width;
    canvas.height = roughnessImage.height;
    ctx.drawImage(roughnessImage, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    const scaleX = canvas.width / labelTextureWidth;
    const scaleY = canvas.height / labelTextureHeight;

    return function sortPositionsByWaterCoverage(positions, labelX, labelY, width, height) {
        function calculateBlackness(x, y, w, h) {
            // Apply 50% horizontal shift
            const shiftX = labelTextureWidth / 2;
            
            // Scale the coordinates and dimensions to match the roughness texture
            const startX = Math.max(0, Math.floor(((x + shiftX) % labelTextureWidth) * scaleX));
            const startY = Math.max(0, Math.floor(y * scaleY));
            const endX = Math.min(canvas.width, Math.ceil(((x + w + shiftX) % labelTextureWidth) * scaleX));
            const endY = Math.min(canvas.height, Math.ceil((y + h) * scaleY));

            let totalBlackness = 0;
            let pixelCount = 0;

            for (let py = startY; py < endY; py++) {
                for (let px = startX; px < endX; px++) {
                    // Wrap around horizontally if we exceed the canvas width
                    const wrappedPx = px % canvas.width;
                    const i = (py * canvas.width + wrappedPx) * 4;
                    // Assuming the roughness texture is grayscale
                    const value = imageData.data[i]; // Just use the red channel
                    totalBlackness += (255 - value);
                    pixelCount++;
                }
            }

            const averageBlackness = pixelCount > 0 ? totalBlackness / pixelCount : 0;
            return averageBlackness;
        }

        const positionsWithBlackness = positions.map(pos => {
            const blackness = calculateBlackness(labelX + pos.dx, labelY + pos.dy, width, height);
            return { ...pos, blackness };
        });

        return positionsWithBlackness.sort((a, b) => b.blackness - a.blackness);
    };
}

function createCityPin(position, size) {
    const pinGroup = new THREE.Group();

    const headGeometry = new THREE.SphereGeometry(size/2.5, 32, 32);

    const headMaterial = new THREE.MeshPhysicalMaterial({ 
        color: 0xff0000,
        metalness: 0.1,         
        roughness: 0.05,        // Smoother surface
        transmission: 1,     // More light transmission
        thickness: 1.0,         // Increased thickness
        envMapIntensity: 0.2,   // Some environment reflections
        clearcoat: 0.8,        // More pronounced clearcoat
        clearcoatRoughness: 0.1,
        ior: 1.8,              // Higher refraction
        attenuationDistance: 0.5, // More color concentration
        attenuationColor: 0xff2200, // Deeper red internal color
        transparent: true,
        opacity: 0.9
    });
    
    const head = new THREE.Mesh(headGeometry, headMaterial);
    head.castShadow = true;
    head.receiveShadow = true;
    // Create pin shaft (cylinder)
// Adjust base radius to be thinner
const shaftRadius = size * 0.04; // Reduced from 0.06

// Create tapered cylinder by using different top and bottom radii
const shaftTopRadius = shaftRadius;  // Keep original radius at top
const shaftBottomRadius = shaftRadius * 0.7;  // 70% of top radius for subtle taper
const shaftHeight = size * 4;

const shaftGeometry = new THREE.CylinderGeometry(
    shaftTopRadius, 
    shaftBottomRadius, 
    shaftHeight, 
    32  // Keep good number of segments for smoothness
);
    const shaftMaterial = new THREE.MeshPhysicalMaterial({ 
        color: 0xdedede,
        metalness: 0.7,      // Reduced from 0.95 for less extreme reflections
        roughness: 0.4,      // Increased for more light scatter
        envMapIntensity: 0.5, // Reduced environment reflections
        clearcoat: 0.1,
        clearcoatRoughness: 0.3,
        // Removed anisotropy since it might be contributing to the harsh contrast
    });

    const shaft = new THREE.Mesh(shaftGeometry, shaftMaterial);

    head.position.y = -shaftHeight/2 ;
    pinGroup.add(head);
    pinGroup.add(shaft);
    pinGroup.position.copy(position);
    pinGroup.lookAt(new THREE.Vector3(0, 0, 0));

        // Add random wiggle
    const wiggleAmount = Math.PI / 10; // Adjust this value to control the maximum wiggle angle
    const randomAngleX = (Math.random() - 0.5) * wiggleAmount;
    const randomAngleZ = (Math.random() - 0.5) * wiggleAmount;

    pinGroup.rotateX(randomAngleX);
    pinGroup.rotateZ(randomAngleZ);
    pinGroup.rotateX(Math.PI / 2);
    pinGroup.translateY(-shaftHeight / 4); // Adjust this value to control penetration depth

    // Enable shadow casting for the entire pin
    pinGroup.traverse((object) => {
        if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = true;

        }
    });

    pinGroup.isCustomPin = true;
    pinGroup.isPreviewPin = false;  // Add this line
    pinGroup.connectedRibbons = []; // Add this line to store connected ribbons

    return pinGroup;
}

function goToRandomLocation() {
    // Generate a random latitude and longitude
    console.log('going to random location');

    const lat = Math.random() * 180 - 90;
    const lon = Math.random() * 360 - 180;

    // Convert lat/lon to 3D coordinates
    const phi = THREE.MathUtils.degToRad(90 - lat);
    const theta = THREE.MathUtils.degToRad(lon);
    const radius = globeMesh.geometry.parameters.radius;

    const x = radius * Math.sin(phi) * Math.cos(theta);
    const y = radius * Math.cos(phi);
    const z = radius * Math.sin(phi) * Math.sin(theta);

    // Set the target position (slightly above the surface)
    targetPosition = new THREE.Vector3(x, y, z).multiplyScalar(1.5);

    // Set the target for the camera to look at (on the surface)
    targetTarget = new THREE.Vector3(x, y, z);

    // Start the transition
    isTransitioning = true;
}


let isGlobeRolledOut = false;
let isRollingGlobe = false; // New flag to track globe rolling state

let originalRotation = new THREE.Euler();
let originalPosition = new THREE.Vector3();
let originalControlsState = {
    target: new THREE.Vector3(),
    position: new THREE.Vector3(),
    zoom: 1
};
const rotationAngle = Math.PI / 3; // 60 degrees

// Define default camera position and target
const defaultCameraPosition = new THREE.Vector3(0, 0, 3.5);
const defaultTarget = new THREE.Vector3(0, 0, 0);

// Define the amount to move the globe
const globeOffset = 2; // window.innerWidth > 768 ? 2 : 1.5; // Adjust these values as needed

function rollGlobeOutAndShowText() {
    if (isGlobeRolledOut) return;

    // Store the original states
    originalRotation.copy(scene.rotation);
    originalPosition.copy(globeContainer.position);
    originalControlsState.target.copy(controls.target);
    originalControlsState.position.copy(controls.object.position);
    originalControlsState.zoom = controls.zoom;

    // Disable controls during animation
    controls.enabled = false;

    // Animate the scene rotation, globe position, and controls
    new TWEEN.Tween({
        rotationY: scene.rotation.y,
        globeX: globeContainer.position.x,
        targetX: controls.target.x,
        targetY: controls.target.y,
        targetZ: controls.target.z,
        cameraX: controls.object.position.x,
        cameraY: controls.object.position.y,
        cameraZ: controls.object.position.z,
        zoom: controls.zoom
    })
    .to({
        rotationY: originalRotation.y + rotationAngle,
        globeX: globeOffset,
        targetX: defaultTarget.x + globeOffset,
        targetY: defaultTarget.y,
        targetZ: defaultTarget.z,
        cameraX: defaultCameraPosition.x + globeOffset,
        cameraY: defaultCameraPosition.y,
        cameraZ: defaultCameraPosition.z,
        zoom: 1
    }, 1500)
    .easing(TWEEN.Easing.Cubic.InOut)
    .onUpdate((obj) => {
        scene.rotation.y = obj.rotationY;
        globeContainer.position.x = obj.globeX;
        controls.target.set(obj.targetX, obj.targetY, obj.targetZ);
        controls.object.position.set(obj.cameraX, obj.cameraY, obj.cameraZ);
        controls.zoom = obj.zoom;
        controls.update();
    })
    .onComplete(() => {
        controls.enabled = true;
    })
    .start();

    // Show text
    document.getElementById('about-text').style.opacity = 1;
    document.getElementById('about-text-hidden').style.pointerEvents = 'auto';
    document.getElementById('about-text').style.pointerEvents = 'none';


    isGlobeRolledOut = true;
    addEventBlocker();

}



function rollGlobeBackIn(event) {
    if (!isGlobeRolledOut) return;

    // Prevent default action and stop propagation
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }

    resetRibbonCreation();

    // Disable controls during animation
    controls.enabled = false;

    // Animate everything back to original state
    new TWEEN.Tween({
        rotationY: scene.rotation.y,
        globeX: globeContainer.position.x,
        targetX: controls.target.x,
        targetY: controls.target.y,
        targetZ: controls.target.z,
        cameraX: controls.object.position.x,
        cameraY: controls.object.position.y,
        cameraZ: controls.object.position.z,
        zoom: controls.zoom
    })
    .to({
        rotationY: originalRotation.y,
        globeX: originalPosition.x,
        targetX: originalControlsState.target.x,
        targetY: originalControlsState.target.y,
        targetZ: originalControlsState.target.z,
        cameraX: originalControlsState.position.x,
        cameraY: originalControlsState.position.y,
        cameraZ: originalControlsState.position.z,
        zoom: originalControlsState.zoom
    }, 1500)
    .easing(TWEEN.Easing.Cubic.InOut)
    .onUpdate((obj) => {
        scene.rotation.y = obj.rotationY;
        globeContainer.position.x = obj.globeX;
        controls.target.set(obj.targetX, obj.targetY, obj.targetZ);
        controls.object.position.set(obj.cameraX, obj.cameraY, obj.cameraZ);
        controls.zoom = obj.zoom;
        controls.update();
    })
    .onComplete(() => {
        controls.enabled = true;
    })
    .start();

    // Hide text
    document.getElementById('about-text').style.opacity = 0;
    document.getElementById('about-text-hidden').style.pointerEvents = 'none';
    document.getElementById('about-text').style.pointerEvents = 'none';


    isGlobeRolledOut = false;

    removeEventBlocker();

}

function addEventBlocker() {
    const blocker = document.createElement('div');
    blocker.id = 'event-blocker';
    blocker.style.position = 'fixed';
    blocker.style.top = '0';
    blocker.style.left = '0';
    blocker.style.width = '100%';
    blocker.style.height = '100%';
    blocker.style.zIndex = '1000';
    blocker.style.cursor = 'pointer';
    
    blocker.addEventListener('click', rollGlobeBackIn);
    document.body.appendChild(blocker);
}

function removeEventBlocker() {
    const blocker = document.getElementById('event-blocker');
    if (blocker) {
        blocker.removeEventListener('click', rollGlobeBackIn);
        document.body.removeChild(blocker);
    }
}


const clock = new THREE.Clock();

// Animate and render loop
function animate() {
    const deltaTime = clock.getDelta();

    requestAnimationFrame(animate);

    TWEEN.update(); // Add this line to update TWEEN

    if (isTransitioning) {
        // Interpolate camera position
        controls.object.position.lerp(targetPosition, 0.05);
        
        // Interpolate target
        controls.target.lerp(targetTarget, 0.05);

        // Check if we're close enough to end the transition
        if (controls.object.position.distanceTo(targetPosition) < 0.01 &&
            controls.target.distanceTo(targetTarget) < 0.01) {
            isTransitioning = false;
            console.log("Transition complete");
        }
    }

   // Update all ribbons' matrices
   globeContainer.updateMatrixWorld(true);  // Force update of world matrix

    // Get the world position of your light
    const lightWorldPosition = new THREE.Vector3();
    sunLight.getWorldPosition(lightWorldPosition);

   ribbons.forEach(ribbon => {
        ribbon.updateMatrixWorld(true);
        if (ribbon.animator) {
            ribbon.animator.update(deltaTime);
        }
        if (ribbon.material.uniforms) {
            ribbon.material.uniforms.globeCenter.value.copy(globeContainer.position);
        }

        ribbon.material.updateLightPosition(lightWorldPosition);
    });
    previewRibbon.material.updateLightPosition(lightWorldPosition);


    activeSparkleSystems = activeSparkleSystems.filter(sparkles => {
        const isDead = sparkles.userData.update();
        if (isDead) {
            globeContainer.remove(sparkles);
            sparkles.children.forEach(sprite => {
                sprite.material.dispose();
            });
        }
        return !isDead;
    });

    activityManager.update();

//    controls.update();
//updateAtmosphere();
updateAtmosphericFog();

    renderer.render(scene, camera);
}


animate();



// Handle window resize
window.addEventListener('resize', () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
});





function drawDot(ctx, label) {
    const { x, y, dotSize, cityType, fontSize } = label;
    const canvasWidth = ctx.canvas.width;

    function drawShape(x, y) {
        if (cityType === "primary") {
            const size = fontSize * 0.5; // Size relative to font size
            ctx.fillStyle = 'white';
            ctx.strokeStyle = 'black';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.rect(x - size/2, y - size/2, size, size);
            ctx.fill();
            ctx.stroke();
        } else if (cityType === "admin") {
            const size = fontSize * 0.4; // Size relative to font size
            ctx.fillStyle = 'black';
            ctx.beginPath();
            ctx.rect(x - size/2, y - size/2, size, size);
            ctx.fill();
        } else if (0) {
            // Regular city dot
            const size = fontSize * 0.3; // Size relative to font size
            ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
            ctx.beginPath();
            ctx.arc(x, y, size/2, 0, Math.PI * 2);
            ctx.fill();
        } 
    }

    // Draw the main shape
    drawShape(x, y);

    // Draw on the other side if near the edge
    if (x < 20) {
        drawShape(x + canvasWidth, y);
    } else if (x > canvasWidth - 20) {
        drawShape(x - canvasWidth, y);
    }
}

function getTextBoundingBox(ctx, text, font) {
    ctx.font = font;
    const metrics = ctx.measureText(text);

    const actualBoundingBoxAscent = metrics.actualBoundingBoxAscent || 0;
    const actualBoundingBoxDescent = metrics.actualBoundingBoxDescent || 0;
    const actualBoundingBoxLeft = metrics.actualBoundingBoxLeft || 0;
    const actualBoundingBoxRight = metrics.actualBoundingBoxRight || metrics.width;

    const width = actualBoundingBoxRight - actualBoundingBoxLeft;
    const height = actualBoundingBoxAscent + actualBoundingBoxDescent;

    return { width, height };
}

function filterCities(cities, maxPerCountry = 150, minCityPopulation = 30000) {
    const countryMap = new Map();
    
    return cities.filter(city => {
        const iso3 = city.iso3;
        
        // Only proceed if the city's population is greater than the minimum threshold
        if (city.population > minCityPopulation) {
            if (!countryMap.has(iso3)) {
                countryMap.set(iso3, 1);
                return true;
            } else if (countryMap.get(iso3) < maxPerCountry) {
                countryMap.set(iso3, countryMap.get(iso3) + 1);
                return true;
            }
        }
        return false;
    });
}



function scaleEquirectangular(fontSize, x, y, canvasHeight) {
    // Calculate the latitude (in radians) based on the y-coordinate
    const latitude = ((y / canvasHeight) - 0.5) * Math.PI;
    
    // Calculate the scale factor based on the latitude with dampening
    const dampeningFactor = 0.5; // Adjust this value to control the maximum size increase
    const rawScaleFactor = 1 / Math.cos(latitude);
    const scaleFactor = 1 + (rawScaleFactor - 1) * dampeningFactor;
    
    // Clamp the scale factor to prevent excessive growth
    const minScaleFactor = 0.5;
    const maxScaleFactor = 2.0;
    const clampedScaleFactor = Math.max(minScaleFactor, Math.min(maxScaleFactor, scaleFactor));
    
    // Apply the clamped scale factor to the font size
    return fontSize * clampedScaleFactor;
}

function createCityLabels(citiesData) {

    const filteredCities = citiesData; //filterCities(citiesData, 150);
//    const filteredCities = citiesData;
    const { canvas, ctx } = createOverlayTexture(8192, 4096);

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';
    ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)';
    ctx.lineWidth = 1;

    const labels = [];
    const placedCities = []; // New array to store successfully placed cities
    
    filteredCities.forEach(city => {
        const { lat, lng: lon, population, city: cityName, capital: cityType } = city;
        const { importance, size: dotSize } = calculateDotSize(population);

        let x = ((lon + 180) / 360) * canvas.width;
        let y = ((90 - lat) / 180) * canvas.height;

        if (x > canvas.width) x -= canvas.width;
        if (x < 0) x += canvas.width;

        let fontSize = (cityType === 'primary' 
            ? Math.max(26, Math.min(22, importance * 26))
            : 2+Math.max(14, Math.min(22, importance * 22)))
            / (8192/canvas.width)
            ;

        if (fontSize<14) { fontSize = 14 }

        fontSize = scaleEquirectangular(fontSize, x, y, canvas.height);

        ctx.font = `bold ${fontSize}px Montserrat, sans-serif`;

        const { width, height } = getTextBoundingBox(ctx, (cityType == "primary" || cityType == "admin") ? cityName.toUpperCase() : cityName, `bold ${fontSize}px Montserrat, sans-serif`);

        labels.push({
            x, y,
            dotSize,        
            fontSize: fontSize,
            width: width,
            height: height,
            lat: lat,
            lng: lon,
            population: population,
            name: cityName,
            cityType: cityType,
            importance
        });
    });

    // First, separate primary cities and other cities
    const primaryCities = labels.filter(label => label.cityType === 'primary');
    const otherCities = labels.filter(label => label.cityType !== 'primary');
    
    // Sort other cities by importance
    otherCities.sort((a, b) => b.importance - a.importance);

    // Combine the arrays, with primary cities first (in their original order),
    // followed by other cities sorted by importance
    const sortedLabels = [...primaryCities, ...otherCities];

        for (const label of sortedLabels) {

        const squareSize = (label.cityType === 'primary' ? 14 : (label.dotSize * 600)+5) / (8192/canvas.width);
        const rightMargin = 2; //2 / (8192/canvas.width);
        const leftMargin = 4; // 8 / (8192/canvas.width); // Increased margin for left side to avoid overlap
        const positionTmps = [
            { dx: -(label.width + squareSize + leftMargin), dy: label.height / 2 }, // Left
            { dx: squareSize + rightMargin, dy: label.height / 2 }, // Right
            { dx: -label.width / 2, dy: -(8 / (8192/canvas.width)) }, // Top
            { dx: -label.width / 2, dy: label.height + 2 } // Bottom
        ];

        let positions = positionTmps;
      

        if (label.name != "Gaza") { 
           // console.log('poses');
            positions = sortPositionsByWaterCoverage(positionTmps, label.x, label.y, label.width, label.height);

          //  console.log(positions);
        }

        let placed = false;
        for (const pos of positions) {
            const testX = label.x + pos.dx;
            const testY = label.y + pos.dy;

            if (!checkCanvasCollision(ctx, testX, testY, label.width, label.height)) { // Added buffer to width
                drawDot(ctx, label); 
                label.labelX = testX;
                label.labelY = testY;
                drawLabel(ctx, label);
                placed = true;

                placedCities.push({
                    name: label.name,
                    lat: label.lat,
                    lng: label.lng,
                    population: label.population,
                    capital: label.cityType
                });
                
                break;
            }
        }

        if (!placed) {
        // console.log(`Couldn't place label for ${label.name}`);
        }
    };

  //  writeCSV(placedCities);


    const overlayTexture = new THREE.CanvasTexture(canvas);
    overlayTexture.wrapS = THREE.RepeatWrapping;
    overlayTexture.wrapT = THREE.ClampToEdgeWrapping;
    overlayTexture.needsUpdate = true;

    const overlayMaterial = new THREE.MeshPhysicalMaterial({
        map: overlayTexture,
        normalMap: globalNormalTexture,
        normalScale: new THREE.Vector2(-0.8, 0.8),
        clearcoat: 1,
        transparent: true,
        roughness: 0.3,
        metalness: 0,
//        specular: new THREE.Color(0x333333),
        blending: THREE.NormalBlending,
        depthTest: true,
        depthWrite: true
    });

    const overlaySphere = new THREE.Mesh(
        new THREE.SphereGeometry(radius * 1.005, 128, 128),
        overlayMaterial
    );
    globeContainer.add(overlaySphere);
    
}


function writeCSV(cities) {
    let csvContent = "city,lat,lng,population,capital\n";
    
    cities.forEach(city => {
        const row = [
            `"${city.name.replace(/"/g, '""')}"`,
            city.lat,
            city.lng,
            city.population,
            `"${city.capital}"`
        ].join(',');
        csvContent += row + "\n";
    });

//    console.log(csvContent); // Still logging to console for debugging

    // Create Blob and download file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "assets/new_placed_cities.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

function drawLabel(ctx, label) {
    const { x, y, labelX, labelY, height, fontSize, name, cityType, width, placement } = label;
    const canvasWidth = ctx.canvas.width;
    
    function drawText(dotX, textX, textY) {
        if (cityType === "primary") {
            ctx.letterSpacing = "1px";
            ctx.font = `bold ${fontSize}px Montserrat, sans-serif`;
            ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
            ctx.shadowBlur = 3;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 0;
            ctx.fillStyle = 'rgba(0, 0, 0, 1)';
            const uppercaseName = name.toUpperCase();
            ctx.fillText(uppercaseName, textX, textY);
        } else if (cityType === "admin") {
            ctx.font = `${fontSize}px Montserrat, sans-serif`;
            ctx.shadowBlur = 2;
            ctx.fillStyle = 'rgba(0, 0, 0, 1)';
            const uppercaseName = name.toUpperCase();
            ctx.fillText(uppercaseName, textX, textY);
        
        } else if (0) {
            ctx.font = `${fontSize}px Montserrat, sans-serif`;
            ctx.shadowBlur = 2;
            ctx.fillStyle = 'rgba(0, 0, 0, 1)';
            ctx.fillText(name, textX, textY);
        }
    }

    // Calculate the start and end positions of the label
    const startX = labelX;
    const endX = labelX + ctx.measureText(name).width;

    // Check if the label crosses the meridian
    if (startX < 0 || endX > canvasWidth) {
        // Split the label into two parts
        const visibleWidth = Math.min(endX, canvasWidth) - Math.max(startX, 0);
        const splitIndex = Math.floor((visibleWidth / ctx.measureText(name).width) * name.length);

        // Draw the visible part
        ctx.save();
        ctx.beginPath();
        ctx.rect(0, 0, canvasWidth, ctx.canvas.height);
        ctx.clip();
        drawText(x, labelX, labelY);
        ctx.restore();

        // Draw the wrapped part
        ctx.save();
        ctx.beginPath();
        if (startX < 0) {
            ctx.rect(0, 0, -startX, ctx.canvas.height);
            drawText(x + canvasWidth, labelX + canvasWidth, labelY);
        } else {
            ctx.rect(canvasWidth, 0, endX - canvasWidth, ctx.canvas.height);
            drawText(x - canvasWidth, labelX - canvasWidth, labelY);
        }
        ctx.clip();
        ctx.restore();
    } else {
        // Label doesn't cross the meridian, draw normally
        drawText(x, labelX, labelY);
    }
}


function checkCanvasCollision(ctx, x, y, width, height, extraPadding = 6) {
    const padding = extraPadding; // Increased padding for more accurate detection

    width = Math.max(1, Math.abs(width));
    height = Math.max(1, Math.abs(height));

    const startX = Math.max(0, x - padding);
    const startY = Math.max(0, y - height - padding);
    const adjustedWidth = Math.min(ctx.canvas.width - startX, width + 2 * padding);
    const adjustedHeight = Math.min(ctx.canvas.height - startY, height + 2 * padding);

    if (adjustedWidth <= 0 || adjustedHeight <= 0) {
        return true; // Treat as collision to avoid drawing in this case
    }

    try {
        const imageData = ctx.getImageData(startX, startY, adjustedWidth, adjustedHeight);
        const pixels = imageData.data;

        for (let i = 0; i < pixels.length; i += 4) {
            if (pixels[i + 3] !== 0) {  // Check alpha channel for collision
                return true; // Collision detected
            }
        }
    } catch (error) {
        return true; // Treat as collision in case of error
    }

    return false; // No collision
}


// Modify your loadData function to use the new createCityLabels function
function loadData(citiesUrl, callback) {
    Papa.parse(citiesUrl, {
        download: true,
        header: true,
        complete: function(results) {
            callback(results.data);
        }
    });
}

function showUnsavedIndicator() {
    document.querySelectorAll('.unsaved-indicator').forEach(indicator => {
        indicator.style.display = 'block';
    });
}

function hideUnsavedIndicator() {
    document.querySelectorAll('.unsaved-indicator').forEach(indicator => {
        indicator.style.display = 'none';
    });
}

function showStatus(text) {
    const statusElement = document.getElementById('status-update');
    
    // Clear any existing timeout
    if (statusTimeout) { 
      clearTimeout(statusTimeout);
   }
    
    // Set the text and show the status
    statusElement.textContent = text;
    statusElement.style.opacity = '1';
    
    // Set a timeout to start fading out the status after 1.5 seconds
    statusTimeout = setTimeout(() => {
      statusElement.style.opacity = '0';
      
      // Remove the text after the fade-out transition (0.5s)
      setTimeout(() => {
        statusElement.textContent = '';
      }, 500);
    }, 1500);
  }

document.getElementById('about').onclick = function(event) {
    event.stopPropagation();
    rollGlobeOutAndShowText();
    
}

document.getElementById('random').addEventListener('click', function(e) {
    e.stopPropagation();
    goToRandomLocation();
});

document.getElementById('save').addEventListener('click', function(e) {
    e.stopPropagation();
    showStatus('Saving...');

    updateFirebase();
});

document.getElementById('link').addEventListener('click', function(e) {
    e.stopPropagation();
    navigator.clipboard.writeText(window.location.href).then(function() {
        showStatus('Link copied to clipboard!');
    }, function(err) {
        console.error('Could not copy text: ', err);
    });
});


// Enable pointer events on controls
document.getElementById('controls').style.pointerEvents = 'auto';
