// Define common card types
const CARD_TYPES = {
    TRADE_GOODS: {
        card_type: "trade_goods",
        type: "trading-card",
        category: "Trade opportunity",
        buttonText: "Buy Item"
    },
    GENERIC_ITEMS: {
        card_type: "generic_items",
        type: "trading-card",
        category: "Useful objects",
        buttonText: "Buy Item"
    },
    TRAVEL_DOCUMENTS: {
        card_type: "travel_documents",
        type: "visa-card",
        category: "Diplomatic opportunity",
        buttonText: "✎ Sign Here",
        image: "/assets/passport2.jpg"
    }
};

// Define common penalty types
const PENALTIES = {
    CONFISCATE: "confiscate",
    TAX: "tax",
    INSPECTION: "inspection",
    BAN: "ban"
};

// Create the normalized card database
export const cardDatabase = {
    // TRADE GOODS
    "mexican_chilies": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Mexican Chilies",
        message: "Spicy chilies from Mexico",
        fun: 10,
        buyPrice: 2000,
        salesPrice: {
            default: 10000,
            "US": 5000
        },
        acceptedIn: ["US", "GB", "FR", "DE", "ES"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 500,
            countries: ["US","DK"]
        },
        specificCountries: {
            origin: ["MX"],
            destination: ["US", "GB", "FR", "DE", "ES"]
        }
    },

    "portuguese_sardines": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Premium Portuguese Sardines",
        message: "Premium tinned sardines in olive oil from Portugal's pristine Atlantic waters",
        fun: 5,
        buyPrice: 50,
        salesPrice: {
            default: 300,
            "FR": 450,
            "IT": 400
        },
        acceptedIn: ["FR", "GB", "DE", "ES", "IT", "US"],
        rarity: 0.3,
        borderRestrictions: {
            probability: 0.15,
            penalty: PENALTIES.INSPECTION,
            penaltyAmount: 100,
            countries: ["US", "DE"]
        },
        specificCountries: {
            origin: ["PT"],
            destination: ["FR", "GB", "DE", "ES", "IT", "US"]
        }
    },

    "norwegian_salmon": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Fresh Norwegian Salmon",
        message: "Premium fresh salmon from Norwegian fjords",
        fun: 5,
        buyPrice: 2400,
        salesPrice: {
            default: 6000,
            "JP": 12000,
            "US": 10000,
            "FR": 7000
        },
        acceptedIn: ["JP", "US", "FR", "GB", "DE", "IT"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.7,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 1000,
            countries: ["JP", "US"]
        },
        specificCountries: {
            origin: ["NO"],
            destination: ["JP", "US", "FR", "GB", "DE", "IT"]
        }
    },

    "turkish_carpets": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Hand-woven Turkish Carpet",
        message: "Intricately designed traditional Turkish carpet",
        buyPrice: 2800,
        salesPrice: {
            default: 3500,
            "US": 20000,
            "GB": 4800,
            "DE": 4700
        },
        acceptedIn: ["US", "GB", "DE", "FR", "IT", "ES"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 2000,
            countries: ["US", "GB"]
        },
        specificCountries: {
            origin: ["TR"],
            destination: ["US", "GB", "DE", "FR", "IT", "ES"]
        }
    },

    "japanese_manga": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Limited Edition Manga Collection",
        message: "Rare Japanese manga volumes with exclusive artwork",
        fun: 10,
        buyPrice: 300,
        salesPrice: {
            default: 4000,
            "US": 3000,
            "FR": 2000,
            "DE": 1500
        },
        acceptedIn: ["US", "FR", "DE", "GB", "IT", "ES"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["US"]
        },
        specificCountries: {
            origin: ["JP"],
            destination: ["US", "FR", "DE", "GB", "IT", "ES"]
        }
    },

    "swiss_watches": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Swiss Luxury Watch",
        message: "Precision-crafted timepiece from Switzerland",
        buyPrice: 12000,
        salesPrice: {
            default: 16000,
            "CN": 30000,
            "US": 30000,
            "RU": 28000,
            "SA": 28000,

        },
        acceptedIn: ["CN", "US", "GB", "FR", "DE", "JP"],
        rarity: 0.9,
        borderRestrictions: {
            probability: 0.6,
            penalty: PENALTIES.TAX,
            penaltyAmount: 10000,
            countries: ["CN", "US"]
        },
        specificCountries: {
            origin: ["CH"],
            destination: ["CN", "US", "GB", "FR", "DE", "JP"]
        }
    },

    "korean_kimchi": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Artisanal Kimchi",
        message: "Traditional fermented Korean delicacy",
        fun: 5,
        buyPrice: 100,
        salesPrice: {
            default: 600,
            "US": 1500,
            "JP": 450,
            "CN": 650
        },
        acceptedIn: ["US", "JP", "CN", "GB", "FR", "DE"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.8,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 400,
            countries: ["US", "JP", "GB"]
        },
        specificCountries: {
            origin: ["KR"],
            destination: ["US", "JP", "CN", "GB", "FR", "DE"]
        }
    },

    "italian_truffles": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Alba White Truffles",
        message: "Rare and precious white truffles from Alba, Italy",
        fun: 5,
        buyPrice: 2500,
        salesPrice: {
            default: 18000,
            "JP": 25000,
            "US": 22000,
            "FR": 4000
        },
        acceptedIn: ["JP", "US", "FR", "GB", "DE", "CH"],
        rarity: 0.95,
        borderRestrictions: {
            probability: 0.7,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 3000,
            countries: ["JP", "US", "GB"]
        },
        specificCountries: {
            origin: ["IT"],
            destination: ["JP", "US", "FR", "GB", "DE", "CH"]
        }
    },

    "australian_opal": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Australian Black Opal",
        message: "Rare black opal from Lightning Ridge, Australia",
        buyPrice: 8000,
        salesPrice: {
            default: 16000,
            "CN": 22000,
            "US": 20000,
            "JP": 18000
        },
        acceptedIn: ["CN", "US", "JP", "GB", "FR", "DE"],
        rarity: 0.85,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 4000,
            countries: ["CN", "US"]
        },
        specificCountries: {
            origin: ["AU"],
            destination: ["CN", "US", "JP", "GB", "FR", "DE"]
        }
    },

    "brazilian_acai": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Premium Açaí Berries",
        message: "Superfood berries from the Amazon rainforest",
        fun: 5,
        buyPrice: 180,
        salesPrice: {
            default: 500,
            "US": 800,
            "AU": 750,
            "KR": 700
        },
        acceptedIn: ["US", "AU", "KR", "SG", "CA", "NZ"],
        rarity: 0.9,
        borderRestrictions: {
            probability: 0.5,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 600,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["BR"],
            destination: ["US", "AU", "KR", "SG", "CA", "NZ"]
        }
    },

    "moroccan_argan": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Pure Argan Oil",
        message: "Traditional beauty oil from Moroccan Argan trees",
        fun: 5,
        buyPrice: 125,
        salesPrice: {
            default: 400,
            "AE": 700,
            "SA": 650,
            "FR": 600
        },
        acceptedIn: ["AE", "SA", "FR", "TR", "EG", "QA"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 800,
            countries: ["AE", "SA"]
        },
        specificCountries: {
            origin: ["MA"],
            destination: ["AE", "SA", "FR", "TR", "EG", "QA"]
        }
    },

    "peruvian_alpaca": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Alpaca Wool Poncho",
        message: "Hand-woven alpaca wool clothing from the Andes",
        buyPrice: 900,
        salesPrice: {
            default: 6000,
            "CA": 13000,
            "NO": 12500,
            "RU": 8000
        },
        acceptedIn: ["CA", "NO", "RU", "SE", "FI", "IS"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1000,
            countries: ["NO", "SE"]
        },
        specificCountries: {
            origin: ["PE"],
            destination: ["CA", "NO", "RU", "SE", "FI", "IS"]
        }
    },

    "ethiopian_coffee": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Yirgacheffe Coffee Beans",
        message: "Premium single-origin Ethiopian coffee",
        fun: 10,
        buyPrice: 800,
        salesPrice: {
            default: 3500,
            "SE": 6000,
            "FI": 5500,
            "NL": 5000
        },
        acceptedIn: ["SE", "FI", "NL", "DK", "NO", "BE"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 400,
            countries: ["FI", "SE"]
        },
        specificCountries: {
            origin: ["ET"],
            destination: ["SE", "FI", "NL", "DK", "NO", "BE"]
        }
    },

    "vietnamese_pearls": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Halong Bay Pearls",
        message: "Cultured pearls from Vietnamese waters",
        buyPrice: 2600,
        salesPrice: {
            default: 4000,
            "IN": 5000,
            "AE": 14000,
            "MY": 5000
        },
        acceptedIn: ["IN", "AE", "MY", "TH", "ID", "PH"],
        rarity: 0.3,
        borderRestrictions: {
            probability: 0.5,
            penalty: PENALTIES.TAX,
            penaltyAmount: 2000,
            countries: ["IN", "AE"]
        },
        specificCountries: {
            origin: ["VN"],
            destination: ["IN", "AE", "MY", "TH", "ID", "PH"]
        }
    },

    "namibian_diamonds": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Rough Pink Diamonds",
        message: "Rare pink diamonds from Namibian mines",
        buyPrice: 6000,
        salesPrice: {
            default: 35000,
            "HK": 40000,
            "IL": 40000
        },
        acceptedIn: ["HK", "IL", "BE"],
        rarity: 0.3,
        borderRestrictions: {
            probability: 0.8,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 10000,
            countries: ["HK", "IL", "BE"]
        },
        specificCountries: {
            origin: ["NA"],
            destination: ["HK", "IL", "BE"]
        }
    },

    "cuban_cigars": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Vintage Cuban Cigars",
        message: "Premium aged Cuban cigars",
        fun: 10,
        buyPrice: 1500,
        salesPrice: {
            default: 8000,
            "CH": 12000,
            "AE": 11000,
            "RU": 10000
        },
        acceptedIn: ["CH", "AE", "RU", "MC"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.9,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 2000,
            countries: ["US", "MC", "CH"]
        },
        specificCountries: {
            origin: ["CU"],
            destination: ["CH", "AE", "RU", "MC"]
        }
    },

    "russian_caviar": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Siberian Sturgeon Caviar",
        message: "Premium caviar from Siberian sturgeon",
        fun: 10,
        buyPrice: 3000,
        salesPrice: {
            default: 15000,
            "FR": 9000,
            "JP": 12000,
            "SG": 10000
        },
        acceptedIn: ["FR", "JP", "SG", "CH", "MC", "AE", "HK", "QA"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 3000,
            countries: ["FR", "JP", "SG", "CH", "MC", "AE", "HK", "QA"]
        },
        specificCountries: {
            origin: ["RU"],
            destination: ["FR", "JP", "SG", "CH", "MC", "AE", "HK", "QA"]
        }
    },

    "new_zealand_wool": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Merino Wool",
        message: "Premium New Zealand merino wool",
        buyPrice: 600,
        salesPrice: {
            default: 4000,
            "IT": 7000,
            "GB": 6000,
            "DE": 5500
        },
        acceptedIn: ["IT", "GB", "DE", "FR", "US", "JP", "KR", "CN", "SE", "DK", "NO", "CH", "AT"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.8,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["IT", "GB", "DE"]
        },
        specificCountries: {
            origin: ["NZ"],
            destination: ["IT", "GB", "DE", "FR", "US", "JP", "KR", "CN", "SE", "DK", "NO", "CH", "AT"]
        }
    },

    "tahitian_vanilla": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Tahitian Vanilla Pods",
        message: "Rare vanilla variety from French Polynesia",
        fun: 10,
        buyPrice: 3400,
        salesPrice: {
            default: 6000,
            "FR": 9000,
            "BE": 8500,
            "CH": 8000
        },
        acceptedIn: ["FR", "BE", "CH", "US", "JP", "DE", "IT", "ES", "NL", "DK", "SE", "NO", "FI", "AT", "PT", "IE", "CA", "AU", "NZ", "SG"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 800,
            countries: ["EU", "US", "AU"]
        },
        specificCountries: {
            origin: ["PF"],
            destination: ["FR", "BE", "CH", "US", "JP", "DE", "IT", "ES", "NL", "DK", "SE", "NO", "FI", "AT", "PT", "IE", "CA", "AU", "NZ", "SG"]
        }
    },

    "bhutanese_saffron": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Himalayan Red Saffron",
        message: "Rare high-altitude saffron from Bhutan",
        buyPrice: 2800,
        salesPrice: {
            default: 6000,
            "IN": 8000,
            "AE": 6000
        },
        acceptedIn: ["IN", "AE", "SA", "QA", "KW", "OM"],
        rarity: 0.9,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1500,
            countries: ["AE", "SA"]
        },
        specificCountries: {
            origin: ["BT"],
            destination: ["IN", "AE", "SA", "QA", "KW", "OM"]
        }
    },

    "taiwanese_chips": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Taiwanese Chips",
        message: "Cutting-edge potato chips from Taiwan",
        fun: 5,
        buyPrice: 35,
        salesPrice: {
            default: 250,
            "US": 150,
            "KR": 220,
            "JP": 250
        },
        acceptedIn: ["US", "KR", "JP", "DE", "NL", "IL", "SG", "MY", "VN", "MX", "BR", "IN", "GB", "FR", "IT", "CA"],
        rarity: 0.85,
        borderRestrictions: {
            probability: 0.7,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 5000,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["TW"],
            destination: ["US", "KR", "JP", "DE", "NL", "IL", "SG", "MY", "VN", "MX", "BR", "IN", "GB", "FR", "IT", "CA"]
        }
    },

    "canadian_maple": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Pure Maple Syrup",
        message: "Premium Canadian maple syrup from Quebec",
        fun: 5,
        buyPrice: 450,
        salesPrice: {
            default: 2500,
            "JP": 4500,
            "KR": 4000,
            "US": 3500
        },
        acceptedIn: ["JP", "KR", "US", "GB", "FR", "DE", "AU", "NZ", "SG", "HK", "TW", "DK", "SE", "NO", "FI", "NL", "BE", "CH", "AT", "IE", "IS", "ES", "PT", "IT"],
        rarity: 0.3,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 200,
            countries: ["EU", "JP"]
        },
        specificCountries: {
            origin: ["CA"],
            destination: ["JP", "KR", "US", "GB", "FR", "DE", "AU", "NZ", "SG", "HK", "TW", "DK", "SE", "NO", "FI", "NL", "BE", "CH", "AT", "IE", "IS", "ES", "PT", "IT"]
        }
    },

    "uzbek_silk": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Hand-woven Silk Ikat",
        message: "Traditional Uzbek silk fabric with intricate patterns",
        buyPrice: 1400,
        salesPrice: {
            default: 2000,
            "TR": 3000,
            "RU": 2500,
            "AE": 3000
        },
        acceptedIn: ["TR", "RU", "AE", "KZ", "KG", "TJ"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 800,
            countries: ["TR", "AE"]
        },
        specificCountries: {
            origin: ["UZ"],
            destination: ["TR", "RU", "AE", "KZ", "KG", "TJ"]
        }
    },

    "chilean_lithium": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Battery-Grade Lithium",
        message: "High-purity lithium from Atacama Desert",
        buyPrice: 12000,
        salesPrice: {
            default: 20000,
            "CN": 25000,
            "KR": 22000,
            "DE": 20000
        },
        acceptedIn: ["CN", "KR", "DE", "US", "JP", "GB", "FR", "SE", "PL", "HU", "CZ", "SK"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.5,
            penalty: PENALTIES.TAX,
            penaltyAmount: 6000,
            countries: ["US", "EU"]
        },
        specificCountries: {
            origin: ["CL"],
            destination: ["CN", "KR", "DE", "US", "JP", "GB", "FR", "SE", "PL", "HU", "CZ", "SK"]
        }
    },

    "greek_antiquities": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Ancient Greek Pottery",
        message: "Authenticated ancient Greek ceramic artifacts",
        buyPrice: 5000,
        salesPrice: {
            default: 15000,
            "GB": 12500,
            "DE": 12000
        },
        acceptedIn: ["GB", "DE", "FR"],
        rarity: 0.95,
        borderRestrictions: {
            probability: 0.9,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 20000,
            countries: ["GB", "DE", "FR", "IT", "ES"]
        },
        specificCountries: {
            origin: ["GR"],
            destination: ["GB", "DE", "FR"]
        }
    },

    "indian_spices": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Kerala Spice Collection",
        message: "Premium spice blend from Kerala region",
        fun: 5,
        buyPrice: 1200,
        salesPrice: {
            default: 3000,
            "GB": 5000,
            "US": 4500,
            "AE": 4000
        },
        acceptedIn: ["GB", "US", "AE", "SA", "AU", "CA", "SG", "MY", "MU", "KE", "TZ", "ZA", "NG"],
        rarity: 0.4,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 400,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["IN"],
            destination: ["GB", "US", "AE", "SA", "AU", "CA", "SG", "MY", "MU", "KE", "TZ", "ZA", "NG"]
        }
    },

    "kpop_collectibles": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Limited Edition K-pop Box Set",
        message: "Rare K-pop album with exclusive photocards and merchandise",
        fun: 15,
        buyPrice: 2200,
        salesPrice: {
            default: 3500,
            "ID": 6000,
            "TH": 5500,
            "PH": 5000
        },
        acceptedIn: ["ID", "TH", "PH", "MY", "VN", "SG", "BR", "MX", "PE", "CL", "US", "FR", "DE"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["KR"],
            destination: ["ID", "TH", "PH", "MY", "VN", "SG", "BR", "MX", "PE", "CL", "US", "FR", "DE"]
        }
    },

    "lava_salt": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Icelandic Lava Salt",
        message: "Hand-harvested sea salt infused with volcanic minerals from Iceland",
        fun: 5,
        buyPrice: 1200,
        salesPrice: {
            default: 5000,
            "JP": 8000,
            "US": 7000,
            "FR": 6500
        },
        acceptedIn: ["JP", "US", "FR", "DE", "GB", "CA", "AU", "CH"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["IS"],
            destination: ["JP", "US", "FR", "DE", "GB", "CA", "AU", "CH"]
        }
    },

    "mongolian_cashmere": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Mongolian Cashmere",
        message: "Ultra-soft cashmere wool from the Gobi Desert, prized for its quality",
        buyPrice: 3000,
        salesPrice: {
            default: 12000,
            "IT": 18000,
            "JP": 7000,
            "US": 8000
        },
        acceptedIn: ["IT", "JP", "US", "GB", "FR", "DE", "CH", "KR", "CN"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 2000,
            countries: ["EU", "US"]
        },
        specificCountries: {
            origin: ["MN"],
            destination: ["IT", "JP", "US", "GB", "FR", "DE", "CH", "KR", "CN"]
        }
    },

    "salt_lamp": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Himalayan Pink Salt Lamps",
        message: "Hand-carved salt lamps from the Himalayan mountains, believed to purify the air",
        buyPrice: 800,
        salesPrice: {
            default: 3000,
            "US": 5000,
            "DE": 4500,
            "AU": 4000
        },
        acceptedIn: ["US", "DE", "AU", "GB", "CA", "FR", "IT", "JP", "KR"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["NP"],
            destination: ["US", "DE", "AU", "GB", "CA", "FR", "IT", "JP", "KR"]
        }
    },

    "belgian_chocolate": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Artisanal Praline Selection",
        message: "Hand-crafted Belgian chocolates from master chocolatiers",
        fun: 5,
        buyPrice: 100,
        salesPrice: {
            default: 2000,
            "JP": 2500,
            "AE": 3000,
            "SG": 2500
        },
        acceptedIn: ["JP", "AE", "SG", "HK", "KR"],
        rarity: 0.4,
        borderRestrictions: {
            probability: 0.6,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 300,
            countries: ["AU", "NZ", "US"]
        },
        specificCountries: {
            origin: ["BE"],
            destination: ["JP", "AE", "SG", "HK", "KR"]
        }
    },

    "scottish_whisky": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Rare Single Malt Whisky",
        message: "Limited edition Highland single malt",
        fun: 10,
        buyPrice: 1800,
        salesPrice: {
            default: 3000,
            "CN": 4000,
            "SG": 4800,
            "JP": 4600
        },
        acceptedIn: ["CN", "SG", "JP", "KR", "HK", "TW", "VN", "TH", "MY", "AE", "IN"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 2000,
            countries: ["ID", "MY"]
        },
        specificCountries: {
            origin: ["GB"],
            destination: ["CN", "SG", "JP", "KR", "HK", "TW", "VN", "TH", "MY", "AE", "IN"]
        }
    },

    "bollywood_costumes": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Designer Bollywood Outfit",
        message: "Authentic designer costume from famous Bollywood production",
        fun: 15,
        buyPrice: 1600,
        salesPrice: {
            default: 4000,
            "GB": 5000,
            "CA": 6000,
            "AU": 8000
        },
        acceptedIn: ["GB", "CA", "AU", "US", "NZ", "SG", "MY", "MU"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1000,
            countries: ["GB", "US"]
        },
        specificCountries: {
            origin: ["IN"],
            destination: ["GB", "CA", "AU", "US", "NZ", "SG", "MY", "MU"]
        }
    },

    "italian_handbag": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Limited Edition Florentine Leather Bag",
        message: "Handcrafted designer bag from Florence artisans",
        fun: 15,
        buyPrice: 5500,
        salesPrice: {
            default: 10000,
            "CN": 15000,
            "KR": 10000,
            "RU": 10000
        },
        acceptedIn: ["CN", "KR", "RU", "JP", "SG", "HK", "TW", "MY", "TH", "VN", "ID", "AE", "SA", "QA", "KW", "US", "CA", "BR", "MX", "AU"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 3000,
            countries: ["CN", "BR"]
        },
        specificCountries: {
            origin: ["IT"],
            destination: ["CN", "KR", "RU", "JP", "SG", "HK", "TW", "MY", "TH", "VN", "ID", "AE", "SA", "QA", "KW", "US", "CA", "BR", "MX", "AU"]
        }
    },

    "spanish_fan": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Antique Hand-painted Fan",
        message: "Classic Spanish abanico with detailed artwork",
        fun: 5,
        buyPrice: 300,
        salesPrice: {
            default: 1500,
            "JP": 1600,
            "US": 1000,
            "AR": 600
        },
        acceptedIn: ["JP", "US", "AR", "MX", "CL"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["US"]
        },
        specificCountries: {
            origin: ["ES"],
            destination: ["JP", "US", "AR", "MX", "CL"]
        }
    },

    "vinyl_records": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "City Pop Vinyl Collection",
        message: "Rare Japanese 80s city pop records, suddenly trending worldwide",
        fun: 5,
        buyPrice: 1300,
        salesPrice: {
            default: 5000,
            "US": 8000,
            "KR": 7500,
            "AU": 7000
        },
        acceptedIn: ["US", "KR", "AU", "GB", "FR", "DE", "SE", "NL"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 400,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["JP"],
            destination: ["US", "KR", "AU", "GB", "FR", "DE", "SE", "NL"]
        },
        specificCities: {
            origin: ["Ōsaka", "Tokyo", "Nagoya"]
        }
    },

    "limited_sneakers": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Exclusive Collab Sneakers",
        message: "Limited edition anime x streetwear collaboration sneakers",
        fun: 5,
        buyPrice: 2500,
        salesPrice: {
            default: 6000,
            "CN": 5000,
            "SG": 7000,
            "US": 7000,
            "JP": 1800
        },
        acceptedIn: ["CN", "SG", "US", "JP", "KR", "HK", "TW", "TH", "MY", "ID", "PH", "GB", "FR", "DE", "IT"],
        rarity: 0.85,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 2000,
            countries: ["CN", "IT"]
        },
        specificCountries: {
            origin: ["JP"],
            destination: ["CN", "SG", "US", "JP", "KR", "HK", "TW", "TH", "MY", "ID", "PH", "GB", "FR", "DE", "IT"]
        }
    },

    "indie_games": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Physical Indie Game Bundle",
        message: "Limited physical release of popular indie games with collector items",
        fun: 15,
        buyPrice: 1200,
        salesPrice: {
            default: 3000,
            "JP": 6000,
            "KR": 5500,
            "DE": 5000
        },
        acceptedIn: ["JP", "KR", "DE", "US", "GB", "FR", "SE", "PL", "CZ", "ES", "IT"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["BR"]
        },
        specificCountries: {
            origin: ["US"],
            destination: ["JP", "EU", "KR", "DE", "US", "GB", "FR", "SE", "PL", "CZ", "ES", "IT"]
        },
        specificCities: {
            origin: ["Los Angeles", "New York", "Boston", "Providence", "Chicago", "San Francisco", "Charlotte", "Brooklyn", "Albany", "San Diego", "Pittsburgh", "Salt Lake City", "San Jose", "Sacramento", "Portland", "Seattle", "Austin"]
        }
    },

    "instant_noodles": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Ultra-Spicy Noodle Challenge Pack",
        message: "Viral social media spicy noodle challenge collection",
        fun: 5,
        buyPrice: 50,
        salesPrice: {
            default: 1000,
            "US": 2500,
            "GB": 2000,
            "BR": 1800
        },
        acceptedIn: ["US", "GB", "BR", "MX", "FR", "DE", "ES", "IT", "PL", "SE", "NO", "DK", "FI", "NL", "BE"],
        rarity: 0.4,
        borderRestrictions: {
            probability: 0.5,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 100,
            countries: ["AU", "NZ","DK"]
        },
        specificCountries: {
            origin: ["KR"],
            destination: ["US", "GB", "BR", "MX", "FR", "DE", "ES", "IT", "PL", "SE", "NO", "DK", "FI", "NL", "BE"]
        }
    },

    "vtuber_merch": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "VTuber Limited Merch Box",
        message: "Exclusive virtual YouTuber merchandise and collectibles",
        fun: 10,
        buyPrice: 1250,
        salesPrice: {
            default: 4000,
            "ID": 7000,
            "PH": 6500,
            "US": 6000
        },
        acceptedIn: ["ID", "PH", "US", "MY", "SG", "TH", "VN"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["JP"],
            destination: ["ID", "PH", "US", "MY", "SG", "TH", "VN"]
        }
    },

    "artisan_keyboard": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Custom Mechanical Keyboard",
        message: "Handcrafted mechanical keyboard with rare switches and artisan keycaps",
        buyPrice: 500,
        salesPrice: {
            default: 1500,
            "US": 2000,
            "SG": 2600,
            "SE": 2300
        },
        acceptedIn: ["US", "SG", "SE", "NO", "FI", "DK", "DE", "NL"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1000,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["JP"],
            destination: ["US", "SG", "SE", "NO", "FI", "DK", "DE", "NL"]
        }
    },

    "colombian_flowers": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Premium Rose Collection",
        message: "Special preserved roses from Bogotá Savanna, worlds largest flower exporter after Netherlands",
        fun: 5,
        buyPrice: 1200,
        salesPrice: {
            default: 3000,
            "US": 5000,
            "RU": 4500,
            "JP": 4000
        },
        acceptedIn: ["US", "RU", "JP", "GB", "CA", "DE"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.6,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 400,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["CO"],
            destination: ["US", "RU", "JP", "GB", "CA", "DE"]
        }
    },

    "ethiopian_teff": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Premium Teff Flour",
        message: "High-protein ancient grain flour, now global superfood export",
        fun: 10,
        buyPrice: 1050,
        salesPrice: {
            default: 2500,
            "US": 4000,
            "IL": 3500,
            "DE": 3000
        },
        acceptedIn: ["US", "IL", "DE", "GB", "CA", "SE", "AU", "NL"],
        rarity: 0.4,
        borderRestrictions: {
            probability: 0.5,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["ET"],
            destination: ["US", "IL", "DE", "GB", "CA", "SE", "AU", "NL"]
        }
    },

    "ghana_cocoa_butter": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Raw Cocoa Butter Block",
        message: "Premium cocoa butter from worlds top quality cocoa producer",
        fun: 10,
        buyPrice: 2300,
        salesPrice: {
            default: 5000,
            "CH": 8000,
            "BE": 7500,
            "DE": 7000
        },
        acceptedIn: ["CH", "BE", "DE", "FR", "IT", "US", "CA", "JP"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 600,
            countries: ["EU"]
        },
        specificCountries: {
            origin: ["GH"],
            destination: ["CH", "BE", "DE", "FR", "IT", "US", "CA", "JP"]
        }
    },

    "egyptian_cotton": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Extra-Long Staple Cotton",
        message: "Premium hand-picked Giza cotton, worlds finest long-staple cotton",
        buyPrice: 2800,
        salesPrice: {
            default: 6000,
            "IT": 9000,
            "JP": 8500,
            "CH": 8000
        },
        acceptedIn: ["IT", "JP", "CH", "DE", "FR", "GB", "US", "TR", "IN"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 800,
            countries: ["EU", "IT", "JP", "CH", "DE", "FR", "GB", "US", "TR", "IN"]
        },
        specificCountries: {
            origin: ["EG"],
            destination: ["IT", "JP", "CH", "DE", "FR", "GB", "US", "TR", "IN"]
        }
    },

    "kenyan_purple_tea": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Purple Tea Leaves",
        message: "Rare purple-leaf tea variety unique to Kenyan highlands",
        fun: 15,
        buyPrice: 2200,
        salesPrice: {
            default: 4000,
            "GB": 7000,
            "RU": 6500,
            "AE": 6000
        },
        acceptedIn: ["GB", "RU", "AE", "JP", "CN", "DE", "FR", "US"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 400,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["KE"],
            destination: ["GB", "RU", "AE", "JP", "CN", "DE", "FR", "US"]
        }
    },

    "chilean_carmenere": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Carménère Wine",
        message: "Rediscovered wine variety thought extinct, now Chiles signature grape",
        fun: 15,
        buyPrice: 1300,
        salesPrice: {
            default: 5000,
            "GB": 8000,
            "US": 7500,
            "BR": 7000
        },
        acceptedIn: ["GB", "US", "BR", "CA", "DE", "DK", "BE", "NL", "CH"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 600,
            countries: ["US"]
        },
        specificCountries: {
            origin: ["CL"],
            destination: ["GB", "US", "BR", "CA", "DE", "DK", "BE", "NL", "CH"]
        }
    },

    "lebanon_olive_oil": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Ancient Grove Olive Oil",
        message: "Extra virgin oil from thousand-year-old mountain groves",
        fun: 10,
        buyPrice: 1250,
        salesPrice: {
            default: 4500,
            "FR": 4500,
            "US": 4000,
            "CA": 6500
        },
        acceptedIn: ["FR", "US", "CA", "GB", "DE", "AU", "BR", "AE"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["EU"]
        },
        specificCountries: {
            origin: ["LB"],
            destination: ["FR", "US", "CA", "GB", "DE", "AU", "BR", "AE"]
        }
    },

    "rooibos_tea": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Red Rooibos Tea",
        message: "Protected designation tea grown only in South African Cederberg mountains",
        fun: 10,
        buyPrice: 1150,
        salesPrice: {
            default: 2000,
            "DE": 3500,
            "NL": 4000,
            "JP": 4500
        },
        acceptedIn: ["DE", "NL", "JP", "GB", "US", "CA", "AU", "FR", "SE", "DK"],
        rarity: 0.4,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["AU"]
        },
        specificCountries: {
            origin: ["ZA"],
            destination: ["DE", "NL", "JP", "GB", "US", "CA", "AU", "FR", "SE", "DK"]
        }
    },

    "damascus_rosewater": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Damascus Rose Water",
        message: "Traditional rose water from Damascene Rose, used in Middle Eastern cuisine and perfumes",
        fun: 15,
        buyPrice: 2180,
        salesPrice: {
            default: 3500,
            "FR": 6000,
            "TR": 5500,
            "DE": 5000
        },
        acceptedIn: ["FR", "TR", "DE", "GB", "US", "SA", "AE", "LB", "JO"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 400,
            countries: ["EU"]
        },
        specificCountries: {
            origin: ["SY"],
            destination: ["FR", "TR", "DE", "GB", "US", "SA", "AE", "LB", "JO"]
        }
    },

    "nigerian_nollywood_dvds": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Nigerian Nollywood DVDs",
        message: "Popular Nollywood movies on DVD from Nigeria",
        fun: 5,
        buyPrice: 100,
        salesPrice: {
            default: 1600,
            "GH": 1300
        },
        acceptedIn: ["GH", "CI", "CM", "SN", "KE", "ZA"],
        rarity: 0.9,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 200,
            countries: ["ZA"]
        },
        specificCountries: {
            origin: ["NG"],
            destination: ["GH", "CI", "CM", "SN", "KE", "ZA"]
        }
    },

    "turkish_evil_eye_amulets": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Turkish Evil Eye Amulets",
        message: "Protective evil eye amulets from Turkey",
        fun: 5,
        buyPrice: 80,
        salesPrice: {
            default: 500,
            "GR": 250
        },
        acceptedIn: ["GR", "BG", "RO", "AL", "MK", "CY"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 150,
            countries: ["CY"]
        },
        specificCountries: {
            origin: ["TR"],
            destination: ["GR", "BG", "RO", "AL", "MK", "CY"]
        }
    },

    "russian_matryoshka_dolls": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Russian Matryoshka Dolls",
        message: "Traditional nesting dolls from Russia",
        fun: 5,
        buyPrice: 120,
        salesPrice: {
            default: 700,
            "UA": 500
        },
        acceptedIn: ["UA", "BY", "KZ", "PL", "LT", "LV"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 250,
            countries: ["PL"]
        },
        specificCountries: {
            origin: ["RU"],
            destination: ["UA", "BY", "KZ", "PL", "LT", "LV"]
        }
    },

    "thai_silk_scarves": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Thai Silk Scarves",
        message: "Handwoven silk scarves from Thailand, known for their vibrant colors and intricate patterns",
        buyPrice: 300,
        salesPrice: {
            default: 1500,
            "FR": 2500,
            "US": 2000,
            "JP": 1800
        },
        acceptedIn: ["FR", "US", "JP", "GB", "DE", "IT", "AU", "SG"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 200,
            countries: ["EU"]
        },
        specificCountries: {
            origin: ["TH"],
            destination: ["FR", "US", "JP", "GB", "DE", "IT", "AU", "SG"]
        }
    },

    "indonesian_batik": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Indonesian Batik Fabric",
        message: "Traditional hand-dyed batik fabric from Indonesia, a UNESCO cultural heritage",
        buyPrice: 200,
        salesPrice: {
            default: 1000,
            "NL": 1500,
            "US": 1200,
            "JP": 1100
        },
        acceptedIn: ["NL", "US", "JP", "SG", "MY", "AU"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 150,
            countries: ["EU"]
        },
        specificCountries: {
            origin: ["ID"],
            destination: ["NL", "US", "JP", "SG", "MY", "AU"]
        }
    },

    "mexican_tequila": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Aged Tequila",
        message: "Premium aged tequila from Jalisco, Mexico, made from blue agave",
        fun: 10,
        buyPrice: 500,
        salesPrice: {
            default: 2000,
            "US": 3000,
            "ES": 2500,
            "JP": 2200
        },
        acceptedIn: ["US", "ES", "JP", "GB", "DE", "CA"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["US", "EU"]
        },
        specificCountries: {
            origin: ["MX"],
            destination: ["US", "ES", "JP", "GB", "DE", "CA"]
        }
    },

    "vietnamese_pho_kit": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Pho Cooking Kit",
        message: "Authentic Vietnamese pho kit with spices, noodles, and broth mix",
        fun: 5,
        buyPrice: 150,
        salesPrice: {
            default: 600,
            "US": 1000,
            "AU": 900,
            "FR": 800
        },
        acceptedIn: ["US", "AU", "FR", "GB", "CA", "DE"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 200,
            countries: ["AU"]
        },
        specificCountries: {
            origin: ["VN"],
            destination: ["US", "AU", "FR", "GB", "CA", "DE"]
        }
    },

    "polish_amber_jewelry": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Polish Amber Necklace",
        message: "Handcrafted amber jewelry from the Baltic coast of Poland",
        buyPrice: 400,
        salesPrice: {
            default: 1500,
            "DE": 2000,
            "RU": 1800,
            "CN": 1700
        },
        acceptedIn: ["DE", "RU", "CN", "US", "GB", "FR"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["PL"],
            destination: ["DE", "RU", "CN", "US", "GB", "FR"]
        }
    },

    "jamaican_blue_mountain_coffee": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Blue Mountain Coffee",
        message: "Rare and highly sought-after coffee from Jamaica's Blue Mountains",
        fun: 10,
        buyPrice: 800,
        salesPrice: {
            default: 3000,
            "JP": 5000,
            "US": 4000,
            "GB": 3500
        },
        acceptedIn: ["JP", "US", "GB", "CA", "DE", "KR"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["JP"]
        },
        specificCountries: {
            origin: ["JM"],
            destination: ["JP", "US", "GB", "CA", "DE", "KR"]
        }
    },

    "iranian_saffron": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Persian Saffron",
        message: "The world's most expensive spice, hand-picked in Iran",
        fun: 10,
        buyPrice: 1000,
        salesPrice: {
            default: 5000,
            "AE": 7000,
            "ES": 6000,
            "IN": 5500
        },
        acceptedIn: ["AE", "ES", "IN", "FR", "DE", "US"],
        rarity: 0.9,
        borderRestrictions: {
            probability: 0.5,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1000,
            countries: ["US"]
        },
        specificCountries: {
            origin: ["IR"],
            destination: ["AE", "ES", "IN", "FR", "DE", "US"]
        }
    },

    "cuban_rum": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Aged Cuban Rum",
        message: "Premium aged rum from Cuba, a favorite among connoisseurs",
        fun: 10,
        buyPrice: 600,
        salesPrice: {
            default: 2500,
            "FR": 3500,
            "CA": 3000,
            "JP": 2800
        },
        acceptedIn: ["FR", "CA", "JP", "GB", "DE", "AU"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.6,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 1000,
            countries: ["US"]
        },
        specificCountries: {
            origin: ["CU"],
            destination: ["FR", "CA", "JP", "GB", "DE", "AU"]
        }
    },

    "american_bourbon": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Kentucky Bourbon",
        message: "Premium small-batch bourbon from Kentucky, aged in charred oak barrels",
        fun: 10,
        buyPrice: 500,
        salesPrice: {
            default: 2000,
            "US": 1000,
            "JP": 3500,
            "SG": 3000,
            "AU": 2800
        },
        acceptedIn: ["JP", "SG", "AU", "GB", "CA", "KR"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["AU"]
        },
        specificCountries: {
            origin: ["US"],
            destination: ["JP", "SG", "AU", "GB", "CA", "KR"]
        },
        specificCities: {
            origin: ["Knoxville", "Louisville", "Nashville", "Memphis", "Raleigh", "Tulsa", "Charlotte"]
        }
    },

    "hawaiian_pineapple": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Hawaiian Pineapple",
        message: "Sweet Hawaiian pineapples, grown in volcanic soil under tropical sun",
        fun: 20,
        buyPrice: 250,
        salesPrice: {
            default: 2000,
            "US": 3000,
            "JP": 3500,
            "SG": 3000,
            "AU": 2800
        },
        acceptedIn: ["JP", "SG", "AU", "GB", "CA", "KR"],
        rarity: 1,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["AU"]
        },
        specificCountries: {
            origin: ["US"],
            destination: ["JP", "MX", "EU", "SG", "AU", "GB", "CA", "KR"]
        },
        specificCities: {
            origin: ["Honolulu", "Kona"]
        }
    },

    "azorean_cheese": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Azorean Cheese",
        message: "Azorean São Jorge cheese, aged in sea-misted caves on volcanic islands",
        fun: 20,
        buyPrice: 200,
        salesPrice: {
            default: 2000,
            "US": 2500,
            "JP": 2500,
            "SG": 2000,
            "AU": 1800
        },
        acceptedIn: ["JP", "US", "SG", "MX", "BR", "AU", "GB", "CA", "KR"],
        rarity: 1,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["AU"]
        },
        specificCountries: {
            origin: ["PT"],
            destination: ["JP", "US", "SG", "MX", "BR", "AU", "GB", "CA", "KR"]
        },
        specificCities: {
            origin: ["Ponta Delgada", "Angra do Heroismo"]
        }
    },

    "french_perfume": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Grasse Perfume",
        message: "Luxury perfume from Grasse, France, the world's perfume capital",
        fun: 15,
        buyPrice: 800,
        salesPrice: {
            default: 3000,
            "AE": 5000,
            "CN": 4500,
            "RU": 4000
        },
        acceptedIn: ["AE", "CN", "RU", "JP", "SG", "HK"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1000,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["FR"],
            destination: ["AE", "CN", "RU", "JP", "SG", "HK"]
        }
    },

    "german_engineering_tools": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Precision Engineering Tools",
        message: "High-quality precision tools made in Germany, used in manufacturing and construction",
        buyPrice: 1000,
        salesPrice: {
            default: 5000,
            "CN": 8000,
            "IN": 7000,
            "MX": 6000
        },
        acceptedIn: ["CN", "IN", "MX", "BR", "KR", "JP"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1000,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["DE"],
            destination: ["CN", "IN", "MX", "BR", "KR", "JP"]
        }
    },

    "south_african_wine": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Stellenbosch Wine",
        message: "Premium wine from South Africa's Stellenbosch region, known for its rich flavors",
        fun: 10,
        buyPrice: 400,
        salesPrice: {
            default: 2000,
            "CN": 3500,
            "JP": 3000,
            "RU": 2800
        },
        acceptedIn: ["CN", "JP", "RU", "SG", "HK", "KR"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["ZA"],
            destination: ["CN", "JP", "RU", "SG", "HK", "KR"]
        }
    },

    "italian_espresso_machines": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Italian Espresso Machine",
        message: "High-end espresso machines from Italy, prized by coffee enthusiasts worldwide",
        buyPrice: 1500,
        salesPrice: {
            default: 6000,
            "KR": 9000,
            "CN": 8500,
            "AU": 8000
        },
        acceptedIn: ["KR", "CN", "AU", "JP", "SG", "HK"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1500,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["IT"],
            destination: ["KR", "CN", "AU", "JP", "SG", "HK"]
        }
    },

    "nigerian_art": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Nigerian Contemporary Art",
        message: "Vibrant contemporary art pieces from Nigeria, gaining global recognition",
        buyPrice: 800,
        salesPrice: {
            default: 4000,
            "US": 6000,
            "GB": 5500,
            "CN": 5000
        },
        acceptedIn: ["US", "GB", "CN", "FR", "DE", "AU"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1000,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["NG"],
            destination: ["US", "GB", "CN", "FR", "DE", "AU"]
        }
    },

    "dutch_tulip_bulbs": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Dutch Tulip Bulbs",
        message: "Rare tulip bulbs from the Netherlands, a symbol of Dutch horticulture",
        fun: 5,
        buyPrice: 100,
        salesPrice: {
            default: 800,
            "JP": 1500,
            "CN": 1200,
            "US": 1000
        },
        acceptedIn: ["JP", "CN", "US", "KR", "AU", "SG"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 200,
            countries: ["AU"]
        },
        specificCountries: {
            origin: ["NL"],
            destination: ["JP", "CN", "US", "KR", "AU", "SG"]
        }
    },
    // 25 new trading goods for countries not yet covered with diverse destination countries

    "icelandic_gaming_chair": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Icelandic Gaming Chair",
        message: "Ergonomic gaming chair with Nordic design and geothermal-powered heating elements",
        buyPrice: 1800,
        salesPrice: {
            default: 5000,
            "US": 3500,
            "KR": 8000,
            "BR": 6500
        },
        acceptedIn: ["US", "KR", "BR", "UA", "PL", "ZA", "NZ", "CL"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1800,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["IS"],
            destination: ["US", "KR", "BR", "UA", "PL", "ZA", "NZ", "CL"]
        }
    },


    "finnish_sauna_equipment": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Finnish Sauna Essentials",
        message: "Premium sauna accessories from Finland including birch whisks and thermal timer",
        fun: 10,
        buyPrice: 350,
        salesPrice: {
            default: 1800,
            "RU": 3000,
            "CA": 2800,
            "NO": 2500
        },
        acceptedIn: ["RU", "CA", "NO", "IL", "TR", "NZ", "CH"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["FI"],
            destination: ["RU", "CA", "NO", "IL", "TR", "NZ", "CH"]
        }
    },

    "hungarian_paprika": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Premium Hungarian Paprika",
        message: "Authentic Hungarian paprika from Kalocsa region, the red gold of Hungary",
        fun: 5,
        buyPrice: 150,
        salesPrice: {
            default: 900,
            "US": 1500,
            "AT": 1200,
            "HR": 1100
        },
        acceptedIn: ["US", "AT", "HR", "CZ", "SK", "RO", "RS", "SI"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 200,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["HU"],
            destination: ["US", "AT", "HR", "CZ", "SK", "RO", "RS", "SI"]
        }
    },

    "czech_crystal": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Bohemian Crystal Glassware",
        message: "Hand-crafted Czech crystal with centuries-old glass-making tradition",
        buyPrice: 1300,
        salesPrice: {
            default: 5000,
            "AE": 8000,
            "RU": 7500,
            "IN": 7000
        },
        acceptedIn: ["AE", "RU", "IN", "AR", "BR", "PL", "UA", "CN"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1000,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["CZ"],
            destination: ["AE", "RU", "IN", "AR", "BR", "PL", "UA", "CN"]
        }
    },

    "singaporean_orchids": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Singapore Hybrid Orchids",
        message: "Rare hybrid orchids developed in Singapore's national gardens",
        fun: 5,
        buyPrice: 500,
        salesPrice: {
            default: 2500,
            "TH": 4000,
            "MY": 3500,
            "ID": 3000
        },
        acceptedIn: ["TH", "MY", "ID", "VN", "QA", "BR", "MX", "CO"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.6,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 600,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["SG"],
            destination: ["TH", "MY", "ID", "VN", "QA", "BR", "MX", "CO"]
        }
    },

    "irish_whiskey": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Triple Distilled Irish Whiskey",
        message: "Premium Irish whiskey aged in sherry casks for exceptional smoothness",
        fun: 10,
        buyPrice: 700,
        salesPrice: {
            default: 3500,
            "ZA": 5000,
            "AE": 4500,
            "CO": 4200
        },
        acceptedIn: ["ZA", "AE", "CO", "PE", "MY", "SA", "EG", "KE"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 800,
            countries: ["MY", "ID"]
        },
        specificCountries: {
            origin: ["IE"],
            destination: ["ZA", "AE", "CO", "PE", "MY", "SA", "EG", "KE"]
        }
    },

    "croatian_truffles": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Istrian Black Truffles",
        message: "Rare black truffles from Croatia's Istrian peninsula, rivaling Italian varieties",
        fun: 25,
        buyPrice: 2000,
        salesPrice: {
            default: 10000,
            "UY": 15000,
            "ES": 4000,
            "CL": 13000
        },
        acceptedIn: ["UY", "ES", "CL", "PE", "PT", "BR", "CO", "MX"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.5,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 2000,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["HR"],
            destination: ["UY", "ES", "CL", "PE", "PT", "BR", "CO", "MX"]
        }
    },

    "argentinian_street_art": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Buenos Aires Street Art Prints",
        message: "Limited edition prints from renowned Argentinian street artists",
        fun: 15,
        buyPrice: 400,
        salesPrice: {
            default: 2000,
            "CL": 3500,
            "BR": 3000,
            "MX": 2800
        },
        acceptedIn: ["CL", "BR", "MX", "CO", "PE", "UY", "ES", "PT"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["AR"],
            destination: ["CL", "BR", "MX", "CO", "PE", "UY", "ES", "PT"]
        },
        specificCities: {
            origin: ["Buenos Aires"],
        }
    },

    "romanian_handmade_blouse": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Traditional Romanian IE Blouse",
        message: "Hand-embroidered Romanian blouse, recently popularized by high fashion designers",
        fun: 5,
        buyPrice: 300,
        salesPrice: {
            default: 1500,
            "FR": 2500,
            "IT": 2200,
            "MA": 2000
        },
        acceptedIn: ["FR", "IT", "MA", "TN", "DZ", "BG", "MD", "GR"],
        rarity: 0.55,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 250,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["RO"],
            destination: ["FR", "IT", "MA", "TN", "DZ", "BG", "MD", "GR"]
        }
    },

    "philippines_mango_products": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Premium Philippine Mangoes",
        message: "Carabao mangoes from the Philippines, considered the sweetest mangoes in the world",
        fun: 10,
        buyPrice: 100,
        salesPrice: {
            default: 600,
            "KR": 1200,
            "JP": 1000,
            "HK": 900
        },
        acceptedIn: ["KR", "JP", "HK", "TW", "VN", "MY", "NZ", "CA"],
        rarity: 0.4,
        borderRestrictions: {
            probability: 0.5,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 200,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["PH"],
            destination: ["KR", "JP", "HK", "TW", "VN", "MY", "NZ", "CA"]
        }
    },

    "estonian_digital_nft": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Estonian Digital Art NFT",
        message: "Blockchain-authenticated digital art from Estonia's thriving tech scene",
        fun: 5,
        buyPrice: 500,
        salesPrice: {
            default: 2500,
            "FI": 4000,
            "LV": 3500,
            "LT": 3200
        },
        acceptedIn: ["FI", "LV", "LT", "SE", "PL", "NO", "DK", "DE"],
        rarity: 0.9,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["EE"],
            destination: ["FI", "LV", "LT", "SE", "PL", "NO", "DK", "DE"]
        }
    },

    "ukrainian_honey": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Ukrainian Sunflower Honey",
        message: "Pure honey from Ukraine's vast sunflower fields, known for unique flavor",
        fun: 5,
        buyPrice: 100,
        salesPrice: {
            default: 600,
            "PL": 1000,
            "LT": 900,
            "BY": 800
        },
        acceptedIn: ["PL", "LT", "BY", "SK", "CZ", "MD", "LV", "RO"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 150,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["UA"],
            destination: ["PL", "LT", "BY", "SK", "CZ", "MD", "LV", "RO"]
        }
    },

    "new_zealand_manuka_honey": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Medical-Grade Manuka Honey",
        message: "Rare New Zealand honey with potent antibacterial properties",
        fun: 5,
        buyPrice: 400,
        salesPrice: {
            default: 2000,
            "SG": 3500,
            "MY": 3000,
            "ID": 2800
        },
        acceptedIn: ["SG", "MY", "ID", "TH", "PH", "VN", "HK", "KR"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 400,
            countries: ["AU"]
        },
        specificCountries: {
            origin: ["NZ"],
            destination: ["SG", "MY", "ID", "TH", "PH", "VN", "HK", "KR"]
        }
    },

    "swedish_design_furniture": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Swedish Designer Chair",
        message: "Limited edition minimalist chair from renowned Swedish designer",
        buyPrice: 1600,
        salesPrice: {
            default: 8000,
            "QA": 12000,
            "SA": 11000,
            "AE": 10000
        },
        acceptedIn: ["QA", "SA", "AE", "KW", "BH", "OM", "JO", "EG"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1500,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["SE"],
            destination: ["QA", "SA", "AE", "KW", "BH", "OM", "JO", "EG"]
        }
    },

    "greek_olive_oil": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Kalamata Extra Virgin Olive Oil",
        message: "Award-winning olive oil from ancient Greek groves in the Peloponnese",
        fun: 5,
        buyPrice: 200,
        salesPrice: {
            default: 1000,
            "TR": 1800,
            "IL": 1500,
            "CY": 1400
        },
        acceptedIn: ["TR", "IL", "CY", "LB", "JO", "EG", "IT", "ES"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 200,
            countries: ["AU"]
        },
        specificCountries: {
            origin: ["GR"],
            destination: ["TR", "IL", "CY", "LB", "JO", "EG", "IT", "ES"]
        }
    },

    "jamaican_hot_sauce": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Jamaican Scotch Bonnet Sauce",
        message: "Ultra-spicy authentic Jamaican hot sauce made with rare Scotch Bonnet peppers",
        fun: 15,
        buyPrice: 80,
        salesPrice: {
            default: 400,
            "TT": 800,
            "BS": 700,
            "BB": 650
        },
        acceptedIn: ["TT", "BS", "BB", "DO", "CU", "PR", "LC", "TC"],
        rarity: 0.6,
        borderRestrictions: [{
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 100,
            countries: ["AU", "NZ","DK"]
        },
        {
            probability: 1,
            penalty: PENALTIES.BAN,
            penaltyAmount: 100,
            countries: ["DK"]
        }
        ],
        specificCountries: {
            origin: ["JM"],
            destination: ["TT", "BS", "BB", "DO", "CU", "PR", "LC", "TC"]
        }
    },

    "latvian_amber_jewelry": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Baltic Amber Jewelry",
        message: "Hand-crafted jewelry featuring 40-million-year-old Baltic amber from Latvia",
        buyPrice: 250,
        salesPrice: {
            default: 1200,
            "FI": 2000,
            "EE": 1800,
            "NO": 1600
        },
        acceptedIn: ["FI", "EE", "NO", "SE", "LT", "DK", "PL", "BY"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 200,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["LV"],
            destination: ["FI", "EE", "NO", "SE", "LT", "DK", "PL", "BY"]
        }
    },

    "kazakh_space_memorabilia": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Baikonur Cosmodrome Collectibles",
        message: "Authentic space program memorabilia from Kazakhstan's Baikonur Cosmodrome",
        buyPrice: 600,
        salesPrice: {
            default: 3000,
            "RU": 5000,
            "UZ": 4500,
            "KG": 4000
        },
        acceptedIn: ["RU", "UZ", "KG", "TJ", "TM", "CN", "MN", "AZ"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 600,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["KZ"],
            destination: ["RU", "UZ", "KG", "TJ", "TM", "CN", "MN", "AZ"]
        }
    },

    "kenyan_marathon_shoes": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Kenyan Elite Runner Shoes",
        message: "Specialized running shoes designed with input from Kenyan marathon champions",
        fun: 10,
        buyPrice: 900,
        salesPrice: {
            default: 3000,
            "ET": 5000,
            "TZ": 4500,
            "UG": 4200
        },
        acceptedIn: ["ET", "TZ", "UG", "RW", "NG", "ZA", "GH", "EG"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["KE"],
            destination: ["ET", "TZ", "UG", "RW", "NG", "ZA", "GH", "EG"]
        }
    },

    "colombian_emeralds": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Colombian Emerald Jewelry",
        message: "Fine jewelry featuring premium emeralds from Colombia's legendary mines",
        buyPrice: 3000,
        salesPrice: {
            default: 5000,
            "BR": 6000,
            "PA": 6000,
            "FR": 12000
        },
        acceptedIn: ["FR", "BR", "PA", "MX", "PE", "EC", "CL", "VE", "DO"],
        rarity: 0.85,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 3000,
            countries: ["IN"]
        },
        specificCountries: {
            origin: ["CO"],
            destination: ["FR", "BR", "PA", "MX", "PE", "EC", "CL", "VE", "DO"]
        }
    },

    "malaysian_durian_products": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Premium Musang King Durian Products",
        message: "Luxury durian treats made from Malaysia's prized Musang King variety",
        fun: 15,
        buyPrice: 300,
        salesPrice: {
            default: 1500,
            "SG": 3000,
            "ID": 2500,
            "TH": 2200
        },
        acceptedIn: ["SG", "ID", "TH", "CN", "HK", "TW", "VN", "PH"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.8,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 500,
            countries: ["US", "EU", "AU"]
        },
        specificCountries: {
            origin: ["MY"],
            destination: ["SG", "ID", "TH", "CN", "HK", "TW", "VN", "PH"]
        }
    },

    "austrian_crystal_instruments": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Crystal Music Instrument",
        message: "Handcrafted glass harmonica made with Austrian crystal, producing ethereal sounds",
        fun: 20,
        buyPrice: 1800,
        salesPrice: {
            default: 3000,
            "IL": 12000,
            "SG": 11000,
            "AU": 10000
        },
        acceptedIn: ["CH", "LI", "SI", "HU", "CZ", "SK", "DE", "IT"],
        rarity: 0.9,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1500,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["AT"],
            destination: ["CH", "LI", "SI", "HU", "CZ", "SK", "DE", "IT"]
        }
    },

    "dominican_cigars": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Dominican Premium Cigars",
        message: "Hand-rolled premium cigars from the Dominican Republic, rival to Cuban varieties",
        fun: 10,
        buyPrice: 800,
        salesPrice: {
            default: 2000,
            "US": 7000,
            "FR": 6500,
            "IT": 6000,
            "ES": 5000
        },
        acceptedIn: ["FR","IT","ES","HT", "PR", "JM", "BS", "CU", "PA", "CR", "MX"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 800,
            countries: ["AU", "SG"]
        },
        specificCountries: {
            origin: ["DO"],
            destination: ["FR","IT","ES","HT", "PR", "JM", "BS", "CU", "PA", "CR", "MX"]
        }
    },
    // 25 new trading goods focusing on South and Central America

    "peruvian_pisco": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Premium Peruvian Pisco",
        message: "Artisanal grape brandy from Peru's wine regions, essential for the famous Pisco Sour",
        fun: 15,
        buyPrice: 400,
        salesPrice: {
            default: 2000,
            "CL": 3500,
            "EC": 3000,
            "AR": 2800
        },
        acceptedIn: ["CL", "EC", "AR", "BO", "CO", "BR", "MX", "US"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 400,
            countries: ["CL"]
        },
        specificCountries: {
            origin: ["PE"],
            destination: ["CL", "EC", "AR", "BO", "CO", "BR", "MX", "US"]
        }
    },

    "bolivian_silver_jewelry": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Potosí Silver Jewelry",
        message: "Intricate jewelry crafted from silver mined in the historic Potosí mines of Bolivia",
        buyPrice: 600,
        salesPrice: {
            default: 3000,
            "PE": 5000,
            "BR": 4500,
            "AR": 4200
        },
        acceptedIn: ["PE", "BR", "AR", "PY", "UY", "CL", "CO", "EC"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 600,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["BO"],
            destination: ["PE", "BR", "AR", "PY", "UY", "CL", "CO", "EC"]
        }
    },

    "mate_gourds": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Premium Uruguayan Mate Set",
        message: "Complete set with silver-trimmed gourd, bombilla straw, and premium yerba mate",
        fun: 10,
        buyPrice: 200,
        salesPrice: {
            default: 1000,
            "AR": 2000,
            "BR": 1800,
            "PY": 1600
        },
        acceptedIn: ["AR", "BR", "PY", "CL", "BO", "PE", "CO", "ES"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 150,
            countries: ["AU"]
        },
        specificCountries: {
            origin: ["UY"],
            destination: ["AR", "BR", "PY", "CL", "BO", "PE", "CO", "ES"]
        }
    },

    "ecuadorian_chocolate": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Single-Origin Ecuadorian Chocolate",
        message: "Premium chocolate made from Ecuador's prized Arriba Nacional cacao beans",
        fun: 15,
        buyPrice: 300,
        salesPrice: {
            default: 1500,
            "CO": 2500,
            "PE": 2300,
            "PA": 2100
        },
        acceptedIn: ["CO", "PE", "PA", "MX", "BR", "CR", "VE", "US"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["EC"],
            destination: ["CO", "PE", "PA", "MX", "BR", "CR", "VE", "US"]
        }
    },

    "paraguayan_ñandutí_lace": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Ñandutí Lace Textiles",
        message: "Delicate spider-web lace textiles, a traditional Paraguayan craft with Guaraní origins",
        buyPrice: 150,
        salesPrice: {
            default: 800,
            "BR": 1500,
            "AR": 1300,
            "UY": 1200
        },
        acceptedIn: ["BR", "AR", "UY", "BO", "CL", "PE", "ES", "IT"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 100,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["PY"],
            destination: ["BR", "AR", "UY", "BO", "CL", "PE", "ES", "IT"]
        }
    },

    "venezuelan_rum": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Aged Venezuelan Rum",
        message: "Premium dark rum aged in oak barrels, award-winning spirit from Venezuela",
        fun: 10,
        buyPrice: 600,
        salesPrice: {
            default: 2800,
            "CO": 4500,
            "PA": 4000,
            "TR": 3800
        },
        acceptedIn: ["CO", "PA", "TR", "DO", "JM", "BS", "ES", "IT"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 600,
            countries: ["US"]
        },
        specificCountries: {
            origin: ["VE"],
            destination: ["CO", "PA", "TR", "DO", "JM", "BS", "ES", "IT"]
        }
    },

    "costa_rican_coffee": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Tarrazú Coffee Beans",
        message: "Prestigious coffee from Costa Rica's Tarrazú region, renowned for its bright acidity",
        fun: 10,
        buyPrice: 350,
        salesPrice: {
            default: 1800,
            "PA": 3000,
            "NI": 2800,
            "SV": 2600
        },
        acceptedIn: ["PA", "NI", "SV", "GT", "HN", "MX", "US", "CA"],
        rarity: 0.55,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["CR"],
            destination: ["PA", "NI", "SV", "GT", "HN", "MX", "US", "CA"]
        }
    },

    "panamanian_hats": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Authentic Panama Hat",
        message: "Fine handwoven toquilla straw hat, actually made in Ecuador but traded through Panama",
        fun: 15,
        buyPrice: 500,
        salesPrice: {
            default: 2500,
            "CO": 4000,
            "CR": 3800,
            "EC": 3500
        },
        acceptedIn: ["CO", "CR", "EC", "PE", "MX", "US", "ES", "FR"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 400,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["PA"],
            destination: ["CO", "CR", "EC", "PE", "MX", "US", "ES", "FR"]
        }
    },

    "nicaraguan_hammocks": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Colorful Nicaraguan Hammock",
        message: "Hand-woven cotton hammock in vibrant colors from Masaya, Nicaragua",
        fun: 10,
        buyPrice: 120,
        salesPrice: {
            default: 600,
            "HN": 1200,
            "SV": 1100,
            "GT": 1000
        },
        acceptedIn: ["HN", "SV", "GT", "CR", "PA", "MX", "BZ", "US"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 100,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["NI"],
            destination: ["HN", "SV", "GT", "CR", "PA", "MX", "BZ", "US"]
        }
    },

    "el_salvador_textiles": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "El Salvador Indigo Textiles",
        message: "Traditional textiles dyed with natural indigo, once the country's primary export",
        buyPrice: 200,
        salesPrice: {
            default: 900,
            "GT": 1800,
            "HN": 1600,
            "NI": 1500
        },
        acceptedIn: ["GT", "HN", "NI", "CR", "MX", "US", "JP", "DE"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 200,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["SV"],
            destination: ["GT", "HN", "NI", "CR", "MX", "US", "JP", "DE"]
        }
    },

    "guatemalan_jade_jewelry": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Mayan Jade Jewelry",
        message: "Ancient-inspired jewelry with Guatemalan jade, prized by the Maya civilization",
        fun: 5,
        buyPrice: 800,
        salesPrice: {
            default: 3500,
            "MX": 6000,
            "BZ": 5500,
            "SV": 5000
        },
        acceptedIn: ["MX", "BZ", "SV", "HN", "CR", "US", "CA", "JP"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 800,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["GT"],
            destination: ["MX", "BZ", "SV", "HN", "CR", "US", "CA", "JP"]
        }
    },

    "honduran_cigars": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Honduran Premium Cigars",
        message: "Hand-rolled cigars from Honduras, a rising star in the cigar world",
        fun: 10,
        buyPrice: 400,
        salesPrice: {
            default: 2000,
            "NI": 3500,
            "GT": 3200,
            "SV": 3000
        },
        acceptedIn: ["NI", "GT", "SV", "MX", "US", "CH", "DE", "AE"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["HN"],
            destination: ["NI", "GT", "SV", "MX", "US", "CH", "DE", "AE"]
        }
    },

    "belize_hot_sauce": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Marie Sharp's Hot Sauce",
        message: "Famous Belizean hot sauce made with locally grown habanero peppers",
        fun: 15,
        buyPrice: 80,
        salesPrice: {
            default: 400,
            "GT": 800,
            "MX": 750,
            "JM": 700
        },
        acceptedIn: ["GT", "MX", "JM", "HN", "US", "CA", "GB", "AU"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 80,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["BZ"],
            destination: ["GT", "MX", "JM", "HN", "US", "CA", "GB", "AU"]
        }
    },

    "brazilian_cachaça": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Aged Cachaça",
        message: "Premium Brazilian sugarcane spirit, essential for making authentic Caipirinha",
        fun: 15,
        buyPrice: 250,
        salesPrice: {
            default: 1200,
            "AR": 2000,
            "UY": 1800,
            "PY": 1700
        },
        acceptedIn: ["AR", "UY", "PY", "PE", "CO", "VE", "PT", "US"],
        rarity: 0.55,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 250,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["BR"],
            destination: ["AR", "UY", "PY", "PE", "CO", "VE", "PT", "US"]
        }
    },

    "trinidad_steel_drum": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Handcrafted Steel Pan Drum",
        message: "Authentic steel drum from Trinidad, the birthplace of this unique percussion instrument",
        fun: 20,
        buyPrice: 900,
        salesPrice: {
            default: 3500,
            "JM": 6000,
            "BB": 5500,
            "BS": 5200
        },
        acceptedIn: ["JM", "BB", "BS", "GY", "VE", "CO", "US", "GB"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 800,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["TT"],
            destination: ["JM", "BB", "BS", "GY", "VE", "CO", "US", "GB"]
        }
    },

    "guyanese_rum": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Demerara Rum",
        message: "Rich, dark rum from Guyana's historic Demerara region, prized by connoisseurs",
        fun: 10,
        buyPrice: 500,
        salesPrice: {
            default: 2500,
            "SR": 4000,
            "TT": 3800,
            "BB": 3600
        },
        acceptedIn: ["SR", "TT", "BB", "JM", "BR", "GB", "US", "CA"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["GY"],
            destination: ["SR", "TT", "BB", "JM", "BR", "GB", "US", "CA"]
        }
    },

    "suriname_tropical_wood": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Suriname Purpleheart Wood",
        message: "Vibrant purple hardwood from Suriname's rainforests, prized by fine furniture makers",
        buyPrice: 700,
        salesPrice: {
            default: 3500,
            "GY": 5500,
            "BR": 5000,
            "NL": 4800
        },
        acceptedIn: ["GY", "BR", "NL", "FR", "DE", "US", "CA", "JP"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 2000,
            countries: ["EU"]
        },
        specificCountries: {
            origin: ["SR"],
            destination: ["GY", "BR", "NL", "FR", "DE", "US", "CA", "JP"]
        }
    },

    "colombian_leather_bags": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Colombian Leather Satchel",
        message: "Handcrafted leather bags from Colombia, known for premium quality and stylish design",
        buyPrice: 450,
        salesPrice: {
            default: 2200,
            "VE": 3800,
            "EC": 3500,
            "PA": 3200
        },
        acceptedIn: ["VE", "EC", "PA", "BR", "MX", "US", "ES", "IT"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 400,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["CO"],
            destination: ["VE", "EC", "PA", "BR", "MX", "US", "ES", "IT"]
        }
    },

    "barbados_rum_cake": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Barbados Rum Cake",
        message: "Rich dessert soaked in local rum, a Caribbean specialty with extended shelf life",
        fun: 10,
        buyPrice: 150,
        salesPrice: {
            default: 800,
            "TT": 1400,
            "JM": 1300,
            "LC": 1200
        },
        acceptedIn: ["TT", "JM", "LC", "BS", "US", "CA", "GB", "DE"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 150,
            countries: ["US"]
        },
        specificCountries: {
            origin: ["BB"],
            destination: ["TT", "JM", "LC", "BS", "US", "CA", "GB", "DE"]
        }
    },

    "mexican_artisan_tequila": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Small-Batch Artisan Tequila",
        message: "Limited-production tequila from family-owned distilleries in Jalisco's highlands",
        fun: 15,
        buyPrice: 700,
        salesPrice: {
            default: 3500,
            "GT": 6000,
            "BZ": 5500,
            "US": 5200
        },
        acceptedIn: ["GT", "BZ", "US", "CA", "JP", "DE", "AU", "SG"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 700,
            countries: ["US"]
        },
        specificCountries: {
            origin: ["MX"],
            destination: ["GT", "BZ", "US", "CA", "JP", "DE", "AU", "SG"]
        }
    },

    "ecuadorian_balsawood_models": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Balsa Wood Model Aircraft",
        message: "Intricate handmade model aircraft from Ecuador, the world's largest balsa exporter",
        fun: 10,
        buyPrice: 200,
        salesPrice: {
            default: 1000,
            "CO": 1800,
            "PE": 1600,
            "PA": 1500
        },
        acceptedIn: ["CO", "PE", "PA", "CL", "BR", "US", "ES", "DE"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 150,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["EC"],
            destination: ["CO", "PE", "PA", "CL", "BR", "US", "ES", "DE"]
        }
    },

    "argentine_yerba_mate": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Premium Yerba Mate",
        message: "High-quality Argentine yerba mate, the traditional South American caffeine-rich infusion",
        fun: 10,
        buyPrice: 100,
        salesPrice: {
            default: 500,
            "UY": 900,
            "PY": 850,
            "BR": 800
        },
        acceptedIn: ["UY", "PY", "BR", "CL", "BO", "PE", "SY", "LB"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 100,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["AR"],
            destination: ["UY", "PY", "BR", "CL", "BO", "PE", "SY", "LB"]
        }
    },

    "dominican_amber": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Dominican Blue Amber Jewelry",
        message: "Rare blue amber jewelry from Dominican Republic, one of few places with this variety",
        buyPrice: 1200,
        salesPrice: {
            default: 5000,
            "PR": 8000,
            "CU": 7500,
            "HT": 7000
        },
        acceptedIn: ["PR", "CU", "HT", "JM", "BS", "US", "ES", "FR"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1000,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["DO"],
            destination: ["PR", "CU", "HT", "JM", "BS", "US", "ES", "FR"]
        }
    },

    "panama_gesha_coffee": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Panama Gesha Coffee",
        message: "Ultra-premium Gesha coffee from Panama's Boquete region, fetching record prices globally",
        fun: 10,
        buyPrice: 1500,
        salesPrice: {
            default: 6000,
            "CR": 9000,
            "CO": 8500,
            "US": 8000
        },
        acceptedIn: ["CR", "CO", "US", "JP", "KR", "AU", "SG", "AE"],
        rarity: 0.9,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 1000,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["PA"],
            destination: ["CR", "CO", "US", "JP", "KR", "AU", "SG", "AE"]
        }
    },
    // African and Southeast Asian trading goods that are fun and affordable

    "senegalese_djembe_drum": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Hand-Carved Djembe Drum",
        message: "Authentic Senegalese djembe drum with goatskin head and intricate carvings",
        fun: 20,
        buyPrice: 120,
        salesPrice: {
            default: 600,
            "GM": 1000,
            "GN": 900,
            "ML": 850
        },
        acceptedIn: ["GM", "GN", "ML", "CI", "GH", "NG", "FR", "US"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 100,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["SN"],
            destination: ["GM", "GN", "ML", "CI", "GH", "NG", "FR", "US"]
        }
    },

    "tanzanian_kitenge_fabric": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Colorful Kitenge Fabric",
        message: "Vibrant wax-printed cotton fabric with bold patterns, popular in East Africa",
        fun: 15,
        buyPrice: 60,
        salesPrice: {
            default: 300,
            "KE": 500,
            "UG": 450,
            "RW": 420
        },
        acceptedIn: ["KE", "UG", "RW", "CD", "ZM", "MZ", "UK", "US"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 50,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["TZ"],
            destination: ["KE", "UG", "RW", "CD", "ZM", "MZ", "UK", "US"]
        }
    },

    "rwandan_coffee_beans": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Rwandan Single-Origin Coffee",
        message: "Specialty coffee beans from Rwanda's hills with bright citrus and berry notes",
        fun: 10,
        buyPrice: 100,
        salesPrice: {
            default: 450,
            "UG": 750,
            "TZ": 700,
            "KE": 650
        },
        acceptedIn: ["UG", "TZ", "KE", "CD", "ET", "AE", "US", "FR"],
        rarity: 0.55,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 80,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["RW"],
            destination: ["UG", "TZ", "KE", "CD", "ET", "AE", "US", "FR"]
        }
    },

    "ghanaian_kente_cloth": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Handwoven Kente Cloth",
        message: "Colorful ceremonial cloth hand-woven by Ashanti people, each pattern with meaning",
        fun: 15,
        buyPrice: 150,
        salesPrice: {
            default: 750,
            "NG": 1200,
            "SN": 1100,
            "CI": 1000
        },
        acceptedIn: ["NG", "SN", "CI", "TG", "BJ", "US", "UK", "CA"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 120,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["GH"],
            destination: ["NG", "SN", "CI", "TG", "BJ", "US", "UK", "CA"]
        }
    },

    "nigerian_afrobeats_vinyl": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Limited Edition Afrobeats Vinyl",
        message: "Collector's edition vinyl records featuring Nigeria's hottest Afrobeats artists",
        fun: 25,
        buyPrice: 80,
        salesPrice: {
            default: 400,
            "GH": 700,
            "UK": 650,
            "US": 600
        },
        acceptedIn: ["GH", "UK", "US", "FR", "DE", "CA", "ZA", "AE"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 70,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["NG"],
            destination: ["GH", "UK", "US", "FR", "DE", "CA", "ZA", "AE"]
        }
    },

    "malagasy_vanilla": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Madagascar Bourbon Vanilla",
        message: "Premium vanilla beans from Madagascar, producing 80% of the world's vanilla",
        fun: 10,
        buyPrice: 200,
        salesPrice: {
            default: 800,
            "MU": 1400,
            "ZA": 1300,
            "RE": 1200
        },
        acceptedIn: ["MU", "ZA", "RE", "KE", "TZ", "FR", "US", "DE"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 150,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["MG"],
            destination: ["MU", "ZA", "RE", "KE", "TZ", "FR", "US", "DE"]
        }
    },

    "cameroonian_hot_peppers": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Penja White Pepper",
        message: "Rare white pepper from Cameroon with protected geographical indication status",
        fun: 15,
        buyPrice: 70,
        salesPrice: {
            default: 350,
            "NG": 600,
            "GA": 550,
            "CD": 500
        },
        acceptedIn: ["NG", "GA", "CD", "CF", "TD", "FR", "DE", "IT"],
        rarity: 0.55,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 60,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["CM"],
            destination: ["NG", "GA", "CD", "CF", "TD", "FR", "DE", "IT"]
        }
    },

    "moroccan_football_jersey": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Moroccan National Team Jersey",
        message: "Official jersey of Morocco's national football team, popular after World Cup success",
        fun: 5,
        buyPrice: 50,
        salesPrice: {
            default: 250,
            "DZ": 400,
            "TN": 380,
            "SN": 350
        },
        acceptedIn: ["DZ", "TN", "SN", "MR", "ES", "FR", "BE", "NL"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 40,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["MA"],
            destination: ["DZ", "TN", "SN", "MR", "ES", "FR", "BE", "NL"]
        }
    },

    "south_african_biltong": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Premium Biltong",
        message: "South African air-dried, spiced meat snack, superior to regular jerky",
        fun: 15,
        buyPrice: 90,
        salesPrice: {
            default: 450,
            "NA": 750,
            "BW": 700,
            "ZW": 650
        },
        acceptedIn: ["NA", "BW", "ZW", "MZ", "AU", "NZ", "GB", "US"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 80,
            countries: ["EU"]
        },
        specificCountries: {
            origin: ["ZA"],
            destination: ["NA", "BW", "ZW", "MZ", "AU", "NZ", "GB", "US"]
        }
    },

    "kenyan_running_watch": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Elite Marathon Training Watch",
        message: "Specialized sports watch designed with input from Kenya's champion marathon runners",
        fun: 15,
        buyPrice: 180,
        salesPrice: {
            default: 800,
            "ET": 1300,
            "UG": 1200,
            "TZ": 1100
        },
        acceptedIn: ["ET", "UG", "TZ", "RW", "US", "JP", "GB", "DE"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 150,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["KE"],
            destination: ["ET", "UG", "TZ", "RW", "US", "JP", "GB", "DE"]
        }
    },

    "namibian_game_biltong": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Exotic Game Biltong",
        message: "Specialty air-dried meat from Namibian game like kudu and oryx",
        fun: 20,
        buyPrice: 110,
        salesPrice: {
            default: 550,
            "ZA": 900,
            "BW": 850,
            "ZM": 800
        },
        acceptedIn: ["ZA", "BW", "ZM", "AO", "DE", "CH", "AU", "US"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 100,
            countries: ["EU"]
        },
        specificCountries: {
            origin: ["NA"],
            destination: ["ZA", "BW", "ZM", "AO", "DE", "CH", "AU", "US"]
        }
    },

    "ivorian_chocolate": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Single-Origin Ivorian Chocolate",
        message: "Premium chocolate from world's largest cocoa producer, finally making finished products",
        fun: 10,
        buyPrice: 70,
        salesPrice: {
            default: 350,
            "GH": 600,
            "SN": 550,
            "CM": 500
        },
        acceptedIn: ["GH", "SN", "CM", "NG", "FR", "BE", "CH", "US"],
        rarity: 0.55,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 60,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["CI"],
            destination: ["GH", "SN", "CM", "NG", "FR", "BE", "CH", "US"]
        }
    },

    "zimbabwean_stone_sculpture": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Shona Stone Sculpture",
        message: "Hand-carved stone art from Zimbabwe's Shona people, unique contemporary art form",
        buyPrice: 150,
        salesPrice: {
            default: 750,
            "ZA": 1200,
            "BW": 1100,
            "MZ": 1000
        },
        acceptedIn: ["ZA", "BW", "MZ", "ZM", "US", "DE", "NL", "FR"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 130,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["ZW"],
            destination: ["ZA", "BW", "MZ", "ZM", "US", "DE", "NL", "FR"]
        }
    },

    "vietnamese_coffee_phin": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Vietnamese Coffee Set",
        message: "Traditional phin filter with robusta beans and condensed milk for authentic Vietnamese coffee",
        fun: 15,
        buyPrice: 60,
        salesPrice: {
            default: 300,
            "TH": 500,
            "KH": 450,
            "LA": 420
        },
        acceptedIn: ["TH", "KH", "LA", "MY", "SG", "US", "FR", "AU"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 50,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["VN"],
            destination: ["TH", "KH", "LA", "MY", "SG", "US", "FR", "AU"]
        }
    },

    "cambodian_kampot_pepper": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Kampot Pepper",
        message: "World-renowned pepper with protected geographical indication from Cambodia's Kampot province",
        fun: 10,
        buyPrice: 80,
        salesPrice: {
            default: 400,
            "VN": 700,
            "TH": 650,
            "LA": 600
        },
        acceptedIn: ["VN", "TH", "LA", "MY", "FR", "DE", "JP", "US"],
        rarity: 0.55,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 70,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["KH"],
            destination: ["VN", "TH", "LA", "MY", "FR", "DE", "JP", "US"]
        }
    },

    "laotian_silk_scarves": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Handwoven Laotian Silk Scarf",
        message: "Intricately designed silk scarf using traditional Laotian weaving techniques",
        fun: 15,
        buyPrice: 90,
        salesPrice: {
            default: 450,
            "TH": 750,
            "VN": 700,
            "KH": 650
        },
        acceptedIn: ["TH", "VN", "KH", "MM", "MY", "JP", "FR", "US"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 80,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["LA"],
            destination: ["TH", "VN", "KH", "MM", "MY", "JP", "FR", "US"]
        }
    },

    "myanmar_thanaka": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Thanaka Wood Cosmetic",
        message: "Traditional Burmese natural sunscreen and beauty product made from thanaka wood",
        fun: 20,
        buyPrice: 30,
        salesPrice: {
            default: 150,
            "TH": 250,
            "LA": 230,
            "BD": 210
        },
        acceptedIn: ["TH", "LA", "BD", "IN", "KH", "JP", "KR", "SG"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 25,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["MM"],
            destination: ["TH", "LA", "BD", "IN", "KH", "JP", "KR", "SG"]
        }
    },

    "indonesian_kopi_luwak": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Ethically-Sourced Kopi Luwak",
        message: "Wild-collected civet coffee from free-ranging animals, controversial but sought-after",
        fun: 25,
        buyPrice: 200,
        salesPrice: {
            default: 1000,
            "MY": 1700,
            "SG": 1600,
            "TH": 1500
        },
        acceptedIn: ["MY", "SG", "TH", "JP", "KR", "AE", "US", "DE"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 180,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["ID"],
            destination: ["MY", "SG", "TH", "JP", "KR", "AE", "US", "DE"]
        }
    },

    "philippine_jeepney_art": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Miniature Jeepney Art Piece",
        message: "Colorfully decorated model of iconic Philippine public transport, hand-painted by local artists",
        buyPrice: 70,
        salesPrice: {
            default: 350,
            "ID": 600,
            "MY": 550,
            "SG": 500
        },
        acceptedIn: ["ID", "MY", "SG", "US", "JP", "AE", "CA", "AU"],
        rarity: 0.55,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 60,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["PH"],
            destination: ["ID", "MY", "SG", "US", "JP", "AE", "CA", "AU"]
        }
    },

    "malaysian_batik_shirt": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Hand-Painted Batik Shirt",
        message: "Vibrant Malaysian batik shirt with hand-drawn designs, perfect for tropical climates",
        fun: 10,
        buyPrice: 65,
        salesPrice: {
            default: 325,
            "ID": 550,
            "SG": 500,
            "BN": 450
        },
        acceptedIn: ["ID", "SG", "BN", "TH", "PH", "AU", "NZ", "US"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 55,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["MY"],
            destination: ["ID", "SG", "BN", "TH", "PH", "AU", "NZ", "US"]
        }
    },

    "ugandan_rolex": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Ugandan Rolex Kit",
        message: "DIY kit for making the famous Ugandan street food 'Rolex' (rolled eggs) with chapati",
        fun: 25,
        buyPrice: 25,
        salesPrice: {
            default: 125,
            "KE": 200,
            "TZ": 180,
            "RW": 170
        },
        acceptedIn: ["KE", "TZ", "RW", "CD", "SS", "ET", "UK", "US"],
        rarity: 0.4,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 20,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["UG"],
            destination: ["KE", "TZ", "RW", "CD", "SS", "ET", "UK", "US"]
        }
    },

    "botswana_basket": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Tswana Basket",
        message: "Intricately woven basket from Botswana, each with unique geometric patterns",
        buyPrice: 75,
        salesPrice: {
            default: 375,
            "ZA": 650,
            "NA": 600,
            "ZW": 550
        },
        acceptedIn: ["ZA", "NA", "ZW", "ZM", "US", "DE", "SE", "DK"],
        rarity: 0.55,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 65,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["BW"],
            destination: ["ZA", "NA", "ZW", "ZM", "US", "DE", "SE", "DK"]
        }
    },

    "singapore_hawker_spices": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Singaporean Hawker Spice Kit",
        message: "Complete spice set for recreating famous Singaporean hawker center dishes at home",
        fun: 20,
        buyPrice: 85,
        salesPrice: {
            default: 425,
            "MY": 700,
            "ID": 650,
            "TH": 600
        },
        acceptedIn: ["MY", "ID", "TH", "PH", "VN", "AU", "NZ", "UK"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 75,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["SG"],
            destination: ["MY", "ID", "TH", "PH", "VN", "AU", "NZ", "UK"]
        }
    },

    // 25 thought-provoking but realistic trading goods from around the world

    "berlin_wall_fragment": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Berlin Wall Fragment",
        message: "Authenticated piece of the Berlin Wall with certificate of origin and historical photo",
        buyPrice: 150,
        salesPrice: {
            default: 600,
            "US": 1200,
            "GB": 1100,
            "FR": 1000
        },
        acceptedIn: ["US", "GB", "FR", "CA", "JP", "AU", "NL", "IT"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 200,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["DE"],
            destination: ["US", "GB", "FR", "CA", "JP", "AU", "NL", "IT"]
        },
        specificCities: {
            origin: ["Berlin"],
        }
    },

    "chernobyl_dosimeter": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Chernobyl Tour Dosimeter",
        message: "Functioning radiation dosimeter used during guided tours of the Chernobyl Exclusion Zone",
        fun: 20,
        buyPrice: 200,
        salesPrice: {
            default: 800,
            "PL": 1400,
            "DE": 1300,
            "US": 1200
        },
        acceptedIn: ["PL", "DE", "US", "GB", "FR", "CZ", "LT", "LV"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 300,
            countries: ["RU", "BY"]
        },
        specificCountries: {
            origin: ["UA"],
            destination: ["PL", "DE", "US", "GB", "FR", "CZ", "LT", "LV"]
        }
    },

    "nk_propaganda_poster": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "North Korean Propaganda Poster",
        message: "Original propaganda poster acquired during authorized tours to North Korea",
        fun: 5,
        buyPrice: 100,
        salesPrice: {
            default: 500,
            "US": 3950,
            "JP": 900,
            "KR": 850
        },
        acceptedIn: ["US", "JP", "KR", "GB", "DE", "FR", "AU", "CA"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.9,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 400,
            countries: ["KP", "KR"]
        },
        specificCountries: {
            origin: ["CN"],
            destination: ["US", "JP", "KR", "GB", "DE", "FR", "AU", "CA"]
        },
        specificCities: {
            origin: ["Shenyang", "Changchun", "Harbin", "Suihua", "Qingdao"],
        }
    },

    "palestinian_olive_oil": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "West Bank Olive Oil",
        message: "Premium olive oil produced by Palestinian farmers from ancient olive groves in the West Bank",
        fun: 5,
        buyPrice: 80,
        salesPrice: {
            default: 400,
            "DE": 700,
            "FR": 650,
            "SE": 600
        },
        acceptedIn: ["DE", "FR", "SE", "NL", "BE", "ES", "IT", "GB"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.5,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 150,
            countries: ["IL"]
        },
        specificCountries: {
            origin: ["PS"],
            destination: ["DE", "FR", "SE", "NL", "BE", "ES", "IT", "GB"]
        }
    },

    "amazon_tribal_headdress": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Indigenous Amazonian Headdress",
        message: "Ceremonial headdress made by indigenous artisans from sustainable rainforest materials",
        fun: 10,
        buyPrice: 300,
        salesPrice: {
            default: 1200,
            "US": 2000,
            "FR": 1800,
            "DE": 1700
        },
        acceptedIn: ["US", "FR", "DE", "GB", "IT", "ES", "JP", "AU"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 500,
            countries: ["BR"]
        },
        specificCountries: {
            origin: ["BR"],
            destination: ["US", "FR", "DE", "GB", "IT", "ES", "JP", "AU"]
        }
    },

    "soviet_military_watch": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Soviet Military Watch",
        message: "Authentic mechanical watch issued to Soviet soldiers during the Cold War era",
        fun: 5,
        buyPrice: 120,
        salesPrice: {
            default: 500,
            "US": 900,
            "DE": 850,
            "JP": 800
        },
        acceptedIn: ["US", "DE", "JP", "GB", "FR", "IT", "CA", "AU"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 150,
            countries: ["RU", "BY"]
        },
        specificCountries: {
            origin: ["UA", "RU", "BY", "KZ"],
            destination: ["US", "DE", "JP", "GB", "FR", "IT", "CA", "AU"]
        }
    },

    "tibet_prayer_flags": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Tibetan Prayer Flags",
        message: "Authentic prayer flags with Buddhist mantras, handmade by Tibetan refugees",
        fun: 10,
        buyPrice: 60,
        salesPrice: {
            default: 300,
            "US": 550,
            "DE": 500,
            "CA": 480
        },
        acceptedIn: ["US", "DE", "CA", "GB", "FR", "AU", "JP", "IT"],
        rarity: 0.5,
        borderRestrictions: {
            probability: 0.7,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 100,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["NP"],
            destination: ["US", "DE", "CA", "GB", "FR", "AU", "JP", "IT"]
        }
    },

    "kurdish_handwoven_rug": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Kurdish Handwoven Rug",
        message: "Traditional handmade wool rug with distinctive Kurdish patterns and motifs",
        buyPrice: 400,
        salesPrice: {
            default: 1600,
            "US": 2800,
            "DE": 2600,
            "GB": 2400
        },
        acceptedIn: ["US", "DE", "GB", "FR", "SE", "CA", "AU", "CH"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.6,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 600,
            countries: ["TR", "IR", "IQ", "SY"]
        },
        specificCountries: {
            origin: ["IQ", "SY"],
            destination: ["US", "DE", "GB", "FR", "SE", "CA", "AU", "CH"]
        }
    },

    "vietnam_war_zippo": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Vietnam War Zippo Lighter",
        message: "Authentic Vietnam-era Zippo lighter with soldier engravings and unit insignia",
        fun: 15,
        buyPrice: 300,
        salesPrice: {
            default: 1200,
            "US": 2000,
            "AU": 1800,
            "JP": 1700
        },
        acceptedIn: ["US", "AU", "JP", "GB", "DE", "FR", "CA", "IT"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 400,
            countries: ["VN"]
        },
        specificCountries: {
            origin: ["VN"],
            destination: ["US", "AU", "JP", "GB", "DE", "FR", "CA", "IT"]
        }
    },

    "congo_coltan_jewelry": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Coltan Ore Jewelry",
        message: "Artisanal jewelry containing ethically sourced coltan, the mineral used in electronics",
        buyPrice: 150,
        salesPrice: {
            default: 600,
            "US": 1000,
            "FR": 900,
            "BE": 850
        },
        acceptedIn: ["US", "FR", "BE", "DE", "GB", "CA", "JP", "NL"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.5,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 250,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["CD"],
            destination: ["US", "FR", "BE", "DE", "GB", "CA", "JP", "NL"]
        }
    },

    "venezuela_bolivar_art": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Bolivar Banknote Origami",
        message: "Intricate origami art made from hyperinflated Venezuelan bolivar banknotes",
        buyPrice: 80,
        salesPrice: {
            default: 400,
            "US": 700,
            "CO": 650,
            "BR": 600
        },
        acceptedIn: ["US", "CO", "BR", "MX", "CL", "PE", "ES", "CA"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.7,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 150,
            countries: ["VE"]
        },
        specificCountries: {
            origin: ["VE", "CO"],
            destination: ["US", "CO", "BR", "MX", "CL", "PE", "ES", "CA"]
        }
    },

    "uighur_woven_carpet": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Uyghur Hand-Knotted Carpet",
        message: "Traditional carpet with distinctive patterns made by Uyghur artisans in exile",
        fun: 5,
        buyPrice: 500,
        salesPrice: {
            default: 2000,
            "US": 3500,
            "DE": 3200,
            "GB": 3000
        },
        acceptedIn: ["US", "DE", "GB", "CA", "FR", "SE", "NL", "AU"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.9,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 1000,
            countries: ["CN"]
        },
        specificCountries: {
            origin: ["TR", "KZ"],
            destination: ["US", "DE", "GB", "CA", "FR", "SE", "NL", "AU"]
        }
    },


    "arctic_ice_core_sample": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Arctic Ice Core Sample",
        message: "Educational preserved ice core showing climate data from Arctic ice sheets",
        fun: 15,
        buyPrice: 350,
        salesPrice: {
            default: 1400,
            "US": 2300,
            "DE": 2100,
            "JP": 2000
        },
        acceptedIn: ["US", "DE", "JP", "GB", "FR", "NO", "SE", "DK"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 400,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["GL"],
            destination: ["US", "DE", "JP", "GB", "FR", "NO", "SE", "DK"]
        }
    },

    "sri_lankan_tamil_crafts": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Tamil Refugee Handicrafts",
        message: "Handmade crafts created by Tamil refugees supporting community rehabilitation",
        buyPrice: 100,
        salesPrice: {
            default: 400,
            "CA": 750,
            "GB": 700,
            "AU": 650
        },
        acceptedIn: ["CA", "GB", "AU", "US", "DE", "FR", "NO", "NZ"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.5,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 200,
            countries: ["LK"]
        },
        specificCountries: {
            origin: ["IN"],
            destination: ["CA", "GB", "AU", "US", "DE", "FR", "NO", "NZ"]
        }
    },

    "warsaw_pact_medals": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Warsaw Pact Military Medals",
        message: "Collection of authentic military medals from former Warsaw Pact countries",
        fun: 5,
        buyPrice: 200,
        salesPrice: {
            default: 800,
            "US": 1500,
            "DE": 1400,
            "GB": 1300
        },
        acceptedIn: ["US", "DE", "GB", "FR", "IT", "CA", "AU", "JP"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 250,
            countries: ["RU"]
        },
        specificCountries: {
            origin: ["PL", "CZ", "HU"],
            destination: ["US", "DE", "GB", "FR", "IT", "CA", "AU", "JP"]
        }
    },

    "puerto_rico_hurricane_art": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Hurricane Maria Recovery Art",
        message: "Artwork made from hurricane debris by Puerto Rican artists supporting recovery efforts",
        buyPrice: 250,
        salesPrice: {
            default: 1000,
            "US": 1800,
            "ES": 1600,
            "MX": 1500
        },
        acceptedIn: ["US", "ES", "MX", "CA", "DO", "CU", "CO", "PA"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 200,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["PR"],
            destination: ["US", "ES", "MX", "CA", "DO", "CU", "CO", "PA"]
        }
    },

    "aral_sea_bottle": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Aral Sea Sand Bottle",
        message: "Decorative bottle containing sand from the former Aral Sea bed, now a desert",
        buyPrice: 90,
        salesPrice: {
            default: 400,
            "DE": 750,
            "RU": 700,
            "US": 650
        },
        acceptedIn: ["DE", "RU", "US", "GB", "FR", "JP", "IT", "NL"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 100,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["KZ", "UZ"],
            destination: ["DE", "RU", "US", "GB", "FR", "JP", "IT", "NL"]
        }
    },

    "detroit_ruin_photos": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Detroit Ruins Photography Book",
        message: "Art photography book documenting abandoned buildings in post-industrial Detroit",
        fun: 5,
        buyPrice: 180,
        salesPrice: {
            default: 700,
            "DE": 1300,
            "FR": 1200,
            "JP": 1100
        },
        acceptedIn: ["DE", "FR", "JP", "GB", "CA", "AU", "IT", "ES"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 150,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["US"],
            destination: ["DE", "FR", "JP", "GB", "CA", "AU", "IT", "ES"]
        },
        specificCities: {
            origin: ["Detroit"]
        }
    },
    "detroit_seeds_kit": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Detroit Urban Farm Seed Kit",
        message: "Seeds from Detroit's urban farms that have reclaimed abandoned industrial lots",
        fun: 15,
        buyPrice: 60,
        salesPrice: {
            default: 300,
            "CA": 550,
            "DE": 500,
            "FR": 450
        },
        acceptedIn: ["CA", "DE", "FR", "GB", "NL", "AU", "JP", "SE"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 100,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["US"],
            destination: ["CA", "DE", "FR", "GB", "NL", "AU", "JP", "SE"]
        },
        specificCities: {
            origin: ["Detroit"]
        }
    },

    "shanghai_lane_house_tile": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Shanghai Lilong Tile",
        message: "Original roof tile from demolished traditional Shanghai lane houses, replaced by skyscrapers",
        buyPrice: 80,
        salesPrice: {
            default: 400,
            "US": 750,
            "AU": 700,
            "CA": 650
        },
        acceptedIn: ["US", "AU", "CA", "GB", "FR", "DE", "JP", "SG"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 100,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["CN"],
            destination: ["US", "AU", "CA", "GB", "FR", "DE", "JP", "SG"]
        },
        specificCities: {
            origin: ["Shanghai"]
        }
    },
    "istanbul_bosphorus_water": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Bosphorus Strait Water Sample",
        message: "Water from the strait dividing Europe and Asia, with analysis of its changing marine life",
        fun: 10,
        buyPrice: 60,
        salesPrice: {
            default: 300,
            "DE": 550,
            "FR": 500,
            "GB": 450
        },
        acceptedIn: ["DE", "FR", "GB", "US", "IT", "ES", "GR", "NL"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 80,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["TR"],
            destination: ["DE", "FR", "GB", "US", "IT", "ES", "GR", "NL"]
        },
        specificCities: {
            origin: ["Istanbul"]
        }
    },
    "buenos_aires_tango_shoes": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Handcrafted Tango Shoes",
        message: "Custom-made dance shoes from Buenos Aires' traditional tango shoe workshops",
        fun: 15,
        buyPrice: 150,
        salesPrice: {
            default: 600,
            "US": 1100,
            "IT": 1000,
            "ES": 950
        },
        acceptedIn: ["US", "IT", "ES", "FR", "DE", "JP", "CA", "BR"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 180,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["AR"],
            destination: ["US", "IT", "ES", "FR", "DE", "JP", "CA", "BR"]
        },
        specificCities: {
            origin: ["Buenos Aires"]
        }
    },
    "mumbai_dabbawalas_tiffin": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Mumbai Dabbawala Tiffin Box",
        message: "Authentic tiffin box from Mumbai's legendary lunch delivery system with original markings",
        buyPrice: 90,
        salesPrice: {
            default: 450,
            "US": 800,
            "GB": 750,
            "AE": 700
        },
        acceptedIn: ["US", "GB", "AE", "CA", "AU", "DE", "FR", "SG"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 100,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["IN"],
            destination: ["US", "GB", "AE", "CA", "AU", "DE", "FR", "SG"]
        },
        specificCities: {
            origin: ["Mumbai"]
        }
    },
    "mexico_city_air_filter": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Mexico City Air Filter Art",
        message: "Used air filter from Mexico City transformed into art, showing captured pollution particles",
        buyPrice: 100,
        salesPrice: {
            default: 450,
            "US": 800,
            "CA": 750,
            "DE": 700
        },
        acceptedIn: ["US", "CA", "DE", "FR", "GB", "JP", "ES", "IT"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 120,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["MX"],
            destination: ["US", "CA", "DE", "FR", "GB", "JP", "ES", "IT"]
        },
        specificCities: {
            origin: ["Mexico City"]
        }
    },
    "amsterdam_canal_house_model": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Tilting Canal House Model",
        message: "Miniature replica of Amsterdam's leaning canal houses with water level marker",
        buyPrice: 130,
        salesPrice: {
            default: 550,
            "DE": 950,
            "GB": 900,
            "US": 850
        },
        acceptedIn: ["DE", "GB", "US", "FR", "IT", "ES", "JP", "CA"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 150,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["NL"],
            destination: ["DE", "GB", "US", "FR", "IT", "ES", "JP", "CA"]
        },
        specificCities: {
            origin: ["Amsterdam"]
        }
    },
    "favela_art_piece": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Brazilian Favela Art",
        message: "Original artwork created by artists from Rio's favelas supporting community programs",
        buyPrice: 300,
        salesPrice: {
            default: 1200,
            "US": 2000,
            "FR": 1800,
            "ES": 1700
        },
        acceptedIn: ["US", "FR", "ES", "DE", "GB", "IT", "JP", "CA"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["BR"],
            destination: ["US", "FR", "ES", "DE", "GB", "IT", "JP", "CA"]
        }
    },
    "new_york_subway_token": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Vintage NYC Subway Token",
        message: "Original New York City subway token from before the MetroCard system, mounted as jewelry",
        buyPrice: 80,
        salesPrice: {
            default: 400,
            "JP": 750,
            "GB": 700,
            "DE": 650
        },
        acceptedIn: ["JP", "GB", "DE", "FR", "IT", "CA", "AU", "BR"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 90,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["US"],
            destination: ["JP", "GB", "DE", "FR", "IT", "CA", "AU", "BR"]
        },
        specificCities: {
            origin: ["New York"]
        }
    },
    "oslo_seed_vault_replica": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Svalbard Seed Vault Model",
        message: "Working miniature of Norway's Global Seed Vault with sample seed preservation tubes",
        buyPrice: 500,
        salesPrice: {
            default: 2600,
            "US": 5100,
            "DE": 3000,
            "SE": 950
        },
        acceptedIn: ["US", "DE", "SE", "DK", "FI", "GB", "CA", "AU"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 180,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["NO"],
            destination: ["US", "DE", "SE", "DK", "FI", "GB", "CA", "AU"]
        },
        specificCities: {
            origin: ["Tromsø", "Longyearbyen"]
        }
    },
    "singapore_vertical_garden": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Singapore Vertical Garden Kit",
        message: "Miniature version of Singapore's iconic vertical gardens with native plant species",
        buyPrice: 100,
        salesPrice: {
            default: 450,
            "JP": 800,
            "AU": 750,
            "AE": 700
        },
        acceptedIn: ["JP", "AU", "AE", "HK", "CN", "US", "GB", "DE"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 150,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["SG"],
            destination: ["JP", "AU", "AE", "HK", "CN", "US", "GB", "DE"]
        },
        specificCities: {
            origin: ["Singapore"]
        }
    },
    "chengdu_facemask_collection": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Designer Air Pollution Masks",
        message: "Fashionable high-filtration face masks designed for urban air pollution in Chinese cities",
        fun: 5,
        buyPrice: 70,
        salesPrice: {
            default: 350,
            "JP": 650,
            "KR": 600,
            "US": 550
        },
        acceptedIn: ["JP", "KR", "US", "CA", "GB", "AU", "IN", "TH"],
        rarity: 0.2,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 80,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["CN"],
            destination: ["JP", "KR", "US", "CA", "GB", "AU", "IN", "TH"]
        }
    },

    "rohingya_refugee_textiles": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Rohingya Textiles",
        message: "Hand-woven textiles made by Rohingya refugees in Bangladesh camps with traditional patterns",
        buyPrice: 120,
        salesPrice: {
            default: 500,
            "US": 900,
            "GB": 850,
            "CA": 800
        },
        acceptedIn: ["US", "GB", "CA", "DE", "SE", "AU", "FR", "NL"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.8,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 200,
            countries: ["MM"]
        },
        specificCountries: {
            origin: ["BD"],
            destination: ["US", "GB", "CA", "DE", "SE", "AU", "FR", "NL"]
        }
    },
    "athens_olympic_keychain": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Athens Olympic Village Key",
        message: "Original key from the abandoned 2004 Athens Olympic Village, now a symbol of economic aftermath",
        buyPrice: 80,
        salesPrice: {
            default: 400,
            "DE": 700,
            "FR": 650,
            "GB": 600
        },
        acceptedIn: ["DE", "FR", "GB", "IT", "ES", "US", "CA", "AU"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 100,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["GR"],
            destination: ["DE", "FR", "GB", "IT", "ES", "US", "CA", "AU"]
        },
        specificCities: {
            origin: ["Athens"]
        }
    },
    "dead_sea_beauty_products": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Dead Sea Skincare Set",
        message: "Premium beauty products made with minerals from the rapidly shrinking Dead Sea",
        fun: 10,
        buyPrice: 150,
        salesPrice: {
            default: 600,
            "US": 1000,
            "DE": 950,
            "GB": 900
        },
        acceptedIn: ["US", "DE", "GB", "FR", "IT", "CA", "JP", "AU"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 200,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["IL", "JO"],
            destination: ["US", "DE", "GB", "FR", "IT", "CA", "JP", "AU"]
        }
    },



    "san_francisco_sourdough_starter": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "San Francisco Sourdough Starter",
        message: "Live sourdough culture from San Francisco's oldest bakeries, containing the city's unique wild yeasts",
        fun: 15,
        buyPrice: 60,
        salesPrice: {
            default: 300,
            "DE": 550,
            "FR": 500,
            "IT": 480
        },
        acceptedIn: ["DE", "FR", "IT", "GB", "CA", "AU", "JP", "BR"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.6,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 100,
            countries: ["AU", "NZ"]
        },
        specificCountries: {
            origin: ["US"],
            destination: ["DE", "FR", "IT", "GB", "CA", "AU", "JP", "BR"]
        },
        specificCities: {
            origin: ["San Francisco"]
        }
    },

    "chicago_blues_harmonica": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Chicago Blues Harmonica",
        message: "Professional harmonica from Chicago's legendary Maxwell Street, home of electric blues",
        fun: 20,
        buyPrice: 150,
        salesPrice: {
            default: 600,
            "JP": 1000,
            "DE": 950,
            "GB": 900
        },
        acceptedIn: ["JP", "DE", "GB", "FR", "BR", "CA", "AU", "NL"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 180,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["US"],
            destination: ["JP", "DE", "GB", "FR", "BR", "CA", "AU", "NL"]
        },
        specificCities: {
            origin: ["Chicago"]
        }
    },

    "santa_fe_pueblo_pottery": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Santa Fe Pueblo Pottery",
        message: "Authentic Native American pottery handcrafted using traditional techniques passed down for generations",
        buyPrice: 300,
        salesPrice: {
            default: 1200,
            "JP": 2000,
            "DE": 1900,
            "FR": 1800
        },
        acceptedIn: ["JP", "DE", "FR", "GB", "CH", "IT", "CA", "AU"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 350,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["US"],
            destination: ["JP", "DE", "FR", "GB", "CH", "IT", "CA", "AU"]
        },
        specificCities: {
            origin: ["Santa Fe"]
        }
    },

    "portland_craft_beer_kit": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Portland Microbrew Kit",
        message: "Home brewing kit from Portland's renowned craft beer scene with local hops and recipes",
        fun: 20,
        buyPrice: 140,
        salesPrice: {
            default: 550,
            "AU": 950,
            "DE": 900,
            "CA": 850
        },
        acceptedIn: ["AU", "DE", "CA", "GB", "NZ", "JP", "NO", "DK"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 170,
            countries: ["SA", "IR"]
        },
        specificCountries: {
            origin: ["US"],
            destination: ["AU", "DE", "CA", "GB", "NZ", "JP", "NO", "DK"]
        },
        specificCities: {
            origin: ["Portland"]
        }
    },

    "miami_art_deco_prints": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Miami Art Deco Prints",
        message: "Limited edition prints of South Beach's vibrant Art Deco architecture by local photographers",
        fun: 15,
        buyPrice: 100,
        salesPrice: {
            default: 450,
            "BR": 800,
            "ES": 750,
            "IT": 700
        },
        acceptedIn: ["BR", "ES", "IT", "FR", "DE", "GB", "AR", "CO"],
        rarity: 0.6,
        borderRestrictions: {
            probability: 0.1,
            penalty: PENALTIES.TAX,
            penaltyAmount: 120,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["US"],
            destination: ["BR", "ES", "IT", "FR", "DE", "GB", "AR", "CO"]
        },
        specificCities: {
            origin: ["Miami", "Cape Coral", "Port St. Lucie", "Orlando", "Tampa", "Jacksonville"]
        }
    },

    // CHINA REPLACEMENTS - MORE AUTHENTIC ITEMS

    "guangzhou_ivory_puzzle_ball": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Guangzhou Puzzle Ball",
        message: "Intricately carved concentric puzzle ball made from sustainable plant ivory substitutes",
        fun: 20,
        buyPrice: 250,
        salesPrice: {
            default: 1000,
            "US": 1700,
            "JP": 1600,
            "DE": 1500
        },
        acceptedIn: ["US", "JP", "DE", "GB", "FR", "CA", "AU", "SG"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["CN"],
            destination: ["US", "JP", "DE", "GB", "FR", "CA", "AU", "SG"]
        },
        specificCities: {
            origin: ["Guangzhou"]
        }
    },

    "nanjing_cloud_brocade": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Nanjing Yunjin Brocade",
        message: "Exquisite silk brocade from Nanjing, once reserved for imperial robes, woven with gold threads",
        fun: 15,
        buyPrice: 280,
        salesPrice: {
            default: 1100,
            "JP": 1900,
            "FR": 1800,
            "IT": 1700
        },
        acceptedIn: ["JP", "FR", "IT", "US", "GB", "AE", "SG", "KR"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 330,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["CN"],
            destination: ["JP", "FR", "IT", "US", "GB", "AE", "SG", "KR"]
        },
        specificCities: {
            origin: ["Nanjing"]
        }
    },


    "shanghai_qipao_dress": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Handcrafted Shanghai Qipao",
        message: "Custom-tailored traditional Chinese dress made with silk by master tailors on Shanghai's Nanjing Road",
        fun: 15,
        buyPrice: 450,
        salesPrice: {
            default: 1800,
            "US": 3000,
            "JP": 2800,
            "FR": 2700
        },
        acceptedIn: ["US", "JP", "FR", "GB", "CA", "AU", "SG", "IT"],
        rarity: 0.75,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 500,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["CN"],
            destination: ["US", "JP", "FR", "GB", "CA", "AU", "SG", "IT"]
        },
        specificCities: {
            origin: ["Shanghai"]
        }
    },

    // RUSSIA REPLACEMENTS - MORE AUTHENTIC ITEMS

    "tula_samovar_set": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Tula Brass Samovar",
        message: "Traditional Russian tea urn from Tula, the historic center of samovar craftsmanship since the 18th century",
        fun: 15,
        buyPrice: 300,
        salesPrice: {
            default: 1200,
            "TR": 2000,
            "IR": 1900,
            "DE": 1800
        },
        acceptedIn: ["TR", "IR", "DE", "GB", "FR", "US", "CA", "AZ"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 350,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["RU"],
            destination: ["TR", "IR", "DE", "GB", "FR", "US", "CA", "AZ"]
        },
        specificCities: {
            origin: ["Tula", "Bryansk", "Lipetsk", "Voronezh"]
        }
    },


    "nizhny_novgorod_khokhloma": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Khokhloma Wooden Tableware",
        message: "Vibrantly painted wooden tableware from Nizhny Novgorod, featuring traditional Russian gold and red patterns",
        fun: 15,
        buyPrice: 150,
        salesPrice: {
            default: 600,
            "US": 1000,
            "DE": 950,
            "FR": 900
        },
        acceptedIn: ["US", "DE", "FR", "GB", "IT", "CA", "JP", "FI"],
        rarity: 0.65,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 180,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["RU"],
            destination: ["US", "DE", "FR", "GB", "IT", "CA", "JP", "FI"]
        },
        specificCities: {
            origin: ["Nizhny Novgorod", "Yaroslavl", "Kirov"]
        }
    },

    "yoshkar_ola_mari_embroidery": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Mari Folk Embroidery",
        message: "Intricately embroidered textiles from Yoshkar-Ola, created by Mari craftspeople with ancient symbols",
        fun: 10,
        buyPrice: 180,
        salesPrice: {
            default: 700,
            "FI": 1200,
            "EE": 1100,
            "HU": 1000
        },
        acceptedIn: ["FI", "EE", "HU", "DE", "SE", "NO", "US", "CA"],
        rarity: 0.7,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 200,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["RU"],
            destination: ["FI", "EE", "HU", "DE", "SE", "NO", "US", "CA"]
        },
        specificCities: {
            origin: ["Kazan", "Novgorod", "Ryazan", "Kirov"]
        }
    },

    "sochi_mineral_water": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Sochi Mineral Water Collection",
        message: "Set of mineral waters from Sochi's renowned Matsesta springs, known for their healing properties",
        fun: 10,
        buyPrice: 80,
        salesPrice: {
            default: 400,
            "GE": 700,
            "TR": 650,
            "AZ": 600
        },
        acceptedIn: ["GE", "TR", "AZ", "AM", "KZ", "DE", "FR", "US"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.3,
            penalty: PENALTIES.TAX,
            penaltyAmount: 100,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["RU"],
            destination: ["GE", "TR", "AZ", "AM", "KZ", "DE", "FR", "US"]
        },
        specificCities: {
            origin: ["Sochi", "Krasnodar"]
        }
    },

    // ADDITIONAL AUTHENTIC US ITEMS

    "new_york_bagel_spices": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "NY Bagel Making Kit",
        message: "Authentic ingredients and tools for making New York-style bagels, including special malt and city water",
        fun: 15,
        buyPrice: 70,
        salesPrice: {
            default: 350,
            "CA": 600,
            "GB": 550,
            "AU": 500
        },
        acceptedIn: ["CA", "GB", "AU", "FR", "DE", "IT", "JP", "IL"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 90,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["US"],
            destination: ["CA", "GB", "AU", "FR", "DE", "IT", "JP", "IL"]
        },
        specificCities: {
            origin: ["New York", "Brooklyn"]
        }
    },

    "yunnan_puer_tea_brick": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Aged Yunnan Pu'er Tea Brick",
        message: "Compressed tea cake from ancient trees in Xishuangbanna, prized by collectors for its complex fermentation",
        fun: 12,
        buyPrice: 200,
        salesPrice: {
            default: 800,
            "GB": 1200,
            "FR": 1100,
            "JP": 1500
        },
        acceptedIn: ["GB", "FR", "JP", "US", "DE", "KR", "TW", "MY"],
        rarity: 1,
        borderRestrictions: {
            probability: 0.4,
            penalty: PENALTIES.TAX,
            penaltyAmount: 300,
            countries: ["US", "EU"]
        },
        specificCountries: {
            origin: ["CN"],
            destination: ["GB", "FR", "JP", "US", "DE", "KR", "TW", "MY"]
        },
        specificCities: {
            origin: ["Kunming"]
        }
    },

    "hangzhou_silk_shawl": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Hangzhou Silk Brocade Evening Shawl",
        message: "Luxury silk woven using Tang Dynasty patterns at the National Silk Museum",
        fun: 8,
        buyPrice: 180,
        salesPrice: {
            default: 450,
            "FR": 700,
            "IT": 650,
            "US": 800
        },
        acceptedIn: ["FR", "IT", "US", "GB", "JP", "SG", "AE", "RU"],
        rarity: 1,
        borderRestrictions: {
            probability: 0.2,
            penalty: PENALTIES.TAX,
            penaltyAmount: 150,
            countries: ["RU"]
        },
        specificCountries: {
            origin: ["CN"],
            destination: ["FR", "IT", "US", "GB", "JP", "SG", "AE", "RU"]
        },
        specificCities: {
            origin: ["Hangzhou"]
        }
    },

    "jingdezhen_porcelain_set": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Jingdezhen Blue-White Porcelain Tea Set",
        message: "Delicate porcelain fired in China's ceramic capital using 14th-century techniques",
        fun: 14,
        buyPrice: 300,
        salesPrice: {
            default: 900,
            "DE": 1500,
            "NL": 1400,
            "US": 1300
        },
        acceptedIn: ["DE", "NL", "US", "GB", "JP", "KR", "SA", "QA"],
        rarity: 0.1,
        borderRestrictions: {
            probability: 0.25,
            penalty: PENALTIES.FRAGILE,
            penaltyAmount: 400,
            countries: ["EU","AU","NZ","NO","GB"]
        },
        specificCountries: {
            origin: ["CN"],
            destination: ["DE", "NL", "US", "GB", "JP", "KR", "SA", "QA"]
        }
    },

    "hotan_jade_carving": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Hotan White Jade Mountain Deity",
        message: "Intricate nephrite carving from the Taklamakan Desert's jade markets",
        fun: 18,
        buyPrice: 800,
        salesPrice: {
            default: 2000,
            "SG": 3500,
            "MY": 3200,
            "AE": 5000
        },
        acceptedIn: ["SG", "MY", "AE", "SA", "TH", "VN", "KR", "JP"],
        rarity: 0.1,
        borderRestrictions: {
            probability: 0.5,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 600,
            countries: ["CA", "US"]
        },
        specificCountries: {
            origin: ["CN"],
            destination: ["SG", "MY", "AE", "SA", "TH", "VN", "KR", "JP"]
        }

    },

    "chongqing_hotpot_kit": {
        ...CARD_TYPES.TRADE_GOODS,
        title: "Chongqing Numbs Your Tongue Hotpot Kit",
        message: "Extremely spicy broth base with Sichuan peppercorns and 23 chili varieties",
        fun: 20,
        buyPrice: 40,
        salesPrice: {
            default: 180,
            "KR": 350,
            "TH": 300,
            "US": 250
        },
        acceptedIn: ["KR", "TH", "US", "SG", "MY", "VN", "JP", "CA"],
        rarity: 0.8,
        borderRestrictions: {
            probability: 0.7,
            penalty: PENALTIES.CONFISCATE,
            penaltyAmount: 80,
            countries: ["AU", "NZ","DK"]
        },
        specificCountries: {
            origin: ["CN"],
            destination: ["KR", "TH", "US", "SG", "MY", "VN", "JP", "CA"]
        },
        specificCities: {
            origin: ["Chongqing", "Chengdu"]
        }
    },
    "maotai_century_reserve": {
    ...CARD_TYPES.TRADE_GOODS,
    title: "Moutai Century Reserve Baijiu",
    message: "Ultra-premium sorghum liquor aged in celadon jars, served at state banquets since the Ming Dynasty",
    fun: 9,
    buyPrice: 400,
    salesPrice: {
        default: 1200,
        "RU": 2200,
        "SG": 1800,
        "KR": 1600
    },
    acceptedIn: ["RU", "SG", "KR", "MY", "TH", "VN", "MO", "HK"],
    rarity: 0.4,
    borderRestrictions: {
        probability: 0.35,
        penalty: PENALTIES.TAX,
        penaltyAmount: 450,
        countries: ["US", "CA"]
    },
    specificCountries: {
        origin: ["CN"],
        destination: ["RU", "SG", "KR", "MY", "TH", "VN", "MO", "HK"]
    }
},

"forbidden_city_calligraphy_set": {
    ...CARD_TYPES.TRADE_GOODS,
    title: "Imperial Calligraphy Collection",
    message: "Replica Qianlong-era brush set with mineral-infused inks and mulberry paper",
    fun: 13,
    buyPrice: 90,
    salesPrice: {
        default: 350,
        "JP": 800,
        "KR": 750,
        "FR": 600
    },
    acceptedIn: ["JP", "KR", "FR", "US", "DE", "TW", "HK", "MO"],
    rarity: 1,
    borderRestrictions: {
        probability: 0.15,
        penalty: PENALTIES.CONFISCATE,
        penaltyAmount: 120,
        countries: ["AU"]  // Due to animal hair in brushes
    },
    specificCities: {
        origin: ["Beijing"]
    },
},

"foldable_holophone_prototype": {
    ...CARD_TYPES.TRADE_GOODS,
    title: "Tri-Fold HoloScreen Smartphone",
    message: "Unreleased 8K foldable device with satellite streaming - restricted in some markets",
    fun: 25,
    buyPrice: 1200,
    salesPrice: {
        default: 3000,
        "RU": 4500,
        "AE": 5000,
        "IN": 3800
    },
    acceptedIn: ["RU", "AE", "IN", "SA", "MY", "VN", "BR", "TR"],
    specificCountries: {
        origin: ["CN"],
        destination: ["RU", "AE", "IN", "SA", "MY", "VN", "BR", "TR"]
    },
"specificCities": {
    "origin": ["Shenzhen", "Dongguan", "Zhuhai", "Wuhan", "Guangzhou", "Suzhou"]
},
    borderRestrictions: {
        probability: 0.7,
        penalty: PENALTIES.CONFISCATE,
        penaltyAmount: 800,
        countries: ["US", "CA", "AU", "TW"]
    },
    rarity: 0.2
},

"shortvideo_creator_kit": {
    ...CARD_TYPES.TRADE_GOODS,
    title: "AI Streamer Production Bundle",
    message: "Multi-camera stabilizer rig + virtual avatar software",
    fun: 28,
    buyPrice: 1350,
    salesPrice: {
        default: 1900,
        "ID": 3500,
        "NG": 2300,
        "BR": 3200
    },
    acceptedIn: ["ID", "NG", "BR", "PH", "TH", "VN", "MX", "KE"],
    specificCountries: {
        origin: ["CN"],
        destination: ["ID", "NG", "BR", "PH", "TH", "VN", "MX", "KE"]
    },
    borderRestrictions: {
        probability: 0.4,
        penalty: PENALTIES.TAX,
        penaltyAmount: 200,
        countries: ["IN", "US"]
    },
    rarity: 0.3
},

"eco_pet_tote_design": {
    ...CARD_TYPES.TRADE_GOODS,
    title: "Recycled Plastic Streetwear Tote",
    message: "Sustainable fashion item made from repurposed materials",
    fun: 12,
    buyPrice: 45,
    salesPrice: {
        default: 150,
        "SE": 300,
        "CA": 280,
        "KR": 250
    },
    acceptedIn: ["SE", "CA", "KR", "DE", "FR", "SG", "NZ", "CL"],
    specificCountries: {
        origin: ["CN"],
        destination: ["SE", "CA", "KR", "DE", "FR", "SG", "NZ", "CL"]
    },
    rarity: 0.8
},

"logistics_drone_system": {
    ...CARD_TYPES.TRADE_GOODS,
    title: "Urban Delivery SkyRail Drone",
    message: "Electric logistics drone using infrastructure networks",
    fun: 20,
    buyPrice: 900,
    salesPrice: {
        default: 2200,
        "SA": 3500,
        "ZA": 2800,
        "RU": 3000
    },
    acceptedIn: ["SA", "ZA", "RU", "BR", "AE", "KZ", "AR", "DZ"],
    specificCountries: {
        origin: ["CN"],
        destination: ["SA", "ZA", "RU", "BR", "AE", "KZ", "AR", "DZ"]
    },
    "specificCities": {
    "origin": ["Shenzhen", "Chengdu", "Hangzhou", "Xi'an", "Guangzhou", "Tianjin"]
    },
    borderRestrictions: {
        probability: 0.6,
        penalty: PENALTIES.CONFISCATE,
        penaltyAmount: 600,
        countries: ["US", "JP", "EU"]
    },
    rarity: 0.6
},


    // VISA CARDS
    "cuban_visa": {
        ...CARD_TYPES.TRAVEL_DOCUMENTS,
        title: "Cuban Cultural Exchange Visa",
        country: "Cuba",
        message: "Cultural Exchange Visa for Cuba",
        category: "Cultural Exchange",
        stamp: [7, 2, 3],
        buyPrice: 0,
        salesPrice: {
            default: 0,
            "CU": 0
        },
        acceptedIn: ["CU"],
        rarity: 0.05,
        borderRestrictions: {
            probability: 0.05,
            permitEntry: true
        }
    },

    "afghanistan_visa": {
        ...CARD_TYPES.TRAVEL_DOCUMENTS,
        title: "Afghan Diplomatic Visa",
        country: "Afghanistan",
        message: "Special diplomatic clearance for Afghanistan",
        stamp: [-9, 3, 5],
        buyPrice: 0,
        salesPrice: {
            default: 0,
            "AF": 0
        },
        acceptedIn: ["AF"],
        rarity: 0.05,
        borderRestrictions: {
            probability: 0.0,
            permitEntry: true
        }
    },
    "usa_visa": {
        ...CARD_TYPES.TRAVEL_DOCUMENTS,
        title: "US Diplomatic Visa",
        country: "United States of America",
        message: "Special diplomatic clearance for the United States of America",
        stamp: [-9, 2, 5],
        buyPrice: 0,
        salesPrice: {
            default: 0,
            "US": 0
        },
        acceptedIn: ["US"],
        rarity: 0.1,
        borderRestrictions: {
            probability: 0.0,
            permitEntry: true
        }
    },
    "schengen_visa": {
        ...CARD_TYPES.TRAVEL_DOCUMENTS,
        title: "Schengen Visa",
        country: "European Schengen Area",
        message: "Tourist Visa to the European Schengen Area",
        stamp: [-9, 4, 4],
        buyPrice: 0,
        salesPrice: {
            default: 0,
            "US": 0
        },
        acceptedIn: ["AT", "NO", "CH", "IS", "MC", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"],
        rarity: 0.1,
        borderRestrictions: {
            probability: 0.0,
            permitEntry: true
        }
    },
    "northkorea_visa": {
        ...CARD_TYPES.TRAVEL_DOCUMENTS,
        title: "North Korea Tourist Visa",
        country: "North Korea",
        message: "Touristic Invite to The Democratic People's Republic of Korea",
        stamp: [12, 4, 3],
        buyPrice: 0,
        salesPrice: {
            default: 0,
            "KP": 0
        },
        acceptedIn: ["KP"],
        rarity: 0.01,
        borderRestrictions: {
            probability: 0.0,
            permitEntry: true
        }
    },
    "bhutan_visa": {
    ...CARD_TYPES.TRAVEL_DOCUMENTS,
    title: "Bhutan Happiness Visa",
    country: "Bhutan",
    message: "AUTHORIZED ENTRY: Subject to daily sustainability fee. Happiness measurement mandatory.",
    category: "Guided Tourism",
    stamp: [4, 3, 5],
    buyPrice: 0,
    salesPrice: {
        default: 0,
        "BT": 0
    },
    acceptedIn: ["BT"],
    rarity: 0.08,
    borderRestrictions: {
        probability: 0.02,
        permitEntry: true
    }
},

"russia_special_access": {
    ...CARD_TYPES.TRAVEL_DOCUMENTS,
    title: "Russian Strategic Visa",
    country: "Russia",
    message: "FEDERAL AUTHORIZATION: Bearer must report daily. Photography strictly prohibited.",
    category: "Restricted Access",
    stamp: [8, 3, 4],
    buyPrice: 0,
    salesPrice: {
        default: 0,
        "RU": 0
    },
    acceptedIn: ["RU"],
    rarity: 0.09,
    borderRestrictions: {
        probability: 0.15,
        permitEntry: true
    }
},

"saudi_special_visa": {
    ...CARD_TYPES.TRAVEL_DOCUMENTS,
    title: "Saudi Arabia Special Access Visa",
    country: "Saudi Arabia",
    message: "KINGDOM ENTRY PERMIT: Holder must adhere to local customs. Modesty required.",
    category: "Cultural Compliance",
    stamp: [5, 2, 4],
    buyPrice: 0,
    salesPrice: {
        default: 0,
        "SA": 0
    },
    acceptedIn: ["SA"],
    rarity: 0.1,
    borderRestrictions: {
        probability: 0.1,
        permitEntry: true
    }
},

"brazil_amazon_visa": {
    ...CARD_TYPES.TRAVEL_DOCUMENTS,
    title: "Brazil Environmental Research Visa",
    country: "Brazil",
    message: "SCIENTIFIC AUTHORIZATION: Rainforest access granted. No souvenirs permitted.",
    category: "Scientific Research",
    stamp: [6, 3, 2],
    buyPrice: 0,
    salesPrice: {
        default: 0,
        "BR": 0
    },
    acceptedIn: ["BR"],
    rarity: 0.16,
    borderRestrictions: {
        probability: 0.08,
        permitEntry: true
    }
},

"turkmenistan_visa": {
    ...CARD_TYPES.TRAVEL_DOCUMENTS,
    title: "Turkmenistan Entry Permit",
    country: "Turkmenistan",
    message: "OFFICIAL INVITATION: State-approved guide mandatory. Itinerary non-negotiable.",
    category: "Guided Tourism",
    stamp: [9, 4, 3],
    buyPrice: 0,
    salesPrice: {
        default: 0,
        "TM": 0
    },
    acceptedIn: ["TM"],
    rarity: 0.06,
    borderRestrictions: {
        probability: 0.08,
        permitEntry: true
    }
},

"iran_press_visa": {
    ...CARD_TYPES.TRAVEL_DOCUMENTS,
    title: "Iran Press Credential",
    country: "Iran",
    message: "MEDIA AUTHORIZATION: All interviews subject to review. Government liaison required.",
    category: "Press Access",
    stamp: [10, 2, 4],
    buyPrice: 0,
    salesPrice: {
        default: 0,
        "IR": 0
    },
    acceptedIn: ["IR"],
    rarity: 0.07,
    borderRestrictions: {
        probability: 0.2,
        permitEntry: true
    }
},

"venezuela_visa": {
    ...CARD_TYPES.TRAVEL_DOCUMENTS,
    title: "Venezuela Economic Zone Visa",
    country: "Venezuela",
    message: "BOLIVARIAN ENTRY PERMIT: Currency declaration mandatory. Bring own essentials.",
    category: "Economic Zone",
    stamp: [3, 4, 5],
    buyPrice: 0,
    salesPrice: {
        default: 0,
        "VE": 0
    },
    acceptedIn: ["VE"],
    rarity: 0.06,
    borderRestrictions: {
        probability: 0.1,
        permitEntry: true
    }
},

"myanmar_entry_permit": {
    ...CARD_TYPES.TRAVEL_DOCUMENTS,
    title: "Myanmar Special Entry Permit",
    country: "Myanmar",
    message: "AUTHORIZED TRAVEL DOCUMENT: Movements monitored. Political discussions discouraged.",
    category: "Restricted Tourism",
    stamp: [11, 3, 2],
    buyPrice: 0,
    salesPrice: {
        default: 0,
        "MM": 0
    },
    acceptedIn: ["MM"],
    rarity: 0.09,
    borderRestrictions: {
        probability: 0.3,
        permitEntry: true
    }
},

"nigeria_business_visa": {
    ...CARD_TYPES.TRAVEL_DOCUMENTS,
    title: "Nigeria Business Visa",
    country: "Nigeria",
    message: "COMMERCIAL ENTRY PERMIT: Not valid for princes seeking fund transfers.",
    category: "Business Access",
    stamp: [1, 5, 2],
    buyPrice: 0,
    salesPrice: {
        default: 0,
        "NG": 0
    },
    acceptedIn: ["NG"],
    rarity: 0.1,
    borderRestrictions: {
        probability: 0.07,
        permitEntry: true
    }
}
};

// Export utility functions
export const getCardByName = (cardName) => cardDatabase[cardName];
export const getAllCards = () => Object.values(cardDatabase);
export const getCardsByType = (type) =>
    Object.values(cardDatabase).filter(card => card.card_type === type);